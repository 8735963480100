import { ChassisCompProps } from '@csa-core/advisor.controlsystemcore'; // "../../implementation/ImplGeneral";
import { ActBtnInfo, LayoutActionType } from '@csa-core/advisor.controlsystemcore'; // "../../types/LayoutActions";
import { DfltActBtnSpecs } from "../../types/LayoutActions";
import { Rack } from '@csa-core/advisor.controlsystemcore'; // "../../types/ProjectTypes";
import { getLocCenter, offsetLoc } from '@csa-core/advisor.controlsystemcore'; // "../../util/GeneralHelpers";
import SnapChassisComp from "./SnapChassisComp";
import { snapCanExtendChassis, SnapPlatformDetails } from '@csa-core/advisor.controlsystemcore'; // "./SnapGeneralImpl";


const _clientMap = new Map<string, SnapPlatformDetails>();

const _getPlatformDtls = (platform: string): SnapPlatformDetails => {
    const dtls = _clientMap.get(platform);
    if (dtls) return dtls;

    throw new Error('Unregistered platform attempted to use snap: ' + platform);
}

export const snapGetActionBtnInfo = (
    action: LayoutActionType,
    rack: Rack,
    slotNum: number): ActBtnInfo => {

    const slots = rack.chassis.modules.length;
    if (slotNum > slots) {
        throw new Error('Invalid slotNUm in snapGetActionBtnInfo!');
    }

    // If the slot requested is 1 to the right
    // of our chassis' last ACTUAL slot...
    if (slotNum === slots) {

        // See if the chassis can be extended
        // with another module. If so...
        if (snapCanExtendChassis(rack.chassis)) {

            // Get platform details...
            const dtls = _getPlatformDtls(rack.chassis.platform);

            // Get the location of the last actual slot.
            const lastSlotLoc = { ...rack.chassis.layout.slotLocs[slots - 1] };
            offsetLoc(lastSlotLoc, rack.ptOrg);

            // Start our 'x' (extra) slot as a copy of that.
            const xSlotLoc = { ...lastSlotLoc };

            // Place it's left side at the right
            // side of the last real slot.
            xSlotLoc.x += lastSlotLoc.width;

            // Set its width to be the platform's default.
            xSlotLoc.width = dtls.defaultXSlotWidth * dtls.imgScaleFactor;

            // Position the act btn pt inside.
            const pt = getLocCenter(xSlotLoc);
            pt.y += DfltActBtnSpecs.height;

            // And return our btn info.
            return {
                action: action,
                chassis: rack.chassis,
                slot: slotNum,
                ctrPt: pt
            };
        }
        else {
            throw new Error('Invalid extension attempt in snapGetActionBtnInfo!');
        }
    }

    const slotLoc = { ...rack.chassis.layout.slotLocs[slotNum] };
    offsetLoc(slotLoc, rack.ptOrg);
    const pt = getLocCenter(slotLoc);
    pt.y += DfltActBtnSpecs.height;
    return {
        action: action,
        chassis: rack.chassis,
        slot: slotNum,
        ctrPt: pt
    };
}

export const snapGetChassisRenderer = (): React.FC<ChassisCompProps> => {
    return SnapChassisComp;
}