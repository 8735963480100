import React from 'react';
import { Circle, Rect, Ring } from 'react-konva';
import { Point } from '@csa-core/advisor.controlsystemcore'; // '../types/SizeAndPosTypes';

const _thicknessDiv = 3;
const _outerMargin = 2;

const _outerColor = '#EFEFEF';
const _symFillColor = 'red';

const getThickness = (radius: number): number => {
    return radius / _thicknessDiv;
}

const getCircleProps = (ptCtr: Point, radius: number) => {
    return {
        x: ptCtr.x,
        y: ptCtr.y,
        radius: radius + _outerMargin,
        fill: _outerColor
    };
}

const getRectProps = (ptCtr: Point, radius: number) => {
    const thickness = getThickness(radius);
    const halfThick = thickness / 2;
    const halfWidth = radius - halfThick;
    return {
        x: ptCtr.x,
        y: ptCtr.y,
        width: halfWidth * 2,
        height: thickness,
        offsetX: halfWidth,
        offsetY: halfThick,
        rotation: -45,
        fill: _symFillColor
    };
}

const getRingProps = (ptCtr: Point, radius: number) => {
    const thickness = getThickness(radius);
    return {
        x: ptCtr.x,
        y: ptCtr.y,
        innerRadius: radius - thickness,
        outerRadius: radius,
        fill: _symFillColor
    };
}


interface Props {
    ptCtr: Point;
    radius: number;
}

const NoDropSymbol = (props: Props) => {

    // Display a 'no-drop' symbol centered at the point supplied.

    // A white(ish) Circle surrounds and contains the symbol.
    const circleProps = getCircleProps(props.ptCtr, props.radius);

    // A Rect is used for the symbol's 'crossing line'.
    const rectProps = getRectProps(props.ptCtr, props.radius);

    // A Ring is used for the symbol's outer 'circle'.
    const ringProps = getRingProps(props.ptCtr, props.radius);

    return (
        <>
            <Circle
                {...circleProps}
            />
            <Rect
                {...rectProps}
            />
            <Ring
                {...ringProps}
            />
        </>
    );
}

export default NoDropSymbol;

