import React from "react";
import {
  ModalRequestSpec,
  addButton,
  requestModal,
} from "../../../modals/ModalHelp";
import { Box, Typography } from "@mui/material";
import MicroImage from "../images/MicroIOInfo.png";
import { ModalStatus } from "@csa-core/advisor.controlsystemcore";

const CirclItems = [
  {
    id: 1,
    text: "1",
    top: 18,
    left: 237,
  },
  {
    id: 2,
    text: "2",
    top: 118,
    left: 252,
  },
  {
    id: 3,
    text: "3",
    top: 118,
    left: 532,
  },
];

interface PostionCircleProps {
  text: string;
  top: number;
  left: number;
}

const PostionCircle = ({ text, top, left }: PostionCircleProps) => {
  return (
    <Box
      sx={{
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        backgroundColor: "red",
        position: "absolute",
        top: `${top}px`,
        left: `${left}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "18px",
          lineHeight: "30px",
          textAlign: "center",
          color: "#FFFFFF",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export const displayIoInfoModal = () => {
  const request: ModalRequestSpec = {
    includeButtons: true,
    closeOnInsideClick: false,
    stayOpenOnBackdropClick: false,
    title: "I/O Information",
    width: 1200,
    content: MicroIOInfo,
  };

  addButton(request, "close", ModalStatus.Cancelled, "contained");

  requestModal(request);
};

const MicroIOInfo = () => {
  return (
    <>
      <Box
        display="flex"
        sx={{
          // justifyContent : "space-between",
          gap: "16px",
          width: "100%",
        }}
      >
        <Box
          display="flex"
          sx={{
            position: "relative",
          }}
        >
          {CirclItems.map((item) => (
            <PostionCircle
              key={item.id}
              text={item.text}
              top={item.top}
              left={item.left}
            />
          ))}

          <img src={MicroImage} height={250} width={800} />
        </Box>
        <Box
          display="flex"
          sx={{
            maxWidth: "300px",
            flexDirection: "column",
            gap: "8px",
            pt: "32px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "22px",
            }}
          >
            1. Embedded I/O is prioritized
          </Typography>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "22px",
            }}
          >
            2. Plug-in I/O is second priority
          </Typography>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "22px",
            }}
          >
            3. Expansion I/O is third priority, specific expansion cards can be
            selected in advanced mode.
          </Typography>
        </Box>
      </Box>
    </>
  );
};
