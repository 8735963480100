import React from 'react';
import { LogRender } from '@csa-core/advisor.controlsystemcore'; // '../types/Globals';
import { Chassis, NO_SLOTID, SelectableDevice } from '@csa-core/advisor.controlsystemcore'; // '../types/ProjectTypes';
import { logger } from '@csa-core/advisor.controlsystemcore'; // '../util/Logger';
import './ProjectTree.scss';


interface Props {
	chassis: Chassis;
	slotId: string;
	device: SelectableDevice | undefined;
	showSelected: boolean;
	slotRange: boolean;
	altLabel?: string;
	onItemClick: (chassis: Chassis, device: SelectableDevice | undefined) => void;
}

const ChassisSlotChild = (props: Props) => {

	const devText = props.altLabel
		? props.altLabel
		: props.device
			? props.device.catNo
			: props.slotRange ? '<empty slots>' : '<empty slot>';

	const styleAttrs = {
		//background: (props.device && props.device.selected) ? 'rgba(33, 150, 243, 0.15)' : 'none'
		background: (props.showSelected) ? 'rgba(33, 150, 243, 0.15)' : 'none'
	};

	const onClick = () => {
		// NOTE: Replace bring-into-view functionality!!
		props.onItemClick(props.chassis, props.device);
	}

	if (LogRender.ProjTree) {
		logger.logRender('ChassisSlotChild: ' + props.chassis.name + ' - ' + props.slotId);
	}

	// If we do NOT have a slot ID (NO_SLOTID), display nothing.
	const slotID = (NO_SLOTID === props.slotId ? '' : props.slotId);

	const dummyMultiMod = (props.device?.isPlaceholder);

	return (
		<>
		{
		dummyMultiMod
			?
			null
			:
			<div
				className="proj-tree-chassis-slot-child"
				onClick={onClick}
				style={{ ...styleAttrs }}
			>
				<div className="proj-tree-chassis-slot-child-slot">
					<span>{slotID}</span>
				</div>
				<div className="proj-tree-chassis-slot-child-content">
					<span>{devText}</span>
				</div>
			</div>
		}
		</>
	);
}

export default React.memo(ChassisSlotChild);

