import { GeneralUIImplBase } from "../../../implementation/GeneralUIImplBase";
import { canExtendChassis, ChassisCompProps, getSlotLocation, LayoutMode } from '@csa-core/advisor.controlsystemcore'; // "../../../implementation/ImplGeneral";
import { ActBtnInfo, LayoutActionType } from '@csa-core/advisor.controlsystemcore'; // "../../../types/LayoutActions";
import {  DfltActBtnSpecs } from "../../../types/LayoutActions";
import { Chassis, ChassisProject, Rack, SelectableDevice } from '@csa-core/advisor.controlsystemcore'; // "../../../types/ProjectTypes";
import FlexHAChassisComp from "../components/FlexHAChassisComp";
import { snapConfigureChassis } from "../../snap/SnapChassisConfig";
import { flexHAGetLayoutInfo } from '@csa-core/advisor.controlsystemcore'; // "./FlexHALayout";
import { getLocCenter, isEmptyLoc, offsetLoc } from '@csa-core/advisor.controlsystemcore'; // "../../../util/GeneralHelpers";
import { PlatformFlexHA } from '@csa-core/advisor.controlsystemcore'; // "../../PlatformConstants";
import { EngInfoModule } from '@csa-core/advisor.controlsystemcore'; // "../../../engData/EngineeringInfo";
import { flexHADoesSlotQualifyForCopy } from '@csa-core/advisor.controlsystemcore'; // "./FlexHAChassis";
import { flexHAGetPlatformDetails } from '@csa-core/advisor.controlsystemcore'; // "./FlexHAGeneralImpl";
import { LocAndSize } from '@csa-core/advisor.controlsystemcore'; // "../../../types/SizeAndPosTypes";
import { getBankInfoFromSlot } from '@csa-core/advisor.controlsystemcore'; // "../../../model/ChassisProject";

const _getLastOccupiedSlotLoc = (chassis: Chassis): LocAndSize => {

    // Walk all possible slot idx's for the chassis. For each...
    for (let slotIdx = chassis.modules.length - 1; slotIdx >= 0; slotIdx--) {

        // Call a helper to give us the loc. We CAN'T simply
        // look in the chassis' layout object any longer
        // because that now only contains slotLocs for
        // slots on bank 0. We COULD call the generic getSlotLocation
        // function here, but since we know who we are, we'll
        // call our own implementation of that and save the route.
        const loc = getSlotLocation(chassis, slotIdx);

        // If the loc is empty (0,0,0,0), the slot is 'occupied'
        // by the right half of a duplex module. Keep moving.
        // Note also that the get-loc function above gives us
        // a copy of the actual loc (in whichever bank's layout
        // that was found). So, we don't need to copy it again.
        if (!isEmptyLoc(loc)) {
            //return { ...loc };
            return loc;
        }
    }

    throw new Error('Unexpected ERROR in _getLastOccupiedSlotLoc!');
}

const _getAddCopyActionBtnInfo = (action: LayoutActionType, rack: Rack, slotNum: number): ActBtnInfo => {
    // If the slot requested is 1 to the right
    // of our chassis' last ACTUAL slot...
    const slots = rack.chassis.modules.length;
    if (slotNum === slots) {

        // See if the chassis can be extended
        // with another module. If so...
        if (canExtendChassis(rack.chassis)) {

            // Get platform details...
            const dtls = flexHAGetPlatformDetails();

            // Get the location of the last actual slot.
            const lastSlotLoc = _getLastOccupiedSlotLoc(rack.chassis);
            offsetLoc(lastSlotLoc, rack.ptOrg);

            // Start our 'x' (extra) slot as a copy of that.
            const xSlotLoc = { ...lastSlotLoc };

            // Place it's left side at the right
            // side of the last real slot.
            xSlotLoc.x += lastSlotLoc.width;

            // Set its width to be the platform's default.
            xSlotLoc.width = dtls.defaultXSlotWidth * dtls.imgScaleFactor;

            // Position the act btn pt inside.
            const pt = getLocCenter(xSlotLoc);
            pt.y += DfltActBtnSpecs.height;

            // And return our btn info.
            return {
                action: action,
                chassis: rack.chassis,
                slot: slotNum,
                ctrPt: pt
            };
        }
        else {
            throw new Error('Invalid extension attempt in flexHAGetActionBtnInfo()::_getAddCopyActionBtnInfo()!');
        }
    }

    const slotLoc = getSlotLocation(rack.chassis, slotNum);

    offsetLoc(slotLoc, rack.ptOrg);
    const pt = getLocCenter(slotLoc);
    pt.y += DfltActBtnSpecs.height;
    return {
        action: action,
        chassis: rack.chassis,
        slot: slotNum,
        ctrPt: pt
    };
}

const _getGeneralActionBtnInfo = (action: LayoutActionType, rack: Rack, slotNum: number): ActBtnInfo => {

    let tip: string | undefined = undefined;
    const slotLoc = getSlotLocation(rack.chassis, slotNum);

    offsetLoc(slotLoc, rack.ptOrg);
    const pt = getLocCenter(slotLoc);
    pt.y += DfltActBtnSpecs.height;
    if (action === LayoutActionType.MakeDuplex ||
        action === LayoutActionType.MakeSimplex) {
        // This is some work... 
        // Get the bank from the slot
        const bankInfo = getBankInfoFromSlot(rack.chassis, slotNum);
        // Get the layout for the bank
        const layout = flexHAGetLayoutInfo(rack.chassis, bankInfo.bankNum);
        // Get the index used for the Terminal Block Loc
        const layoutSlotIdx = slotNum - bankInfo.startSlot;
        // Get the Terminal Block Loc
        const tbLoc = { ...layout.ioTBLocs[layoutSlotIdx] };
        // Offset it 
        offsetLoc(tbLoc, rack.ptOrg);
        // Get the center...
        const ptCenter = getLocCenter(tbLoc);
        // Set our x and y
        pt.x = ptCenter.x - 5;
        pt.y = ptCenter.y;
        // Set our tip.
        tip = 'Convert to Simplex Wiring';
        // If we are a Simplex Slot (ie Make Simplex a Duplex)
        if (action === LayoutActionType.MakeDuplex) {
            // Update our x to the Right Edge of 
            // the Simplex Terminal Block Loc.
            pt.x = tbLoc.x + tbLoc.width - 5;
            // Set our tip.
            tip = 'Convert to Duplex Wiring';
        }
    }

    return {
        action: action,
        chassis: rack.chassis,
        slot: slotNum,
        ctrPt: pt,
        tip: tip,
    };
}


class FlexHAGeneralUIImpl extends GeneralUIImplBase {

    getChassisRenderer(): React.FC<ChassisCompProps> {

        return FlexHAChassisComp;
    }


    getActionBtnInfo(
        action: LayoutActionType,
        rack: Rack,
        slotNum: number): ActBtnInfo {

        // Note: We allow the slot number to be the
        // 'extension slot' index, but NOT beyond.
        const slots = rack.chassis.modules.length;
        if (slotNum > slots) {
            throw new Error('flexHAGetActionBtnInfo(): Invalid slot number passed in!');
        }

        // These may include the Extension Slot
        if (action === LayoutActionType.AddModule ||
            action === LayoutActionType.ModeCopy)
            return _getAddCopyActionBtnInfo(action, rack, slotNum);

        // Any other actions that are module specific.
        return _getGeneralActionBtnInfo(action, rack, slotNum);
    }


    hasGetActBtnInfo(__platform: string): boolean {

        return true;
    }

    configureChassis(
        project: ChassisProject,
        platform: string,
        selectChassisCallback: (chassis: Chassis | undefined) => void,
        selectDeviceCallback: (device: SelectableDevice | undefined) => void,
        contentChangedCallback: () => void,
        chassis?: Chassis
    ): void {

        return snapConfigureChassis(
            project,
            platform,
            selectChassisCallback,
            selectDeviceCallback,
            contentChangedCallback,
            chassis
        );
    }

    doesSlotQualifyForBtnAction(
        mode: LayoutMode,
        type: LayoutActionType,
        chassis: Chassis,
        slotNum: number): boolean {

        if (chassis.platform !== PlatformFlexHA)
            throw new Error('flexHADoesSlotQualifyForBtnAction(): Invalid Platform!');

        if (type === LayoutActionType.ModeCopy) {
            if (mode.engInfo && mode.engInfo.isModule) {
                // If the module to copy occupies more than 1 slot...
                if ((mode.engInfo as EngInfoModule).slotsUsed > 1) {
                    if (!flexHADoesSlotQualifyForCopy(chassis, mode.engInfo as EngInfoModule, slotNum)) {
                        return false;
                    }
                }
            }
        }
        else if (type === LayoutActionType.MakeDuplex) {
            // Must be an odd slot number
            if ((slotNum % 2) !== 1)
                return false;

            // Must have a simplex mod in primary slot.
            const mod = chassis.modules[slotNum];
            if (!mod || mod.slotFiller || mod.slotsUsed > 1)
                return false;

            // Must have a simplex in secondary slot.
            const nextMod = chassis.modules[slotNum + 1];
            if (!nextMod || nextMod.slotFiller)
                return false;
        }
        else if (type === LayoutActionType.MakeSimplex) {
            // Must be an odd slot number
            if ((slotNum % 2) !== 1)
                return false;

            // Must have a Duplex mod in primary slot.
            const mod = chassis.modules[slotNum];
            if (!mod || mod.slotsUsed < 2)
                return false;
        }

        return true;
    }
}

export const createFlexHAGeneralUIImpl = (): GeneralUIImplBase => {
    return new FlexHAGeneralUIImpl();
}
