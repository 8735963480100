import React from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { BasicProdInfo } from '@csa-core/advisor.controlsystemcore'; // '../../types/ProjectTypes';


interface Props {
    accyInfo: BasicProdInfo;
}

const DetailsAccyItem = (props: Props) => {

    return (
        <>
            <span className='a-type__body floating-details-accy-cat'>
                {props.accyInfo.catNo + ' '}
            </span>
            <span className='a-type__body'>
                {props.accyInfo.description}
            </span>
        </>
    );

}

export default DetailsAccyItem;

