import { MicroChassis } from "../types/ProjectTypes";
import { PowerUnitType, PowerBreakdown, PowerType } from "../types/PowerTypes";
import { Dimensions } from "../util/DimensionHelp";
import { isPlatformSnapType } from "../util/PlatformHelp";
import { getPlatformPowerType, getPwrValStr} from "../util/PowerHelp";
import { getProductDescription } from "./ChassisProject";
import { iterationSlotMicro } from '../platforms/micro/model/MicroGeneralImpl';

export interface DocLinkInfo {
    text: string;
    url: string;
}

export interface DocLinkGroupInfo {
    platform: string;
    title?: string;
    links: DocLinkInfo[];
}

// Moved back to the UI (same file and location).
//export const makePlatformDocLinkGrp = (platform: string, title = ''): DocLinkGroupInfo => {
//    const grpInfo: DocLinkGroupInfo = {
//        platform: platform,
//        links: new Array<DocLinkInfo>()
//    };

//    if (title.length > 0) {
//        grpInfo.title = title;
//    }

//    const infoPlatform = getPlatformProjectInfo(platform);

//    grpInfo.links.push({
//        text: 'Selection Guide',
//        url: infoPlatform.docSelGuide,
//    });

//    grpInfo.links.push({
//        text: 'Technical Information',
//        url: infoPlatform.docTechInfo,
//    });

//    grpInfo.links.push({
//        text: 'User Manual',
//        url: infoPlatform.docUserManual,
//    });

//    return grpInfo;
//}

export enum DetailGrpType {
    Singleton = 'Singleton',
    Group = 'Group'
}

export interface DetailItem {
    name: string;
    value: string;
}

export interface DetailGroup {
    type: DetailGrpType;
    title: string;
    items: DetailItem[];
    // id is not displayed. Can
    // be used to hang extra info
    // on the group when needed.
    id?: string; 
}


export const makeDetailGroup = (type: DetailGrpType, title = ''): DetailGroup => {
    return {
        type: type,
        title: title,
        items: new Array<DetailItem>()
    };
}

export const addDetailItem = (group: DetailGroup, name: string, value: string) => {
    group.items.push({ name: name, value: value });
}

export const makeSingletonDtlGroup = (name: string, value: string): DetailGroup => {
    const grp = makeDetailGroup(DetailGrpType.Singleton, name);
    addDetailItem(grp, name, value);
    return grp;
}

export const getPowerUsageValue = (
    supplied: number,
    consumed: number,
    unitType: PowerUnitType
): string => {
    const rmn = Math.max(0, supplied - consumed);
    const cnsVal = getPwrValStr(consumed, unitType);
    const rmnVal = getPwrValStr(rmn, unitType);
    return cnsVal + ' used (' + rmnVal + ' remaining)';
}

export const makePowerUsageGrp = (
    platform: string,
    supplied: PowerBreakdown,
    consumed: PowerBreakdown): DetailGroup => {
    const pwrGrp = makeDetailGroup(DetailGrpType.Group, 'Power Consumption');

    // If we have a MOD/SA platform...
    if (isPlatformSnapType(platform)) {
        addDetailItem(pwrGrp, 'MOD',
            getPowerUsageValue(supplied.modPower, consumed.modPower, PowerUnitType.mA));
        addDetailItem(pwrGrp, 'SA',
            getPowerUsageValue(supplied.saPower, consumed.saPower, PowerUnitType.mA));
    }
    else {
        addDetailItem(pwrGrp, '5V',
            getPowerUsageValue(supplied.mAat5V, consumed.mAat5V, PowerUnitType.mA));
        addDetailItem(pwrGrp, '24V',
            getPowerUsageValue(supplied.mAat24V, consumed.mAat24V, PowerUnitType.mA));
        addDetailItem(pwrGrp, 'W',
            getPowerUsageValue(supplied.mWatt, consumed.mWatt, PowerUnitType.mW));
    }
    return pwrGrp;
}

export const makeDevicePowerGrp = (
    platform: string,
    supplier: boolean,
    pwrInfo: PowerBreakdown): DetailGroup => {

    const pwrGrp = makeDetailGroup(DetailGrpType.Group,
        supplier ? 'Power Supplied' : 'Power Consumed');

    const pwrType = getPlatformPowerType(platform);
    if (pwrType === PowerType.PT_ModSA) {
        addDetailItem(pwrGrp, 'MOD',
            getPwrValStr(pwrInfo.modPower, PowerUnitType.mA));
        addDetailItem(pwrGrp, 'SA',
            getPwrValStr(pwrInfo.saPower, PowerUnitType.mA));
    }
    else if(pwrType === PowerType.PT_524mA) {         
        addDetailItem(pwrGrp, '5V',
            getPwrValStr(pwrInfo.mAat5V, PowerUnitType.mA));
        addDetailItem(pwrGrp, '24V',
            getPwrValStr(pwrInfo.mAat24V, PowerUnitType.mA));
        addDetailItem(pwrGrp, 'W',
            getPwrValStr(pwrInfo.mWatt, PowerUnitType.mW));
    }
    else if (pwrType===PowerType.PT_AlwaysOK)
        {
            addDetailItem(pwrGrp, 'N/A','');  
        }
    return pwrGrp;
}

export const getDimDtlValueFor = (mmVal: number): string => {
    const val: string = mmVal + ' mm';
    return val;
}

export const makeDimensionsGrp = (dims: Dimensions): DetailGroup => {
    const dimGrp = makeDetailGroup(DetailGrpType.Group, 'Dimensions');
    addDetailItem(dimGrp, 'Height(y)', getDimDtlValueFor(dims.height));
    addDetailItem(dimGrp, 'Width(x)', getDimDtlValueFor(dims.width));
    addDetailItem(dimGrp, 'Depth(z)', getDimDtlValueFor(dims.depth));
    return dimGrp;
}

export const getIOusageMicro = (chassis?: MicroChassis): DetailGroup => {
    const pluginLength = chassis?.pluginModules?.length ? chassis?.pluginModules?.length : 0;
    const genGrp = makeDetailGroup(DetailGrpType.Group, 'I/O Usage');
    const totModules = chassis?.totalModules || 0
    if(chassis){
        const[totalUsedPluginSlots, totalUsedExpansionSlots ] = iterationSlotMicro(chassis,pluginLength)
        addDetailItem(genGrp, 'Plug-in', totalUsedPluginSlots.toString()+' of '+ pluginLength.toString() );
        addDetailItem(genGrp, 'Expansion', totalUsedExpansionSlots.toString()+' of '+(totModules - pluginLength).toString());
    }

    return genGrp;
}


export const makeAccysGroup = (platform: string, accys: string[]) => {
    const accysGrp = makeDetailGroup(DetailGrpType.Group, 'Accessories');
    accys.forEach(accy => {
        //const prodData = getProductData(platform, accy);
        //const desc = prodData ? prodData.Description : '<description not available>';
        const desc = getProductDescription(platform, accy);
        addDetailItem(accysGrp, accy, desc);
    })
    return accysGrp;
}

//export const getChassisDetails = (chassis: Chassis): DetailGroup[] => {
//    return GetPlatformImpl(chassis.platform).getChassisDetails(chassis);
//}

//export const getDeviceDetails = (device: SelectableDevice): DetailGroup[] => {
//    return GetPlatformImpl(device.platform).getDeviceDetails(device);
//}

//export const makeDeviceStatusGroup = (device: GraphicalDevice): DetailGroup => {
//    device;
//    const statGrp = makeDetailGroup(DetailGrpType.Group, '');
//    addDetailItem(statGrp, 'Product Lifecycle', 'Active');
//    addDetailItem(statGrp, 'LeadTime', 'In-Stock');
//    addDetailItem(statGrp, 'List Price', '$84.24');
//    return statGrp;
//}