import { SettingOption } from "../types/SettingsTypes";
import DefCountriesJSON from "../platforms/clx/data/CLXDefCountries.json"
import DefIndustriesJSON from "../platforms/clx/data/CLXDefIndustries.json"
import { getCountries_API, getIndustries_API} from "../services/selectionAPIs/SelectionApi";
import { ServiceResponse } from '../services/apis/Response';
import { AxiosError } from "axios";
import { raTimeoutMessage } from "../services/apis/ProductApiService";
import { LogRender, UseDefaultCountries, UseDefaultIndustries } from "../types/Globals";
import { logger } from "../util/Logger";
import { PlatformLoadTracker, ApiTypeID } from "../types/APITypes";


//////// COUNTRIES ///////////////////////////////////////////////////////////////

interface GSCountry {
	id: number;
	country: string;
	countryCode: string;
}

// Cache: Map< platform, SettingOption[]>
const _countryCache = new Map<string, SettingOption[]>();

export const getCountries = (platform: string) => {
	// Countries should be loaded before this call!
	const countries = _countryCache.get(platform);
	if (countries)
		return countries;

	throw new Error(`getCountries(): Countries for ${platform} not loaded`);
}

export const loadCountries = (tracker: PlatformLoadTracker) => {
	const countries = _countryCache.get(tracker.platform);
	if (countries)
		return tracker.onAPIResolvedCallBack(ApiTypeID.Countries, tracker);

	_requestCountries(tracker);
}

const _onCountryAPIResolved = (jsonObj: object, defaultJson: boolean, tracker: PlatformLoadTracker) => {
	const countries: SettingOption[] = [];
	const locs = Object.values(jsonObj);
	locs.forEach((loc) => {
		const gsCountry = loc as GSCountry;
		// Validate
		if (gsCountry.country && gsCountry.countryCode) {
			// Do we need to 'sanitize' the strings ???
			//const display = sanitizeNameString(gsCountry.country, true);
			//const id = sanitizeNameString(gsCountry.countryCode, true);
			const display = gsCountry.country;
			const id = gsCountry.countryCode;
			countries.push({ id: id, display: display });
		}
	})

	// If we failed to get the industries and it is NOT
	// the default json (i.e. jsonObj is API data)...
	if (countries.length === 0 && defaultJson === false) {
		// Recurse with the default data.
		_onCountryAPIResolved(DefCountriesJSON, true, tracker);
		return;
	}
	else if (countries.length === 0) {
		// This should not happen. We should ALWAYS
		// have valid default json in the app!!!
		throw new Error('Cannot load countries for ' + tracker.platform);
	}

	// Cache the results
	_countryCache.set(tracker.platform, countries);

	// Mark the tracker complete.
	tracker.countriesLoaded = true;
	tracker.defCountries = defaultJson;

	tracker.onAPIResolvedCallBack(ApiTypeID.Countries, tracker);
}

const _requestCountries = async (tracker: PlatformLoadTracker) => {
	// Check the global to use the Default.
	if (UseDefaultCountries) {
		_onCountryAPIResolved(DefCountriesJSON, true, tracker);
		return;
	}

	try {
		// Call out to the API.
		const result = await getCountries_API(tracker.platform);
		const response = new ServiceResponse(result);
		if (response.isSuccessful() && response.data) {
			_onCountryAPIResolved(response.data, false, tracker);
			return;
		}
	}
	catch (error) {
		// If we timed out, the error will be an AxiosError. If so...
		if (error instanceof AxiosError) {
			// If we timed out, the code will be 'ECONNABORTED' and
			// the message will be raTimeoutMessage (RA_REQUEST_TIMEOUT).
			if (error.code === 'ECONNABORTED' && error.message === raTimeoutMessage) {
				if (LogRender.SelectionAPIs)
					logger.log(`REQUEST TIMED OUT: Request for ${tracker.platform}'s Countries timed out.`);
			}
		}

		// If we are here, use the default.
		_onCountryAPIResolved(DefCountriesJSON, true, tracker);

		return;
	}

	// If we are here, use the default.
	_onCountryAPIResolved(DefCountriesJSON, true, tracker);
}


export const getInstallLocDisplayStr = (platform: string, installLocID: string) => {
	// We should always have countries loaded
	// for the platform before we call this!
	const locs = _countryCache.get(platform);
	if (locs) {
		const ind = locs.find(x => x.id === installLocID);
		if (ind)
			return ind.display;
	}
	else {
		// WCS - Called before countries load is resolved.
		// TEMP - Just return something without crashing.
		if (installLocID === 'US') {
			return 'United States of America (USA)';
		}

		throw new Error(`getInstallLocDisplayStr(): Countries for ${platform} is not loaded.`);
	}

	//  return an empty string
	return '';
}


//////// INDUSTRY ///////////////////////////////////////////////////////////////
export interface GSIndustry {
	id: number;
	name: string;
	description: string;
}

// Cache: Map< platform, arrIndustries>
const _industryCache = new Map<string, SettingOption[]>();


export const getIndustries = (platform: string): SettingOption[] => {
	// Industries for the platform should be loaded!!!
	const industries = _industryCache.get(platform);
	if (industries)
		return industries;

	throw new Error(`getIndustries(): Industry for ${platform} is not loaded.`);
}

export const loadIndustries = (tracker: PlatformLoadTracker) => {
	const industries = _industryCache.get(tracker.platform);
	if (industries)
		return tracker.onAPIResolvedCallBack(ApiTypeID.Industries, tracker);

	// Request the industries from an API.
	_requestIndustries(tracker);
}

const _onIndustryAPIResolved = (jsonObj: object, defaultJson: boolean, tracker: PlatformLoadTracker) => {
	const industries: SettingOption[] = [];
	const locs = Object.values(jsonObj);
	locs.forEach((loc) => {
		const gsIndustry = loc as GSIndustry;
		// Validate
		if (gsIndustry.description && gsIndustry.name) {
			// Do we need to 'sanitize' the strings ???
			//const display = sanitizeNameString(gsIndustry.description, true);
			//const id = sanitizeNameString(gsIndustry.name, true);
			const display = gsIndustry.description;
			const id = gsIndustry.name;
			industries.push({ id: id, display: display });
		}
	})

	// If we failed to get the industries and it is NOT
	// the default json (i.e. jsonObj is API data)...
	if (industries.length === 0 && defaultJson === false) {
		// Recurse with the default data.
		_onIndustryAPIResolved(DefIndustriesJSON, true, tracker);
		return;
	}
	else if (industries.length === 0) {
		// This should not happen. We should ALWAYS
		// have valid default json in the app!!!
		throw new Error('Cannot load industries for ' + tracker.platform);
	}

	// Cache the results
	_industryCache.set(tracker.platform, industries);

	// Mark the tracker complete.
	tracker.industriesLoaded = true;
	tracker.defIndustries = defaultJson;

	return tracker.onAPIResolvedCallBack(ApiTypeID.Industries, tracker);
}

const _requestIndustries = async (tracker: PlatformLoadTracker) => {
	if (UseDefaultIndustries) {
		_onIndustryAPIResolved(DefIndustriesJSON, true, tracker);
		return;
	}

	try {
		// Call out to the API.
		const result = await getIndustries_API(tracker.platform);
		const response = new ServiceResponse(result);
		if (response.isSuccessful() && response.data) {
			_onIndustryAPIResolved(response.data, false, tracker);
			return;
			}
	}
	catch (error) {
		// If we timed out, the error will be an AxiosError. If so...
		if (error instanceof AxiosError) {
			// If we timed out, the code will be 'ECONNABORTED' and
			// the message will be raTimeoutMessage (RA_REQUEST_TIMEOUT).
			if (error.code === 'ECONNABORTED' && error.message === raTimeoutMessage) {
				if (LogRender.SelectionAPIs)
					logger.log(`REQUEST TIMED OUT: Request for ${tracker.platform}'s Industries timed out.`);
			}
		}

		// If we are here, use the default.
		_onIndustryAPIResolved(DefIndustriesJSON, true, tracker);

		return;
	}

	// If we are here, use the default.
		_onIndustryAPIResolved(DefIndustriesJSON, true, tracker);
}

export const getIndustryDisplayStr = (platform: string, industryID: string) => {
	// The industries for this platform should
	// be loaded before anyone calls this!
	const industries = _industryCache.get(platform);
	if (industries) {
		const ind = industries.find(x => x.id === industryID);
		if (ind)
			return ind.display;
	}
	else
		throw new Error(`getIndustryDisplayStr(): Industry for ${platform} is not loaded.`);

	//  return an empty string
	return '';
}
