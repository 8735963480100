import React from 'react';
import './SimpleTabs.scss';
import { SvgIcon } from '@mui/material';
import { getStatusIconInfo } from '../../util/IconHelp';
import { StatusLevel } from '@csa-core/advisor.controlsystemcore'; // '../../types/MessageTypes';


export interface SimpleTabInfo {
    text: string;
    id: number;
    iconMsgLvl?: StatusLevel;
}


interface TabProps {
    info: SimpleTabInfo;
    currentID: number;
    onSelectTab: (id: number) => void;
}

const Tab = (props: TabProps) => {

    const [Icon, iconColor] = getStatusIconInfo(props.info.iconMsgLvl);

    const tabClass = (props.info.id === props.currentID)
        ? 'tab-button tab-button__selected-tab'
        : 'tab-button';

    const onClick = () => {
        if (props.info.id !== props.currentID) {
            props.onSelectTab(props.info.id);
        }
    }


    return (
        <div
            className={tabClass}
            onClick={onClick}
        >
            <span className='tab-button-text'>
                {props.info.text}
            </span>
            {Icon
                ?
                <SvgIcon
                    component={Icon}
                    inheritViewBox
                    sx={{ color: iconColor, stroke: iconColor, fill: iconColor }}
                />
                : null
            }        </div>
    );
}


interface SimpleTabsProps {
    tabInfo: SimpleTabInfo[];
    currentID: number;
    onTabSelected: (id: number) => void;
}

const SimpleTabs = (props: SimpleTabsProps) => {

    let nextKey = 1;

    return (
        <div className='tabs-row'>
            <div className='tabs-row-buttons'>
                {props.tabInfo.map(info => {
                    return (
                        <Tab
                            key={nextKey++}
                            info={info}
                            currentID={props.currentID}
                            onSelectTab={props.onTabSelected}
                        />
                    );
                })}
            </div>
        </div>
        );
}

export default SimpleTabs;

