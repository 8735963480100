

// Version Last Updated: 
// Version 0: CLX,Cplx,Flex,FlexHA,Micro800 initial implementation
// Version 1: 2024.10.9 - Chassis meta data added
export const CSA_VERSION = '1';


export const isVersionOutOfDate = (cfgVersion: string | undefined): boolean => {
    if (!cfgVersion)
        return true;

    // The current format of 'version' is
    // a simple number. If we get into
    // 'major.minor.patch' format, look at
    // Semver (https://www.npmjs.com/package/semver)
    // for comparisons.

    return (parseInt(cfgVersion, 10) < parseInt(CSA_VERSION, 10));
}


export const csaVersionPropName = 'ctrlSysAdvVersion';
export const getVersionFromThumbprint = (thumbprint: string | undefined, allowUndefinedVersion: boolean): string | undefined => {
    if (thumbprint) {
        const idxVer = thumbprint.indexOf(csaVersionPropName);
        if (idxVer > 0) {
            // format: ["versionPropName":"1",]
            const idxStart = thumbprint.indexOf(':', idxVer) + 2;
            let idxEnd = thumbprint.indexOf(',', idxVer) - 1;
            if (idxStart < idxEnd) {
                const version = thumbprint.substring(idxStart, idxEnd);
                return version;
            }
            else if (idxStart > 0 && idxEnd < 0) {
                // Last prop in the json, end at '}'
                idxEnd = thumbprint.indexOf('}', idxVer) - 1;
                if (idxStart < idxEnd) {
                    const version = thumbprint.substring(idxStart, idxEnd);
                    return version;
                }
            }
        }
    }

    // Depending on the situation, allow
    // an undefined to be returned.
    if (allowUndefinedVersion)
        return undefined;

    return '0';
}

