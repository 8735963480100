import {
    PlatformCLX,
    PlatformCpLX,
    PlatformFlex
} from "../platforms/PlatformConstants";
import {
    isSupportedPlatform,
    getFamilyFromPlatform,
} from "../platforms/PlatformIDs";
//import { selectPlatform } from "../platforms/PlatformSelector";
import { LoadPlatform } from "../services/selectionAPIs/PlatformDataLoader";
import { onPlatformLoadedCallback } from "../types/APITypes";
import { AllowTestPlatformLoads } from "../types/Globals";
import { LoadStatus } from "../types/ProjectTypes";
import { coreDisplayAlertMsg } from "../uiInterfaces/UIMessenger";
import { logger } from "./Logger";

export type PlatformStatusMap = Map<string, LoadStatus>;
export type selectPlatformCallback = (platform: string) => void;

export interface PlatformInfo {
    startPlatform: string;
    platStat: PlatformStatusMap;
    allComplete: boolean;
    anyErrors: boolean;
}

export const makeEmptyPlatformInfo = (): PlatformInfo => {
    return {
        startPlatform: '',
        platStat: new Map<string, LoadStatus>(),
        allComplete: false,
        anyErrors: false
    }
}

const _resetPlatformInfo = (info: PlatformInfo) => {
    info.startPlatform = '';
    info.platStat.clear();
    info.allComplete = false;
    info.anyErrors = false;
}

export const addToPlatformInfo = (info: PlatformInfo, platform: string) => {
    if (!info.platStat.has(platform)) {
        info.platStat.set(platform, LoadStatus.Startup);
    }
}

const _setAllLoadStatPending = (info: PlatformInfo) => {
    const platformsToChange: string[] = new Array<string>();
    info.platStat.forEach((stat, platform) => {
        if (stat !== LoadStatus.Pending) {
            platformsToChange.push(platform);
        }
    });
    platformsToChange.forEach(platform => {
        info.platStat.set(platform, LoadStatus.Pending);
    });
}

// List all platforms we're interested in testing. These
// aren't actually used unless the AllowTestPlatformLoads
// global is set to true.
const _allTestPlatforms: string[] = [PlatformCLX, PlatformCpLX];


// Primary work function.
export const prepAndLoadPlatforms = (
    info: PlatformInfo,
    defaultPlatform: string,
    onPlatformLoaded: onPlatformLoadedCallback
) => {

    // Sanity check that we got default platform. 
    if (defaultPlatform) {

        // Make sure the info starts clean (empty);
        _resetPlatformInfo(info);

        // Add the specified default FIRST.
        addToPlatformInfo(info, defaultPlatform);

        // Then, if we're allowing test platforms...
        if (AllowTestPlatformLoads) {
            // Add them as well. Note that any
            // dups will just be skipped over.
            _allTestPlatforms.forEach(p => {
                if (isSupportedPlatform(p)) {
                    addToPlatformInfo(info, p);
                }
                else {
                    throw new Error('ERROR: Invalid test platform!');
                }
            })
        }

        // Set all entries to pending.
        _setAllLoadStatPending(info);

        // Finally, request the load of each platform,
        // passing the loader the callback function 
        // we were given. It will call it once for
        // each platform after the load has been
        // completed, successfully or not.
        info.platStat.forEach((stat, platform) => {
            LoadPlatform(platform, '', onPlatformLoaded);
        })
    }
    else {
        throw new Error('ERROR: prepPlatformInfoForLoad not given default!');
    }
}

// Update the allComplete and anyErrors properties
// in the platform info object according to current
// load status of all entries.
const _updatePlatformInfo = (info: PlatformInfo) => {

    info.allComplete = true;
    info.anyErrors = false;

    info.platStat.forEach((stat, platform) => {
        switch (stat) {
            case LoadStatus.Pending:
                info.allComplete = false;
                logger.log('Load status still pending for platform: ' + platform);
                break;

            case LoadStatus.Error:
                info.anyErrors = true;
                break;

            case LoadStatus.Ready:
                break;

            default:
                throw new Error('Unexpected status in _updatePlatformInfo. ' +
                    platform + ': ' + stat);
        }
    });
}

// Helper. Updates the platform info's internal
// load status for the specified platform to 'ready'
// or 'error' depending on loadOk arg provided.
export const updatePlatformInfoForLoad = (info: PlatformInfo, platform: string, loadOk: boolean) => {
    if (info.platStat.has(platform)) {
        if (!loadOk) {
            logger.error('Error loading platform: ' + platform);
        }
        const newStat = loadOk ? LoadStatus.Ready : LoadStatus.Error;
        info.platStat.set(platform, newStat);
        _updatePlatformInfo(info);
    }
    else {
        throw new Error('Missing platform entry in _updatePlatformInfoForLoad!');
    }
}

// Collect and return all platform IDs contained
// in the info object that are 'ready' for use.
export const getPlatformsAvailableForUse = (info: PlatformInfo): string[] => {
    // Set up array to collect available platforms.
    const availForUse = new Array<string>();

    // For each in our info...
    info.platStat.forEach((stat, platform) => {

        // If it's ready, add to the avails.
        if (stat === LoadStatus.Ready) {
            availForUse.push(platform);
        }
    });

    return availForUse;
}

// Note: Commented since nothing is referencing this, HOWEVER,
// history shows this was being called in AppView to select
// a platform. Possibly became obsolete(?) or someone deleted
// the call(?). Main reason to remove this is that it references
// selectPlatform(), which is defined in a TSX component. 

//// Helper used to aid in choosing a platform from
//// what's available and ready for use.
//export const selectPlatformFrom = (
//    info: PlatformInfo,
//    defaultPlatform: string,
//    onSelectCallback: selectPlatformCallback
//) => {
//    // Get all platforms that are available for use.
//    const availForUse = getPlatformsAvailableForUse(info);

//    // Sanity check. The 'default' platform provided is
//    // generally the active platform that was requested
//    // via querystring, etc. If we were given that default,
//    // check to see if it's included in what we were told
//    // is available. If NOT...
//    if (defaultPlatform && !availForUse.includes(defaultPlatform)) {
//        // Log relevant info and 'alert' the message as well.
//        let msg = '';
//        if (info.platStat.has(defaultPlatform)) {
//            msg = 'Default (requested) platform (' + defaultPlatform +
//                ') is NOT available. Status: ' + info.platStat.get(defaultPlatform);
//        }
//        else {
//            msg = 'Default (requested) platform (' + defaultPlatform +
//                ') is NOT INCLUDED in platform info provided!';
//        }
//        logger.error(msg);
//        window.alert(msg);
//    }

//    // See how many platforms we have available,
//    // and make our decision how to proceed.
//    switch (availForUse.length) {

//        // If none, display the error message.
//        case 0:
//            coreDisplayAlertMsg('NO platforms are available!');
//            break;

//        // If we have exactly one choice, just
//        // call the callback function provided
//        // with that choice, and without delay.
//        case 1:
//            onSelectCallback(availForUse[0]);
//            break;

//        // More than one choice. Here, we'll call
//        // a helper to pop up a selector. When
//        // that's been dismissed, the helper will
//        // make the callback for us.
//        default:
//            selectPlatform(availForUse, onSelectCallback);
//            break;
//    }
//}

export const getWindowTitleFor = (platform: string): string => {
    if (isSupportedPlatform(platform)) {
        const family = getFamilyFromPlatform(platform);
        const title = family + ' ' + platform + ' Advisor';
        return title;
    }
    return 'Advisor for: ' + platform;
}

export const isPlatformSnapType = (platform: string): boolean => {
    switch (platform) {
        case PlatformCpLX:
        case PlatformFlex:
            return true;
    }
    return false;
}

