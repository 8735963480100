import React from 'react';
import { Rect } from 'react-konva';
import { LocAndSize } from '@csa-core/advisor.controlsystemcore'; // '../types/SizeAndPosTypes';
import { getStageSpacingDtls } from '@csa-core/advisor.controlsystemcore'; // '../util/LayoutHelp';
import { Theme } from '@rockwell-automation-inc/ra-meatball';


interface SelRectProps {
    stroke: string;
    strokeWidth: number;
    dash: number[];
    cornerRadius: number;
}

const getSelRectProps = (): SelRectProps => {
    const scaleFactor = getStageSpacingDtls().scaleFactor;
    return {
        stroke: Theme.palette.primary.main,
        strokeWidth: 5 * scaleFactor,
        dash: [15 * scaleFactor, 10 * scaleFactor],
        cornerRadius: 15 * scaleFactor,
    }
}

let selRctProps: SelRectProps | undefined = undefined;


interface Props {
    selRect: LocAndSize;
}

const SelectedChassisRect = (props: Props) => {

    // Establish rect prop details one time.
    if (!selRctProps) {
        selRctProps = getSelRectProps();
    }

    return (
        <Rect
            listening={false}
            {...props.selRect}
            {...selRctProps}
        />
    );
}

export default SelectedChassisRect;
