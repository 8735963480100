import React from "react";
import { IModalResultCallback, StatusLevel } from '@csa-core/advisor.controlsystemcore'; // "../types/MessageTypes";
import { getStatusIconInfo, ImportedMuiIcon, ReactSVGComp } from "../util/IconHelp";
import { ButtonPropsVariantOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";


//// Constants for most common status returns. ANY
//// other numbers can be used for custom buttons, but
//// these two should be RESERVED for what we would
//// normally think of as OK and CANCELLED.
//export const ModalStatus = {
//    Cancelled: 0,
//    Confirmed: 1,
//    Ok: 2,
//    Yes: 3,
//    No: 4,
//};

//// Callback functions must match standard.
////    status: gives end result of modal
////    data: passes the SAME data object that was sent in
////          with request. This gives the callback the
////          contextual info needed to do whatever it needs
////          to do. Example, after a delete confirmation,
////          what's actually supposed to get deleted.
//export interface IModalResultCallback {
//    (status: number, data?: object): void;
//}

// Button Specification. Includes the text
// we use for the button and the status that will
// returned via callback if the button is clicked.
export interface ModalButtonInfo {

    // The text that will be used on
    // on the button.
    btnText: string;

    // The status that be returned via
    // callback if the button is clicked.
    resultStatus: number;

    variant: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;

    disabled?: boolean;

    // Undefined placement defaults to 'right'
    placement?: 'left' | 'center' | 'right'; 
}

// Interface used to request a Modal in many
// variations for messages, confirmations, etc.,
// including completely custom dialogs.
export interface ModalRequestSpec {
    // Instructions for inclusion of optional
    // buttons at bottom of modal.
    //   if true - button row at bottom will be included,
    //            using button info provided. If none
    //            is provided, default buttons will be
    //            included (CONFIRM and CANCEL).
    //   if false - no buttons will be included,
    //            regardless of buttonInfo content.
    includeButtons: boolean;
    buttonInfo?: ModalButtonInfo[];

    // Optional spec. If not provided, a click inside of the
    // modal or the Enter key WILL close it if it has buttons
    // and will NOT if it doesn't.
    closeOnInsideClick?: boolean;

    // Optional spec. If not provided, a click OUTSIDE of the
    // modal (backdrop click) will close it if it DOES NOT
    // have buttons, and leave it open if it DOES have buttons.
    stayOpenOnBackdropClick?: boolean;

    // Optional specs for desired size of the modal's content,
    // not including extra width/height added by our ModalWrapper
    // for padding, etc.
    width?: number;   // default width will be
                      // used if not provided.
    height?: number;  // auto height if not provided

    // Optional specs for desired modal box position.
    // These are percentages of the browser client window
    // (window.innerWidth, window.innerHeight).
    // If not provided, default of 50 (50%) is used, which
    // centers the box in that client area.
    // NOTE: These have been removed as options for now.
    // They were NOT being used at all, and leaving them
    // in added complexity not worth dealing with when
    // modals auto-scale to fit available space.
    //xCenterPct?: number;
    //yCenterPct?: number;

    // Title at top of Modal, if provided. If
    // not, no title will be included.
    title?: string;

    // Message text. Used by default content provider.
    // Available for use by custom content providers.
    message?: string;

    // Optional icon information. If provided, icon
    // will be displayed left of primary modal content.
    // If not provided, no icon will be included, and
    // primary content will use full modal width.
    // Icon can be in either SVG or MUI format, using
    // the associated prop.
    iconSvg?: ReactSVGComp;
    iconMui?: ImportedMuiIcon;

    iconColor?: string;

    // Optional callback. Not relevant except for requests
    // that care about the result status (ex: confirmations).
    callback?: IModalResultCallback;

    // Optional data sent in by original requestor.
    // Whatever is sent in will be sent back in any
    // callback call.
    requestorData?: object;

    // Optional alternate content. If provided, will
    // be used in place of default. Vertically, 'content'
    // is placed below the title (if present) and above
    // the buttons row (if buttons are present).
    // Horizontally, content is placed to the right of the
    // icon (if present), otherwise uses full width of modal.
    content?: React.FC<ModalRequestSpec>;   

    // Reserved Purpose - Do NOT set this property manually.
    // Property is set internally by ModalWrapper prior
    // to rendering, then available to all content providers.
    // Content provider can call this function to close
    // the modal, returning (via callback) the given status.
    endModal?: (status: number) => void;

    // Reserved Purpose - Do NOT set this property manually.
    // Property is set internally by ModalWrapper, and is
    // then available to all content providers.
    // Content provider can call this function to cause
    // the entire modal to be rendered again.
    fullRender?: () => void;
}


// Function used to actually launch a modal as 
// specified in the incoming props.
export const requestModal = (props: ModalRequestSpec) => {

    // Route the request to the registered handler.
    modalRequestHandler(props);
}


// Quick helper to create a basic message-type (alert-like)
// Modal request spec. Note that the caller can futher modify
// the spec after we return for such purposes as spec'ing
// custom content, a custom icon, etc. Alternatively, a
// ModalRequestSpec object can be created and filled manually
// (without using this helper). which can then be passed directly
// to the requestModal function.
export const createBasicMessageRequest = (
    msg: string,
    title?: string,
    iconLevel = StatusLevel.NA
): ModalRequestSpec => {
    const [Icon, iconColor] = getStatusIconInfo(iconLevel);
    return {
        includeButtons: false,
        title: title,
        message: msg,
        iconSvg: Icon,
        iconColor: iconColor
    };
}


// Quick helper to create a basic confirmation (ok-cancel like)
// Modal request spec. Note that the caller can futher modify
// the spec after we return for such purposes as spec'ing 
// custom content, a custom icon, add customized buttons, etc.
// Alternatively, a ModalRequestSpec object can be created and
// filled manually (without using this helper), which can then
// be passed directly to the requestModal function.
export const createBasicConfirmRequest = (
    msg: string,
    title?: string,
    callback?: IModalResultCallback,
    data?: object,
    iconLevel = StatusLevel.NA
): ModalRequestSpec => {
    const [Icon, iconColor] = getStatusIconInfo(iconLevel);
    return {
        includeButtons: true,
        title: title,
        message: msg,
        iconSvg: Icon,
        iconColor: iconColor,
        callback: callback,
        requestorData: data
    };
}

// Helper used to add a custom button definition to a modal req spec.
// TWO things to note:
//    1. Buttons will NEVER be included unless the .includeButtons
//       property is also set to true.
//    2. If ANY button definitions are included in the .buttonInfo
//       array, then ONLY THOSE buttons will be used. If you want
//       to do something like add a third button to the basic two
//       defaults used for confirmations (CONFIRM and CANCEL), you
//       need to add ALL THREE definitions to the array.
export const addButton = (
    req: ModalRequestSpec,
    text: string,
    rsltStat: number,
    variant: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>,
    placement: 'left' | 'center' | 'right' = 'right'): ModalButtonInfo => {
    if (!req.buttonInfo) {
        req.buttonInfo = new Array<ModalButtonInfo>();
    }
    const newButtonInfo: ModalButtonInfo = {
        btnText: text,
        resultStatus: rsltStat,
        variant: variant,
        placement: placement
    };
    req.buttonInfo.push(newButtonInfo);
    return newButtonInfo;
}


// BEGIN - Internal Use ONLY
// Type requirement for any function that will act
// as a modal request handler. There will only be
// one of these, located at the top app level.
interface IModalRequestHandler {
    (props: ModalRequestSpec): void;
}

// Default implmenentation of the request handler.
let modalRequestHandler: IModalRequestHandler = (): void => {
    // NOTE: This should be one of the only places(if not
    // THE only place) where a call to window.alert is
    // still appropriate, since there's no modal handler
    // available to actually show us the message in
    // our normal manner.
    window.alert('NO function has been registered to handle Modal requests!');
    return;
};

// Function used to register our actual handler.
export const RegisterModalRequestHandler = (func: IModalRequestHandler) => {
    modalRequestHandler = func;
}
// END - Internal Use ONLY

