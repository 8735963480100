import { IConfigSpec, initializeConfig } from "platform-services";

const defGetApiFunction = (api: any): string => {
    throw new Error('Environment not set.');
}

export interface IEnvConfig {
    config: IConfigSpec;
    getApiUrl: (api: any) => string;
}

let envConfig: IEnvConfig = {
    config: { ...initializeConfig() },
    getApiUrl: defGetApiFunction,
};

// EXTERNAL Export from the Core.
export const registerCoreEnvConfig = (extEnvConfig: IEnvConfig) => {
    envConfig = extEnvConfig;
}

export const coreGetEnvConfig = (): IEnvConfig => {
    return envConfig;
}