import React from 'react';
import './DocumentLinks.scss';
import '../../styles/Meteor.scss'
import { DocLinkInfo } from '@csa-core/advisor.controlsystemcore'; // '../../model/DeviceDetail';


interface Props {
    info: DocLinkInfo;
}

const DocumentLink = (props: Props) => {

    return (
        <a
            className="document-link a-type__subTitle3"
            href={props.info.url}
            target="_blank"
            rel="noreferrer"
        >
            {props.info.text}
        </a>
    );
}

export default DocumentLink;
