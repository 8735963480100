import React, { useRef, useState } from 'react';
import Konva from 'konva';
import { Group, Line, Rect } from 'react-konva';
import { DfltActBtnSpecs } from '../../types/LayoutActions';
import { ActBtnSpecs, ActBtnInfo } from '@csa-core/advisor.controlsystemcore'; // '../../types/LayoutActions';
import { Point } from '@csa-core/advisor.controlsystemcore'; // '../../types/SizeAndPosTypes';
import { logger } from '@csa-core/advisor.controlsystemcore'; // '../../util/Logger';
import { LogRender } from '@csa-core/advisor.controlsystemcore'; // '../../types/Globals';
import { useAppDispatch } from '@csa-core/advisor.controlsystemcore'; // '../../redux/hooks';
import { setLayoutViewTip, ViewTipInfo } from '@csa-core/advisor.controlsystemcore'; // '../../redux/slices/LayoutViewTipSlice';
import { establishActBtnScale } from './ActionButtons';

const getHalfLineLen = (btnDim: number, gapPct: number): number => {
    return (btnDim / 2) - (btnDim * gapPct);
}

const getHorzLinePts = (ptCtr: Point, specs: ActBtnSpecs): number[] => {
    const halfLen = getHalfLineLen(specs.width, specs.lineGapPct)
    const pts = new Array<number>();
    pts.push(ptCtr.x - halfLen);
    pts.push(ptCtr.y);
    pts.push(ptCtr.x + halfLen);
    pts.push(ptCtr.y);
    return pts;
}

const getVertLinePts = (ptCtr: Point, specs: ActBtnSpecs): number[] => {
    const halfLen = getHalfLineLen(specs.height, specs.lineGapPct)
    const pts = new Array<number>();
    pts.push(ptCtr.x);
    pts.push(ptCtr.y - halfLen);
    pts.push(ptCtr.x);
    pts.push(ptCtr.y + halfLen);
    return pts;
}

interface Props {
    btnInfo: ActBtnInfo;
    onClick: (actBtnInfo: ActBtnInfo) => void;
    btnSpecs?: ActBtnSpecs;
}

const AddModActionButton = ( props : Props ) => {
    const [ isMouseover, setIsMouseover ] = useState( false );
    const dispatch = useAppDispatch();

    // 2024.8 Depending on the initial stage scale
    // for the platform, adjust our button specs.
    const specsScale = useRef<number>( establishActBtnScale( props.btnInfo.chassis.platform ) );
    const specRef = props.btnSpecs ? props.btnSpecs : DfltActBtnSpecs;
    const specs : ActBtnSpecs = {
        ...specRef,
        width: specRef.width * specsScale.current,
        height: specRef.height * specsScale.current
    };

    const ptCtr = props.btnInfo.ctrPt;

    const rctProps = {
        x: ptCtr.x - (specs.width / 2),
        y: ptCtr.y - (specs.height / 2),
        width: specs.width,
        height: specs.height,
        // Most buttons will have same height.
        cornerRadius: specs.height * specs.cornerRadPct
    };

    const commonProps = {
        stroke: isMouseover ? specs.mouseoverStroke : specs.stroke,
        fill: isMouseover ? specs.mouseoverFill : specs.fill,
        strokeWidth: specs.strokeWidth
    };


    const onPointerDown = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;
        e.evt.stopPropagation();
        dispatch(setLayoutViewTip(undefined));
    }

    const onPointerClick = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;
        e.evt.stopPropagation();

        props.btnInfo.event = e.evt;

        // Then notify our parent we were clicked.
        props.onClick(props.btnInfo);
    }

    const onMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'pointer';
        }
        setIsMouseover(true);

        if (props.btnInfo.tip) {
            const tip: ViewTipInfo = { text: props.btnInfo.tip, rcClient: e.target.getClientRect() };
            dispatch(setLayoutViewTip(tip));
        }
    }

    const onMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'default';
        }
        setIsMouseover(false);
        dispatch(setLayoutViewTip(undefined));
    }

    if (LogRender.ActButtons) {
        logger.logRender('AddModActionButton');
    }

    return (
        <Group
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onPointerDown={onPointerDown}
            onPointerClick={onPointerClick}
        >
            <Rect
                {...rctProps}
                {...commonProps}
            />
            <Line
                points={getHorzLinePts(ptCtr, specs)}
                {...commonProps}
            />
            <Line
                points={getVertLinePts(ptCtr, specs)}
                {...commonProps}
            />
        </Group>
    );

}

// Note: There's no point in memo-izing these at
// this point, and there is a small cost we avoid.
// At present, each time we're used, we're getting
// a NEW btnInfo prop, so we'd never actually use
// the memo as things are currently set up.
export default AddModActionButton;
//export default React.memo(AddModActionButton);

