import { AxiosError } from "axios";
import {
    EngInfoComponent,
    EngInfoMap,
    EngInfoPowerSupply,
    IEngDataComponent,
    makeEngInfoFrom
} from "../engData/EngineeringInfo";
import {
    engDataPostload,
    engDataPreload,
    getDfltEngData,
} from "../implementation/ImplEngData";
import { raTimeoutMessage } from "../services/apis/ProductApiService";
import { ServiceResponse } from "../services/apis/Response";
import { getEngineeringData_API } from "../services/selectionAPIs/SelectionApi";
import { PlatformLoadTracker, ApiTypeID } from "../types/APITypes";
import {
    EngInfoPackage,
    makeEmptyEngDataPackage,
    PSMatches,
    makeEmptyPSMatches,
    PSMap,
    InVltgToPSMatches,
    IOModuleMap,
    IOTypeAndQty
} from "../types/EngDataTypes";
import { LogRender, UseDefaultEngineeringData } from "../types/Globals";
import { IOTypeInfo } from "../types/IOModuleTypes";
import { PSInputVoltage } from "../types/PowerTypes";
import { EnvRating, isKnownDeviceType, LoadStatus } from "../types/ProjectTypes";
import { getIOModulesInPkg, microgetIOModulesInPkg, getPluginModulesInPkg } from "../util/EngInfoHelp";
import { getBasicIOTypesFromSet } from "../util/IOModuleHelp";
import { logger } from "../util/Logger";


const _minSrcDataEntries = 2;
const _engPackageCache = new Map<string, EngInfoPackage>();

const _doAccessoryCheck = false;

const _checkAccys = (map: EngInfoMap) => {
    logger.warn('** START ** _checkAccys **');

    const accys = new Set<string>();
    const refs = new Set<string>();

    map.forEach((info,) => {
        info.collectAccyPropVals(refs, accys);
    })

    refs.forEach(ref => {
        if (!accys.has(ref)) {
            logger.warn('Referenced accy not defined: ' + ref);
        }
    });

    logger.warn('** END ** _checkAccys **');
}


const _loadEngInfoMap = (jsonData: object, infoMap: EngInfoMap): boolean => {

    // Sanity check. The map we're passed SHOULD
    // be empty. If not, we won't error out. Instead...
    if (infoMap.size > 0) {

        // Log an error to the console and
        // clear out the existing content.
        logger.error('WARNING: _loadEngInfoMap given map with existing content!');
        infoMap.clear();
    }

    // Cast incoming object as 
    // an array of EngData objects.
    const srcData = jsonData as IEngDataComponent[];

    // If we can...
    if (srcData) {

        // See how many entries it has.
        const numEntries = srcData.length;

        // If enough...
        if (numEntries >= _minSrcDataEntries) {

            // Count any entries without CatNo's.
            let numCatless = 0;

            // For each entry...
            for (let idx = 0; idx < numEntries; idx++) {

                // Get it.
                const entry = srcData[idx];

                // And its catalog number.
                const cat = entry.CatalogNumber;

                // If it HAS one...
                if (cat && (cat.length > 0)) {

                    // Check for dup. If we haven't seen
                    // this catalog number before...
                    if (!infoMap.has(cat)) {

                        // Sanity check for, and log any with
                        // types that we don't recognize.
                        if (!isKnownDeviceType(entry.Type)) {
                            logger.warn('_loadEngDataMap - CatNo with unknown/unsupported type: ' + cat + ', ' + entry.Type);
                        }

                        // Add the entry to our
                        // map, keyed by CatNo.
                        infoMap.set(cat, makeEngInfoFrom(entry));
                    }
                    else {
                        // Log the dup info.
                        logger.warn('Skipping dup entry in _loadEngInfoMap: ' + cat);
                    }
                }
                else {
                    // No CatNo???
                    numCatless += 1;
                }
            }

            // If we saw any, log the number of
            // entries missing catalog numbers.
            if (numCatless > 0) {
                logger.warn('_loadEngDataMap skipped ' + numCatless + ' entry(s) without CatNo(s)!');
            }

            // If the map still contains
            // enough entries, return success.
            if (infoMap.size >= _minSrcDataEntries) {
                return true;
            }
        }
    }

    // Fail.
    return false;
}


const _psInputVltgs: PSInputVoltage[] = [
    PSInputVoltage.DC24V,
    PSInputVoltage.DC48V,
    PSInputVoltage.DC125V,
    PSInputVoltage.AC120V,
    PSInputVoltage.AC240V
];


const _envs: EnvRating[] = [
    EnvRating.Standard,
    EnvRating.ConformalCoated,
    EnvRating.ExtTemperature
];


const _makeEmptyVltgToMatchesMap = () => {
    const vltgToMatches = new Map<PSInputVoltage, PSMatches>();

    _psInputVltgs.forEach(vltg => {
        vltgToMatches.set(vltg, makeEmptyPSMatches());
    });

    return vltgToMatches;
}

const _anyPSMatches = (matches: PSMatches): boolean => {
    return ((matches.singles.length > 0) || (matches.redundants.length > 0));
}

const _trimPSEnvBranch = (vltgToMatches: InVltgToPSMatches): boolean => {

    // Set up an array to hold any voltages that
    // contain no actual power supply matches.
    const unusedVltgs = new Array<PSInputVoltage>();

    // For each input voltage...
    _psInputVltgs.forEach(vltg => {

        // Get the associated 'matches' object.
        const matches = vltgToMatches.get(vltg);

        // If we can...
        if (matches) {

            // and it's empty...
            if (!_anyPSMatches(matches)) {

                // Add it to our 'unused' collection.
                unusedVltgs.push(vltg);
            }
        }
    });

    // Actually remove any that we marked
    // as 'unused' from the provided map.
    unusedVltgs.forEach(vltg => {
        vltgToMatches.delete(vltg);
    })

    // Return our final state, true if we
    // have anything left and false if not.
    return (vltgToMatches.size > 0);
}

const _trimPSMap = (psMap: PSMap) => {

    // Set up an array to hold any environment ratings
    // whose branch, after trimming, is empty.
    const unusedEnvs = new Array<EnvRating>();

    // For each rating we have...
    _envs.forEach(env => {

        // Get the associated map branch.
        const envEntry = psMap.get(env);

        // If we can...
        if (envEntry) {

            // Trim its contents. A return of true from
            // our tells us that the env branch has at
            // least some power supplies in it. If not...
            if (!_trimPSEnvBranch(envEntry)) {

                // Add the env rating to
                // our 'unused' array.
                unusedEnvs.push(env);
            }
        }
    });

    // Finally, remove any envs that were
    // determined to be empty from our map.
    unusedEnvs.forEach(env => {
        psMap.delete(env);
    })
}

const _mapPSInfo = (ps: EngInfoPowerSupply, envBranch: InVltgToPSMatches) => {
    ps.supplyVltg.allSupported.forEach(vltg => {
        const vltgEntry = envBranch.get(vltg);
        if (vltgEntry) {
            if (ps.redundant) {
                vltgEntry.redundants.push(ps);
            }
            else {
                vltgEntry.singles.push(ps);
            }
        }
        else {
            throw new Error('Missing vltg entry in _mapPSInfo!');
        }
    });
}

const _mapPowerSupply = (ps: EngInfoPowerSupply, pkg: EngInfoPackage) => {
    //pkg.allPS.push(ps);
    const envBranch = pkg.mappedPS.get(ps.envInfo.rating);
    if (envBranch) {
        _mapPSInfo(ps, envBranch);
    }
    else {
        throw new Error('Missing env branch in _mapPowerSupply!');
    }
}

const _collectAndMapPowerSupplies = (powerSupplies: EngInfoPowerSupply[], pkg: EngInfoPackage) => {

    // Sanity checks. We should have been given
    // data for AT LEAST one power supply, and the
    // map in the provided pkg should be empty. 
    if ((powerSupplies.length === 0) || (pkg.mappedPS.size > 0)) {
        throw new Error('Invalid call to _mapPowerSupplies!');
    }

    // We COULD dynamically build the map one PS at a time,
    // but we'll instead start with a full structure with each
    // input voltage represented under each environment type.
    // This way, WE control the ORDER of the keys at all levels.
    _envs.forEach(env => {
        pkg.mappedPS.set(env, _makeEmptyVltgToMatchesMap());
    });

    powerSupplies.forEach(ps=> {
        _mapPowerSupply(ps, pkg);
    });

    _trimPSMap(pkg.mappedPS);
}

const _findAndMapPowerSupplies = (pkg: EngInfoPackage) => {

    // Find any/all Power Supply entries in the info map.
    const psEntries = Array.from(pkg.infoMap.values()).filter(entry => entry.isPS);

    // If any...
    if (psEntries.length > 0) {

        // Cast entries to PS objects.
        const asPS = psEntries as EngInfoPowerSupply[];

        // Call our map helper.
        _collectAndMapPowerSupplies(asPS, pkg);
    }
}

const _collectIOModuleInfo = (pkg: EngInfoPackage) => {

    const mapIOTypes = new Map<string, IOTypeAndQty>();
    const ioMods = getIOModulesInPkg(pkg);
    const ioModsMicro = microgetIOModulesInPkg(pkg);
    //Existing Logic of io module only
    ioMods.forEach(mod => {
        mod.pointType.typeData.forEach((type) => {
            mapIOTypes.set(type.type, type);
        });
        pkg.ioModInfo.set(mod.catNo, mod);
    });
    //Micro 800 Logic of emable all io type down for micro 800
    ioModsMicro.forEach(mod => {
        mod.pointType.typeData.forEach((type) => {
            mapIOTypes.set(type.type, type);
        });
        pkg.ioModInfoMicro.set(mod.catNo, mod);
    });
    // Add Plugin Modules
    const ioPlugin = getPluginModulesInPkg(pkg);
    ioPlugin.forEach(mod => {
        pkg.pluginModInfo.set(mod.catNo, mod);
    });

    pkg.ioModTypes = getBasicIOTypesFromSet(mapIOTypes);
}

//Listing both plugin and io modules
export const getPluginModInfoMap = (platform: string): IOModuleMap => {
    const pkg = getEngineeringData(platform);
    return pkg.pluginModInfo;
}


const _applyPlatform = (pkg: EngInfoPackage) => {
    if (pkg.platform) {
        const entries = Array.from(pkg.infoMap.values());
        entries.forEach(entry => {
            entry.platform = pkg.platform;
        });
    }
    else {
        throw new Error('_applyPlatform for pkg without platform!');
    }
}

export const getEngineeringData = (platform: string): EngInfoPackage => {
    if (_engPackageCache.has(platform)) {
        const pkg = _engPackageCache.get(platform);
        if (pkg) {
            return pkg;
        }
    }

    throw new Error('FAILED to getEngineeringData for: ' + platform);
}

export const isEngDataLoaded = (platform: string): boolean => {
    try {
        const pkg = getEngineeringData(platform);
        return (pkg != null);
    }
    catch (__e) {
        return false;
    }
}

export const getIOModInfoMap = (platform: string): IOModuleMap => {
    const pkg = getEngineeringData(platform);
    return pkg.ioModInfo;
}

export const getIOModInfoMapMicro = (platform: string): IOModuleMap => {
    const pkg = getEngineeringData(platform);
    return pkg.ioModInfoMicro;
}

export const getIOModTypes = (platform: string): IOTypeInfo[] => {
    const pkg = getEngineeringData(platform);
    return pkg.ioModTypes;
}

export const getAlternateFromInfoMap = (
    infoMap: EngInfoMap,
    catNo: string,
    needCC: boolean,
    needET: boolean,
    needXTR = false
): string => {

    const info = infoMap.get(catNo);
    if (info && info.isComponent) {
        const compInfo = info as EngInfoComponent;
        return compInfo.getAlternate(needCC, needET, needXTR);
    }
    return '';
}


export const loadEngineeringData = (tracker: PlatformLoadTracker) => {
    const engPkg = _engPackageCache.get(tracker.platform);
    if (engPkg) {
        tracker.onAPIResolvedCallBack(ApiTypeID.EngineeringData, tracker);
    }
    else {
        const pkg = makeEmptyEngDataPackage(tracker.platform);
        _engPackageCache.set(tracker.platform, pkg);
        pkg.status = LoadStatus.Pending;
        _requestEngineeringData(pkg, tracker);
    }
}

const _onEngDataAPIResolved = async (
    pkg: EngInfoPackage,
    jsonObj: object,
    usingDefault: boolean,
    tracker: PlatformLoadTracker) => {

    // Load the package's data map
    // using the JSON provided.
    const infoOk = _loadEngInfoMap(jsonObj, pkg.infoMap);

    // If the result is valid...
    if (infoOk) {
        _collectIOModuleInfo(pkg);
        _findAndMapPowerSupplies(pkg)

        if (_doAccessoryCheck) {
            _checkAccys(pkg.infoMap);
        }

        pkg.status = LoadStatus.Ready;

        // Mark the tracker complete.
        tracker.engineeringDataLoaded = true;
        tracker.defEngineeringData = usingDefault;

        tracker.onAPIResolvedCallBack(ApiTypeID.EngineeringData, tracker);
        return;
    }
    else {
        // We have no data (yet). If we're not ALREADY
        // using default (locally imported) data...
        if (!usingDefault) {

            // See if we can get default data
            // to use from the platform.
            const dfltJSON = getDfltEngData(tracker.platform);

            // If we can...
            if (dfltJSON) {
                // Recurse using the default data.
                await _onEngDataAPIResolved(pkg, dfltJSON, true, tracker);
                return;
            }
        }
    }

    pkg.status = LoadStatus.Error;

    // If we're still here, we can't get
    // anything for this platform.
    throw new Error('Cannot load Engineering Data for ' + tracker.platform);
}

const _requestEngineeringData = async (pkg: EngInfoPackage, tracker: PlatformLoadTracker) => {

    await engDataPreload(tracker.platform, pkg);

    if (!UseDefaultEngineeringData) {
        try {
            // Call out to the API.
            const result = await getEngineeringData_API(tracker.platform);
            const response = new ServiceResponse(result);
            if (response.isSuccessful() && response.data) {
                await _onEngDataAPIResolved(pkg, response.data, false, tracker);
            }
        }
        catch (error) {
            // If we timed out, the error will be an AxiosError. If so...
            if (error instanceof AxiosError) {
                // If we timed out, the code will be 'ECONNABORTED' and
                // the message will be raTimeoutMessage (RA_REQUEST_TIMEOUT) -
                // see productApiService.GetDetailedProduct() for details...
                if (error.code === 'ECONNABORTED' && error.message === raTimeoutMessage) {
                    if (LogRender.SelectionAPIs)
                        logger.log(`REQUEST TIMED OUT: Request for ${tracker.platform}'s Engineering Data timed out.`);
                }
            }
        }
    }

    // If we're still pending... 
    if (pkg.status === LoadStatus.Pending) {
        // See if we can get default
        // JSON data for the platform.
        const dfltJSON = getDfltEngData(tracker.platform);

        // If we can...
        if (dfltJSON) {
            // Continue with the default data.
            await _onEngDataAPIResolved(pkg, dfltJSON, true, tracker);
        }
    }

    // When we get to this point, our status
    // should be Ready if our load worked
    // out one way or another. If so...
    if (pkg.status === LoadStatus.Ready) {

        // Allow the platform to do its postload,
        // postLoad operation(s) if it requires any.
        await engDataPostload(tracker.platform, pkg);

        // Apply the package's platform id to all
        // mapped entries. Note that we want to do
        // this AFTER the post load in case a platform's
        // postload implementation added anything to
        // the map.
        _applyPlatform(pkg);

        //// Load any platform-related comm details.
        //await engDataLoadCommDtls(tracker.platform, pkg);

        // Load complete.
        return;
    }

    // FAIL
    pkg.status = LoadStatus.Error;
    throw new Error('Cannot load Engineering Data for ' + tracker.platform);
}
