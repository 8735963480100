import React from 'react';
import {
    getModuleSlotBreakdown,
    getModuleSlotID,
    LayoutModeType,
} from '@csa-core/advisor.controlsystemcore'; // '../../model/ChassisProject';
import SelectableDeviceComp from '../../components/SelectableDeviceComp';
import { getChassisRenderSpec } from '../../util/LayoutModeHelp';
import { canExtendChassis, ChassisCompProps, getDefaultXSlotWidth, getEmptySlotImage, getSlotID } from '@csa-core/advisor.controlsystemcore'; // '../../implementation/ImplGeneral';
import ChassisElComp from '../common/ChassisElComp';
import { ChassisElementType } from '@csa-core/advisor.controlsystemcore'; // '../common/CommonPlatformTypes';
import SlotIDComp from '../../components/SlotIDComp';
import { LocAndSize } from '@csa-core/advisor.controlsystemcore'; // '../../types/SizeAndPosTypes';
import { getEmptyLoc } from '@csa-core/advisor.controlsystemcore'; // '../../util/GeneralHelpers';


const _addXSlot = (props: ChassisCompProps):
    [show: boolean, loc: LocAndSize, id: string] => {

    // Start with assumption we WON'T 
    // have an 'X' (extra empty) slot.
    let xWidth = 0;

    // If the chassis is a drag target AND
    // has a specified xSlot width...
    if (props.chassis.dragTarget && (props.chassis.xSlotWidth > 0)) {

        // Use it.
        xWidth = props.chassis.xSlotWidth;
    }
    else {
        // Not a drag target. We STILL might show
        // the x-slot if either the chassis is selected,
        // OR if we're in Copy layout mode.
        const xPossible = props.showAsSelected ||
            (props.layoutMode && (props.layoutMode.type === LayoutModeType.Copy));

        // If either AND the chassis can be extended...
        if (xPossible && canExtendChassis(props.chassis)) {

            // Get the default X slot width from
            // the platform, if any.
            xWidth = getDefaultXSlotWidth(props.chassis.platform);
        }
    }

    // If we ended up with a width...
    if (xWidth) {

        // See how many slot locs the chassis layout actually has.
        const slotLocs = props.chassis.layout.slotLocs.length;

        // Get a copy of the LAST one.
        const loc = { ...props.chassis.layout.slotLocs[slotLocs - 1] };

        // Shift it to the right by its own width.
        loc.x += loc.width;

        // Set its new width to be what we found above.
        loc.width = xWidth;

        let xSlotId = -1;
        const lastMod = props.chassis.modules[props.chassis.modules.length - 1];
        if (lastMod) {
            xSlotId = lastMod.slotID + 1;
        }

        // We DO want a right slot.
        return [true, loc, xSlotId.toString()];
    }

    // We DON'T want one.
    return [false, getEmptyLoc(), ''];
}


const SnapChassisComp = (props: ChassisCompProps) => {

    // Get our chassis layout. We should ALWAYS be able to.
    const layout = props.chassis.layout;
    if (!layout) {
        throw new Error('SnapChassisComp passed chassis without valid layout!');
    }

    const defaultRender = (!props.renderType) || (!props.layoutMode);
    const modeForSpec = defaultRender ? undefined : props.layoutMode;

    // Call a helper to give us rendering details for the various 
    // elements of our chassis (ps, modules, empty slots, etc.)
    const elRenderSpec = getChassisRenderSpec(modeForSpec,
        props.chassis, true, (props.showAsSelected === true));

    // Get breakdown of our slots (which have modules and which are empty).
    const [modSlots, emptySlots] = getModuleSlotBreakdown(props.chassis.modules);

    // See if our chassis should be depicted as 'selected'.
    const showSelected = (props.showAsSelected === true);

    const emptySlotOpacity = showSelected ? 0.7 : 1.0;
    const showSlotIds = defaultRender ? false : true;

    const [extraRightSlot, extraSlotLoc, extraSlotId] = _addXSlot(props);

    const showRightCap = (!extraRightSlot && layout.rightCapImgSrc);

    const emptySlotImg = getEmptySlotImage(props.chassis);

    const renderModule = (slotNum: number) => {
        const module = props.chassis.modules[slotNum];
        if (module) {
            const rndType = (props.renderType !== undefined)
                ? module.slotFiller ? elRenderSpec.slotFillers : elRenderSpec.modules
                : undefined;
            const slotId = (showSlotIds && !module.isFPD && !module.isInterconnect)
                ? getModuleSlotID(module)
                : undefined;
            return (
                <SelectableDeviceComp
                    key={slotNum}
                    device={module}
                    devDragStatus={module.dragStatus}
                    showSelected={showSelected && (props.localDeviceSelected === module)}
                    renderType={rndType}
                    ptOrg={props.ptOrg}
                    relLocation={layout.slotLocs[slotNum]}
                    slotId={slotId}
                />
            );
        }
        else {
            return (
                null
            );
        }
    }

    return (
        <>
            {emptySlots.map(slot => {
                return <ChassisElComp
                    key={slot}
                    elType={ChassisElementType.EmptySlot}
                    renderType={elRenderSpec.emptySlots}
                    imgSrc={emptySlotImg}
                    ptOrg={props.ptOrg}
                    relLocation={layout.slotLocs[slot]}
                    opacity={emptySlotOpacity}
                />
            })}
            {showSlotIds && emptySlots.map(slot => {
                return <SlotIDComp
                    key={'SID' + slot}
                    slotId={getSlotID(props.chassis, slot)}
                    ptOrg={props.ptOrg}
                    relSlotLoc={layout.slotLocs[slot]}
                    emptySlot={true}
                />
            })}
            {modSlots.map(slot => {
                return (renderModule(slot));
            })}
            {showRightCap
                ? <ChassisElComp
                    key={'RC'}
                    elType={ChassisElementType.RightCap}
                    renderType={elRenderSpec.rightCap}
                    imgSrc={layout.rightCapImgSrc}
                    ptOrg={props.ptOrg}
                    relLocation={layout.rightCapLoc}
                />
                : null
            }
            {extraRightSlot
                ? <>
                    <ChassisElComp
                        key={'XRS'}
                        elType={ChassisElementType.EmptySlot}
                        renderType={elRenderSpec.emptySlots}
                        imgSrc={emptySlotImg}
                        ptOrg={props.ptOrg}
                        relLocation={extraSlotLoc}
                    />
                    <SlotIDComp
                        key={'SID' + extraSlotId}
                        slotId={extraSlotId}
                        ptOrg={props.ptOrg}
                        relSlotLoc={extraSlotLoc}
                        emptySlot={false}
                    />
                </>

                : null
            }
        </>
    );

}

export default SnapChassisComp;


