import { getBankInfo, getNumBanks } from "../../../model/ChassisProject";
import { Chassis, ChassisModule } from "../../../types/ProjectTypes";
import { Point, Size, LocAndSize } from "../../../types/SizeAndPosTypes";
import { StageUnitsPerMM } from "../../../types/StageTypes";
import { getImgNameFromPath, offsetLoc, scaleSize } from "../../../util/GeneralHelpers";
import { RendInfo, RendPortion } from "../../../util/SysDsgHelp";
import { flexHAGetLayoutInfo } from "./FlexHALayout";
import { getSlotRenderLoc, NetCommInfo } from "../../../util/SysDsgHelp";


const _getIOTbImage = (mod: ChassisModule): string => {
    if (mod.slotsUsed === 2) {
        return '5015_IO_TB_Duplex.png';
    }
    else if (mod.slotFiller) {
        return '5015_IO_TB_Cover.png';
    }
    else {
        return '5015_IO_TB_Simplex.png';
    }
}

const _scaleLoc = (loc: LocAndSize, scale: number) => {
    loc.x = Math.round(loc.x * scale);
    loc.y = Math.round(loc.y * scale);
    loc.width = Math.round(loc.width * scale);
    loc.height = Math.round(loc.height * scale);
}

export const flexHAGetChassisRendInfo = (chassis: Chassis): RendInfo[] => {

    // Get image src for items we'll ALWAYS need.
    const bpImg = '5015_Backplate.png';
    const baseImg = '5015-A4IOKIT_tp.png';

    const rendInfo = new Array<RendInfo>();

    //// Set up initial md object.
    //const md: SysDsgChassisMetadata = {
    //    guid: chassis.id,
    //    rendInfo: new Array <MdBankRendInfo>()
    //}

    // We'll scale the info we provide so
    // 1 unit is ~equal to 1 millimeter.
    const scaleAdj = 1.0 / StageUnitsPerMM;

    // See how many banks we have.
    const numBanks = getNumBanks(chassis);

    // For each...
    for (let bank = 0; bank < numBanks; bank++) {

        // Get general bank information.
        const bankInfo = getBankInfo(chassis, bank);

        // Create an array of objects to hold
        // info about EACH image component.
        const imgEls = new Array<RendPortion>();

        // Get the bank's layout.
        const layout = flexHAGetLayoutInfo(chassis, bank);


        // Get a copy of the backplate's loc.
        const bp = { ...layout.backplateLoc };

        // Determine the point relative to the layout
        // that we want our bank's upper left to be.
        // want to be the 
        const ptBankUpLeft: Point = {
            x: bp.x,
            y: layout.nonPSCompsLoc.y
        }

        // The size in our layout object is only set
        // for the primary bank (the layout hanging on
        // the chassis itself). And, THAT size refers to
        // the overall size in CSA of ALL banks. HERE, we
        // want the overall depiction size of just this
        // bank.
        const bankSize: Size = {
            width: bp.x + bp.width - ptBankUpLeft.x,
            height: bp.y + bp.height - ptBankUpLeft.y
        }

        // Determine that size at scale.
        const scaledBankSize = scaleSize(bankSize, scaleAdj, true);

        // Layout locs are relative to the upper-left corner
        // of the depiction of the entire chassis in CSA. The
        // locs we provide here will be similar, but relative
        // to the upper-left corner of the individual bank's
        // depiction. Set up an associated offset for that.
        const ptOffset: Point = { x: -ptBankUpLeft.x, y: -ptBankUpLeft.y };

        // For each element, we'll start with the
        // associated loc found in the layout, then
        // offset that, and add an associated img el
        // structure. Scaling will be done at the end
        // on the entire array.
        // Backplate...
        offsetLoc(bp, ptOffset);
        imgEls.push({
            loc: bp,
            image: bpImg,
            border: { stroke: 'lightgray' }
        });

        // Bank exp lead comp if we have one...
        if (layout.locLeadComp) {
            const loc = { ...layout.locLeadComp };
            offsetLoc(loc, ptOffset);
            imgEls.push({
                loc: loc,
                image: '5015-BEBRXT.png'
            });
        }

        // I/O bases...
        for (let baseIdx = 0; baseIdx < layout.ioBaseLocs.length; baseIdx++) {
            const loc = { ...layout.ioBaseLocs[baseIdx] };
            offsetLoc(loc, ptOffset);
            imgEls.push({
                loc: loc,
                image: baseImg
            });
        }

        // Modules and terminal block...
        for (let locSlotIdx = 0; locSlotIdx < bankInfo.slotsInBank; locSlotIdx++) {
            const chasSlot = bankInfo.startSlot + locSlotIdx;
            const mod = chassis.modules[chasSlot];
            if (mod) {
                const modLoc = { ...layout.slotLocs[locSlotIdx] };
                offsetLoc(modLoc, ptOffset);
                imgEls.push({
                    loc: modLoc,
                    image: getImgNameFromPath(mod.imgSrc),
                    slot: locSlotIdx
                });

                if (chasSlot > 0) {
                    const tbLoc = { ...layout.ioTBLocs[locSlotIdx] };
                    offsetLoc(tbLoc, ptOffset);
                    imgEls.push({
                        loc: tbLoc,
                        image: _getIOTbImage(mod),
                        border: { stroke: 'black' }
                    });

                    locSlotIdx += (mod.slotsUsed - 1);
                }
            }
        }

        // Bank exp trailer comp if we have one...
        if (layout.locTrailComp) {
            const loc = { ...layout.locTrailComp };
            offsetLoc(loc, ptOffset);
            imgEls.push({
                loc: loc,
                image: '5015-BEBLXT.png'
            });
        }

        // Run the entire array of elements we collected,
        // and scale each's location to our final scale.
        imgEls.forEach(el => {
            _scaleLoc(el.loc, scaleAdj);
        })

        // Add the completed rendering info
        // for the bank to our array. 
        rendInfo.push({
            size: scaledBankSize,
            els: imgEls
        });
    }

    // Return final results.
    return rendInfo;
}


export const flexHAGetChassisNetworkInfo = (
    chassis: Chassis,
    rendInfo: RendInfo[],
    redundant: boolean
): NetCommInfo[] | undefined => {

    // Warning suppression
    chassis;
    redundant;

    // Get the location of the adapter base kit as it will be
    // rendered in the System Designer. The actual comm modules
    // are part of that kit (located inside of it).
    const [, adptrBaseLoc] = getSlotRenderLoc(rendInfo, 0);

    // We SHOULD be able to. If we can...
    if (adptrBaseLoc) {

        // Create an array of comm info objects.
        const comms = new Array<NetCommInfo>();

        // We'll say that the top and height of our comm
        // locations, which are inside of slot 0, will 
        // match that of the adapter base.
        const commTop = adptrBaseLoc.y;
        const commHeight = adptrBaseLoc.height;

        // Each will have a width that's 22%
        // of that of the adapter base.
        const commWidth = Math.round(adptrBaseLoc.width * 0.22);

        // The primary comm will be located 43% across the
        // base adapter's width, and the redundant comm at 69%
        const priOffsetX = Math.round(adptrBaseLoc.width * 0.43);
        const redOffsetX = Math.round(adptrBaseLoc.width * 0.69);

        const xPrimary = adptrBaseLoc.x + priOffsetX;
        const xRedundant = adptrBaseLoc.x + redOffsetX;

        // Push info for our primary and secondary
        // comm modules used onto our array.
        comms.push({
            catNo: '5015-AENFTXT',
            id: 'Primary',
            slot: 0,
            loc: {
                x: xPrimary,
                y: commTop,
                width: commWidth,
                height: commHeight
            }
        });
        comms.push({
            catNo: '5015-AENFTXT',
            id: 'Redundant',
            slot: 0,
            loc: {
                x: xRedundant,
                y: commTop,
                width: commWidth,
                height: commHeight
            }
        });

        // Return the array.
        return comms;
    }

    // Unexpected.
    console.error('Unexpected error in flexHAGetChassisNetworkInfo!');
    return undefined;
}
