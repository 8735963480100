import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ModalRequestSpec, requestModal } from '../modals/ModalHelp';
import { getLocAttrFromProject, getProjectFromChassis } from '@csa-core/advisor.controlsystemcore'; // '../model/ChassisProject';
import { Chassis, ChassisProject } from '@csa-core/advisor.controlsystemcore'; // '../types/ProjectTypes';
import { addCheckerListener } from '@csa-core/advisor.controlsystemcore'; // '../util/Checker';
import { AF_Base } from '@csa-core/advisor.controlsystemcore'; // '../util/CheckerAutoFix';
import { getNewProjectLog, LogMsgLevel, ProjectLog, ProjectStatusFilterButtonType, sortLogMessages, StatusLogType } from '@csa-core/advisor.controlsystemcore'; // '../util/ProjectLog'
import { PrepareAccordionDetail, ProjectLogHeader, ProjectStatusDisplayHeader } from './ProjectStatusDisplayHeader';
import { ProjectCommsInfo } from '@csa-core/advisor.controlsystemcore'; // '../model/CommDetails';
import PerformanceUsage from '../components/details/PerformanceUsage';
import PerformanceRequired from '../components/details/PerformanceRequired';
import './ProjectStatus.scss';
import ProjectStatusFilterButton from './ProjectStatusFilterButton';
import ProjectStatusAccordionRow from './ProjectStatusAccordionRow';
import { ReactComponent as InfoIconSVG } from '../svg/information-circle.svg';
import { ReactComponent as WarningIconSVG } from '../svg/errors.svg';
import { ReactComponent as ErrorIconSVG } from '../svg/failed.svg';
import { executeAutoFix } from '@csa-core/advisor.controlsystemcore'; // '../implementation/ImplChecker';
import { useCommInfo } from '../context/SelectionInfoContext';
import { PlatformMicro } from '@csa-core/advisor.controlsystemcore'; // '../platforms/PlatformConstants';
import PerformanceCapabilities from '../components/details/PerformanceCapabilities';
////////////// STATUS MODAL DISPLAY ////////////////////////////////
///// These component(s) are inserted into the global modal dialog. 

export const displayProjectLogMessages = (log: ProjectLog, project: ChassisProject, contentChanged: () => void) => {
    const modalProps: StatusDisplay = {
        content: ProjectStatusDisplay,
        statusLog: log,
        project: project,
        contentChanged: contentChanged,
        logTitle: '',
        title: 'Configuration/Chassis Status',
        width: Math.min(800, window.innerWidth),
        closeOnInsideClick: false,
        stayOpenOnBackdropClick: true,
        includeButtons: true,
        buttonInfo: [{ btnText: 'OK', resultStatus: 0, variant: 'contained' }],
    };

    requestModal(modalProps);
}


export const displayChassisLogMessages = (log: ProjectLog, chassis: Chassis, contentChanged: () => void) => {
    // Get the project from the chassis...
    const proj = getProjectFromChassis(chassis);
    if (proj != null) {
        const modalProps: StatusDisplay = {
            content: ProjectStatusDisplay,
            statusLog: log,
            project: proj,
            contentChanged: contentChanged,
            logTitle: (chassis.name ? chassis.name : 'Chassis'),
            title: 'Chassis Status:',
            width: Math.min(800, window.innerWidth),
            closeOnInsideClick: false,
            stayOpenOnBackdropClick: true,
            includeButtons: true,
            buttonInfo: [{ btnText: 'OK', resultStatus: 0, variant: 'contained' }],
            chassis: chassis,
        };

        requestModal(modalProps);
    }
}

// Helpers.
//const _logSortPredicate = (a: ProjectLog, b: ProjectLog): number => {
//    // If the status of B is greater than A (i.e. worse), 
//    // put A after B.
//    if (a.logStatus < b.logStatus)
//        return 1;
//    else if (a.logStatus > b.logStatus)
//        return -1;

//    // Status levels are equal, sort on #errs,
//    // then #warnings, and then infos..
//    if (a.errors < b.errors)
//        return 1;
//    else if (a.errors > b.errors)
//        return -1;

//    if (a.warnings < b.warnings)
//        return 1;
//    else if (a.warnings > b.warnings)
//        return -1;

//    if (a.infos < b.infos)
//        return 1;
//    else if (a.infos > b.infos)
//        return -1;

//    return 0;
//}
// Interface(s)
export interface StatusDisplay extends ModalRequestSpec {
    statusLog: ProjectLog;
    project: ChassisProject;
    chassis?: Chassis;
    logTitle: string;
    contentChanged: () => void;
}


export interface Props {
    status: ModalRequestSpec;
}


export interface filterButtonTypes {
    info: boolean;
    warning: boolean;
    error: boolean;
}
// Component

export const ProjectStatusDisplay = (props: ModalRequestSpec) => {
    const [refresh, setRefresh] = useState(0);
    const noRender = useRef<boolean>(false);
    const [selectedButton, setSelectedButton] = useState<filterButtonTypes>({
        info: true,
        warning: true,
        error: true,
    })
    const theStatus = useRef<StatusDisplay>(props as StatusDisplay);
    if (theStatus.current.project == null && !theStatus.current.logTitle) {
        noRender.current = true;
    }
    // taking the latest project comms info from Context state.
    const {CommInfo} = useCommInfo(); 
    const [arrLogs, setArrLogs] = useState<ProjectLogHeader[]>([]);
    const initializing = useRef<boolean>(false);
    const currentChassis = useRef<Chassis | undefined>(undefined);
    const [projectLevelLog, setProjectLevelLog] = useState<ProjectLogHeader | undefined>(undefined)

    useEffect(() => {
        if (initializing.current === false && noRender.current === false) {
            initializing.current = true;

            // If we are displaying just a chassis
            if (theStatus.current.statusLog.logType === StatusLogType.chassis) {
                //	// Do we need to sort the messages...
                //	if (theStatus.current.statusLog.messages.length > 1 && theStatus.current.statusLog.logStatus > LogMsgLevel.info) {
                //		sortLogMessages(theStatus.current.statusLog);
                //	}

                //	const header = theStatus.current.statusLog as ProjectLogHeader;
                //	header.title = (theStatus.current.logTitle.length > 0 ? theStatus.current.logTitle : 'Chassis');
                //	setArrLogs([header]);
                prepChassisLog();
                //console.log("rendering chassis log");
            }
            else if (theStatus.current.project != null) {
                prepProjectLog();
                console.log("rendering project log");
            }
        }
        initializing.current = false;

    }, [props, noRender, theStatus]) // End useEffect()


    const prepChassisLog = () => {
        // Do we need to sort the messages...
        if (theStatus.current.statusLog.messages.length > 1 && theStatus.current.statusLog.logStatus > LogMsgLevel.info) {
            sortLogMessages(theStatus.current.statusLog);
        }

        const header = theStatus.current.statusLog as ProjectLogHeader;
        header.chassis = theStatus.current.chassis;
        header.title = (theStatus.current.logTitle.length > 0 ? theStatus.current.logTitle : 'Chassis');
        setArrLogs([header]);
    }

    const prepProjectLog = () => {

        if (theStatus.current && theStatus.current.project) {
            const logs: ProjectLogHeader[] = [];

            // Add all of the chassis logs to our array.
            theStatus.current.project.content.racks.forEach((rack) => {
                if (rack.chassis.statusLog != null) {
                    const header = rack.chassis.statusLog as ProjectLogHeader
                    // Do we need to sort the messages...
                    if (rack.chassis.statusLog.messages.length > 1 && rack.chassis.statusLog.logStatus > LogMsgLevel.info) {
                        sortLogMessages(rack.chassis.statusLog);
                    }
                    header.chassis = rack.chassis;
                    header.title = (rack.chassis.name != null ? rack.chassis.name : 'Chassis');
                    logs.push(header);
                }
            });

            // Sort the logs...
            //logs.sort(_logSortPredicate);

            // If the main log has any messages... Note: These messages
            // would be general project messages not associated with
            // any particular chassis.
            // if (theStatus.current.project.content.statusLog.messages.length > 0) {

            //Do we need to sort the general project messages...
            // if (theStatus.current.project.content.statusLog.messages.length > 1 && theStatus.current.project.content.statusLog.logStatus > LogMsgLevel.info) {
            //     sortLogMessages(theStatus.current.project.content.statusLog);
            // }

            // We cannot use the main log instance - this has TOTAL
            // number of errors/warnings/infos from all of the
            // project chassis(s). Dupe the main log as a Header.
            const dupe = { ...theStatus.current.project.content.statusLog } as ProjectLogHeader;
            // Set the title and clear the level/totals.
            //dupe.title = 'Project Messages';
            dupe.errors = 0;
            dupe.warnings = 0;
            dupe.infos = 0;
            dupe.logStatus = LogMsgLevel.none;
            dupe.chassis = undefined;
            // Reestablish the totals and level from the messages.
            dupe.messages.forEach((msg) => {
                switch (msg.level) {
                    case LogMsgLevel.error:
                        dupe.errors++;
                        dupe.logStatus = LogMsgLevel.error; // Highest level - just set it
                        break;
                    case LogMsgLevel.warning:
                        dupe.warnings++;
                        break;
                    case LogMsgLevel.info:
                        dupe.infos++;
                        break;
                }
            });

            // If the status level still is 'none', meaning we
            // did not find any error messages above...
            if (dupe.logStatus === LogMsgLevel.none) {
                // If we have warnings, set the status to warning. Otherwise Info...
                dupe.logStatus = (dupe.warnings > 0 ? LogMsgLevel.warning : LogMsgLevel.info);
            }

            // Add the general log as the FIRST log header in our array.
            setProjectLevelLog(dupe);
            // logs.unshift(dupe);
            // }

            setArrLogs(logs);
        }
    }

    const handleProjectStatusButtonClick = (id: string) => {
        switch (id) {
            case ProjectStatusFilterButtonType.Info:
                setSelectedButton({ ...selectedButton, info: !selectedButton.info })
                break;
            case ProjectStatusFilterButtonType.Warning:
                setSelectedButton({ ...selectedButton, warning: !selectedButton.warning })
                break;
            case ProjectStatusFilterButtonType.Error:
                setSelectedButton({ ...selectedButton, error: !selectedButton.error })
                break;
            default:
                break;

        }

    }
    const setCurrentChassis = useCallback((af: AF_Base) => {
        if (theStatus.current.project) {
            // Find the chassis ID from the auto fix.
            let chassisID = '';
            arrLogs.forEach((hdr) => {
                if (!chassisID) {
                    hdr.messages.forEach((msg) => {
                        if (msg.idAutoFix === af.id) {
                            chassisID = af.targetInstanceID;
                        }
                    });
                }
            });

            if (chassisID) {
                const rack = theStatus.current.project.content.racks.find((rack) => rack.chassis.id === af.targetInstanceID);
                if (rack && rack.chassis)
                    currentChassis.current = rack.chassis;
            }
        }
    }, [arrLogs]);

    const onCheckerFinished = useCallback(() => {
        if (theStatus.current.statusLog.logType === StatusLogType.chassis && currentChassis.current != null) {
            let newLog = getNewProjectLog(StatusLogType.chassis);
            if (currentChassis.current.statusLog)
                newLog = currentChassis.current.statusLog;

            theStatus.current.statusLog.errors = newLog.errors;
            theStatus.current.statusLog.infos = newLog.infos;
            theStatus.current.statusLog.logStatus = newLog.logStatus;
            theStatus.current.statusLog.logType = newLog.logType;
            theStatus.current.statusLog.messages = newLog.messages;
            theStatus.current.statusLog.warnings = newLog.warnings;
            theStatus.current.statusLog.show = newLog.show;

            prepChassisLog();
        }
        else {
            if (theStatus.current.project)
                prepProjectLog();
        }

        setRefresh(refresh + 1);
    }, [refresh]);

    const onAutoFixResolved = useCallback(() => {
        setRefresh(refresh + 1);
    }, [refresh]);

    const onAutoFixIssue = useCallback((af: AF_Base) => {
        if (theStatus.current.project) {
            // Execute the auto fix.
            const success = executeAutoFix(af.platform, af, theStatus.current.project, onAutoFixResolved);
            if (success) {
                if (theStatus.current.statusLog.logType === StatusLogType.chassis)
                    setCurrentChassis(af);


                addCheckerListener(onCheckerFinished, true);
                theStatus.current.contentChanged();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onAutoFixResolved, refresh, arrLogs, onCheckerFinished, setCurrentChassis]);

    // If we do not have messages/something, just display a simple message..
    if (noRender.current) {
        return (<div>There are no error, warning, or informational messages.</div>);
    }

    let nextKey = 0;

    const getChassisLogVals = (buttonType: ProjectStatusFilterButtonType) => {
        switch (buttonType) {

            case ProjectStatusFilterButtonType.Info:
                return theStatus.current.statusLog.infos;

            case ProjectStatusFilterButtonType.Warning:
                return theStatus.current.statusLog.warnings;

            case ProjectStatusFilterButtonType.Error:
                return theStatus.current.statusLog.errors;

            default:
                return 0;
        }
    }

    const getProjectLogVals = (buttonType: ProjectStatusFilterButtonType) => {
        switch (buttonType) {

            case ProjectStatusFilterButtonType.Info:
                return theStatus.current.project.content.statusLog.infos;

            case ProjectStatusFilterButtonType.Warning:
                return theStatus.current.project.content.statusLog.warnings;

            case ProjectStatusFilterButtonType.Error:
                return theStatus.current.project.content.statusLog.errors;

            default:
                return 0;
        }
    }

    const getFilterButtonValue = (logType: StatusLogType, buttonType: ProjectStatusFilterButtonType) => {
        switch (logType) {
            case StatusLogType.chassis:
                return getChassisLogVals(buttonType);

            case StatusLogType.project:
                return getProjectLogVals(buttonType)
            default:
                return getChassisLogVals(buttonType);

        }
    }

    const prepareFilterButtonInfo = () => {
        const logType = theStatus.current.statusLog.logType !== StatusLogType.chassis && theStatus.current.project != null ?
            StatusLogType.project : StatusLogType.chassis;

        const infoVal = getFilterButtonValue(logType, ProjectStatusFilterButtonType.Info);
        const warningVal = getFilterButtonValue(logType, ProjectStatusFilterButtonType.Warning);
        const errorVal = getFilterButtonValue(logType, ProjectStatusFilterButtonType.Error);


        return {
            info: {
                id: ProjectStatusFilterButtonType.Info,
                isDisabled: !(infoVal > 0),
                value: infoVal,
                label: `Info ${infoVal}`,
                icon: InfoIconSVG,
                borderColor: "#005DB4",
                selectionStatus: selectedButton.info
            },
            warning: {
                id: ProjectStatusFilterButtonType.Warning,
                isDisabled: !(warningVal > 0),
                label: `Warning ${warningVal}`,
                value: warningVal,
                icon: WarningIconSVG,
                borderColor: "#FDB81E",
                selectionStatus: selectedButton.warning
            },
            error: {
                id: ProjectStatusFilterButtonType.Error,
                isDisabled: !(errorVal > 0),
                value: errorVal,
                label: `Error ${errorVal}`,
                icon: ErrorIconSVG,
                borderColor: "#DF3704",
                selectionStatus: selectedButton.error
            },
        }

    }


    const FilterButtonInformation = prepareFilterButtonInfo();
const renderPerformance = (platform: string, anyControllers: boolean) => {
  switch (platform) {
    case PlatformMicro: {
      return (
        <PerformanceCapabilities
          commsInfo={CommInfo}
          project={theStatus.current.project}
        />
      );
    }
    default: {
      return anyControllers ? (
        <PerformanceUsage
          commsInfo={CommInfo}
          selectedButton={selectedButton}
        />
      ) : (
        <PerformanceRequired commsInfo={CommInfo} />
      );
    }
  }
};
    const locAttrInfo = getLocAttrFromProject(theStatus.current.project);

    const getProjPerforamnceDetails = (CommInfo: ProjectCommsInfo, anyControllers: boolean) => {
        return (
            <div className='perf-content'>
                { renderPerformance( locAttrInfo.platform,anyControllers)}
                <PrepareAccordionDetail
                    log={projectLevelLog}
                    selectedButton={selectedButton}
                    autoFixCallback={onAutoFixIssue}
                />
            </div>
        )
    }
    const getProjectPerformace = (CommInfo: ProjectCommsInfo) => {
      const anyControllers = CommInfo.allCtlrChassis?.controllers > 0;
      let title = "";
      switch (locAttrInfo.platform) {
        case PlatformMicro:
          title = "Controller Performance Capabilities";
          break;

        default:
          title = anyControllers
            ? "Configuration Performance Review"
            : "Remote I/O Performance Requirements";
          break;
      }
      const details = getProjPerforamnceDetails(CommInfo, anyControllers);

      return {
        title,
        details,
      };
    };
    //console.log("thevalueofthestatus", theStatus);

    return (
        <div className="project-status-display">
            <div className="project-status-info-button" >
                {FilterButtonInformation &&
                    <>
                        <ProjectStatusFilterButton
                            buttonInfo={FilterButtonInformation.info}
                            onButtonClick={handleProjectStatusButtonClick}
                        />

                        <ProjectStatusFilterButton
                            buttonInfo={FilterButtonInformation.warning}
                            onButtonClick={handleProjectStatusButtonClick}
                        />

                        <ProjectStatusFilterButton
                            buttonInfo={FilterButtonInformation.error}
                            onButtonClick={handleProjectStatusButtonClick}
                        />
                    </>}
            </div>
            {/* Project performance status section will be visible only in project issue model not in chassis log model */}
            {
                theStatus.current.statusLog.logType !== StatusLogType.chassis && (CommInfo) &&
                <ProjectStatusAccordionRow
                    title={getProjectPerformace(CommInfo).title}
                    details={getProjectPerformace(CommInfo).details}
                />
            }

            {
                arrLogs.map((log) => {
                    return (
                        <ProjectStatusDisplayHeader
                            selectedButton={selectedButton}
                            log={log}
                            expanded={true}
                            autoFixCallback={onAutoFixIssue}
                            contentChanged={theStatus.current.contentChanged}
                            key={nextKey++}
                        />
                    )
                })
            }

        </div>
    )
};
