import { EngInfoComponent } from "../engData/EngineeringInfo";

export enum LayoutModeType {
    Normal = 'Normal',
    Copy = 'Copy',
    Delete = 'Delete',
    Drag = 'Drag'
}

export interface LayoutMode {
    type: LayoutModeType;

    // Used for copy and drag modes only
    origCat: string;
    origCC: boolean; // conformal
    origET: boolean; // extended temp
    engInfo: EngInfoComponent | undefined;
}
