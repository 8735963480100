import React from 'react';
import '../styles/AppLayout.scss';
import SettingGroup from '../settings/SettingGroup';
import {
    ProjectSetting,
    SettingOption,
    SettingCode
} from '@csa-core/advisor.controlsystemcore'; // '../types/SettingsTypes';
import { getSettingGroups } from '@csa-core/advisor.controlsystemcore'; // '../settings/SettingsHelp';
import { ChassisProject } from '@csa-core/advisor.controlsystemcore'; // '../types/ProjectTypes';


interface Props {
    project: ChassisProject;
    valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
}

export const IOSettings = (props: Props) => {

    const groups = getSettingGroups(props.project, SettingCode.AttrGrpIOSettings);

    let nextGroupKey = 1;

    return (
        <div className="project-settings-io-mode">
            <div className="project-settings-io-mode-setting">
                {groups.map(group => {
                    return <SettingGroup
                        key={nextGroupKey++}
                        info={group}
                        valueChanged={props.valueChanged}
                />
                }) }
            </div>
        </div >
    );

}

