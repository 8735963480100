import React from 'react';
import { getChassisRenderer } from '../implementation/GenerallUIImpl';
import { ChassisCompProps } from '@csa-core/advisor.controlsystemcore'; // '../implementation/ImplGeneral';
import { LogRender } from '@csa-core/advisor.controlsystemcore'; // '../types/Globals';
import { logger } from '@csa-core/advisor.controlsystemcore'; // '../util/Logger';


const ChassisComp = (props: ChassisCompProps) => {

    const PlatformChassisComp = getChassisRenderer(props.chassis.platform);


    if (LogRender.Layout) {
        logger.logRender('Chassis ' + props.chassis.name +
            ' bumper(' + props.bumper + ')');
    }

    if (PlatformChassisComp) {
        return (
            <PlatformChassisComp
                {...props}
            />
        );
    }
    else {
        return (null);
    }
}

export default React.memo(ChassisComp);

