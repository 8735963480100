import React, { useEffect, useRef, useState } from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { SelectableDevice } from '@csa-core/advisor.controlsystemcore'; // '../../types/ProjectTypes';
import DetailsDeviceProduct from './DetailsDeviceProduct';
import DetailsStatus from './DetailsStatus';
import SimpleTabs, { SimpleTabInfo } from '../choices/SimpleTabs';
import DetailsChassisPower from './DetailsChassisPower';
import DetailsCommonDocTab from './DetailsCommonDocTab';
import DetailsDeviceSpecs from './DetailsDeviceSpecs';
import DetailsCommonAccysTab from './DetailsCommonAccysTab';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button } from '@mui/material';
import { configureAccysFor } from '../../accessories/ConfigAccys';


const TabID = {
    Specifications: 1,
    Power: 2,
    Accessories: 3,
    Documentation: 4
};


interface Props {
    device: SelectableDevice;
    ps: boolean;
    onClickPowerSettings?: () => void;

}

const DetailsDevice = ({device,ps, onClickPowerSettings }: Props) => {
    const [currentTab, setCurrentTab] = useState<number>(TabID.Specifications);
    const lastDevice = useRef<SelectableDevice>(device);


    const loadTabInfo = (): SimpleTabInfo[] => {
        const info = new Array<SimpleTabInfo>();
    
        info.push({
            text: 'Specifications',
            id: TabID.Specifications
        });
        device?.parent && ps && info.push({
            text: 'Power',
            id: TabID.Power
        });
        info.push({
            text: 'Accessories',
            id: TabID.Accessories
        });
    
        info.push({
            text: 'Documentation',
            id: TabID.Documentation
        });
    
        return info;
    }
    
    const tabInfo = loadTabInfo();

    useEffect(() => {
        // Confirm we have a new device and, if so,
        // the new device has the TAB we are on.
        if (lastDevice.current !== device && tabInfo.length > 0) {
            lastDevice.current = device;

            // If the current Tab ID is NOT in the array...
            if (tabInfo.some(tb => tb.id === currentTab) === false) {
                // Set the current tab.
                setCurrentTab(tabInfo[0].id);
            }
        }
    }, [device, tabInfo, setCurrentTab, currentTab]);


    const onTabSelected = (tabID: number) => {
        if (tabID !== currentTab) {
            setCurrentTab(tabID);
        }
    }

    const inclAccyEdit = ((currentTab === TabID.Accessories) &&
        device.accysPossible);

    const onClickEditAccys = () => {
        configureAccysFor(device);
    }

    const renderTab = (tabID: number) => {
        switch (tabID) {
            case TabID.Specifications:
                return (
                    <DetailsDeviceSpecs
                        device={device}
                        ps={ps}
                    />
                );
            case TabID.Power:
                // Chassis power details also used
                // for Comm/Ctrl/FPD/SAPowerSupplies.
                return (
                    device?.parent && <DetailsChassisPower chassis={device.parent} />
                );

            case TabID.Accessories:
                return (
                    <DetailsCommonAccysTab device={device} />
                );

            case TabID.Documentation:
                return (
                    <DetailsCommonDocTab device={device} />
                );

            default:
                return (null);
        }
    }

    return (
        <div className='floating-details-content'>
            <div className='floating-details-upper'>
                <DetailsDeviceProduct
                    device={device}
                    ps = { ps }
                    onClickPowerSettings = { onClickPowerSettings }
                    
                />
                <DetailsStatus device={device} />
            </div>
            <div className='floating-details-tab-area'>
                <SimpleTabs
                    tabInfo={tabInfo}
                    currentID={currentTab}
                    onTabSelected={onTabSelected}
                />
                {inclAccyEdit
                    ? <Button
                        disableRipple
                        variant={'text'}
                        startIcon={<EditOutlinedIcon />}
                        onClick={onClickEditAccys}
                        sx={{
                            marginLeft: 'auto',
                            textTransform: 'none',
                            justifyContent: 'left',
                            padding: '4px 4px'
                        }}
                    >
                        Edit Accessories
                    </Button>

                    : null}
           </div>
            <div className='floating-details-tab-content'>
                {renderTab(currentTab)}
            </div>
        </div>
    );
}

export default DetailsDevice;
