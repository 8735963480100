// CORE_RECHECK - Delete IModalResultCallback/StatusLevel
// and StatusLevel - add imports when Core complete.

//import { IModalResultCallback } from "../modals/ModalHelp";
//import { StatusLevel } from "../types/MessageTypes";

interface IModalResultCallback {
    (status: number, data?: object): void;
}

enum StatusLevel {
    NA = 'NA',
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error'
}


////////////////////////////////////////////////////////////////////////
// NOTE: These exports, unless otherwise specified, should be LOCAL to 
// the Core and NOT exported to other Apps. This functionality could NOT 
// be migrated to the Core from the UI. However, some Core functions call 
// these UI functions. We are NOT importing UI functionality into the Core!
// We have an interface that can be defined/hooked by the UI during startup.
// If defined, the Core will forward calls to the UI. If not, we will log 
// the messages where appropriate.
////////////////////////////////////////////////////////////////////////

export type ToastId = number | string;

// EXTERNAL Export from the Core.
export interface IMessenger {
    displayUnderConstructionMsg?: (msg: string) => void;
    displayUnexpectedErrorMsg?: (msg: string,
        callback?: IModalResultCallback,
        callbackData?: object) => void;
    displayAlertMsg?: (
        msg: string,
        statusLevel?: StatusLevel,
        title?: string) => void;
    toastSaving?: (text: string) => ToastId;
    toastLoadProgress?: (id: ToastId, text: string) => void;
    toastSaved?: (id: ToastId, text: string) => void;
}

let messenger: IMessenger = {};

// EXTERNAL Export from the Core.
export const registerUIMessenger = (uiMessenger: IMessenger) => {
    messenger = uiMessenger;
}


export const coreDisplayUnderConstructionMsg = (msg: string) => {
    if (messenger.displayUnderConstructionMsg)
        messenger.displayUnderConstructionMsg(msg);
    else
        console.log(`Under Construction: ${msg}`);

}

export const coreDisplayUnexpectedErrorMsg = (
    msg: string,
    callback?: IModalResultCallback,
    callbackData?: object
) => {
    if (messenger.displayUnexpectedErrorMsg)
        messenger.displayUnexpectedErrorMsg(msg, callback, callbackData);
    else
        console.log(`Unexpected Error: ${msg}`);
}

export const coreDisplayAlertMsg = (
    msg: string,
    statusLevel = StatusLevel.Warning,
    title?: string) => {
    if (messenger.displayAlertMsg)
        messenger.displayAlertMsg(msg, statusLevel, title);
    else
        console.log(`Alert: ${msg}`);
}

export const coreToastSaving = (text: string): ToastId => {
    if (messenger.toastSaving)
        return messenger.toastSaving(text);
    return '';
}

export const coreToastLoadProgress = (id: ToastId, text: string) => {
    if (messenger.toastLoadProgress)
        messenger.toastLoadProgress(id, text);
}

export const coreToastSaved = (id: ToastId, text: string) => {
    if (messenger.toastSaved)
        messenger.toastSaved(id, text);
}
