import { GeneralUIImplBase } from "../../../implementation/GeneralUIImplBase";
import { ChassisCompProps } from '@csa-core/advisor.controlsystemcore'; // "../../../implementation/ImplGeneral";
import { ActBtnInfo, LayoutActionType } from '@csa-core/advisor.controlsystemcore'; // "../../../types/LayoutActions";
import { DfltActBtnSpecs } from "../../../types/LayoutActions";
import { Chassis, ChassisProject, DeviceType, MicroChassis, Rack, SelectableDevice } from '@csa-core/advisor.controlsystemcore'; // "../../../types/ProjectTypes";
import { LocAndSize } from '@csa-core/advisor.controlsystemcore'; // "../../../types/SizeAndPosTypes";
import { getLocCenter, offsetLoc } from '@csa-core/advisor.controlsystemcore'; // "../../../util/GeneralHelpers";
import MicroChassisComp from "../components/MicroChasisComp";
import { microConfigureChassis } from "../config/MICROChassisConfig";
import { defaultheight, defaultWidthBuDeletion, microCanExtendChassis } from '@csa-core/advisor.controlsystemcore'; // "./MicroGeneralImpl";



class MicroGeneralUIImpl extends GeneralUIImplBase {

	getChassisRenderer(): React.FC<ChassisCompProps> {

		return MicroChassisComp;
	}


	getActionBtnInfo(
		action: LayoutActionType,
		rack: Rack,
		slotNum: number): ActBtnInfo {

		//Logic for micro 800 layout once deleted controller/base unit	
		const getMicroChassis = rack.chassis as MicroChassis
		if (slotNum === -1) {
			const loc: LocAndSize = {
				x: 0,
				y: 0,
				width: defaultWidthBuDeletion,
				height: defaultheight
			};
			const slotLoc = { ...loc };
			offsetLoc(slotLoc, rack.ptOrg);
			const pt = getLocCenter(slotLoc);
			pt.y += DfltActBtnSpecs.height;
			return {
				action: action,
				chassis: rack.chassis,
				slot: slotNum,
				ctrPt: pt
			};

		}
		else {
			const slots = rack.chassis.modules.length;
			const isIomodules = rack.chassis.modules.some(mod => mod?.deviceType === DeviceType.IOExpansion)
			const pluginLimit = getMicroChassis.pluginModules?.length || 0;
			if (slotNum > slots) {
				throw new Error('Invalid slotNUm in snapGetActionBtnInfo!');
			}

			// If the slot requested is 1 to the right
			// of our chassis' last ACTUAL slot...
			if (slotNum === slots) {

				// See if the chassis can be extended
				// with another module. If so...
				if (microCanExtendChassis(rack.chassis)) {

					// Get platform details...
					let lastSlotLoc = { ...rack.chassis.layout.slotLocs[slots - 1] };
					// Get the location of the last actual slot.
					lastSlotLoc = isIomodules || getMicroChassis.bu ? { ...rack.chassis.layout.slotLocs[slots - 1] } : { x: 0, y: 0, width: 350, height: 551 }
					if (pluginLimit === slots && getMicroChassis.bu) {
						const xpS = rack.chassis.ps && getMicroChassis.bu ? rack.chassis.ps.imgSize.width : 0;
						lastSlotLoc.x = xpS + getMicroChassis.bu?.imgSize.width || 0;
						lastSlotLoc.x -= 230
					}


					offsetLoc(lastSlotLoc, rack.ptOrg);

					// Start our 'x' (extra) slot as a copy of that.
					const xSlotLoc = { ...lastSlotLoc };

					// Place it's left side at the right
					// side of the last real slot.
					xSlotLoc.x += lastSlotLoc.width;

					// Set its width to be the platform's default.
					xSlotLoc.width = 230;

					// Position the act btn pt inside.
					const pt = getLocCenter(xSlotLoc);
					pt.y += DfltActBtnSpecs.height;

					// And return our btn info.
					return {
						action: action,
						chassis: rack.chassis,
						slot: slotNum,
						ctrPt: pt
					};
				}
				else {
					throw new Error('Invalid extension attempt in snapGetActionBtnInfo!');
				}
			}
			const slotLoc = { ...rack.chassis.layout.slotLocs[slotNum] };
			offsetLoc(slotLoc, rack.ptOrg);
			const pt = getLocCenter(slotLoc);
			pt.y += DfltActBtnSpecs.height;
			return {
				action: action,
				chassis: rack.chassis,
				slot: slotNum,
				ctrPt: pt
			};
		}
	}

	hasGetActBtnInfo(__platform: string): boolean {

		return true;
	}

	configureChassis(
		project: ChassisProject,
		platform: string,
		selectChassisCallback: (chassis: Chassis | undefined) => void,
		selectDeviceCallback: (device: SelectableDevice | undefined) => void,
		contentChangedCallback: () => void,
		chassis?: Chassis
	): void {

		return microConfigureChassis(
			project,
			platform,
			selectChassisCallback,
			selectDeviceCallback,
			contentChangedCallback,
			chassis
		);
	}
}

export const createMicroGeneralUIImpl = (): GeneralUIImplBase => {
	return new MicroGeneralUIImpl();
}