import React from "react";
import "./PerformanceInfo.scss";
import "../../styles/Meteor.scss";
import { getNetBandwidthItemInfo } from '@csa-core/advisor.controlsystemcore'; // "../../model/CommDetails";
import { ProjectCommsInfo } from '@csa-core/advisor.controlsystemcore'; // "../../model/CommDetails";
import PerformanceColumn from "./PerformanceColumn";
import { DetailItem } from '@csa-core/advisor.controlsystemcore'; // "../../model/DeviceDetail";
import { formatAsIntWithCommas, formatBytesString } from '@csa-core/advisor.controlsystemcore'; // "../../util/StringFormatHelp";

interface Props {
  commsInfo: ProjectCommsInfo;
}

const PerformanceRequired = (props: Props) => {

    const remInfo = props.commsInfo.allRemIOChassis;

    const ctlrDtlItems = new Array<DetailItem>();
    ctlrDtlItems.push({
        name: 'EtherNet/ IP Nodes:',
        value: formatAsIntWithCommas(remInfo.nodes)
    });
    ctlrDtlItems.push({
        name: 'Memory:',
        value: formatBytesString(remInfo.reqAppMem, false, true)
    });

    const commDtlItems = new Array<DetailItem>();
    commDtlItems.push({
        name: 'Packets per Second:',
        value: formatAsIntWithCommas(remInfo.ppsToCtlr + remInfo.ppsFromCtlr)
    });
    commDtlItems.push(getNetBandwidthItemInfo(remInfo));

    return (
        <div className='perf-columns'>
            <PerformanceColumn
                title={'Controller'}
                progWidth={0}
                perfUsageItems={[]}
                detailItems={ctlrDtlItems}
            />
            <PerformanceColumn
                title={'Communication'}
                progWidth={0}
                perfUsageItems={[]}
                detailItems={commDtlItems}
            />
        </div>
    );
};

export default PerformanceRequired;

