import { EngInfoModule } from "../../../engData/EngineeringInfo";
import {
    addModuleAtSlot,
    defDuplicateChassis,
    deleteModuleAtSlot,
    updateChassis
} from "../../../implementation/ImplGeneral";
import { chassisChanged, copyAccys, getProjectFromChassis, updateAllChassis } from "../../../model/ChassisProject";
import { ActBtnInfo, LayoutActionType } from "../../../types/LayoutActions";
import { StatusLevel } from "../../../types/MessageTypes";
import {
    Chassis,
    DeviceType,
    FlexHAChassis,
    FlexHASAPowerSupply,
    GraphicalDevice,
    NO_SLOT,
    SelectableDevice
} from "../../../types/ProjectTypes";
import { coreDisplayAlertMsg } from "../../../uiInterfaces/UIMessenger";
import { chassisChanging, suspendUndoSnapshots } from "../../../util/UndoRedo";
import { PlatformFlexHA } from "../../PlatformConstants";
import { flexHASAPwrSortPredicate } from "./FlexHAGeneralImpl";
import { flexHACreatePowerSupply, flexHA_DefDuplexMod, flexHA_DefSAPsu, flexHA_DefSimplexMod } from "./FlexHAHardwareImpl";


// General converter - safely cast chassis.
export const getAsFlexHAChassis = (chassis: Chassis | undefined): FlexHAChassis | undefined => {
    const fhaChassis = chassis as FlexHAChassis;
    if (fhaChassis == null || fhaChassis.saPSU == null)
        return undefined;
    return fhaChassis;
}

export const flexHADoesSlotQualifyForCopy = (
    chassis: Chassis,
    infoModToCopy: EngInfoModule,
    slotNum: number): boolean => {

    if (chassis.platform !== PlatformFlexHA)
        throw new Error('flexHADoesSlotQualifyForCopy(): Invalid platform!');

    // We should not be called unless we are in
    // 'copy mode' and the source module occupies
    // more than 1 slot. Check that we have the
    // duplex module.
    if (infoModToCopy.slotsUsed < 2)
        return true;

    // If the slot is NOT ODD...
    if (slotNum % 2 !== 1)
        return false;

    // If the next slot is occupied... 
    const modNextSlot = chassis.modules[slotNum + 1];
    if (modNextSlot && modNextSlot.slotFiller === false)
        return false;

    return true;
}

export const flexHAToggleDuplex = (btnInfo: ActBtnInfo) => {
    // If we are going from Duplex to Simplex...
    const chassis = btnInfo.chassis;
    const slot = btnInfo.slot;

    const toSimplex = btnInfo.action === LayoutActionType.MakeSimplex;
    const msgCompType = (toSimplex ? 'Simplex' : 'Duplex');
    const msgFailure = `Unable to convert the module at slot ${slot} to ${msgCompType}`;

    // We can only operate on modules in ODD
    // number slots...
    if ((slot % 2) !== 1) {
        coreDisplayAlertMsg(msgFailure, StatusLevel.Warning);
        return;
    }

    if (toSimplex) {
        const modDuplex = chassis.modules[slot];
        if (!modDuplex || modDuplex.slotsUsed < 2) {
            coreDisplayAlertMsg(msgFailure, StatusLevel.Warning);
            return;
        }

        const duplexPlaceholder = chassis.modules[slot + 1];
        // This is more of a diagnostic check. 
        // We should not fail here!
        if (!duplexPlaceholder || !duplexPlaceholder.isPlaceholder || duplexPlaceholder.id !== modDuplex.id) {
            console.error(`FlexHA Duplex data corrupted: ${msgFailure}`);
            coreDisplayAlertMsg(msgFailure, StatusLevel.Warning);
            return;
        }

        // Replace the Duplex module with 2 Simplex modules.
        chassisChanging(chassis);
        const wasSuspended = suspendUndoSnapshots(true);

        // Note: We could manipulate module array here, but
        // the overhead from standard funcs is not that bad.
        if (deleteModuleAtSlot(chassis, slot)) {
            addModuleAtSlot(chassis, flexHA_DefSimplexMod, slot, true);
            addModuleAtSlot(chassis, flexHA_DefSimplexMod, slot + 1, true);

            suspendUndoSnapshots(wasSuspended);

            updateChassis(chassis, false);
            return;
        }

        // Something went wrong, reset undo/redo snapshots.
        suspendUndoSnapshots(wasSuspended);
    }
    else {
        // Making a Duplex
        const modSlotA = chassis.modules[slot];
        const modSlotB = chassis.modules[slot + 1];
        if (modSlotA && modSlotB && modSlotA.slotsUsed === 1) {

            chassisChanging(chassis);
            const wasSuspended = suspendUndoSnapshots(true);

            // Note: We could manipulate module array here, but
            // the standard funcs do everything correctly and, if
            // something changes, only one place to change the code.
            if (deleteModuleAtSlot(chassis, slot) && deleteModuleAtSlot(chassis, slot + 1)) {
                addModuleAtSlot(chassis, flexHA_DefDuplexMod, slot, true);

                suspendUndoSnapshots(wasSuspended);

                updateChassis(chassis, false);
                return;
            }

            // Something went wrong, reset undo/redo snapshots.
            suspendUndoSnapshots(wasSuspended);
        }
    }

    coreDisplayAlertMsg(msgFailure, StatusLevel.Warning);
    return;
}

export const flexHAIsSAPowerSupply = (device?: GraphicalDevice) => {
    let isSAPSU = false;
    if (device && device.platform === PlatformFlexHA) {
        if (device.deviceType === DeviceType.PS) {
            // Cast it to a FlexHASAPowerSupply.
            // If we have a defined I/O base index,
            // it is a Flex HA SA PSU.
            const saPSU = (device as FlexHASAPowerSupply);
            isSAPSU = (!isNaN(saPSU.ioBaseIndex) && saPSU.ioBaseIndex >= 0 && saPSU.ioBaseIndex < 6);
        }
    }

    return isSAPSU;
}

export const flexHAGetIOBaseIndexForSlot = (chassis: Chassis, idxSlot: number): number => {

    if (idxSlot >= chassis.modules.length || idxSlot < 1)
        throw new Error('flexHAGetIOBaseIndexForSlot() slot out of range for I/O Base index.');

    const adjustedIndex = idxSlot - 1;
    return Math.floor(adjustedIndex / 4);
}

export const flexHAGetIOBaseFirstSlotIdx = (chassis: Chassis, ioBaseIndex: number): number => {

    return (ioBaseIndex * 4) + 1;
}

export const flexHAAssignSlotIDs = (chassis: Chassis) => {
    const lenModArr = chassis.modules.length;
    for (let idx = 0; idx < lenModArr; ++idx) {
        const mod = chassis.modules[idx];
        if (mod) {
            mod.slotIdx = idx;
            if (mod.isPlaceholder)
                mod.slotID = NO_SLOT;
            else
                mod.slotID = idx;
        }
    }
}

export const flexHADuplicateChassis = (genericSource: Chassis, insertCopyAt: number): Chassis | null => {
    const source = genericSource as FlexHAChassis;
    if (source.saPSU == null)
        throw new Error('flexHADuplicateChassis(): Source chassis is NOT a FLEXHA Chassis!');

    // Call our common Duplicate function.
    const untypedDup = defDuplicateChassis(genericSource, insertCopyAt);

    // We should have a FlexHA Chassis with
    // an array of SA Power Supplies.
    const dup = (untypedDup ? untypedDup as FlexHAChassis : untypedDup);
    if (!dup || dup.saPSU == null)
        throw new Error('flexHADuplicateChassis(): Duplicate chassis is NOT a FLEXHA Chassis!');

    // Copy any SA Power Supplies.
    source.saPSU.forEach((saPSU) => {
        const dupPSU: FlexHASAPowerSupply = { ...saPSU };
        dupPSU.parent = dup;
        dupPSU.selected = false;
        copyAccys(saPSU, dupPSU);
        dup.saPSU.push(dupPSU);
    });

    return dup;
}

export const flexHADeleteSAPwr = (psu: SelectableDevice): boolean => {
    const chassis = getAsFlexHAChassis(psu.parent);
    if (chassis) {
        const idxSAPwr = chassis.saPSU.findIndex(ps => ps === psu);
        if (idxSAPwr >= 0) {
            // take a snapshot
            chassisChanging(chassis);
            // remove the psu - no need to sort array.
            chassis.saPSU.splice(idxSAPwr, 1);
            // update the chassis
            updateChassis(chassis, true);
            chassisChanged(chassis);
            const project = getProjectFromChassis(chassis);
            if (project) 
                updateAllChassis(project.content);
            
            return true;
        }
    }
    return false;
}

export const flexHAAddSAPwr = (utChassis: Chassis, idxIOBase: number): FlexHASAPowerSupply | undefined => {
    const chassis = getAsFlexHAChassis(utChassis);
    if (chassis && idxIOBase < (chassis.modules.length / 4)) {
        // If we do NOT have one yet...
        if (!chassis.saPSU.some(x => x.ioBaseIndex === idxIOBase)) {
            const saPwr = flexHACreatePowerSupply(flexHA_DefSAPsu) as FlexHASAPowerSupply;
            if (saPwr && saPwr.ioBaseIndex != null) {
                // take a snapshot
                chassisChanging(chassis);

                saPwr.parent = utChassis;
                saPwr.ioBaseIndex = idxIOBase;
                chassis.saPSU.push(saPwr);

                chassis.saPSU.sort(flexHASAPwrSortPredicate);

                // update the chassis
                updateChassis(chassis, true);
                chassisChanged(chassis);
                const project = getProjectFromChassis(chassis);
                if (project)
                    updateAllChassis(project.content);

                return saPwr;
            }
        }
    }
    return undefined;
}