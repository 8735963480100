import { Profile, User } from "oidc-client";
import { IProject } from "platform-services/dist/interfaces/IProject";
import { Id } from "react-toastify";
import { getLocationComponents, UrlComponents } from "../authentication/util/LocationSaveHelp";
import { onChassisLoaded } from "../implementation/ImplGeneral";
import {
    createConfigForChassisProj,
    updateAllChassis
} from "../model/ChassisProject";
import {
    setInitialPointEntryInfo,
    updateGuidedSelection
} from "../model/GuidedSelection";
import {
    addLocAttrInfoFromStrtDtls,
    getLocationAttrInfo,
    setAttrsFromSelectionArray
} from "../model/LocAttributeInfo";
import { BusCodeUrlParam, CIDUrlParam, DefaultPlatform, ProjGuidUrlParam } from "../platforms/PlatformConstants";
import {
    getPlatformFromBusCode,
    getPlatformFromFamily,
    isSupportedBusCode,
    isSupportedFamily,
} from "../platforms/PlatformIDs";
import { GSIndustry } from "../projInfo/LocationAndIndustry";
import { setConfigEverSaved } from "../redux/slices/SaveRestoreSlice";
import { store } from "../redux/store";
import configurationApiService from "../services/apis/ConfigurationApiService";
import projectApiService from "../services/apis/ProjectApiService";
import { ServiceResponse } from "../services/apis/Response";
import { LoadPlatform } from "../services/selectionAPIs/PlatformDataLoader";
import {
    AllowTestPlatformLoads,
    ExtraTestPlatforms,
    DEV_DEBUG
} from "../types/Globals";
import {
    ChassisProject,
    LoadStatus,
    LocAttributeInfo,
    locAttrStartupDetails
} from "../types/ProjectTypes";
import { getSavedConfigSnapshot, updateConfigFromLocalPersist } from "../userProject/LocalPersist";
import { loadProjItemInfo } from "../userProject/UserProjectHelp";
import {
    ConfigurationExchange,
    ProjectItem
} from "../userProject/UserProjectTypes";
import { CSA_VERSION, getVersionFromThumbprint } from '@csa-core/advisor.controlsystemcore' //"../version/Version";
import { getNewInstanceId } from "./InstanceIdHelp";
import { logger } from "./Logger";
import {
    makeEmptyPlatformInfo,
    PlatformInfo,
    updatePlatformInfoForLoad
} from "./PlatformHelp";
import { coreToastLoadProgress } from "../uiInterfaces/UIMessenger";


export enum StartupResult {
    OkToStart = 'OkToStart',
    InvalidCid = 'InvalidCid',
    GuidMismatch = 'GuidMismatch',
    InvalidConfigProj = 'InvalidConfigProj',
    InvalidProjGuid = 'InvalidProjGuid',
    NoUserProj = 'NoUserProj',
    InvalidConfig = 'InvalidConfig',
    BadPathPlatform = 'BadPathPlatform',
    BadBusCodePlatform = 'BadBusCodePlatform',
    NoPlatform = 'NoPlatform'
}


export interface StartupDetails {
    // 2024.10.9 Config version
    csaVersion?: string;

    // User's country and industry,
    // as best determinable at startup.
    country: string;
    industryID: string;

    // Primary (or default) platform.
    platform: string;

    // Set of all platforms that required successful
    // loads of all relevant platform-specific data.
    requiredPlatforms: Set<string>;

    // All platforms we'll at least attemp to load.
    availPlatforms: Set<string>;

    // If present, contains data retrieved from a
    // previously saved configuration, OR a configuration
    // retrieved from localStorage.
    configInfo?: ConfigurationExchange;

    // Set to true if configInfo is present AND its
    // source was from localStorage. Can be true or
    // false regarless of the cidInUserProj value.
    configFromLocalStg: boolean;

    // If present, contains data retrieved via the
    // projectApiService. When we have configInfo,
    // the userProj is the one referenced from that
    // config. Without configInfo, the userProj is
    // the LAST project the user touched. In general
    // use, when we HAVE configInfo, the project it
    // references will be the same as the last-used
    // project, but we can't always guarantee that.
    //userProj?: UserProject;

    projectGuid: string;

    // If present, contains the information to
    // create basic LocAttrInfo objects from
    // the projectApiService/configInfo. After
    // all async loads have completed, the
    // LocAttrInfo objects can be completed from
    // the LocAttrPersist data.
    locAttrDtls?: locAttrStartupDetails[];
}

//type typeStartupDtlsCallback = (okToStart: boolean, details: StartupDetails, errMsg?: string) => void;
type typeStartupDtlsCallback = (result: StartupResult, details: StartupDetails) => void;
type typePlatformsLoadedCallback = (
    details: StartupDetails,
    okToStart: boolean,
    errMsg: string
) => void;


// Helper.
const _addPlatform = (dtls: StartupDetails, platform: string, required: boolean) => {
    // If we get a platform...
    if (platform) {

        // If requird...
        if (required) {

            // First one in becomes the primary platform.
            if (!dtls.platform) {
                dtls.platform = platform;
            }

            // Add to required set.
            dtls.requiredPlatforms.add(platform);
        }

        // All get added to avail set.
        dtls.availPlatforms.add(platform);
    }
    else {
        throw new Error('ERROR: Empty platform in _addPlatform!');
    }
}

const _rehydrateConfig = (cfgStr: string): ChassisProject | undefined => {
    const ob = JSON.parse(cfgStr);

    // See if we can get it as a ChassisProject.
    const asConfig = ob as ChassisProject;

    // If it looks valid...
    if (asConfig && asConfig.content) {
        // Set the version to '0' if undefined.
        asConfig.ctrlSysAdvVersion = asConfig.ctrlSysAdvVersion ?? '0';

        // For each rack...
        asConfig.content.racks.forEach(rack => {

            // Rehook the chassis' parent ref.
            rack.chassis.parent = asConfig.content;

            // Call its onloaded helper
            onChassisLoaded(rack.chassis);
        });

        // And finally, rehook the content's
        // parent ref back up to the cfg.
        asConfig.content.parent = asConfig;

        // Return the configuration.
        return asConfig;
    }

    return undefined;
}

const _findPlatformsFromThumbprint = (dtls: StartupDetails, thumbprint: string): boolean => {

    // If we have anything provided...
    if (thumbprint) {

        // Try to 'rehydrate' it into a ChassisProject.
        const cfg = _rehydrateConfig(thumbprint);

        // If we can...
        if (cfg) {
            dtls.csaVersion = cfg.ctrlSysAdvVersion;

            // For each rack...
            cfg.content.racks.forEach(rack => {

                // Rehook the chassis' parent ref.
                rack.chassis.parent = cfg.content;

                // Call its onloaded helper
                onChassisLoaded(rack.chassis);

                // Collect any/all platforms encountered.
                // All will require successful platform loads.
                _addPlatform(dtls, rack.chassis.platform, true);
            });

            // For each LocAttrPersist...
            const arrLocDtls: locAttrStartupDetails[] = [];
            cfg.config.arrLocAttr.forEach((loc) => {
                // Add the platform
                _addPlatform(dtls, loc.platform, true);

                // Add needed information to the startup details.
                const locDtls: locAttrStartupDetails = {
                    platform: loc.platform,
                    idLocAttr: loc.id,
                    industryID: loc.industryID,
                    installLocID: loc.installLocID,
                }

                arrLocDtls.push(locDtls);
            });

            dtls.locAttrDtls = (arrLocDtls ? arrLocDtls : undefined);

            // TO DO:
            // What would happen if we allowed a configuration
            // to be saved that did NOT have any content. We
            // should probably prevent that, but if not, we
            // wouldn't really have any platforms at this point.
            // Return success for now.
            return true;
        }

        // See if we can parse the version from the
        // thumbprint. Allow a return of undefined
        // if the thumbprint is not at least 200 chars.
        const allowUndefinedVer = thumbprint.length < 200;
        dtls.csaVersion = getVersionFromThumbprint(thumbprint, allowUndefinedVer);
    }

    // Rehydrate failed.
    return false;
}

const _getPlatformFromUrlComps = (locInfo: UrlComponents):
    [result: StartupResult, platform: string] => {

    // Look at the path for family requested.
    const family = (locInfo.path.length > 1)
        ? locInfo.path.substring(1)
        : '';

    // If there is anything.
    if (family) {

        // Return based on whether we recognize it or not.
        if (isSupportedFamily(family)) {
            return [StartupResult.OkToStart, getPlatformFromFamily(family)];
        }
        else {
            return [StartupResult.BadPathPlatform, ''];
        }
    }

    // If we're still here, and have any search string...
    if (locInfo.search) {

        // See if we have a bus code.
        const srchParms = new URLSearchParams(locInfo.search);
        const busCode = srchParms.get(BusCodeUrlParam);

        // If so...
        if (busCode) {

            // Return based on whether we recognize it or not.
            if (isSupportedBusCode(busCode)) {

                // Return the associated platform.
                return [StartupResult.OkToStart, getPlatformFromBusCode(busCode)];
            }
            else {
                return [StartupResult.BadBusCodePlatform, ''];
            }
        }
    }

    // If we're still here, we'll return 
    // success and our platform default.
    return [StartupResult.OkToStart, DefaultPlatform];
}

const _getProjectItems = async (projectId: string) => {
    try {
        const result = await configurationApiService.getProjectItems(projectId);
        const response = new ServiceResponse(result);
        if (response.isSuccessful()) {
            const projItems = response.getData() as ProjectItem[];
            if (projItems && (projItems.length > 0)) {
                loadProjItemInfo(projItems);
            }
        }
    }
    catch (e) {
        logger.error('EXCEPTION: projectApiService.getProjectItems');
        logger.error('projectId: ' + projectId);
        logger.error(e);
    }
}

export const getProjectIndustry = async (projectId: string): Promise<string> => {
    let industryID = '';
    try {
        const result = await projectApiService.getProjectIndustry(projectId);
        const response = new ServiceResponse(result);
        if (response.isSuccessful()) {
            const industry = response.getData() as GSIndustry;
            if (industry && (industry.name.length > 0)) {
                industryID = industry.name;
            }
        }
    }
    catch (e) {
        logger.error('EXCEPTION: configurationApiService.getProjectIndustry');
        logger.error('projectId: ' + projectId);
        logger.error(e);
    }

    return industryID;
}


const _getUserProject = async (dtls: StartupDetails, toastId: Id, projectGuid?: string) => {
    try {
        coreToastLoadProgress(toastId, 'Getting project...')
        const result = projectGuid
            ? await projectApiService.getUserProjectByGuid(projectGuid)
            : await projectApiService.getLastUserOpenedProject()

        const response = new ServiceResponse(result);

        if (response.isSuccessful()) {
            const userProjData = response.getData() as IProject;
            if (userProjData && (userProjData.id > 0)) {
                coreToastLoadProgress(toastId, 'Getting project content...')
                await _getProjectItems(userProjData.guid);
                dtls.projectGuid = userProjData.guid;
                // Get the Project's Industry (if any)
                const industryID = await getProjectIndustry(userProjData.guid);
                if (industryID)
                    dtls.industryID = industryID;
            }
        }
    }
    catch (e) {
        if (projectGuid) {
            logger.error('EXCEPTION getting user project: ' + projectGuid);
            logger.error(e);
        }
    }
}

const _getRequestedConfig = async (dtls: StartupDetails, toastId: Id, configId: string) => {
    try {
        coreToastLoadProgress(toastId, 'Getting configuration...')

        // Attempt to get the requested configuration.
        const result = await configurationApiService.getConfiguration(configId);

        // If we're still here (didn't catch an exception),
        // Get a respons object that we can read.
        const response = new ServiceResponse(result);

        // If we got a saved configuration back...
        if (response.isSuccessful()) {

            // Get the contained data.
            const config = response.getData() as ConfigurationExchange;

            // If we can...
            if (config) {

                // We can only use it if it hasn't been
                // deleted. When deleted, the 'soft-delete'
                // date has a value in it (null of not).
                // If it HAS been deleted, just log a message.
                if (config.softDeleteDateUtc) {
                    logger.warn('Denying access to deleted configuration.')
                }
                else {
                    // Not deleted. If it has a proj guid...
                    if (config.projectId) {
                        // Remember that this one WAS saved at least once.
                        configurationApiService.recordConfigIdSaved(configId);


                        // Place the config into our details.
                        dtls.configInfo = config;

                        // And try to get the referenced user project.
                        await _getUserProject(dtls, toastId, dtls.configInfo.projectId);

                        store.dispatch(setConfigEverSaved(true));
                    }
                }
            }
        }
    }
    catch (e) {
        logger.warn('Failed to get requested configuration.')
    }
}

const _isRAUser = (profile: Profile): boolean => {
    if (profile.email && (profile.email.indexOf('rockwellautomation.com') > 0)) {
        return true;
    }
    if (profile.Company) {
        const cmp = profile.Company as string;
        if (cmp.toUpperCase() === 'ROCKWELL AUTOMATION') {
            return true;
        }
    }
    return false;
}

const _getUserCountryAndIndustry = (user: User | undefined): [country: string, industry: string] => {

    const userProfile = (user && user.profile)
        ? user.profile
        : undefined;

    const country = userProfile && userProfile.Country
        ? userProfile.Country
        : '';
    let industry = userProfile && userProfile.raJobIndustry
        ? userProfile.raJobIndustry
        : '';

    // If we have no industry at this point, and the
    // user appears to be RA, use 'Other'.
    if (DEV_DEBUG && !industry && userProfile && _isRAUser(userProfile)) {
        industry = 'Other';
    }

    return [country, industry];
}

export const establishStartupDetails = async (user: User | undefined,
    toastId: Id, callback: typeStartupDtlsCallback) => {

    // Try to establish default starting values
    // for install location and industry. 
    const [country, industry] = _getUserCountryAndIndustry(user);

    // Create dtls object.
    const dtls: StartupDetails = {
        country: country,
        industryID: industry,
        platform: '',
        requiredPlatforms: new Set<string>(),
        availPlatforms: new Set<string>(),
        //cidInUserProj: false,
        configFromLocalStg: false,
        projectGuid: ''
    }

    // Break our location down into components.
    const locInfo = getLocationComponents();
    const urlQueryParams = new URLSearchParams(locInfo.search);

    // Look for a projectGuid requested.
    let projGuidRequested: string | undefined = undefined;
    const projGuidVal = urlQueryParams.get(ProjGuidUrlParam);
    if (projGuidVal && (projGuidVal !== 'false')) {
        projGuidRequested = projGuidVal;
    }


    // NOTE: If we weren't given a user, our assumption
    // is that we're doing a prequalified 'provisional' start.
    // That is, the user is NOT fully authorized, but nothing was
    // found in the url preventing us from starting otherwise.
    // Provisional users are restricted to the Design page

    // See if we have a cid.
    const cidRequested = urlQueryParams.get(CIDUrlParam);

    // If so (and we have a user)...
    if (user && cidRequested) {

        // Attempt to retrieve it.
        await _getRequestedConfig(dtls, toastId, cidRequested);

        // If we can...
        if (dtls.configInfo) {
            // We got our config, which should ALSO have
            // retrieved user project info. If so...
            if (dtls.projectGuid) {

                // Check the projectGuid requested, if there was
                // one. If so, it should match that of the userProj
                // referenced from the configInfo. If not, error out.
                //if (projGuidRequested && (dtls.userProj.guid !== projGuidRequested)) {
                //    callback(StartupResult.GuidMismatch, dtls);
                //    return;
                //}
            }
            else {
                // If not, call back with error.
                callback(StartupResult.InvalidConfigProj, dtls);
                return;
            }
        }
    }

    // If we have a user and still don't have a
    // user project guid established at this point...
    if (user && !dtls.projectGuid) {

        // Try to get it.
        await _getUserProject(dtls, toastId, projGuidRequested);

        // If we can't, it's possible that our user HAS
        // no last-used project or some other error
        // occurred. Startup will NOT be allowed to continue.
        if (!dtls.projectGuid) {
            if (projGuidRequested) {
                callback(StartupResult.InvalidProjGuid, dtls);
                return;
            }
            // We now allow an authorized user to start WITHOUT having
            // a user project. The start, however, will be restricted to
            // the design page until they create one, similar to how we
            // deal with a user that isn't logged in. NOTE: This particular
            // case of being logged in and NOT having a project established
            // should ONLY occur if the user NEVER CREATED a project.
        //    else {
        //        callback(StartupResult.NoUserProj, dtls);
        //        return;
        //    }
        }
    }

    // If we have both a user project AND a cid...
    //if (dtls.userProj && cidRequested) {
    if (cidRequested) {

        // NO: We CAN have a cid at this point now, and NOT
        // have config info yet. That will be the case if
        // the config was never saved yet.
        // Sanity check. If we had a cid requested,
        // we should have successfully loaded a config
        // above if we're still here at this point.
        //if (!dtls.configInfo) {
        //    throw new Error('Unexpected Error: Missing config!');
        //}

        // Call a helper to potentially replace the
        // starting configuration found in our dtls.
        coreToastLoadProgress(toastId, 'Updating configuration...')
        dtls.configFromLocalStg = updateConfigFromLocalPersist(dtls, cidRequested);
    }

    // If we're still here, everything should be
    // ok so far. If we have config info...
    if (dtls.configInfo) {

        // Try to rehydrate our chassis project from the
        // saved thumbprint. Note that the rehydrate
        // function should also have provided all
        // needed platforms in our details.
        coreToastLoadProgress(toastId, 'Loading configuration...')
        const configRehydrated = _findPlatformsFromThumbprint(dtls, dtls.configInfo.configThumbprint)

        // If we can't, we can't continue.
        if (!configRehydrated) {
            // Try to pull the config version from
            // the thumbprint. Allow an undefined
            // version when TP less than 200 chars.
            const allowUndefinedVer = dtls.configInfo.configThumbprint.length < 200;
            dtls.csaVersion = getVersionFromThumbprint(dtls.configInfo.configThumbprint, allowUndefinedVer);

            // Failed to rehydrate.
            callback(StartupResult.InvalidConfig, dtls);
            return;
        }
    }
    else {
        // We're starting a NEW configuration.
        dtls.csaVersion = CSA_VERSION;

        const [presult, reqPlatform] = _getPlatformFromUrlComps(locInfo);

        if (presult === StartupResult.OkToStart) {
            _addPlatform(dtls, reqPlatform, true);
        }
        else {
            callback(presult, dtls);
            return;
        }
    }

    // Final call back.
    // If we have a primary platform established...
    if (dtls.platform) {

        // If we're supposed to ALSO include additionals
        // for testing purposes, add those as well. It doesn't
        // matter if any here are duplicates of platforms that
        // we've already added. For any that are NOT, we'll say
        // that they are NOT required. We'll still allow startup
        // if we fail to successfully load a platform that is
        // NOT specifically required.
        if (AllowTestPlatformLoads) {
            ExtraTestPlatforms.forEach(p => {
                _addPlatform(dtls, p, false);
            })
        }

        // Call back success.
        callback(StartupResult.OkToStart, dtls);
        return;
    }
    else {
        // No starting platform?
        callback(StartupResult.NoPlatform, dtls);
        return;
    }
}

// Checks to see if it's OK to do a 'provisional'
// startup for a user who is NOT logged in.
export const isUnauthorizedStartOk = (): boolean => {

    // Get search params from our current url.
    const searchParams = new URLSearchParams(window.location.search);

    // If it specs a projectGuid that's NOT empty and NOT with a
    // string value of 'false' (that check might no longer be reqd),
    // then the user NEEDs to be logged in to access the project.
    // Provisional startup is NOT allowed in that case.
    const projGuid = searchParams.get(ProjGuidUrlParam);
    if (projGuid && (projGuid !== 'false')) {
        return false;
    }

    // Next, check for a cid.
    const cidRequested = searchParams.get(CIDUrlParam);

    // If we have one...
    if (cidRequested) {
        // Try to find a saved snapshot matching
        // our cid. Note that we're ONLY interested
        // in one with an EMPTY project guid. 
        const savedSnapshot = getSavedConfigSnapshot(cidRequested, '');

        // Return true if we got one and false otherwise.
        return savedSnapshot ? true : false;
    }

    // If we're still here, the url contained NEITHER
    // a projectGuid NOR a cid. This will be a completely
    // new configuration, and we WILL allow a provisional
    // starup (user not logged in).
    return true;
}

const _getPlatformInfoForLoads = (dtls: StartupDetails): PlatformInfo => {
    const info = makeEmptyPlatformInfo();
    info.startPlatform = dtls.platform;
    info.platStat.set(dtls.platform, LoadStatus.Pending);
    dtls.availPlatforms.forEach(p => {
        if (!info.platStat.has(p)) {
            info.platStat.set(p, LoadStatus.Pending);
        }
    });

    return info;
}

const _isPlatformOk = (platform: string, platformInfo: PlatformInfo): boolean => {
    const stat = platformInfo.platStat.get(platform);
    if (stat) {
        switch (stat) {
            case LoadStatus.Ready:
                return true;

            case LoadStatus.Error:
                return false;

            default:
                throw new Error('Unexpected final load status for platform: ' + platform);
        }
    }
    else {
        throw new Error('Unexpected Error in _isPlaformOk for platform: ' + platform);
    }
}

const _checkPlatformLoadRslts = (dtls: StartupDetails, platformInfo: PlatformInfo):
    [okToStart: boolean, errMsg: string] => {

    // See how many required platforms we have.
    const numReqd = dtls.requiredPlatforms.size;

    // We should ALWAYS have at least one. If so...
    if (numReqd > 0) {

        // See if any are NOT ok to use.
        let allReqdOk = true;
        dtls.requiredPlatforms.forEach(p => {
            if (!_isPlatformOk(p, platformInfo)) {
                allReqdOk = false;
            }
        });

        // If all are ok...
        if (allReqdOk) {

            // Then check extras, those that are available,
            // but not required. Add any that did NOT successfully
            // load to a 'bad' set.
            const badExtras = new Set<string>();
            let allExtrasOk = true;
            dtls.availPlatforms.forEach(p => {
                if (!dtls.requiredPlatforms.has(p)) {
                    if (!_isPlatformOk(p, platformInfo)) {
                        allExtrasOk = false;
                        badExtras.add(p);
                    }
                }
            });

            // If all were ok, all platforms in our
            // startup details should be ready to use.
            if (allExtrasOk) {
                return [true, ''];
            }
            else {
                // We found one or more extras that failed.
                // For each, REMOVE the entry from the available
                // platforms set to prevent any attempted use.
                badExtras.forEach(p => {
                    dtls.availPlatforms.delete(p);
                });

                // For this case, we're still ok to start,
                // but we'll also include an error message.
                return [true, 'Loads of one or more EXTRA platforms FAILED.'];
            }
        }
        else {
            // Fail if any required platform load failed.
            return [false, 'Required platform could not be loaded.'];
        }
    }
    else {
        throw new Error('Unexpected Error in _checkPlatformLoadRslt!');
    }
}

export const loadStartupPlatforms = (dtls: StartupDetails, toastId: Id, callback: typePlatformsLoadedCallback) => {
    const arrPendingLocsToLoad: LocAttributeInfo[] = [];

    const _onPlatformsLoaded = (platform: string, loadOk: boolean) => {
        updatePlatformInfoForLoad(platformInfo, platform, loadOk);
        if (platformInfo.allComplete) {
           let executeCallback = true;
            const [okToStart, errMsg] = _checkPlatformLoadRslts(dtls, platformInfo);

            // If we are OK and we have Locations to load...
            if (okToStart && arrPendingLocsToLoad.length === 0) {

                // Start an array of loc attr startup
                // detail objects to be what we find in our
                // startup details, if any.
                let locAttrStartInfo = dtls.locAttrDtls;

                // If not...
                if (!locAttrStartInfo) {

                    // Then, if our startup dtls has BOTH
                    // industry and country...
                    if (dtls.industryID && dtls.country) {

                        // Create a new array.
                        locAttrStartInfo = new Array<locAttrStartupDetails>();

                        // And add start info for the platform,
                        // industry, and (initial) inst loc.
                        locAttrStartInfo.push({
                            idLocAttr: getNewInstanceId(),
                            platform: dtls.platform,
                            industryID: dtls.industryID,
                            installLocID: dtls.country
                        });
                    }
                }
                
                // If we ended up with ANY loc attr
                // starting information...
                if (locAttrStartInfo) {
                    // We have LocAttrs to load. We will NOT
                    // execute the callback in this function.
                    executeCallback = false;

                    // For each 'location' that we have...
                    locAttrStartInfo.forEach((locDtls) => {
                        // Create a LocAttrInfo from the details 
                        // and add it to the pending load array.
                        // As the Gudided Selection is loaded 
                        // into each location, the callback 
                        // _onLocAttrInitialized() is executed.
                        const locObj = addLocAttrInfoFromStrtDtls(locDtls);
                        if (locObj) {
                            locObj.id = locDtls.idLocAttr;
                            arrPendingLocsToLoad.push(locObj);
                        }
                    });

                    // We need to daisy-chain calls to
                    // updateGuidedSelection(). We found
                    // when timing issues when we updated
                    // more than one at a time.
                    if (arrPendingLocsToLoad.length > 0)
                        updateGuidedSelection(arrPendingLocsToLoad[0], _onLocAttrInitialized, true);
                    else
                        executeCallback = true;
               }
            }

            if (executeCallback) {
                callback(dtls, okToStart, errMsg);
            }
        }
    }

    const _onLocAttrInitialized = (success: boolean, locObj?: LocAttributeInfo) => {
        // We should always get the locObj that 
        // was passed into updateGuidedSelection(). 
        if (locObj == null)
            throw new Error('onLocAttrInitialized(): Invalid location returned');

        // Remove the ID...
        const spliceIdx = arrPendingLocsToLoad.findIndex(x => x === locObj);
        if (spliceIdx < 0)
            throw new Error('onLocAttrInitialized(): Location not found in pending array.');
        arrPendingLocsToLoad.splice(spliceIdx, 1);

        // If we have more to load...
        if (arrPendingLocsToLoad.length > 0) {
            updateGuidedSelection(arrPendingLocsToLoad[0], _onLocAttrInitialized, true);
        }
        else {
            // We should always succeed, but if we do NOT,
            // We are still OK to start the App...       
            callback(dtls, true, '');
        }
    }

    const platformInfo = _getPlatformInfoForLoads(dtls);

    if (!platformInfo.startPlatform || (platformInfo.platStat.size === 0)) {
        throw new Error('Invalid platform info in loadStartupPlatforms!');
    }

    platformInfo.platStat.forEach((stat, platform) => {
        LoadPlatform(platform, toastId, _onPlatformsLoaded);
    });
}

export const makeChassisProjFromStartupDtls = (dtls: StartupDetails):
    ChassisProject | undefined => {

    if (dtls.configInfo) {
        const cfg = _rehydrateConfig(dtls.configInfo.configThumbprint);
        if (cfg) {
            const instLoc = cfg.config.installLocID;
            const ind = cfg.config.industryID;
            const entryMode = cfg.config.IOEntryMode;

            // The user can change our configuration name on the
            // projects site. Get the name from the config info.
            const configInfoName = dtls.configInfo.name.trim();

            // If there's anything there after trimming, and if
            // it's NOT the same as we already have in our config,
            // use the changed name.
            if ((configInfoName.length > 0) && (configInfoName !== cfg.config.projectName)) {
                cfg.config.projectName = configInfoName;
            }

            updateAllChassis(cfg.content);

            // We always should have a Loc, but check.
            if (getLocationAttrInfo(cfg.config.currLocAttrID) == null) {
                logger.error('makeChassisProjFromStartupDtls(): No locations were saved!');
                const loc = createConfigForChassisProj(dtls.platform, instLoc, ind, entryMode);
                cfg.config.currLocAttrID = loc.currLocAttrID;
            }
            else {
                finalizeLocAttrFromStartupDtls(cfg);
            }

            return cfg;
        }
    }
    return undefined;
}

const finalizeLocAttrFromStartupDtls = (project: ChassisProject) => {
    if (project.config.arrLocAttr.length === 0)
        return;

    project.config.arrLocAttr.forEach((locPersist) => {
        const locAttr = getLocationAttrInfo(locPersist.id);
        if (locAttr) {
            // Update the settings in the LocAttrInfo.
            setAttrsFromSelectionArray(locPersist.settings, locAttr);

            // Update the I/O Entry Mode.
            locAttr.ioEntryMode = locPersist.ioEntryMode;

            // Create the PointEntry section.
            setInitialPointEntryInfo(locAttr, locPersist.ioSelections);
        }
    });

    // Clear out the persis data from the project
    project.config.arrLocAttr.length = 0;
}

