import React from 'react';
import './CommonToolbar.scss';
import { ToolbarAction } from '@csa-core/advisor.controlsystemcore'; // './ToolbarActions';
import { Button } from '@mui/material';
import { ChevronLeftIcon } from '@rockwell-automation-inc/ra-meatball';


interface Props {
    onTBAction: (action: string) => void;
}

const SelLeftPanelToolbar = (props: Props) => {

    return (
        <div className='common-toolbar common-toolbar-no-margins'>
            <Button
                id={ToolbarAction.DESIGN}
                variant='text'
                startIcon={<ChevronLeftIcon />}
                onClick={() => props.onTBAction(ToolbarAction.DESIGN)}
            >
                Back to Design
            </Button>
        </div>
    );
}

export default SelLeftPanelToolbar;
