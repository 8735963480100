import { ActBtnInfo, LayoutActionType } from '@csa-core/advisor.controlsystemcore'; // "../types/LayoutActions";
import { Chassis, ChassisProject, Rack, SelectableDevice } from '@csa-core/advisor.controlsystemcore'; // "../types/ProjectTypes";
import { getEmptyPt } from '@csa-core/advisor.controlsystemcore'; // "../util/GeneralHelpers";
import { LayoutMode } from '@csa-core/advisor.controlsystemcore'; // "../util/LayoutModeHelp";
import { ChassisCompProps } from '@csa-core/advisor.controlsystemcore'; // "./ImplGeneral";


export class GeneralUIImplBase {

    configureChassis(
        __project: ChassisProject,
        __platform: string,
        __selectChassisCallback: (chassis: Chassis | undefined) => void,
        __selectDeviceCallback: (device: SelectableDevice | undefined) => void,
        __contentChangedCallback: () => void,
        __chassis?: Chassis
    ): void {

        // Default does nothing
        return;
    }

    getChassisRenderer(
        __platform: string
    ): React.FC<ChassisCompProps> | undefined {

        // Default does nothing
        return undefined;
    }

    getActionBtnInfo(
        __action: LayoutActionType,
        __rack: Rack,
        __slotNum: number
    ): ActBtnInfo {

        // Default.
        return {
            action: __action,
            chassis: __rack.chassis,
            slot: __slotNum,
            ctrPt: getEmptyPt()
        };

    }

    hasGetActBtnInfo(
        __platform: string
    ): boolean {

        // Must implement default behavior
        throw new Error('GeneralUIImplBase::hasGetActBtnInfo() must be implemented');
    }

    doesSlotQualifyForBtnAction(
        __mode: LayoutMode,
        __type: LayoutActionType,
        __chassis: Chassis,
        __slotNum: number
    ): boolean {

        // Default - Slot Qualifies!
        return true;
    }
}