import React from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { Chassis } from '@csa-core/advisor.controlsystemcore'; // '../../types/ProjectTypes';
import { PlatformCLX } from '@csa-core/advisor.controlsystemcore'; // "../../platforms/PlatformConstants";
import {
    addDetailItem,
    DetailGroup,
    DetailGrpType,
    makeDetailGroup,
    makeDimensionsGrp
} from '@csa-core/advisor.controlsystemcore'; // '../../model/DeviceDetail';
import DetailsGroup from './DetailsGroup';
import { chassisHasController, getEnvRatingFromSpec } from '@csa-core/advisor.controlsystemcore'; // '../../model/ChassisProject';
import { getChassisDimensions } from '@csa-core/advisor.controlsystemcore'; // '../../implementation/ImplGeneral';

const getGenInfoGroup = (chassis: Chassis): DetailGroup => {
    const envRating =
        getEnvRatingFromSpec(chassis.extendedTemp, chassis.conformal);
    const highAvail =
        (chassis.redundant && chassisHasController(chassis));

    const genGrp = makeDetailGroup(DetailGrpType.Group);
    addDetailItem(genGrp, 'Environmental Rating', envRating.toString());

    // High avail controller (redundant) applicable only to CLX.
    if (chassis.platform === PlatformCLX) {
        addDetailItem(genGrp, 'High Availability Controller', highAvail ? 'Yes' : 'No');
    }

    return genGrp;
}

interface Props {
    chassis: Chassis;
}

const DetailsChassisSpecs = (props: Props) => {

    // 2024.10.1 Currently only displaying TOTAL
    // dimensions (i.e. combining banks if exist).
    const [chassisDims, /*arrBankDims*/] = getChassisDimensions(props.chassis);

    const dimGrp = makeDimensionsGrp(chassisDims); 

    const genGrp = getGenInfoGroup(props.chassis);
    return (
        <div className='floating-details-tab'>
            <DetailsGroup group={dimGrp} />
            <DetailsGroup group={genGrp} />
        </div>
    );
}

export default DetailsChassisSpecs;
