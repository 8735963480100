import React from 'react';
import { HdrErrState } from "./SettingHeader";
import { IconButton } from '@mui/material';
import './Settings.scss';
import InfoIcon from '../svg/information-circle.svg';
import FailedIcon from '../svg/failed.svg';
import WarningIcon from '../svg/errors.svg';
import { StatusLevel } from '@csa-core/advisor.controlsystemcore'; // '../types/MessageTypes';
import { displayAlertMsg } from '../util/MessageHelp';


export const getMessageStdIcon = (state: HdrErrState): StatusLevel => {
	switch (state) {
		case HdrErrState.error:
			return StatusLevel.Error;
		case HdrErrState.warning:
			return StatusLevel.Warning;
		case HdrErrState.info:
			return StatusLevel.Info;
	}

	return StatusLevel.NA;
}


export const getSettingLabelColor = (state: HdrErrState, defColor = '#000000'): string => {
	switch (state) {
		case HdrErrState.error:
			return '#DF3704';
		case HdrErrState.warning:
			return '#FDB81E';
	}

	return defColor;
}


export interface Props {
	state: HdrErrState;
	iconSize: string;
	message?: string;
}

export const SettingIconButton = (props: Props) => {
	const disable = (props.message == null);

	// Define our onClick function for the IconButton.
	const onInfo = () => {
		if (props.message)
			displayAlertMsg(props.message, getMessageStdIcon(props.state));
	};

	let szIcon = Number(props.iconSize.replace('px', ''));
	if (isNaN(szIcon)) {
		// Assume a 'rem' or 'em' passed in and multiply by 16.
		// Not sure what to do if a '%' or some other font size
		// unit is passed in???
		szIcon = Number(props.iconSize.replace(/\D/g, '')) * 16;
	}

	switch (props.state) {
		case HdrErrState.none:
			break;
		default:
		case HdrErrState.info:
			return (
				<IconButton
					disabled={disable}
					onClick={onInfo}>
					<img src={InfoIcon} width={szIcon} className={disable ? "setting-icon-btn-svg-disabled" : "setting-icon-btn-svg-info"} />
				</IconButton>
			);
		case HdrErrState.warning:
			return (
				<IconButton
					disabled={disable}
					onClick={onInfo}>
					<img src={WarningIcon} width={szIcon} className={disable ? "setting-icon-btn-svg-disabled" : "setting-icon-btn-svg-warning"} />
				</IconButton>
			);
		case HdrErrState.error:
			return (
				<IconButton
					disabled={disable}
					onClick={onInfo}>
					<img src={FailedIcon} width={szIcon} className={disable ? "setting-icon-btn-svg-disabled" : "setting-icon-btn-svg-error"} />
				</IconButton>
			);
	}

	return <span></span>;
}