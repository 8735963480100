import React, { useState } from 'react';
import Konva from 'konva';
import { Image } from 'react-konva';
import GreenCheckIcon from './precoloredSvgs/green-good.svg';
import BlueInfoIcon from './precoloredSvgs/blue-information-circle.svg';
import YellowWarningIcon from './precoloredSvgs/yellow-errors.svg';
import RedErrorIcon from './precoloredSvgs/red-failed.svg';
import { Point } from '@csa-core/advisor.controlsystemcore'; // '../../types/SizeAndPosTypes';
import { Chassis } from '@csa-core/advisor.controlsystemcore'; // '../../types/ProjectTypes';
import { LogMsgLevel } from '@csa-core/advisor.controlsystemcore'; // '../../util/ProjectLog';
import { displayChassisLogMessages } from '../../projStatus/ProjectStatusDisplay';
import { getChassisIconLocation } from '@csa-core/advisor.controlsystemcore'; // '../../util/NameHelp';
import { useCachedImage } from '../../imageHelp/useCachedImage';
import { Theme } from '@rockwell-automation-inc/ra-meatball';
//import { LogRender } from '../../types/Globals';
//import { logger } from '../../util/Logger';


const getIconImageSrc = (msgLevel: LogMsgLevel) => {
    switch (msgLevel) {
        case LogMsgLevel.warning:
            return YellowWarningIcon;

        case LogMsgLevel.info:
            return BlueInfoIcon;

        case LogMsgLevel.none:
            return GreenCheckIcon;

        default:
            return RedErrorIcon;
    }
}

const shadowOffset = 4;

export interface Props {
    chassis: Chassis;
    ptOrg: Point;
    status: LogMsgLevel;
    contentChanged: () => void;
}

export const ChassisStatusIconBtn = (props: Props) => {
    const [isMouseover, setIsMouseover] = useState(false);
    const [imgSrc, /*imgSrcStatus*/, /*imgSize*/] = useCachedImage(getIconImageSrc(props.status));

    const iconLoc = getChassisIconLocation(props.ptOrg);

    const onPointerDown = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;

        if (props.chassis.statusLog)
            displayChassisLogMessages(props.chassis.statusLog, props.chassis, props.contentChanged);
    }

    const onMouseOver = (e: Konva.KonvaEventObject<PointerEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'pointer';
        }
        e.cancelBubble = true;
        setIsMouseover(true);
    }

    const onMouseOut = (e: Konva.KonvaEventObject<PointerEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'default';
        }
        e.cancelBubble = true;
        setIsMouseover(false);
    }

    //if (LogRender.Layout) {
    //    logger.logRender('Stat icon for: ' + props.chassis.name);
    //}

    return (
        <Image
            image={imgSrc}
            {...iconLoc}
            shadowEnabled={isMouseover}
            shadowColor={Theme.palette.lightGrey.main}
            shadowOffsetX={shadowOffset}
            shadowOffsetY={shadowOffset}
            shadowOpacity={0.8}
            onPointerDown={onPointerDown}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        />
    );
}

export default React.memo(ChassisStatusIconBtn);

