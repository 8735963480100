
// 2024.5.30 Note: Projects SAVE the Platform IDs. If
// these are changed (even case), WE WILL GET AN INVALID
// PLATFORM in the startup details for any project
// saved with the old ID!!!
export const PlatformCLX = 'ControlLogix';  // 1756
export const PlatformCpLX = 'CompactLogix'; // 5069
export const PlatformFlex = 'FLEX 5000';    // 5094
export const PlatformFlexHA = 'FLEXHA 5000';// 5015 
export const PlatformMicro= 'Micro800';// 2080

export const BusCodeCLX = 'CS_1756';
export const BusCodeCpLX = 'CS_5069';
export const BusCodeFlex = 'CS_5094';
export const BusCodeMicro = 'CS_M800';
export const BusCodeFlexHA = 'CS_5015';     

export const FamilyCLX = '1756';
export const FamilyCpLX = '5069';
export const FamilyFlex = '5094';
export const FamilyMicro = '2080';
export const FamilyFlexHA = '5015';        

export const DefaultPlatform = PlatformCLX;

export const ProjGuidUrlParam = 'projectGuid';
export const CIDUrlParam = 'cid';
export const BusCodeUrlParam = 'Business';

