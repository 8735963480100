import React from 'react';
import { addButton, ModalRequestSpec, requestModal } from '../modals/ModalHelp';
import { launchOldAddModule } from '../selection/ModuleSelectionUtil';
import { Chassis, getSelectedCompSlotQuantity, ModalStatus, selectCompsCallback, SelectCompsExchangeData } from '@csa-core/advisor.controlsystemcore'; // '../types/ProjectTypes';
import { CompChoiceDetail, SelectComponentsProps, SelectedCompInfo } from '@csa-core/advisor.controlsystemcore'; // './SelectComponentsTypes';
import { SelectComponents } from './SelectComponents';
import { EngInfoModule } from '@csa-core/advisor.controlsystemcore'; // '../engData/EngineeringInfo';
import {
    getMaxNewModules,
    getModuleSelectionInfo,
    getSlotTypeRestriction
} from '@csa-core/advisor.controlsystemcore'; // '../implementation/ImplGeneral';
import { isServerEnvProd } from '@csa-core/advisor.controlsystemcore'; // '../util/ServerEnvironment';
import { imageRepository } from '@csa-core/advisor.controlsystemcore'; // '../imageHelp/ImageRepos';


// DEVELOPER SWITCH
// Determines whether NEW component selection method is used.
// IMPORTANT: During development, keep the "DEV_DEBUG &&" part
// in place to prevent accidental delivery to Dev or QA.
//const UseNewSelectComps = DEV_DEBUG && false;
// 2024.5.2 turn on for QA testing.
const UseNewSelectComps = true;

const _btnText = 'ADD';

// Temporary configurability. Will most likely change as
// SelectComponentsProps is finalized. Provided for now
// to give examples of how to quickly and easily build
// out detail of each component we're allowing as a 
// possible selection.
const _wantDetailedChoices = true;
const _wantTypeFilters = true;


//interface SelectCompsExchangeData {
//    chassis: Chassis;
//    initialSlot: number;
//    contentChangedCallback: () => void,
//    selectorProps: SelectComponentsProps;
//}


// Helper function. Creates a sorted array of strings
// containing all types and subtypes found in the proved
// array of module info.
const getTypeFiltersFor = (mods: EngInfoModule[]): string[] => {
    const filterSet = new Set<string>();

    mods.forEach(mod => {
        filterSet.add(mod.type);
        if (mod.subType1) filterSet.add(mod.subType1);
        if (mod.subType2) filterSet.add(mod.subType2);
    })

    return Array.from(filterSet.values()).sort();
}

//const mapModCatToSlots = new Map<string, number>();

//export const getSlotsOccupied = (platform: string, catalog: string) => {
//    // If we have it in the map...
//    const slotsOccupied = mapModCatToSlots.get(catalog);
//    if (slotsOccupied)
//        return slotsOccupied;

//    // Look it up.
//    return getSelectedCompSlotQuantity(platform, [{ catNo: catalog, quantity: 1, description: '', isSelected: false }]);
//}


//export const getSelectedCompSlotQuantity = (platform: string, sels: SelectedCompInfo[]): number => {
//    let slotsQty = 0;
//    sels.forEach(sel => {
//        const slotsOccupied = mapModCatToSlots.get(sel.catNo);
//        // If we have it in the map...
//        if (slotsOccupied)
//            slotsQty += sel.quantity * slotsOccupied;
//        else {
//            // Look up how many slots the module occupies.
//            // For things that are not module, we default
//            // them to '1'.
//            let slotsUsedSet = false;
//            const info = getEngInfoForComp(platform, sel.catNo);
//            if (info && info.isModule) {
//                // Cast to module info...
//                const modInfo = info as EngInfoModule;
//                if (modInfo.slotsUsed > 0) {
//                    mapModCatToSlots.set(sel.catNo, modInfo.slotsUsed);
//                    slotsQty += sel.quantity * modInfo.slotsUsed;
//                    slotsUsedSet = true;
//                }
//            }
//            // If we did not find it as a module...
//            if (!slotsUsedSet) {
//                // Set the slots occupied to 1.
//                mapModCatToSlots.set(sel.catNo, 1);
//                slotsQty += sel.quantity
//            }
//        }
//    });

//    return slotsQty;
//}

//// Callback from modal completion.
//const selectCompsCallback = (status: number, data?: object) => {

//    // We only care if we got a confirm. If so...
//    if (status === ModalStatus.Confirmed) {

//        // Cast the data back to our exchange data.
//        const exchData = data as SelectCompsExchangeData;

//        // If we can...
//        if (exchData) {
//            // Get the selector props out of that.
//            const selProps = exchData.selectorProps;

//            // Determine the total number of modules to add.
//            const totalQty = getSelectedCompSlotQuantity(exchData.chassis.platform, selProps.selections);

//            // If any...
//            if (totalQty > 0) {
//                // Call a helper to add what was selected.
//                addModulesToChassis(exchData.chassis, selProps.selections,
//                    totalQty, exchData.initialSlot);

//                // Notify that the project has changed.
//                exchData.contentChangedCallback();

//                const project = getProjectFromChassis(exchData.chassis);
//                if (project)
//                    updateAllChassis(project.content);
//            }

//            // Toss our cache...
//            mapModCatToSlots.clear();
//        }
//    }
//}


// MAIN Launch function. Called from ChassisLayoutView when
// a user clicks on a '+' icon on an empty chassis slot.
export const LaunchSelectCompsFor = (chassis: Chassis, initialSlot: number, onContentChanged: () => void, event?: PointerEvent) => {

    // Determine if we should launch the Old Version..
    let useOld = !UseNewSelectComps;
    if (useOld && event) {
        // If we are in ANY server environment other than PRODUCTION...
        if (!isServerEnvProd()) {
            // If the Ctrl and Shift keys were preesed...
            if (event.shiftKey && event.ctrlKey) {
                // Use the New AddModule Dialog.
                useOld = false;
            }
        }
    }

    if (useOld) {
        launchOldAddModule(chassis, initialSlot, onContentChanged);
        return;
    }

    // Initialize the most basic form of the props that
    // will actually get to the SelectComponents U/I.
    const selectCompsProps: SelectComponentsProps = {
        selections: new Array<SelectedCompInfo>(),
    }

    //const firstSlot = (initialSlot === 0);
    const modRestriction = getSlotTypeRestriction(chassis, initialSlot);

    // Build array of catalog numbers for all modules
    // that COULD be added to the requested chassis.
    const suitableCatNos = new Array<string>();

    const suitableMods = getModuleSelectionInfo(chassis, modRestriction);
    suitableMods.forEach(modInfo => {
        // If the module has an alias, we MUST
        // pass the actual catalog in here. An API
        // is called to validate and get info and 
        // any catalogs that fail are omitted.
        //suitableCatNos.push(modInfo.catNo);
        suitableCatNos.push(modInfo.getAlias());
    });

    // Add the associated prop.
    selectCompsProps.catNoChoices = suitableCatNos;

    // Call a helper to tell us the max number of modules
    // that could be added, and set the associated prop.
    // Note: getMaxNewModules() will treat slot fillers 
    // as empty slots (3rd param true).
    selectCompsProps.maxQuantity = getMaxNewModules(chassis, modRestriction, true);

    // If configured above, add 'detailed choices' above.
    if (_wantDetailedChoices) {
        const details = new Array<CompChoiceDetail>();

        const imgSite = imageRepository.getUrl();
        suitableMods.forEach(modInfo => {
            const compDtl: CompChoiceDetail = {
                catNo: modInfo.catNo,
                description: modInfo.description,
                type: modInfo.type,
                subType1: modInfo.subType1,
                subType2: modInfo.subType2
            }

            if (modInfo.imgName) {
                compDtl.imgSrc = imgSite + modInfo.imgName;
            }

            details.push(compDtl);
        });

        selectCompsProps.detailedChoices = details;
    }

    // If configured above, add potential filters.
    if (_wantTypeFilters) {
        selectCompsProps.filters = getTypeFiltersFor(suitableMods);
    }

    // Create an object we'll use to exchange everything
    // needed with our 'wrapper'. Here we combine the 
    // things we need to remember (chassis, slot, and 
    // the onContentChanged callback) along with the 
    // props we'll use for the SelectComponts itself.
    const reqData: SelectCompsExchangeData = {
        chassis: chassis,
        initialSlot: initialSlot,
        contentChangedCallback: onContentChanged,
        selectorProps: selectCompsProps
    }

    // Build our full modal request specification.
    const mdlReqSpec: ModalRequestSpec = {

        // Configurable as desired:
        includeButtons: true,
        closeOnInsideClick: false,
        stayOpenOnBackdropClick: true,
        width: Math.min(window.innerWidth - 50, 1500),
        title: 'Add Modules',

        // Our final callback.
        callback: selectCompsCallback,

        // Exchange data
        requestorData: reqData,

        // The (inside) content of the modal will
        // be our wrapper component (defined below).
        content: SelectCompsWrapper
    }

    const btnInfo = addButton(mdlReqSpec, _btnText,
        ModalStatus.Confirmed, 'contained');
    btnInfo.disabled = true;

    // Trigger the modal popup.
    requestModal(mdlReqSpec);
}


export const SelectCompsWrapper = (propsModal: ModalRequestSpec) => {

    const exchData = propsModal.requestorData as SelectCompsExchangeData;

    const checkResults = (selections: SelectedCompInfo[]) => {
        if (propsModal.buttonInfo && propsModal.fullRender) {
            const qtySelected = getSelectedCompSlotQuantity(exchData.chassis.platform, selections);
            const anySelected = qtySelected > 0;
            const restrict = getSlotTypeRestriction(exchData.chassis, exchData.initialSlot);

            // Note: getMaxNewModules() will treat Slot
            // Fillers and Interconnect Cables as empty 
            // slots (3rd param true).
            const maxExceeded = (qtySelected > getMaxNewModules(exchData.chassis, restrict, true));

            const addBtn = propsModal.buttonInfo[0];
            const wasDisabled = addBtn.disabled;
            const disable = (!anySelected || maxExceeded);
            if ((disable !== wasDisabled)) {
                addBtn.disabled = disable;
                propsModal.fullRender();
            }
        }
    }

    exchData.selectorProps

    return (
        <SelectComponents
            {...exchData.selectorProps}
            checkResults={checkResults}
        />
    );
}
