import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

//import createOidcMiddleware from 'redux-oidc';
//import { reducer as oidcReducer } from "redux-oidc";
//import { AuthUserManager } from '../authentication/util/AuthService';


// Note: Since the core produces Declaration files,
// we need to import the State Interfaces. Otherwise
// compilation will fail.
import viewInfoReduced, { ViewInfoState } from './slices/ViewSlice';
import startupInfoReduced, { StartupInfoState } from './slices/StartupInfoSlice';
import saveRestInfoReduced, { SaveRestInfoState } from './slices/SaveRestoreSlice';
import layoutTipInfoReduced, { LayoutTipInfoState } from './slices/LayoutViewTipSlice'

const rootPersistConfig = {
    key: "root",
    storage, // defaults to local storage
    whitelist: ['oidc'] // Only oidc will be persisted
};

const rootReducer = combineReducers({
    //oidc: oidcReducer,
    viewInfo: viewInfoReduced,
    startupInfo: startupInfoReduced,
    saveRestInfo: saveRestInfoReduced,
    layoutTipInfo: layoutTipInfoReduced,
});

//const oidcMiddleware = createOidcMiddleware(AuthUserManager);

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);


export const store = configureStore({
	reducer: persistedReducer,
    //middleware: [thunk, oidcMiddleware],
    middleware: [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
