import {
    PlatformCLX,
    PlatformCpLX,
    PlatformFlex,
    PlatformFlexHA,
    PlatformMicro,
} from '@csa-core/advisor.controlsystemcore'; // "../platforms/PlatformConstants";
import { LayoutMode } from '@csa-core/advisor.controlsystemcore'; // "../util/LayoutModeHelp";
import { ActBtnInfo, LayoutActionType } from '@csa-core/advisor.controlsystemcore'; // "../types/LayoutActions";
import { Chassis, ChassisProject, Rack, SelectableDevice } from '@csa-core/advisor.controlsystemcore'; // "../types/ProjectTypes";
import { GeneralUIImplBase } from "./GeneralUIImplBase";
import { ChassisCompProps } from '@csa-core/advisor.controlsystemcore'; // "./ImplGeneral";
import { createCLXGeneralUIImpl } from "../platforms/clx/model/CLXGeneralUIImpl";
import { createCpLXGeneralUIImpl } from "../platforms/cplx/model/CpLXGeneralUIImpl";
import { createFlexGeneralUIImpl } from "../platforms/flex/model/FlexGeneralUIImpl";
import { createFlexHAGeneralUIImpl } from "../platforms/flexHA/model/FlexHAGeneralUIImpl";
import { createMicroGeneralUIImpl } from "../platforms/micro/model/MicroGeneralUIImpl";


class GeneralUIImplManager extends GeneralUIImplBase {

    platformClasses: Map<string, GeneralUIImplBase>;

    constructor() {
        super();
        this.platformClasses = new Map<string, GeneralUIImplBase>();
        this.platformClasses.set(PlatformCLX, createCLXGeneralUIImpl());
        this.platformClasses.set(PlatformCpLX, createCpLXGeneralUIImpl());
        this.platformClasses.set(PlatformFlex, createFlexGeneralUIImpl());
        this.platformClasses.set(PlatformFlexHA, createFlexHAGeneralUIImpl());
        this.platformClasses.set(PlatformMicro, createMicroGeneralUIImpl());
    }


    configureChassis(
        project: ChassisProject,
        platform: string,
        selectChassisCallback: (chassis: Chassis | undefined) => void,
        selectDeviceCallback: (device: SelectableDevice | undefined) => void,
        contentChangedCallback: () => void,
        chassis?: Chassis): void {

        const platformClass = this.platformClasses.get(platform);
        if (platformClass == null)
            throw new Error(`GeneralUIImplManager: platform ${platform} must be implemented`);

        platformClass.configureChassis(
            project,
            platform,
            selectChassisCallback,
            selectDeviceCallback,
            contentChangedCallback,
            chassis
        );
    }

    getChassisRenderer(platform: string): React.FC<ChassisCompProps> | undefined {
        const platformClass = this.platformClasses.get(platform);
        if (platformClass == null)
            throw new Error(`GeneralUIImplManager: platform ${platform} must be implemented`);

        return platformClass.getChassisRenderer(platform);
    }

    getActionBtnInfo(
        action: LayoutActionType,
        rack: Rack,
        slotNum: number
    ): ActBtnInfo {
        const platform = rack.chassis.platform;
        const platformClass = this.platformClasses.get(platform);
        if (platformClass == null)
            throw new Error(`GeneralUIImplManager: platform ${platform} must be implemented`);

        return platformClass.getActionBtnInfo(
            action,
            rack,
            slotNum
        );
    }

    hasGetActBtnInfo(
        platform: string
    ): boolean {
        const platformClass = this.platformClasses.get(platform);
        if (platformClass == null)
            throw new Error(`GeneralUIImplManager: platform ${platform} must be implemented`);

        return platformClass.hasGetActBtnInfo(platform);
    }

    doesSlotQualifyForBtnAction(
        mode: LayoutMode,
        type: LayoutActionType,
        chassis: Chassis,
        slotNum: number
    ): boolean {
        const platform = chassis.platform;
        const platformClass = this.platformClasses.get(platform);
        if (platformClass == null)
            throw new Error(`GeneralUIImplManager: platform ${platform} must be implemented`);

        return platformClass.doesSlotQualifyForBtnAction(mode, type, chassis, slotNum);
    }
}

// General UI Implementation Manager - Create on first use.
let manager: GeneralUIImplManager | undefined = undefined;
export const getGeneralUIImplMgr = (): GeneralUIImplManager => {
    if (manager)
        return manager;

    manager = new GeneralUIImplManager();
    return manager;
}