import React from 'react';
import ChoiceGroup from '../../components/choices/ChoiceGroup';
import GuidedSelChoiceGroup from '../../components/choices/GuidedSelGroup';
import { ChoiceInfo, ChoiceInfoType, GroupChoiceInfo, GuidedSelChoiceInfo, StatusLevel, PanelMessage } from '@csa-core/advisor.controlsystemcore'; // '../../types/MessageTypes';
import { ProjectSetting } from '@csa-core/advisor.controlsystemcore'; // '../../types/SettingsTypes';
import { CfgDlgTabID, ConfigChassisCBData } from '@csa-core/advisor.controlsystemcore'; // './ChassisConfig';
import VerticalTab from '../../components/choices/VerticalTab';
import ChassisNameEditor from '../../components/ChassisNameEditor';
import MessagePanel from '../../components/MessagePanel';
import { isNewStatusWorse } from '@csa-core/advisor.controlsystemcore'; // '../../util/GeneralHelpers';
import { Point } from '@csa-core/advisor.controlsystemcore'; // '../../types/SizeAndPosTypes';
import FloatingMessagePanel from '../../components/FloatingMessagePanel';
import { isPlatformSnapType } from '@csa-core/advisor.controlsystemcore'; // '../../util/PlatformHelp';
import { PlatformFlex, PlatformFlexHA } from '@csa-core/advisor.controlsystemcore'; // '../PlatformConstants';


interface SelProps {
	data: ConfigChassisCBData;
	selectionChanged: () => void;
	gsSelectionChanged: (set: ProjectSetting, newChoice: ChoiceInfo) => void;
}


export const CfgDlgChassisSels = (props: SelProps) => {
	let nextKey = 0;
	return (
		<div className='config-config-chassis-sels'>
			{
				props.data.ChassisPage.map((grp) => {
					if (grp.infoType === ChoiceInfoType.GuideSel) {
						const gsGrp = grp as GuidedSelChoiceInfo;

						return (
							<GuidedSelChoiceGroup
								grpInfo={gsGrp}
								gsSetting={gsGrp.setting}
								key={++nextKey}
								outlinedSelectors={true}
								gsSelectionChanged={props.gsSelectionChanged}
							/>);
					}
					else {
						const chGrp = grp as GroupChoiceInfo
						return (
							<ChoiceGroup
								grpInfo={chGrp}
								selectionChanged={props.selectionChanged}
								outlinedSelectors={true}
								key={++nextKey}
							/>
						)
					}
				})
			}
		</div>
	);
}

const usesStaticPwrTabText = (platform: string) => {
	if (isPlatformSnapType(platform) || platform === PlatformFlexHA)
		return true;
	return false;
}

const _renderStaticPwrTabText = (platform: string) => {
	// Note: We are using the ' ' + ' ' concatenation syntax
	// because the back-tick ` ` syntax causes formatting
	// issues with CSS "white-space: pre-wrap', which is
	// needed to pick up '\n' new line escape sequences. 

	const headerText = 'Important Information:';
	const footerText = 'Additional information on how to calculate/connect Power Supplies can be found in the Technical Documentation ' +
		'Center (links provided with Module Details for a selected Module)';

	// So far we just have CompactLogix and Flex that
	// will have static POWER text in the config dialog.
	// Start with the text for CompactLogix.
	let bodyText = 'Power Supplies are available as recommended accessories on Controllers, EtherNet/IP Adapters, ' +
		'and Field Power Distributors (FPDs). You can manually add the required number of power supplies depending upon your configuration.';

	if (platform === PlatformFlex)
		bodyText = 'Power Supplies are available as recommended accessories on Adapters. You can manually add the required number of ' +
			'power supplies depending upon your configuration requirements.Please Note that SA power to I/O Modules must be wired directly ' +
			'to the SA Power Terminals on each I/O Module that requires it.';
	else if (platform === PlatformFlexHA)
		bodyText = 'MOD Power Supplies are automatically included in the configuration for each FLEXHA 5000 I/O System. Separate power ' +
			'supplies provide redundant power to the Power Conditioners. Each power supply should be fed with a separate AC source to ' +
			'maintain redundancy in the event of an AC power failure.\n\n' +
			'SA Power Supplies are available as Recommended accessories on each I/O Base. SA Power can be extended across multiple I/O ' +
			'bases to meet your System\'s needs.';

	return (
		<>
			<div className="config-chassis-power-tab-static-text-header">
				{headerText}
			</div>
			<div className="config-chassis-power-tab-static-text-body">
				{bodyText}
			</div>
			<div className="config-chassis-power-tab-static-text-body">
				{footerText}
			</div>
		</>
	);
}

export const CfgDlgPowerSupplySels = (props: SelProps) => {
    let nextKey = 0;

    return (
        <div className="config-config-power-supply-sels">
			{usesStaticPwrTabText(props.data.cfgAttrInfo.platform) ? (
				<div key={++nextKey}>
					{/*Render static text for platforms that will not have Power Tab choices.*/ }
					{_renderStaticPwrTabText(props.data.cfgAttrInfo.platform)}
                </div>
            ) : (
                props.data.PowerPage.map((grp) => {
                    if (grp.infoType === ChoiceInfoType.GuideSel) {
                        const gsGrp = grp as GuidedSelChoiceInfo;
                        return (
                            <GuidedSelChoiceGroup
                                grpInfo={gsGrp}
                                gsSetting={gsGrp.setting}
                                key={++nextKey}
                                outlinedSelectors={true}
                                gsSelectionChanged={props.gsSelectionChanged}
                            />
                        );
                    } else {
                        const chGrp = grp as GroupChoiceInfo;
                        return (
                            <ChoiceGroup
                                grpInfo={chGrp}
                                selectionChanged={props.selectionChanged}
                                outlinedSelectors={true}
                                key={++nextKey}
                            />
                        );
                    }
                })
            )}
        </div>
    );
};
  
interface CfgDlgProps {
	data: ConfigChassisCBData;
	selectionChanged: () => void;
	gsSelectionChanged: (gsSetting: ProjectSetting, newChoiceInfo: ChoiceInfo) => void;
	onTabSelected: (tabID: number) => void;
	onCloseInfoPanel: () => void;
	infoPanelOpen: boolean;
	currentTab: number;
	inclOldStyleMsgPanel: boolean;
	infoPt: Point;
	infoMsgs: PanelMessage[];
}

const renderTab = (tabID: number, props: CfgDlgProps) => {
	switch (tabID) {
		case CfgDlgTabID.Chassis:
			return (
				<CfgDlgChassisSels
					data={props.data}
					selectionChanged={props.selectionChanged}
					gsSelectionChanged={props.gsSelectionChanged}
				/>
			);

		case CfgDlgTabID.PowerSupply:
			return (
				<CfgDlgPowerSupplySels
					data={props.data}
					selectionChanged={props.selectionChanged}
					gsSelectionChanged={props.gsSelectionChanged}
				/>
			);

		default:
			return (
				<MessagePanel
					messages={props.data.panelMessages}
				/>
			);
	}
}

const ChassisConfigDlg = (props: CfgDlgProps) => {

	const cfgDlgBottomRedText = 'Click on all error or warning status icons and review messages before saving.';
	const showBtmText = isNewStatusWorse(props.data.msgLevel, StatusLevel.Info);

	const onNameEditFinished = (cancelled: boolean, newName: string | undefined) => {
		// If editing wasn't cancelled...
		if (!cancelled) {
			if (newName && (newName.length > 0)) {
				props.data.chassisName = newName;
			}
		}
	}

	return (
		<div>
			<div className='config-chassis-name-area'>
				<ChassisNameEditor
					startValue={props.data.chassisName}
					onFinished={onNameEditFinished}
				/>
			</div>
			<div className='config-chassis-content'>
				<div className='config-chassis-vert-tab-area'>
					<span className='a-type__body config-chassis-vert-tab-title-text'>
						{'Categories'}
					</span>
					<VerticalTab
						text={'Chassis Settings'}
						id={CfgDlgTabID.Chassis}
						isSelected={CfgDlgTabID.Chassis === props.currentTab}
						onSelect={props.onTabSelected}
					/>
					<VerticalTab
						text={'Power Settings'}
						id={CfgDlgTabID.PowerSupply}
						isSelected={CfgDlgTabID.PowerSupply === props.currentTab}
						onSelect={props.onTabSelected}
					/>
					{props.inclOldStyleMsgPanel
						? <VerticalTab
							text={'Old messages'}
							id={CfgDlgTabID.Messages}
							isSelected={CfgDlgTabID.Messages === props.currentTab}
							onSelect={props.onTabSelected}
						/>
						: null}
				</div>
				<div className='config-chassis-tab-content'>
					{renderTab(props.currentTab, props)}
				</div>
			</div>
			{showBtmText
				? <span className='config-chassis-btm-text'>
					{cfgDlgBottomRedText}
				</span>
				: null}
			{props.infoPanelOpen
				? <FloatingMessagePanel
					ptCenter={props.infoPt}
					messages={props.infoMsgs}
					onClose={props.onCloseInfoPanel}
				/>
				: null}
		</div>
	);
}

export default ChassisConfigDlg;
