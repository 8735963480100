import React, { useCallback, useEffect, useRef, useState } from 'react';
import './FloatingDetails.scss';
import { Size } from '@csa-core/advisor.controlsystemcore'; // '../../types/SizeAndPosTypes';
import {
    Chassis,
    SelectableDevice,
    DeviceType,
    GraphicalDevice,
    ChassisModule,
    MicroChassis
} from '@csa-core/advisor.controlsystemcore'; // '../../types/ProjectTypes';
import { LayoutModeType, LayoutMode } from '@csa-core/advisor.controlsystemcore'; // '../../util/LayoutModeHelp';
import DetailsTitle from './DetailsTitle';
import DetailsChassis from './DetailsChassis';
import { getProjectFromChassis } from '@csa-core/advisor.controlsystemcore'; // '../../model/ChassisProject';
import DetailsWatermark from './DetailsWatermark';
import DetailsDevice from './DetailsDevice';
import { isSizeEqual, setSizeEqual } from '@csa-core/advisor.controlsystemcore'; // '../../util/GeneralHelpers';
import { CallbackSizeChanged } from '../../appLayout/ChassisLayoutView';
import { logger } from '@csa-core/advisor.controlsystemcore'; // '../../util/Logger';
import { useLayoutMode, useSelectedChassis, useSelectedDevice } from '../../context/SelectionInfoContext';
import { LogRender, StartFloatingDtlsMinimized } from '@csa-core/advisor.controlsystemcore'; // '../../types/Globals';
import { getModuleEngInfo } from '@csa-core/advisor.controlsystemcore'; // '../../util/EngInfoHelp';
import { ioExpasnionPowerLimit } from '@csa-core/advisor.controlsystemcore'; // '../../platforms/micro/model/MicroGeneralImpl';
import { configureChassis } from '../../implementation/GenerallUIImpl';

// Optimal size of unscaled details
// window, which seems to fit current
// variations of content well.
const _optimalSize: Size = {
    width: 760,
    height: 316
};

// Max percentages to use of view's
// actual current size. These are
// used to when determining if/when
// we need/want to scale our details
// content down so it doesn't consume
// too much view real estate.
const _maxWidthPct = 0.95;
const _maxHeightPct = 0.5;

// We won't scale down smaller than the
// following constant value. Below a
// certain point, our details content
// becomes unreadable/unusable.
const _minScalePreferred = 0.9;
const _minScaleAbsolute = 0.6;

// 2023.10.19 Increase the width from 130
// to 220 to accommodate 'Power Supply Details'.
const _minimizedSize: Size = {
    width: 220,
    height: 34
};

// NOTE: We use an absolute position for
// our window. For some reason, however,
// hard y locations seem to start at the
// top of our toolbar, which is ABOVE
// the div that 'contains' our details.
// As such, we need to add the toolbar's
// height when calculating our y location.
const _toolbarHeight = 56;

// Gap size gives us the separation between
// our 'floating' window and the lower-right
// corner of the layout view's client area.
const _gapSize: Size = {
    width: 10,
    height: 10
};

// Minimum view size, below which we
// will NOT render any details.
const _minViewSize: Size = {
    width: 400,
    height: 275
};

const waterMarkText = 'click on a device to see details';


enum DevType {
    None = 'None',
    PS = 'PS',
    BU = 'BU',
    Module = 'Module',
    Chassis = 'Chassis'
}

const getSelectionInfo = (
    selChas: Chassis | undefined,
    selDev: SelectableDevice | undefined):
    [devType: DevType, dev: GraphicalDevice | undefined] => {

    if (selDev) {
        if (selDev.deviceType === DeviceType.PS) {
            return [DevType.PS, selDev];
        }
        else if (selDev.deviceType === DeviceType.BU) {
            return [DevType.BU, selDev];
        }
        else {
            return [DevType.Module, selDev];
        }
    }
    else if (selChas) {
        return [DevType.Chassis, selChas];
    }
    return [DevType.None, undefined];
}

const getTitle = (devType: DevType, __minimized: boolean): string => {
    switch (devType) {
        case DevType.Chassis:
            return 'Chassis Details';

        case DevType.Module:
        case DevType.BU:
            return 'Module Details';

        case DevType.PS:
            return 'Power Supply Details';

        default:
            return 'Details';
    }
}

const getSizeWithBorders = (clientSize: Size): Size => {
    return {
        width: clientSize.width,
        height: clientSize.height
    };
}

const getStdDivProps = (clientSize: Size, viewSize: Size, yAdjust = _toolbarHeight): object => {

    const sizeInclBorders = getSizeWithBorders(clientSize);

    return {
        left: viewSize.width - sizeInclBorders.width -
            _gapSize.width,
        top: viewSize.height - sizeInclBorders.height +
            yAdjust - _gapSize.height,
        minWidth: clientSize.width,
        maxWidth: clientSize.width,
        minHeight: clientSize.height,
        maxHeight: clientSize.height
    };
}

const getSizeAllowed = (viewSize: Size): [allowed: Size, aspect: number] => {
    const sizeAllowed: Size = {
        width: viewSize.width * _maxWidthPct,
        height: viewSize.height * _maxHeightPct
    };
    return [sizeAllowed, sizeAllowed.width / sizeAllowed.height];
}

const getScaleInfo = (sizeWanted: Size, viewSize: Size):
    [fitsAsIs: boolean, tooSmallToRender: boolean, scale: number] => {

    // Call a helper to give us the size that would
    // possibly fit into our view using our _maxPct
    // percentages of width and height. This also
    // gives us the aspect of that size (W/H).
    const [sizeAllowed, allowedAspect] = getSizeAllowed(viewSize);

    // If the wize we want fits into the allowed size
    // in BOTH directions as is, return that it fits
    // and that no scaling is needed.
    if ((sizeWanted.width <= sizeAllowed.width) &&
        (sizeWanted.height <= sizeAllowed.height)) {
        return [true, false, 1.0];
    }

    // It does NOT fit as is.
    // Calculate the aspect of the size that
    // we wanted. We want to RETAIN this aspect
    // in our final depiction.
    const aspectWanted = sizeWanted.width / sizeWanted.height;

    // Determine the resulting scale we'd need.
    const calcScale = (allowedAspect < aspectWanted)
        ? sizeAllowed.width / sizeWanted.width
        : sizeAllowed.height / sizeWanted.height;

    // Then establish starting vals for final info.
    // Start optimistic about NOT ending up too small.
    let tooSmall = false;

    // Starting scale will be what we calculated, but NO
    // SMALLER than the scale we'd PREFER not to go below.
    let scale = Math.max(calcScale, _minScalePreferred);

    // If the scale we wanted is smaller than
    // what we would normally scale down to...
    if (calcScale < scale) {

        // Then calculate the scale that COULD be used
        // width-wise, and STILL leave our details window
        // completely visible inside of the view.
        const scaleToFitWidth = (viewSize.width - 10) / sizeWanted.width;

        // If that scale is below our absolute minimum...
        if (scaleToFitWidth < _minScaleAbsolute) {
            // Things would be TOO small to keep
            // in the view. Set our don't-do-it flag.
            tooSmall = true;
            scale = 1.0;
        }
        else {
            // Otherwise, set our final scale to the larger of 
            // that absolute minumum and what we actually wanted.
            // When we do this, we're essentially allowing the
            // scale to go lower than we would normally prefer,
            // but also at the expense of violating our _maxPct
            // specs for how MUCH of the width and height of the
            // view we want our details to consume.
            scale = Math.max(_minScaleAbsolute, calcScale);
        }
    }

    // Return:
    //   1. false    - to indicate that the request will NOT
    //                 fit as is (without being scaled down).
    //   2. tooSmall - true or false to indicate whether
    //                 this one should even be rendered or not.
    //   2. scale -    as finally determined.
    return [false, tooSmall, scale];
}

//const getScaleColor = (scale: number): string => {
//    if (scale < 0.5) {
//        return 'pink';
//    }
//    else if (scale < 0.6) {
//        return 'lavender';
//    }
//    else if (scale < 0.7) {
//        return 'lightblue';
//    }
//    else if (scale < 0.8) {
//        return 'lightgreen';
//    }
//    else if (scale < 0.9) {
//        return 'lightyellow';
//    }
//    else {
//        return '#EEEEEE';
//    }
//}

const getTranslateValue = (baseSize: Size, scale: number): string => {

    const scaledWidth = baseSize.width * scale;
    const scaledHeight = baseSize.height * scale;

    const xShift = Math.round((baseSize.width - scaledWidth) / 2);
    const yShift = Math.round((baseSize.height - scaledHeight) / 2);
    return (xShift.toString() + 'px ' + yShift.toString() + 'px');
}

const getTransformProps = (clientSize: Size, scale: number): object => {
    if (scale < 1.0) {

        const szWithBorders = getSizeWithBorders(clientSize);

        return {
            scale: scale.toString(),
            translate: getTranslateValue(szWithBorders, scale)
            //backgroundColor: getScaleColor(scale)
        };
    }
    else {
        throw new Error('Unexpected transform request!');
    }
}

const getDivProps = (
    mode: LayoutMode,
    minimized: boolean,
    viewSize: Size,
    yAdjust = _toolbarHeight):
    [okToRender: boolean, props: object] => {

    if (mode.type !== LayoutModeType.Normal) {
        return [false, {}];
    }

    if (minimized) {
        return [true, getStdDivProps(_minimizedSize, viewSize, yAdjust)];
    }

    const [fitsAsIs, tooSmall, scale] = getScaleInfo(_optimalSize, viewSize);

    if (tooSmall) {
        return [false, {}];
    }
    else {
        const divProps = getStdDivProps(_optimalSize, viewSize, yAdjust);
        if (fitsAsIs) {
            return [true, divProps];
        }
        else {
            return [true, {
                ...divProps,
                ...getTransformProps(_optimalSize, scale)
            }];
        }
    }
}

interface Props {
    callbackSzChanged: CallbackSizeChanged;
    contentChanged: () => void;
}

const FloatingDetails = (props: Props) => {

    const { layoutMode } = useLayoutMode();
    const { selectedChassis, selectChassis } = useSelectedChassis();
    const { selectedDevice, selectDevice } = useSelectedDevice();

    const [minimized, setMinimized] = useState<boolean>(StartFloatingDtlsMinimized);
    const [renderCnt, setRenderCnt] = useState(0);
    const viewSize = useRef<Size>({ height: 0, width: 0 });
    const chassis = selectedChassis;
    const onViewSizeChanged = useCallback((sz: Size) => {
        if (!isSizeEqual(viewSize.current, sz)) {
            setSizeEqual(viewSize.current, sz);
            setRenderCnt(renderCnt + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderCnt])


    const onEditChassis =  useCallback(() => {
        if (chassis) {
            chassis.isPower = false;
            const project = getProjectFromChassis(chassis);
            if (project) {
                configureChassis(chassis.platform, project,
                    selectChassis, selectDevice,
                    props.contentChanged, chassis);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chassis])

   
    const onEditPowerSettings = useCallback(() => {
        if (chassis) {
            chassis.isPower = true;
            const project = getProjectFromChassis(chassis);
            if (project) {
                configureChassis(chassis.platform, project,
                    selectChassis, selectDevice,
                    props.contentChanged, chassis);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chassis])

    useEffect(() => {
        // Assign our callback - the parent can use this to
        // notify us when the view size is updated.
        props.callbackSzChanged.callbackToFloatingDtls = onViewSizeChanged;
    }, [props, onViewSizeChanged])



    // Bail early if our view size is too small.
    // We will NOT render any details in that case.
    if ((viewSize.current.width < _minViewSize.width) ||
        (viewSize.current.height < _minViewSize.height)) {
        return (null);
    }


    // Determine what, if anything is selected.
    const [devType, dev] =
        getSelectionInfo(selectedChassis, selectedDevice);
  

    const microChassis  = chassis as MicroChassis;
    let isModuleExpansionPower = false;
    const ioPowermod = dev as ChassisModule;
    if (devType === DevType.PS && ioPowermod && ioPowermod?.slotID) {
        const pluginLimit  = microChassis?.pluginModules?.length || 0;
        if(pluginLimit >= 0){
            isModuleExpansionPower = (ioPowermod?.slotID === pluginLimit + ioExpasnionPowerLimit)
        }
    
    }


    

    let modIsPS = false;
    if (devType === DevType.Module) {
        const mod = dev as ChassisModule;
        const info = getModuleEngInfo(mod.platform, mod.catNo);
        if (info)
            modIsPS = (info.saPwrSupplier || info.modPwrSupplier);
    }

    // Establish our title text.
    const title = getTitle(devType, minimized);

    // Call a helper to give us ALL of the props
    // we want to use for our div style, including
    // position, size, and scale/translate info if
    // we're doing that for our current view size.
    const [okToRender, divProps] =
        getDivProps(layoutMode, minimized, viewSize.current);

    if (!okToRender) {
        return (null);
    }

    // If we get a pointer-down event, just consume it so
    // that it doesn't make its way to our layout below us.
    const onPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }

    const onMinRest = () => {
        setMinimized(!minimized);
    };
 

    if (LogRender.Details) {
        logger.logRender('Details render: ' + (minimized ? 'Min' : 'Norm'));
    }

    const renderContent = () => {
        switch (devType) {
            case DevType.Chassis:
                return (
                    <DetailsChassis
                        chassis={dev as Chassis}
                        onClickEdit={onEditChassis}
                    />
                );

            case DevType.PS:
                return (
                    <DetailsDevice
                        device={dev as SelectableDevice}
                        ps={!isModuleExpansionPower}
                        onClickPowerSettings={onEditPowerSettings}
                    />
                );

            case DevType.Module:
            case DevType.BU:
                return (
                    <DetailsDevice
                        device={dev as SelectableDevice}
                        ps={modIsPS}
                        onClickPowerSettings={onEditPowerSettings}
                    />
                );

            default:
                return (
                    <DetailsWatermark text={waterMarkText} />
                );
        }
    }

    return (
        <div
            className='floating-details-window'
            onPointerDown={onPointerDown}
            style={{ ...divProps }}
        >
            <DetailsTitle
                title={title}
                minimized={minimized}
                onMinRest={onMinRest}
            />
            {!minimized ? renderContent() : null}
        </div>
    );
}

export default React.memo(FloatingDetails);

