import { DocLinkGroupInfo, DocLinkInfo } from "@csa-core/advisor.controlsystemcore";
import { getPlatformProjectInfo } from "../../util/ProjInfoHelp";

export const makePlatformDocLinkGrp = (platform: string, title = ''): DocLinkGroupInfo => {
    const grpInfo: DocLinkGroupInfo = {
        platform: platform,
        links: new Array<DocLinkInfo>()
    };

    if (title.length > 0) {
        grpInfo.title = title;
    }

    const infoPlatform = getPlatformProjectInfo(platform);

    grpInfo.links.push({
        text: 'Selection Guide',
        url: infoPlatform.docSelGuide,
    });

    grpInfo.links.push({
        text: 'Technical Information',
        url: infoPlatform.docTechInfo,
    });

    grpInfo.links.push({
        text: 'User Manual',
        url: infoPlatform.docUserManual,
    });

    return grpInfo;
}
