import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocAndSize } from "../../types/SizeAndPosTypes";
import { RootState } from "../store";

export interface ViewTipInfo {
    text : string;
    rcClient : LocAndSize;
}

// Exporting LayoutTipInfoState due to --declaration flag in
// the core's tsconfig. The store needs this interface
// defined in order to produce a declaration file.
export interface LayoutTipInfoState {
    layoutViewTip?: ViewTipInfo;
}

const initialState: LayoutTipInfoState = {
    layoutViewTip: undefined,
};

export const layoutTipInfo = createSlice({
    name: 'layoutTipInfo',
    initialState,
    reducers: {
        setLayoutViewTip: (state, action: PayloadAction<ViewTipInfo | undefined>) => {
            state.layoutViewTip = action.payload;
        }
    }
});

export const {
    setLayoutViewTip
} = layoutTipInfo.actions;

// state.layoutTipInfo defined in store.ts.
export const layoutViewTip = (state: RootState) => state.layoutTipInfo.layoutViewTip;
export default layoutTipInfo.reducer; 