import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ModalStyles.scss';
import Modal from '@mui/material/Modal';
import { IconButton, SvgIcon } from '@mui/material';
import { ModalRequestSpec } from './ModalHelp';
import ModalButtonRow from './ModalButtonRow';
import PrettyTextComp from '../components/PrettyTextComp';
import { ModalStatus, Size } from '@csa-core/advisor.controlsystemcore'; // '../types/SizeAndPosTypes';
import { isSizeEmpty } from '@csa-core/advisor.controlsystemcore'; // '../util/GeneralHelpers';
import { logger } from '@csa-core/advisor.controlsystemcore'; // '../util/Logger';
import { CloseIcon, Theme } from '@rockwell-automation-inc/ra-meatball';

const _logInfo = false;

const _defaultBoxWidth = 530;
const _minBoxWidth = 200;
const _minBoxHeight = 100;

const _modalMargin = 10;
const _totalMargin = _modalMargin * 2;

const _minVertBasedScale = 0.6;

const _iconSize = 50;

// Static props that will ALWAYS 
// be included in our div style.
const _baseDivProps = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '24px 24px 32px 24px',
    rowGap: '16px',
    left: '50%',
    top: '50%',
    transformOrigin: 'center'
}

const getTransformValue = (scale: number): string => {
    let val = 'translate(-50%, -50%)';
    if (scale < 1.0) {
        val += ' scale(' + scale.toString() + ')';
    }
    return val;
}

const getDivProps = (
    contentWidth: number,
    contentHeight: number | undefined,
    scale: number
): object => {

    if (_logInfo) {
        logger.log('Modal getBoxStyle');
        logger.log('   contentWidth: ' + contentWidth +
            ', contentHeight: ' + contentHeight);
        logger.log('   scale: ' + scale);
    }

    return {
        ..._baseDivProps,
        width: contentWidth,
        height: contentHeight,
        transform: getTransformValue(scale)
    };

}


interface Props {
    open: boolean;
    onClose: (status: number) => void;
    reqProps: ModalRequestSpec;
}

const ModalWrapper = (props: Props) => {
    const [renderCount, setRenderCount] = useState(0);
    const resultStatus = useRef<number>(0);

    // Ref to contain the last-known size of our browser's client window.
    const windowSize = useRef<Size>({ width: window.innerWidth, height: window.innerHeight });

    // Ref to contain ACTUAL modal size as obtained from
    // the modal's outer div. Note: We get that size and
    // set our ref only ONE time.
    const modalSize = useRef<Size>({ width: 0, height: 0 });

    // Scale we should use.
    //   - starts at 1, and will typically remain there.
    //   - will NEVER exceed 1.0
    //   - will be LESS than 1.0 only when modal
    //     would otherwise not fit inside of available
    //     browser client window
    const modalScale = useRef<number>(1.0);

    // Determine if we want to effectively close
    // and cancel if we get a click or Enter key
    // from INSIDE of our modal area. If our props
    // provides a spec for that, use it. Otherwise,
    // our default behavior will be to:
    //    - close if we don't have any buttons.
    //    - DON'T close if we DO have buttons.
    const closeOnInsideClick = (props.reqProps.closeOnInsideClick !== undefined)
        ? props.reqProps.closeOnInsideClick
        : !props.reqProps.includeButtons

    // Determine if we want to STAY OPEN (and not
    // cancel) if we get a click OUSIDE of our
    // modal area (a 'backdropClick'). If our props
    // provides a spec for that, use it. Otherwise,
    // our default behavior will be to:
    //    - close if we don't have any buttons.
    //    - STAY OPEN if we DO have buttons.
    const stayOpenOnBackdropClick = (props.reqProps.stayOpenOnBackdropClick !== undefined)
        ? props.reqProps.stayOpenOnBackdropClick
        : props.reqProps.includeButtons

    // Our Modal parent doesn't seem to allow us to get
    // keyup or keydown events off of our div. So, we'll
    // do it a different way. After we've been rendered...
    useEffect(() => {

        // If we determined that we want to close on
        // inside clicks (and Enter key)...
        if (closeOnInsideClick) {

            // Then we'll add our own listener to watch for
            // keyup events from anywhere in the document.
            // Note that by including our handler function
            // definition INSIDE of our useEffect, we don't
            // have to place it in a useCallback.
            // If we get ANY keyup...
            const handleKeyUp = (evt: KeyboardEvent) => {

                // Close
                props.onClose(ModalStatus.Cancelled);

                // Keep anyone else from getting the event.
                evt.stopPropagation();
            };

            // attach the event listener
            document.addEventListener('keyup', handleKeyUp);

            // remove the event listener
            return () => {
                document.removeEventListener('keyup', handleKeyUp);
            };
        }
    });


    const establishFullModalSize = useCallback((node: HTMLDivElement | null) => {

        const establishScale = () => {

            // Determine what scale we'd need to fit our modal in each direction,
            // allowing for a small margin around it. Never use a scale greater than 1.
            // Start with x direction.
            const scaleX =
                Math.min(1.0,
                    (windowSize.current.width - _totalMargin) / modalSize.current.width);

            // Then calc vertical based on height available.
            const calcScaleY =
                Math.min(1.0,
                    (windowSize.current.height - _totalMargin) / modalSize.current.height);

            // Finally set scaleY.
            // If what we calculated for vert scale is LESS than
            // our scale X, we'll use it, but no lower than
            // a hard minimum.
            const scaleY = (calcScaleY < scaleX)
                ? Math.max(calcScaleY, _minVertBasedScale)
                : calcScaleY;

            // Then choose the smaller our 2 final scales.
            const scale = Math.min(scaleX, scaleY);

            // If our result is DIFFERENT from
            // what we've already got stored...
            if (scale !== modalScale.current) {

                if (_logInfo) {
                    logger.log('Scale changing - Triggering re-render');
                }

                // Store the new scale
                modalScale.current = scale;

                // trigger a re-rendering of our modal.
                setRenderCount(renderCount => renderCount + 1);
            }
        }

        const onWindowResize = () => {

            // Save new window size in our useRef.
            windowSize.current.width = window.innerWidth;
            windowSize.current.height = window.innerHeight;

            if (_logInfo) {
                logger.log('Window resized to: ' +
                    window.innerWidth + ' x ' + window.innerHeight);
            }

            // Change scale if/as needed.
            establishScale();
        }

        let listeningForResize = false;

        // If we HAVE our node (div reference) AND we have
        // NOT already set our modalSize useRef value...
        if (node && isSizeEmpty(modalSize.current, true)) {

            // Set to the client size provided by the node.
            modalSize.current.width = node.clientWidth;
            modalSize.current.height = node.clientHeight;

            if (_logInfo) {
                logger.log('Node client size: ' + node.clientWidth +
                    ' x ' + node.clientHeight);
                logger.log('Adding window resize listener');
            }

            // Add a listener to listen for resizes
            // of our browser client window.
            window.addEventListener('resize', onWindowResize);
            listeningForResize = true;

            // Change scale if/as needed.
            establishScale();
        }

        return () => {
            if (listeningForResize) {
                if (_logInfo) {
                    logger.log('Removing window resize listener');
                }
                window.removeEventListener('resize', onWindowResize);
            }
        };
    }, []);


    // Handle click from ANYWHERE inside of our modal.
    const onClickContent = () => {

        // If we determined that we want to close on
        // inside clicks (and Enter key)...
        if (closeOnInsideClick) {

            // Then call the term function with a Cancelled status.
            props.onClose(ModalStatus.Cancelled);
        }
    }

    // Establish our content width. Note that
    // content size doesn't include padding.
    const contentWidth = (props.reqProps.width &&
        props.reqProps.width > _minBoxWidth)
        ? props.reqProps.width
        : _defaultBoxWidth;

    // And our height (only if actually specified)
    const contentHeight = (props.reqProps.height &&
        props.reqProps.height > _minBoxHeight)
        ? props.reqProps.height
        : undefined;

    const divProps = getDivProps(
        contentWidth,
        contentHeight,
        //modalSize.current,
        //windowSize.current,
        modalScale.current
    );

    // Determine if a title was provided or not.
    const haveTitle = props.reqProps.title && props.reqProps.title.length;

    const haveIcon = ((props.reqProps.iconSvg !== undefined) ||
        (props.reqProps.iconMui !== undefined))

    // Determine what color our icon (if we have one) should be.
    const iconColor = (haveIcon && (props.reqProps.iconColor !== undefined))
        ? props.reqProps.iconColor
        : 'black';

    // Our onClose gets called when our modal wants to close
    // due to either a backdrop click (outside click) or the
    // Escape key being hit.
    const onCloseModal = (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {

        // If we're supposed to STAY OPEN on the backdrop click
        // case, we'll just ignore it and NOT actually close.
        if (stayOpenOnBackdropClick && (reason === 'backdropClick'))
            return;

        props.onClose(resultStatus.current);
    }

    // Handler we make available to children to let us
    // know we're done, and should close.
    const onReqToEndModal = (status: number) => {
        resultStatus.current = status;
        props.onClose(resultStatus.current);
    }

    const onReqModalFullRender = () => {
        setRenderCount(renderCount + 1);
    }

    const onClickCloseRequest = () => {
        onReqToEndModal(ModalStatus.Cancelled)
    }

    // Set the 'reserved' endModal to
    // reference the handler.
    props.reqProps.endModal = onReqToEndModal;

    props.reqProps.fullRender = onReqModalFullRender;


    // Determine whether (and what) custom content was provided.
    const CustomContent: React.FC<ModalRequestSpec> | undefined = props.reqProps.content;
    const haveCustomContent = (CustomContent !== undefined);

    const inclDismissal = !(props.reqProps.includeButtons || haveCustomContent);
    const dismissalText = inclDismissal
        ? 'Click or press any key to continue...'
        : '';

    const closeIconColor = Theme.palette.mediumGrey.main;

    const renderIcon = () => {
        if (props.reqProps.iconSvg) {
            const Icon = props.reqProps.iconSvg;
            return (
                <div className='modal-icon'>
                    <SvgIcon
                        component={Icon}
                        inheritViewBox
                        sx={{
                            color: iconColor,
                            width: '40px',
                            height: '40px'
                        }}
                    />
                </div>
            );
        }
        else if (props.reqProps.iconMui) {
            return (
                <div className='modal-icon'>
                    <props.reqProps.iconMui
                        style={{ color: iconColor, fontSize: _iconSize }}
                    />
                </div>
            );
        }
        return (null);
    }

    if (_logInfo) {
        logger.logRender('Render modal');
    }

    return (
        <Modal
            open={props.open}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0,0,0,0.22)' } } }}
        >
            <div
                id={'ModalBaseDiv'}
                ref={establishFullModalSize}
                style={divProps}
                onClick={onClickContent}
            >
                {haveTitle
                    ? <div className='modal-title-area'>
                        <span className='modal-title'>
                            {props.reqProps.title}
                        </span>
                        <IconButton
                            onClick={onClickCloseRequest}
                            sx={{ color: closeIconColor, marginLeft: 'auto' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    : null
                }
                {haveIcon
                    ?
                    <div className='modal-content-with-icon'>
                        {renderIcon()}
                        <div className='modal-scrollable-content'>
                            {haveCustomContent
                                ? <CustomContent {...props.reqProps} />
                                : <PrettyTextComp text={props.reqProps.message} />
                            }
                        </div>
                    </div>
                    :
                    <div className='modal-scrollable-content'>
                        {haveCustomContent
                            ? <CustomContent {...props.reqProps} />
                            : <PrettyTextComp text={props.reqProps.message} />
                        }
                    </div>

                }
                {props.reqProps.includeButtons
                    ? <ModalButtonRow
                        btnSpecs={props.reqProps.buttonInfo}
                        onClickBtn={onReqToEndModal}
                    />
                    : null
                }
                {inclDismissal
                    ? <div className='modal-message-dismissal'>
                        <span>
                            {dismissalText}
                        </span>
                    </div>
                    : null
                }
            </div>
        </Modal>
    );
}

export default ModalWrapper;

