import React, { useEffect, useState } from 'react'
import {
    ProjectSetting,
    SettingGroupInfo,
    SettingOption,
    SettingCode
} from '@csa-core/advisor.controlsystemcore'; // '../types/SettingsTypes';
import { getSettingGroups } from '@csa-core/advisor.controlsystemcore'; // '../settings/SettingsHelp';
import SettingGroup from '../settings/SettingGroup';
import {
    ComponentRenderInfo,
    DesignPageChildID,
    registerDesignPageCompRenderInfo,
    unregisterDesignPageCompRenderInfo
} from '@csa-core/advisor.controlsystemcore'; // '../settings/DesignPageComponentRenderInfo';
import { getLocAttrFromProject } from '@csa-core/advisor.controlsystemcore'; // '../model/ChassisProject';
import { ChassisProject } from '@csa-core/advisor.controlsystemcore'; // '../types/ProjectTypes';

interface Props {
    project: ChassisProject;
    settingSectionID: SettingCode;
    valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
}


export const SettingsSection = (props: Props) => {

    let nextKey = 1;
    const infoGroups: SettingGroupInfo[] = getSettingGroups(props.project, props.settingSectionID);

    return (
        <>
            {
                infoGroups.map( (group) => {
                    return (
                        <SettingGroup
                            info={group}
                            key={nextKey++}
                            valueChanged={props.valueChanged}
                        />
                    )
                })
            }
        </>
    );
}


export const GuidedSelSettingsSection = (props: Props) => {
    const [ , setRenderCnt] = useState(1);

    useEffect(() => {
        // Register our refresh hooks.
        const hook: ComponentRenderInfo = { componentID: DesignPageChildID.GuidedSelSection, renderCount: 1, setRenderCount: setRenderCnt };
        registerDesignPageCompRenderInfo(hook);

        return (() => unregisterDesignPageCompRenderInfo(hook));
    }, []);


    let nextKey = 1;

    //const loc = project.config.locationSettings;
    const loc = getLocAttrFromProject(props.project);

    // If we do not have the location OR we do not have attr
    // groups (i.e. Guided Sel has not loaded.)...
    if (loc.attrGroups.length == 0)
        return null;

    return (
        <>
            {
                loc.attrGroups.map((group) => {
                    return (
                        <SettingGroup
                            info={group}
                            key={nextKey++}
                            valueChanged={props.valueChanged}
                        />
                    )
                })
            }
        </>
    );
}
