import React from 'react';
import './OLD_ModuleSelection.scss'
import { ModuleSelectionTreeFolder } from './ModuleSelectionTreeFolder';
//import { ModalRequestSpec } from '../modals/ModalHelp';
import { ModuleTreeFolder } from '@csa-core/advisor.controlsystemcore'; // '../util/ModSelectionHelp';
import { EngInfoModule } from '@csa-core/advisor.controlsystemcore'; // '../engData/EngineeringInfo';


//export interface ModSelTreeModalSpec extends ModalRequestSpec {
//	treeRoot: ModuleTreeFolder;
//}


interface Props {
	treeRoot: ModuleTreeFolder;
	onModuleAdded: (module: EngInfoModule, auto: boolean) => void;
}


export const ModuleSelectionTree = (props: Props) => {
	// Note: The root folder should NOT have any
	// devices in it AND it will NOT be displayed.
	// The root is just a container.

	let nextKey = 0;
	return (
		<div className="mstree-root-container">
			<div className="mstree-root">
				{
				props.treeRoot.treeFolders.length > 0 ?
				props.treeRoot.treeFolders.map((folder) => {
					return (
						<ModuleSelectionTreeFolder
							folder={folder}
							level={0}
							onModuleAdded={props.onModuleAdded }
							key={nextKey++}
						/>);
				}) 
						:
				<div>No compatible modules found</div>
				}
			</div >
		</div>
	);
}

