import { convertGuidedSelAttrToAppVal } from "../../implementation/ImplHardwareGen";
import { refreshLocAttrInfoSelectionArray, updateGuidedSelection } from "../../model/GuidedSelection";
import { getUserModuleSelectionsFromPointSectionInfo } from "../../model/IOModule";
import { collectHardwareInfo, KeyValuePair, ProdSelCategory } from "../../model/ProductSelection";
import { makeSettingGroup } from "../../settings/SettingsHelp";
import { PSInputVoltage } from "../../types/PowerTypes";
import {
    Chassis,
    ChassisCfgGrpCategory,
    ChassisProject,
    createNewLocAttributeInfo,
    EnvRating,
    IOModuleWiring,
    LocAttributeInfo,
    onCfgLocAttrCreatedCallback,
} from "../../types/ProjectTypes";
import { IOEntryModeEnum } from "../../types/SettingsTypes";
import { coreDisplayAlertMsg } from "../../uiInterfaces/UIMessenger";
import { getCommModuleEngInfo, getControllerEngInfo } from "../../util/EngInfoHelp";
import { logger } from "../../util/Logger";
import { getChassisEnvType } from "../common/ChassisConfig";
import { flexHAGetChassisCatalog } from "../flexHA/model/FlexHALayout";
import { PlatformCpLX, PlatformFlex, PlatformFlexHA } from "../PlatformConstants";

export const snapGetChassisCatalogFromEnvRating = (platform: string, er: EnvRating): string => {
    switch (platform) {
        case PlatformCpLX:
            if (er === EnvRating.Standard) {
                return '5069-CHASSIS';
            }
            return '5069-CHASSIS-K';

        case PlatformFlex:
            if (er === EnvRating.ConformalCoated)
                return '5094-CHASSIS-K';
            else if (er === EnvRating.ExtTemperature)
                return '5094-CHASSIS-XT';
            return '5094-CHASSIS';

        // Note: FlexHA is snap, but not snap. FlexHA
        // can get called within this snap function.
        case PlatformFlexHA:
            return flexHAGetChassisCatalog(er);

        default:
            break;
    }

    throw new Error(`snapGetChassisCatalogFromEnvRating(): Platform ${platform} is NOT snap`)
}

export const snapPrepareLocAttrHardwareForGen = (loc: LocAttributeInfo, project: ChassisProject) => {
    refreshLocAttrInfoSelectionArray(loc);

    const platform = loc.platform;

    // Start our chassis catalog out as 'standard.
    loc.hardware.catChassis = snapGetChassisCatalogFromEnvRating(loc.platform, EnvRating.Standard);

    // Hardcode necessity: Attribute 'ER' - Env. Rating.
    const er = loc.arrAttributeNameToValue.find(x => x.attrID === 'ER');
    if (er) {
        loc.hardware.envRating = convertGuidedSelAttrToAppVal(platform, er.attrID, er.optionID, true) as EnvRating;
        loc.hardware.catChassis = snapGetChassisCatalogFromEnvRating(platform, loc.hardware.envRating);
    }

    // Set the controller chassis catalog to the remote.
    loc.hardware.catControllerChassis = loc.hardware.catChassis;

    // Hardcode necessity: Attribute 'CTRL' - defines whether a
    // configuration will be generated with or without a Controller
    loc.hardware.remoteIOOnly = true;
    if( platform === PlatformCpLX)
        loc.hardware.remoteIOOnly = loc.arrAttributeNameToValue.some(attr => attr.attrID === 'CTRL' && attr.optionID === 'No');

    // Hardcode necessity: Attribute 'CT' - Defines whether I/O
    // will be placed in Controller chassis or if all I/O will
    // be in remote chassis. Value 'Ded' for 'dedicated'. Note:
    // CpLX does not have 'CT' right now but may at some point.
    loc.hardware.ctrlDedicatedChassis = false;
    if (platform === PlatformCpLX)
        loc.hardware.ctrlDedicatedChassis = loc.arrAttributeNameToValue.some(attr => attr.attrID === 'CT' && attr.optionID === 'Ded');

    // We will never need a comm in a controller chassis.
    loc.hardware.ctrlChassisNeedsScanner = false;

    // We do not have any PSUs - Redundant, but set them.
    loc.hardware.catPowerSupply = undefined;
    loc.hardware.catPwrSupCtrlChassis = undefined;

    // We do not have redundancy
    loc.hardware.redCtrlChassis = false;

    loc.hardware.ioModuleSelections = getUserModuleSelectionsFromPointSectionInfo(loc.pointEntrySection, project.config.IOEntryMode);
    loc.hardware.ioModuleSelections.forEach((sel) => {
        for (let idx = 0; idx < sel.quantity; ++idx)
            loc.hardware.catIOModules.push(sel.catalog);
    });

    loc.hardware.ioModuleCount = loc.hardware.catIOModules.length;

    // For chassis size, 'Auto' will return a default value of 31
    // which is the Max modules allowed in a CpLX "chassis"". Below,
    // when retrieving Controller and Comm, we will take the lesser
    // of chassisSize (set here) and what the Controller/Comm says 
    // it supports.
    let chassisSize = 31;
    const cs = loc.arrAttributeNameToValue.find(x => x.attrID === 'CS');
    if (cs)
        chassisSize = Number(convertGuidedSelAttrToAppVal(platform, cs.attrID, cs.optionID, true));

    // If we need any additional attributes to get the correct
    // selections, add them here. Currently 'snap' does not need any.
    const additionalAttributes: KeyValuePair[] = [];

    const arrHardware = collectHardwareInfo(loc, additionalAttributes);
    arrHardware.forEach((hwInfo) => {
        switch (hwInfo.category) {
            case ProdSelCategory.Controller:
                {
                    loc.hardware.catController = hwInfo.mainCatalog;
                    loc.hardware.catControllerPartner = hwInfo.associatedCatalogs;
                    // The controller is the power supplier for the chassis.
                    loc.hardware.catCtrlPowerSupplier = loc.hardware.catController;

                    // Get the chassis size from Engineering data.
                    const info = getControllerEngInfo(platform, loc.hardware.catController);
                    if (info && info.maxSnapModules) {
                        loc.hardware.numCtrlChassisSlot = (chassisSize < info.maxSnapModules ? chassisSize : info.maxSnapModules);
                        // Add ONE. The create function wants the
                        // total slots available, not modules supported
                        // by the slot 0 module.
                        loc.hardware.numCtrlChassisSlot += 1;
                    }
                }
                break;

            case ProdSelCategory.Comm:
                {
                    loc.hardware.catScanner = hwInfo.mainCatalog;
                    // The scanner is the power supplier for the chassis.
                    loc.hardware.catRemotePowerSupplier = loc.hardware.catScanner;
                   // So far, all comms can support up to 31 modules.
                    // If in the furute that changes, we need to ping
                    // engineering data in the same way we do for
                    // the controller chassis size.
                    loc.hardware.numChassisSlot = 31;
                    const info = getCommModuleEngInfo(platform, loc.hardware.catScanner);
                    if (info && info.maxSnapModules)
                        loc.hardware.numChassisSlot = (chassisSize < info.maxSnapModules ? chassisSize : info.maxSnapModules);

                    // Add ONE. The create function wants the
                    // total slots available, not modules supported
                    // by the slot 0 module.
                    loc.hardware.numChassisSlot += 1;
               }
                break;

            default:
                coreDisplayAlertMsg('Unknown Hardware Category: ' + hwInfo.category);
                break;
        }
    });

    // Finalize some values... If we are Remote I/O Only...
    if (loc.hardware.remoteIOOnly) {
        loc.hardware.catController = '';
        loc.hardware.numCtrlChassisSlot = 0;
    }

    // Validate the hardware - we should always have values for
    // the remote I/O chassis/PSU/Comm
    if (!loc.hardware.catScanner)
        throw new Error('snapPrepareLocAttrHardwareForGen(): Failed to select valid Snap Remote Comm.');

    // If we are NOT remote I/O only and we do NOT have a controller...
    if (!loc.hardware.remoteIOOnly && !loc.hardware.catController)
        throw new Error('snapPrepareLocAttrHardwareForGen(): Failed to select valid CompactLogix Controller.')

    return;
}

export interface SnapChassisCfgData {
    envType: EnvRating;
    wiringType: IOModuleWiring;
    psVoltage: PSInputVoltage;
    numSlots: number;
}

export const createSnapChassisCfgData = (): SnapChassisCfgData => {
    return {
        envType: EnvRating.Standard,
        wiringType: IOModuleWiring.Screw,
        psVoltage: PSInputVoltage.DC24V,
        numSlots: 31,
    };
}

export const snapGetCfgDataFromChassis = (chassis: Chassis): SnapChassisCfgData => {
    const inputVoltage = (chassis && chassis.ps)
        ? chassis.ps.inputVoltage
        : PSInputVoltage.DC24V;

    return {
        envType: getChassisEnvType(chassis),
        numSlots: chassis.modules.length,
        wiringType: chassis.defaultIOModWiring,
        psVoltage: inputVoltage,
    };
}

export const snapGetChassisCfgDataFromLocAttr = (loc: LocAttributeInfo): SnapChassisCfgData => {
    const data = createSnapChassisCfgData();

    // Refresh the selections in the loc's arrAttributeNameToValue.
    refreshLocAttrInfoSelectionArray(loc);
    const platform = loc.platform;

    const er = loc.arrAttributeNameToValue.find(x => x.attrID === 'ER');
    if (er)
        data.envType = convertGuidedSelAttrToAppVal(platform, er.attrID, er.optionID, true) as EnvRating;

    const cv = loc.arrAttributeNameToValue.find(x => x.attrID === 'CV');
    if (cv)
        data.psVoltage = convertGuidedSelAttrToAppVal(platform, cv.attrID, cv.optionID, true) as PSInputVoltage;

    data.numSlots = -1;
    const cs = loc.arrAttributeNameToValue.find(x => x.attrID === 'CS');
    if (cs)
        data.numSlots = convertGuidedSelAttrToAppVal(platform, cs.attrID, cs.optionID, true) as number;

    const rtb = loc.arrAttributeNameToValue.find(x => x.attrID === 'RTB');
    if (rtb)
        data.wiringType = convertGuidedSelAttrToAppVal(platform, rtb.attrID, rtb.optionID, true) as IOModuleWiring;

    const arrHW = collectHardwareInfo(loc, [{ Key: 'CS', Value: data.numSlots.toString() }]);
    arrHW.forEach((comp) => {
        switch (comp.category) {
            // We are ADDING a Chassis here without a Controller
            // or Comm... However, base the num slots on the Comm
            // if it has NOT been set.
            case ProdSelCategory.Comm:
                if (data.numSlots < 0) {
                    const info = getCommModuleEngInfo(platform, comp.mainCatalog);
                    if (info && info.maxSnapModules)
                        data.numSlots = info.maxSnapModules;
                    else
                        data.numSlots = (platform === PlatformCpLX ? 31 : 16);
                }
                break;
            default:
                break;
        }
    });

    return data;
}


////////////// Config/Edit Chassis Dlg driven off of Guided Selection ////////////////////////////
// We are using the Guided Selection and it's rules
// to drive most of the Chassis Cfg Dialog. For CLX,
// the Power Supply selection will be based on the
// Control Voltage selected in the Guided Selection,
// BUT the actual PSU choice we be from a PSU list
// generated based on Engineering Data.

let _cfgAttrCallback: onCfgLocAttrCreatedCallback | undefined = undefined;
export const snapGetLocAttrInfoForChassisEdit = (platform: string, callback: onCfgLocAttrCreatedCallback) => {
    _cfgAttrCallback = callback;

    const configAttrInfo = createNewLocAttributeInfo(platform, '', '', IOEntryModeEnum.Basic);

    // Note: We skip validation so that everything is
    // reloaded and all options are present (3rd param === true)
    updateGuidedSelection(configAttrInfo, _onGdSelForChassisEditLoaded, true);
}

export const snapPrepareLocAttrForChassisConfig = (locAttrInfo: LocAttributeInfo): boolean => {
    // Start by pulling ALL of the settings into a groups.
    // The idea here is to rearrange the settings into 3
    // attribute groups: Cfg Page 1; Cfg Page 2; and all
    // the other settings not in the first 2 pages. The
    // pages relate to the Cfg Dlg Tabs.
    const cfgDlgPage1 = makeSettingGroup(ChassisCfgGrpCategory.Chassis);
    const cfgDlgPage2 = makeSettingGroup(ChassisCfgGrpCategory.Power);
    const cfgNonDisplay = makeSettingGroup(ChassisCfgGrpCategory.Hidden);

    // Preprocess our settings
    locAttrInfo.attrGroups.forEach((grp) => {
        grp.settings.forEach((setting) => {
            const idxAutoOption = setting.options.findIndex(x => x.id === 'Auto');
            if (idxAutoOption >= 0) {
                // Remove it from the setting options.
                setting.options.splice(idxAutoOption, 1);

                // Remove it from our map.
                if (setting.gsMapOptionTxtValToInfo) {
                    let mapKeyForAuto = '';
                    setting.gsMapOptionTxtValToInfo.forEach((val, key) => {
                        if (val.id === 'Auto')
                            mapKeyForAuto = key;
                    });

                    if (mapKeyForAuto)
                        setting.gsMapOptionTxtValToInfo.delete(mapKeyForAuto);
                }

                // Select the first entry IF Auto is selected.
                if (setting.selectedOption.id === 'Auto')
                    setting.selectedOption = setting.options[0];
            }
        })
    });

    // Note: the settings will be added in the order that
    // they appear in the guide selection, which at this
    // point is the correct order. If that changes, we will
    // need to add a sort function or a smarter way of
    // building out each page's setting array.
    locAttrInfo.attrGroups.forEach((grp) => {
        grp.settings.forEach((setting) => {
            switch (setting.id) {
                case 'ER':
                case 'RTB':
                    cfgDlgPage1.settings.push(setting);
                    break;
                case 'CV':
                    cfgDlgPage2.settings.push(setting);
                    break;
                default:
                    // 2024.4.22 Ignore any errors on
                    // the hidden attributes. These
                    // attributes should NOT impact
                    // the Edit/Add of a chassis.
                    setting.ignoreError = true;
                    cfgNonDisplay.settings.push(setting);
                    break;
            }
        });
    });

    // Validate we have the correct number of settings in
    // each page. From above, Page1 has 4 and Page2 has 1.
    const valid = (cfgDlgPage1.settings.length === 2 && cfgDlgPage2.settings.length === 1);
    if (valid === false)
        logger.error('snapGetLocAttrInfoForChassisEdit(): Error - not all required attribute settings found in Guided Selection.');

    // Dump the original attr groups, and add
    // the new groups.
    locAttrInfo.attrGroups.length = 0;
    locAttrInfo.attrGroups.push(cfgDlgPage1);
    locAttrInfo.attrGroups.push(cfgDlgPage2);
    locAttrInfo.attrGroups.push(cfgNonDisplay);

    return valid;
}

const _onGdSelForChassisEditLoaded = (success: boolean, locAttrInfo: LocAttributeInfo | undefined) => {
    if (_cfgAttrCallback == null)
        throw new Error('Snap::_onGdSelForChassisEditLoaded(): callback function undefined!');

    if (success && locAttrInfo) {
        const valid = snapPrepareLocAttrForChassisConfig(locAttrInfo);
        _cfgAttrCallback(valid, locAttrInfo);
        return;
    }

    throw new Error('Snap::_onGdSelForChassisEditLoaded(): Guided Selection failed!');

}