import React, { useState } from 'react';
import { HdrErrState, SettingHeader } from './SettingHeader';
import './Settings.scss';
import { ProjectSetting, SettingOption } from '@csa-core/advisor.controlsystemcore'; // '../types/SettingsTypes';
import { Slider } from '@mui/material';


// Component takes an array of choices and puts
// them on a 'slider'. Pretty safe with 3 choices,
// but any more than 3 would warrant a Combo if
// choice labels are long.

interface Props {
    setting: ProjectSetting;
    valueChanged: (setting: ProjectSetting, newTextValue: SettingOption | undefined) => void;
}

export const SliderSetting = (props: Props) => {
    const [renderCnt, setRenderCnt] = useState(1);
    // Place any hooks above here!.
    if (props.setting.visible === false)
        return null;


    const types = props.setting.options.map((value, index) => {
        return {
            // We need the value(s) to be ordered 0 thru 100 where
            // 0 would be the first option and 100 would be the
            // last on the slider.
            value: index * (100 / (props.setting.options.length - 1)),
            label: value.display,
        };
    })

    const handleChange = (e: Event, value: number | number[]) => {
        const newVal = value as number;
        const type = types.find(i => i.value === newVal)?.label;
        if (type) {
            const newOption = props.setting.options.find(i => type === i.display);
            if (newOption) {
                props.valueChanged(props.setting, newOption);

                // If we do not re-render, slider is not updated(?).
                setRenderCnt(renderCnt + 1);
            }
        }
    }

    let state = HdrErrState.info;
    if (props.setting.msgWarningOrError) {
        state = (props.setting.valid ? HdrErrState.warning : HdrErrState.error);
    }

    return (
        <div className="setting-control-container">
            <SettingHeader
                message={props.setting.msgWarningOrError ? props.setting.msgWarningOrError : props.setting.defaultMessage}
                state={state}
                title={props.setting.name}
                key={props.setting.name} />
            <div className="setting-control-body">
                <div className="setting-slider-container">
                    <Slider
                        size="small"
                        step={100 / (props.setting.options.length - 1)}
                        value={types.find(i => i.label === props.setting.selectedOption.display)?.value}
                        marks={types}
                        onChange={handleChange}
                        sx={{ color: '#2196F3' }}
                        />
                </div>
            </div>
        </div>
    );
}
