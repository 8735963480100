import React, { useRef, useState, useEffect } from 'react';
import '../../common/ChassisConfig.scss';
import '../../../styles/Meteor.scss'
import { ModalStatus, PlatformMicro } from '@csa-core/advisor.controlsystemcore'; // '../../PlatformConstants';
import { getChassisSlotUsage, updateChassis } from '@csa-core/advisor.controlsystemcore'; // "../../../implementation/ImplGeneral";
import {
	MICROChassisCfgData
} from '@csa-core/advisor.controlsystemcore'; // '../model/MicroGuidedSelection';
import {
	makeChoicesGroup,
} from '@csa-core/advisor.controlsystemcore'; // '../../../util/ChoicesHelp';

import {
	StatusLevel,
	ChoiceInfo,
	MessageCategory,
	MessageType,
	PanelMessage,
	createNewGrpChoiceInfo,
	BaseChoiceInfo,
	createNewGdSelGrpChoiceInfo,
	ChoiceGroupType
} from '@csa-core/advisor.controlsystemcore'; // '../../../types/MessageTypes';
import {
	Chassis,
	ChassisProject,
	ResultStatus,
	SelectableDevice,
	LocAttributeInfo,
	ChassisCfgGrpCategory,
	MicroChassis,
	EnvRating
} from '@csa-core/advisor.controlsystemcore'; // '../../../types/ProjectTypes';
import { PSInputVoltage } from '@csa-core/advisor.controlsystemcore'; // "../../../types/PowerTypes";
import {
	addChassis,
	getChassisName,
	getLocAttrFromProject,
	updateRackLayout
} from '@csa-core/advisor.controlsystemcore'; // '../../../model/ChassisProject';
import {
	addButton,
	ModalRequestSpec,
	requestModal
} from '../../../modals/ModalHelp';
import {
	microPrepareLocAttrForChassisConfig,
	microGetChassisCfgDataFromLocAttr
} from '@csa-core/advisor.controlsystemcore'; // '../model/MicroGuidedSelection';
import { chassisChanging, suspendUndoSnapshots } from '@csa-core/advisor.controlsystemcore'; // '../../../util/UndoRedo';
import { Point } from '@csa-core/advisor.controlsystemcore'; // '../../../types/SizeAndPosTypes';
import { IncludeOldStyleMsgPnlInChassisConfig } from '@csa-core/advisor.controlsystemcore'; // '../../../types/Globals';
import { ProjectSetting } from '@csa-core/advisor.controlsystemcore'; // '../../../types/SettingsTypes';
import { cloneLocAttributeInfo, getLocAttributeSetting } from '@csa-core/advisor.controlsystemcore'; // '../../../model/GuidedSelection';
import { getLocAttrInfoForChassisEdit } from '@csa-core/advisor.controlsystemcore'; // '../../../implementation/ImplHardwareGen';
import {
	CfgDlgMsgDtls,
	CfgDlgTabID,
	cfgDlgUnspecifiedChassisName,
	cfgDlgUpdateMessages,
	//cfgDlgUnspecifiedChassisName,
	ConfigChassisCBData,
	getChassisEnvType,
	InitCfgData,
	loadTipPanelMsgs,
} from '@csa-core/advisor.controlsystemcore'; // '../../common/ChassisConfig';
import ChassisConfigDlg from '../../common/ChassisConfigComp';
import { cfgDlgGSSelectionChanged, cfgDlgInitializeLocAttrInfoFromChassis } from '../../common/ChassisConfigGuidedSel';
import { replaceChassis } from '@csa-core/advisor.controlsystemcore'; // '../../common/ChassisConfigReplaceChassis';
import { microgetpowersupply, microCreatePowerSupply } from '@csa-core/advisor.controlsystemcore'; // '../model/MicroGeneralImpl';


const unexpected = (msg: string) => {
	alert(msg);
	throw new Error(msg);
}

const _situationalMsgInfo = new Map<MessageCategory, CfgDlgMsgDtls>();

let liveTipCallback = (category: MessageCategory, type: MessageType, pt: Point) => {
	category;
	type;
	pt;
	alert('No live tip callback established');
}

const tipCallback = (category: MessageCategory, type: MessageType, pt: Point) => {
	liveTipCallback(category, type, pt);
}


let _initData: InitCfgData | undefined = undefined;

const getInitialSelsFromChassis = (chassis: MicroChassis): MICROChassisCfgData => {

	const envType = getChassisEnvType(chassis);
	const wiringType = chassis.defaultIOModWiring;
	const numRMs =  0;
	const numSlots = chassis.modules.length;
	const inputVoltage = chassis.defaultPowerSupplyValue ? chassis.defaultPowerSupplyValue : PSInputVoltage.DC24V;
	const psSel = '';
	const highAvail = false;
	const buSelCatalog = chassis.bu?.catNo ? chassis.bu?.catNo  : ''
	return {
		envType: envType,
		wiringType: wiringType,
		numRMs: numRMs,
		psVoltage: inputVoltage || PSInputVoltage,
		psSelCatalog: psSel,
		chassisCatalog: chassis.catNo,
		numSlots: numSlots,
		highAvail: highAvail,
		buSelCatalog: buSelCatalog
	};
}

const onChassisEditAttrInfoLoaded = (success: boolean, attrInfo: LocAttributeInfo) => {
	if (_initData == null || attrInfo == null)
		return;

	const [totalSlotsInUse, slotFillers] = getChassisSlotUsage(_initData.chassis);
	const initSelections = (_initData.chassis ? getInitialSelsFromChassis(_initData.chassis) : microGetChassisCfgDataFromLocAttr(attrInfo));
	if (_initData.chassis)
		cfgDlgInitializeLocAttrInfoFromChassis(attrInfo, initSelections);

	let arrChassisPage: BaseChoiceInfo[] = [];
	const attrGrpChassis = attrInfo.attrGroups.find(x => x.title === ChassisCfgGrpCategory.Chassis);
	if (attrGrpChassis) {
		arrChassisPage = attrGrpChassis.settings.map((setting, idx) => {
			return createNewGdSelGrpChoiceInfo(
				attrInfo, setting, tipCallback, ChassisCfgGrpCategory.Chassis, idx
			);
		});
	}

	let arrPowerPage: BaseChoiceInfo[] = [];
	let lastIdx = 0;
	const attrGrpPower = attrInfo.attrGroups.find(x => x.title === ChassisCfgGrpCategory.Power);
	if (attrGrpPower) {
		arrPowerPage = attrGrpPower.settings.map((setting, idx) => {
			lastIdx = idx;
			return createNewGdSelGrpChoiceInfo(
				attrInfo, setting, tipCallback, ChassisCfgGrpCategory.Power, idx
			);
		});
	}
	const psTempGrp = makeChoicesGroup(ChoiceGroupType.RequireOneOf, 'Power Supply');
	const psGrpInfo = createNewGrpChoiceInfo(psTempGrp, ['']);
	// Add the 'non-Guided selection' Power Group
	// to the Power Page.
	psGrpInfo.pageID = ChassisCfgGrpCategory.Power;
	psGrpInfo.index = lastIdx + 1;
	arrPowerPage.push( );

	const callbackData: ConfigChassisCBData = {
		project: _initData.project,
		cfgAttrInfo: attrInfo,
		initialGSSelections: [], // initially set to empty.

		selectChassisCallback: _initData.selectChassisCallback,
		selectDeviceCallback: _initData.selectDeviceCallback,
		contentChangedCallback: _initData.contentChangedCallback,

		chassis: _initData.chassis,
		chassisName: _initData.chassis ? getChassisName(_initData.chassis) : '',
		origCat: _initData.chassis ? _initData.chassis.catNo : '',
		origPSCat: (_initData.chassis && _initData.chassis.ps) ? _initData.chassis.ps.catNo : '',
		origTotalSlotsInUse: totalSlotsInUse,
		origSlotFillers:slotFillers,
		origQtyRMMods: initSelections.numRMs,
		allPossibleWiringTypes: 1,
		mapCatToWiringSpt: undefined,
		msgLevel: StatusLevel.NA,
		panelMessages: [],
		gsPanelMessage: [],

		ChassisPage: arrChassisPage,
		PowerPage: arrPowerPage,

		psGrp: psGrpInfo,
		additionalGrps: [],
	};

	cfgDlgUpdateMessages(callbackData, _situationalMsgInfo, tipCallback);
	const title = _initData.chassis
		? 'Edit Chassis'
		: 'Add Chassis';

	const request: ModalRequestSpec = {
		includeButtons: true,
		closeOnInsideClick: false,
		stayOpenOnBackdropClick: true,
		title: title,
		callback: configChassisCallback,
		requestorData: callbackData,
		content: MICROChassisConfig,
		width: Math.min(800, window.innerWidth - 40),
	};

	addButton(
		request,
		(_initData.chassis ? 'SAVE' : 'ADD'),
		ModalStatus.Confirmed,
		'contained');

	requestModal(request);
}

export const microGetChassisCatalogFromEnvRating = ( er: EnvRating): string=>{
	if (er === EnvRating.ConformalCoated)
		return '2080-CHASSIS-K';
	return '2080-CHASSIS';
}



	// Callback from the Modal.
const configChassisCallback = (status: number, data?: object) => {

	// If we got our 'go-ahead'...
	if (status === ModalStatus.Confirmed) {

		// Get our data object.
		const cbData = data as ConfigChassisCBData;

		// If we can...
		if (cbData) {
		
			const gsData = microGetChassisCfgDataFromLocAttr(cbData.cfgAttrInfo);
		
			const chassisCatalog = microGetChassisCatalogFromEnvRating(gsData.envType);
			const getBucatalog = cbData?.chassis && ( cbData?.chassis.bu?.catNo || cbData.chassis.buCatnumber)
			const getChassisCat = getBucatalog || gsData.buSelCatalog
			// Get the PSU selection for micro series
			const psCat = microgetpowersupply(gsData.psVoltage,getChassisCat )
			const newName = (cbData.chassisName.length > 0)
			? cbData.chassisName
			: cfgDlgUnspecifiedChassisName;
			// If we're editing an EXISTING chassis...
			if (cbData.chassis?.bu) {
				
				// Determine what, if anything actually changed.
				const changeChassis = (chassisCatalog !== cbData.origCat);
				const psVoltageChange = cbData.chassis.defaultPowerSupplyValue !== gsData.psVoltage
				const changeName = (getChassisName(cbData.chassis) !== newName);
				const changePS = (psCat !== cbData.origPSCat);
				const anyChanges = (changeChassis || changeName || changePS || psVoltageChange);
				
				// If anything is actually changing...
				if (anyChanges) {
					// BEFORE making any change, call our helper to take
					// a snapshot of our CURRENT content for undo purposes.
					chassisChanging(cbData.chassis);

					// Then suspend those snapshots until we're
					// done here. If we don't do this, adding the
					// new replacement chassis and the modules would
					// EACH end up as their own undo snapsnots.
					suspendUndoSnapshots(true);

					// De-select the currently selected chassis
					// and device if any selected.
					cbData.selectChassisCallback(undefined);

					cbData.selectDeviceCallback(undefined);

					// If we're actually changing the chassis itself...
					if (changeChassis) {
	// Replace using the new info.

						// Replace using the new info.
						const [rsltStatus, /*msgLevel*/, /*msg*/] =
							replaceChassis(cbData.chassis, chassisCatalog, psCat, false);


						// Test result.k
						if (rsltStatus !== ResultStatus.Success) {
							throw new Error('Final Chassis replace FAILED!');
						}
							
					}
					if(cbData.chassis.bu){
			
						cbData.chassis.defaultPowerSupplyValue = gsData.psVoltage
						cbData.chassis.ps  =  psCat !== '' ? microCreatePowerSupply(psCat) : undefined;
						cbData.chassis.isIoSeperate = true;
						suspendUndoSnapshots(false);
						updateChassis(cbData.chassis)
						
					}
					

					// Set the new name if that changed.
					if (changeName) {
						cbData.chassis.name = newName;
					}

	

					// Turn undo snapshots back on.
					suspendUndoSnapshots(false);

					// Update our rack layout. This might not be
					// needed in all cases, but doesn't hurt anything.
					if (cbData.chassis.parent) {
						updateRackLayout(cbData.chassis.parent);

					}
					else {
						throw new Error('Chassis without parent after replace?');
					}

					// Finally, (re)select our chassis. There shouldn't
					// be a need to 'bring it into view'. If it wasn't
					// already at least partially visible, we wouldn't
					// have been able to edit its configuration.
					cbData.selectChassisCallback(cbData.chassis);
				}
			}
		
           else{
				// We're adding a NEW chassis. Before we do,
				// De-select the currently selected chassis
				// and device if any selected.
				cbData.selectChassisCallback(undefined);
				cbData.selectDeviceCallback(undefined);

				// Add the new chassis using the info we got above.
				const newChassis = addChassis(cbData.project, PlatformMicro,
					chassisCatalog, -1, psCat, gsData.buSelCatalog);

				// If we got new chassis added...
				if (newChassis) {

					// Give the chassis its name.
					newChassis.name = newName;

					// Select the new chassis
					cbData.selectChassisCallback(newChassis);

                }
	}

            // Either way, we changed project content.
            cbData.contentChangedCallback();
        }
        else {
            // Unexpected
            throw new Error('ERROR: Invalid data in configChassisCallback!');
        }
    }
}



export const microConfigureChassis = (
	project: ChassisProject,
	platform: string,
	selectChassisCallback: (chassis: Chassis | undefined) => void,
	selectDeviceCallback: (device: SelectableDevice | undefined) => void,
	contentChangedCallback: () => void,
	chassis?: Chassis
) => {
	_initData = {
		project: project,
		selectChassisCallback: selectChassisCallback,
		selectDeviceCallback: selectDeviceCallback,
		contentChangedCallback: contentChangedCallback,
		chassis: chassis,
	};
	if (chassis) {
		getLocAttrInfoForChassisEdit(chassis.platform, onChassisEditAttrInfoLoaded);
	}
	else {
		// Get the current loc, if it is NOT Micro, we
		// need to create a new loc for the edit.
		const loc = getLocAttrFromProject(project);
		if (loc.platform !== platform) {
			getLocAttrInfoForChassisEdit(platform, onChassisEditAttrInfoLoaded);
		}
		else {
			// We need to clone the project's guided selection.
			const newLocInfo = cloneLocAttributeInfo(loc);
			microPrepareLocAttrForChassisConfig(newLocInfo);
			onChassisEditAttrInfoLoaded(true, newLocInfo);
		}
	}


}



const MICROChassisConfig = (request: ModalRequestSpec) => {
	const [currentTab, setCurrentTab] = useState<number>(CfgDlgTabID.Chassis);
	const [count, setCount] = useState<number>(0);
	const [infoPanelOpen, setInfoPanelOpen] = useState<boolean>(false);

	const divRef = useRef<HTMLDivElement | null>(null);
	const infoPt = useRef<Point>({ x: 0, y: 0 });
	const initializing = useRef(true);
	const infoMsgs = useRef<PanelMessage[]>(new Array<PanelMessage>());

	const data = request.requestorData as ConfigChassisCBData;

	if (!data) {
		unexpected('INVALID Request to ConfigAccys!');
	}

	const inclOldStyleMsgPanel = IncludeOldStyleMsgPnlInChassisConfig &&
		(data.panelMessages.length > 0);

		useEffect(()=>{
			if(data?.chassis?.isPower){
				setCurrentTab(CfgDlgTabID.PowerSupply)
			}
	
		},[data?.chassis?.isPower])
	const onTabSelected = (tabID: number) => {
		if (tabID !== currentTab) {
			setCurrentTab(tabID);
		}
	}

	const selectionChanged = () => {
	// 2024.2.16 This is for a non-Guided Selection
		// driven change - As of now, only for the PSU.

		// An option selection was changed, or at
		// least an attempt to change it was made.
		// Update the .allSelections collection in
		// our data object to match what we now
		// SHOULD have.
		// cfgDlgUpdatePSGroup(data, tipCallback);
		cfgDlgUpdateMessages(data, _situationalMsgInfo, tipCallback);

		// Trigger a re-render.
		setCount(count + 1);
	}

	const gsSelectionChanged = (gsSetting: ProjectSetting, newChoiceInfo: ChoiceInfo) => {
		const reRender = cfgDlgGSSelectionChanged(request,initializing.current, _situationalMsgInfo, tipCallback, gsSetting, newChoiceInfo);
		if (reRender)
			setCount(count + 1);
	}

	const findModalBaseDiv = (): HTMLElement | undefined => {
		if (divRef.current) {
			let parent = divRef.current.parentElement;
			while (parent) {
				if (parent.id === 'ModalBaseDiv') {
					return parent;
				}
				parent = parent.parentElement;
			}
		}
		return undefined;
	}

	const _horizontallyCenterTipInModal = true;

	const onTipCallback = (category: MessageCategory, type: MessageType, pt: Point) => {

		// The pt arg is the center of the icon that was
		// clicked in DOM coords (browser viewport). We
		// need the point relative to our Modal.
		// Find the base div of our parent modal.
		const mdlBase = findModalBaseDiv();

		// The get ITS client rect.
		const baseClRect = mdlBase ? mdlBase.getBoundingClientRect() : undefined;

		// If we can, adjust our pt accordingly.
		if (baseClRect) {
			pt.x -= baseClRect.left;
			pt.y -= baseClRect.top;

			// If we leave our pt here, the tip popup will/would
			// be centered horizontally at our point.
			// If our 'center in modal' flag is set, change
			// the x to the middle of our client instead.
			if (_horizontallyCenterTipInModal) {
				pt.x = (baseClRect.width / 2);
			}
		}


		// Save the adjusted point.
		infoPt.current = { ...pt };

		// Load messages for the given id.
		loadTipPanelMsgs(infoMsgs.current, _situationalMsgInfo, category, type);

		// And and set our panel-open state.
		// That'll re-render us, which will
		// then INCLUDE the floating panel.
		setInfoPanelOpen(true);
	}

	const onCloseInfoPanel = () => {
		setInfoPanelOpen(false);
		infoPt.current.x = 0;
		infoPt.current.y = 0;
		infoMsgs.current.length = 0;
	}

	liveTipCallback = onTipCallback;

	// If this is our first time through...
	if (initializing.current) {
		// Get the EnvRating Setting.
		const er = getLocAttributeSetting(data.cfgAttrInfo, 'ER');
		if (er) {
			// We have to do this here. By simulating
			// a Guided Selection change, the options
			// will be verified and disabled if need be.
			const chInfo: ChoiceInfo = {
				id: er.selectedOption.id,
				label: er.selectedOption.display,
				disabled: false
			}
			gsSelectionChanged(er, chInfo);
		}

		initializing.current = false;
	}
    return (
        <div
			className='config-chassis-dialog'
            ref={divRef}
		>
			<ChassisConfigDlg
				data={data}
				selectionChanged={selectionChanged}
				gsSelectionChanged={gsSelectionChanged}
				onTabSelected={onTabSelected}
				onCloseInfoPanel={onCloseInfoPanel}
				infoPanelOpen={infoPanelOpen}
				currentTab={currentTab}
				inclOldStyleMsgPanel={inclOldStyleMsgPanel}
				infoPt={infoPt.current}
				infoMsgs={infoMsgs.current}
			/>
        </div>
    );
}

export default MICROChassisConfig;
