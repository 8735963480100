import { EngInfoModule, ModalStatus } from '@csa-core/advisor.controlsystemcore'; // "../engData/EngineeringInfo";
import { addModuleAtSlot, getSlotTypeRestriction } from '@csa-core/advisor.controlsystemcore'; // "../implementation/ImplGeneral";
import { addButton, createBasicConfirmRequest, ModalRequestSpec, requestModal } from "../modals/ModalHelp";
import { Chassis } from '@csa-core/advisor.controlsystemcore'; // "../types/ProjectTypes";
import { makeModuleTreeFor, ModuleTreeFolder } from '@csa-core/advisor.controlsystemcore'; // "../util/ModSelectionHelp";
import OLD_ModuleSelection from "./OLD_ModuleSelection";


interface ModSelInfo {
    module: EngInfoModule;
    quantity: number;
}


// Used as Component props as well as data in callback.
export interface ModuleSelectionProps {
    treeRoot: ModuleTreeFolder;
    selections: ModSelInfo[];
    chassis?: Chassis;
    slotTarget: number;
    contentChanged: () => void;
}


export const getNewModuleSelection = (modInfo: EngInfoModule, quantity: number):
    ModSelInfo => {
    return {
        module: modInfo,
        quantity: quantity,
    }
}


// Callback
const oldAddModuleCallback = (status: number, data?: object) => {
    // Cast the data object to our component props.
    const modSelData = data as ModuleSelectionProps;
    // If the user OK'd the new harwdare generation...
    if (status === ModalStatus.Confirmed && modSelData != null) {

        if (modSelData.chassis && modSelData.selections.length > 0) {
            const catNo = modSelData.selections[0].module.catNo;
            const success =
                addModuleAtSlot(modSelData.chassis, catNo, modSelData.slotTarget, false);
            if (success) {
                modSelData.contentChanged();
            }
            else {
                // The module insert failed. Assuming that module choices
                // offered were already pre - qualified, a failure here is
                // unexpected. If it DOES occur, it most likely means that
                // there was an module choice offered that wasn't compatible
                // with the chassis for one reason or another. In that case,
                // we DO want to at least tell the user something. However,
                // we CAN'T use normal displayAlertMsg function, since that
                // uses the Modal system, and we ALREADY HAVE a modal running
                // at this point. As such, we need to use the generic built-in
                // window.alert function instead for our message.
                const msg = 'ERROR: Module Insert Failed\n\n' +
                    'The module selected (' + catNo +
                    ') could not be added to the chassis.\n\n' +
                    'The most likely cause is a mismatch between the ' +
                    'environmental ratings of the module and chassis.';
                window.alert(msg);
            }
        }
    }
}


const _makeOldAddModuleReqSpec = (): ModalRequestSpec => {
    // Create our base interface.
    const requestSpec = createBasicConfirmRequest('');
    requestSpec.callback = oldAddModuleCallback;
    requestSpec.content = OLD_ModuleSelection;
    requestSpec.closeOnInsideClick = false;
    requestSpec.stayOpenOnBackdropClick = true;
    requestSpec.width = Math.min(window.innerWidth - 100, 600); // Modal Width.

    requestSpec.title = 'Select Module to Add';
    const btnInfo = addButton(requestSpec, 'ADD MODULE',
        ModalStatus.Confirmed, 'contained');
    btnInfo.disabled = true;

    return requestSpec;
}

// Props creation for modal dialog.
export const launchOldAddModule = (chassis: Chassis, slot: number, contentChanged: () => void) => {

    const restrict = getSlotTypeRestriction(chassis, slot);

    // Create the data interface to be used in our component
    // as well as our callback function.
    const ModuleSelectionProps: ModuleSelectionProps = {
        treeRoot: makeModuleTreeFor(chassis, restrict),
        chassis: chassis,
        slotTarget: slot,
        contentChanged: contentChanged,
        selections: new Array<ModSelInfo>()
    };

    // Create the modal request spec and set our data in it.
    const requestSpec = _makeOldAddModuleReqSpec();

    // Set requestorData to our data/props.
    requestSpec.requestorData = ModuleSelectionProps;

    requestModal(requestSpec);
}



