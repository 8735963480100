// Constants for most common status returns. ANY
// other numbers can be used for custom buttons, but
// these two should be RESERVED for what we would
// normally think of as OK and CANCELLED.
export const ModalStatus = {
    Cancelled: 0,
    Confirmed: 1,
    Ok: 2,
    Yes: 3,
    No: 4,
};

// Callback functions must match standard.
//    status: gives end result of modal
//    data: passes the SAME data object that was sent in
//          with request. This gives the callback the
//          contextual info needed to do whatever it needs
//          to do. Example, after a delete confirmation,
//          what's actually supposed to get deleted.
export interface IModalResultCallback {
    (status: number, data?: object): void;
}
