import { loadProductSelection } from "../../model/ProductSelection";
import { loadCountries, loadIndustries } from "../../projInfo/LocationAndIndustry";
import { loadEngineeringData } from "../../model/EngineeringData";
import { initializePlatform } from "../../platforms/SupportedPlatforms";
import { createNewPlatformLoadTracker, onPlatformLoadedCallback, PlatformLoadTracker, ApiTypeID } from "../../types/APITypes";
import { loadDefGuidedSelection } from "../../model/GuidedSelection";
import { Id } from "react-toastify";
import { coreToastLoadProgress } from "../../uiInterfaces/UIMessenger";


const _platformTrackers = new Map<string, PlatformLoadTracker>();
const _pendingTrackers: PlatformLoadTracker[] = [];

export const getPlatformsLoaded = (): string[] => {
	const arrPlatforms: string[] = [];
	_platformTrackers.forEach(( val, key) => {
		arrPlatforms.push(key);
	});

	return arrPlatforms;
}

export const LoadPlatform = (platform: string, toastId: Id, callback: onPlatformLoadedCallback) => {
	// If we have it...
	let tracker = _platformTrackers.get(platform);
	if (tracker) {
		// We have an entry - either the platform is
		// loading or is loaded. If the tracker is 
		// NOT pending (i.e. platform should be loaded)... 
		if (_pendingTrackers.find(x => x === tracker) == null) {
			// If not loaded, we have a problem!
			const isLoaded = isPlatformLoaded(tracker, true);
			if (!isLoaded)
				throw new Error(`LoadInitialPlatformData(): Platform ${platform} should be loaded but is not!`);

			callback(platform, isLoaded);
		}

		// Loaded or not, we exit here.
		return;
	}

	// Initialize the platform itself. This step
	// should get all of the platform's implementations
	// registered. In no event should it perform
	// ANY asyncronous process.
	initializePlatform(platform);

	// Create a new tracker and add it to our map.
	tracker = createNewPlatformLoadTracker(platform, callback, apiResolvedCallback, onDefaultGuidedSelectionLoaded);
	_platformTrackers.set(platform, tracker);

	// Add the tracker to our pending list. N[ote: We
	// may have several platform load requests at once
	// in the future. We do not want to notify anyone
	// that the a single platform has loaded when another
	// is still pending (i.e. UI acts like everything is
	// good to go, but it's not).
	_pendingTrackers.push(tracker);

	// Note: should load and parse data before calling
	// the apiResolvedCallback() in the tracker.
	coreToastLoadProgress(toastId, 'Loading industries...');
	loadIndustries(tracker);
	coreToastLoadProgress(toastId, 'Loading countries...');
	loadCountries(tracker);
	coreToastLoadProgress(toastId, 'Loading product selection info...');
	loadProductSelection(tracker);
	coreToastLoadProgress(toastId, 'Loading engineering info...');
	loadEngineeringData(tracker);
}


const apiResolvedCallback = (apiType: ApiTypeID, platformTracker: PlatformLoadTracker) => {
	// API Type is to let us know what API
	// has been loaded. Not used now, but
	// is an important piece of info.
	apiType; // prevent warning.
	platformTracker; // prevent warning.

	// Spin through our pending trackers. If all are
	// loaded NOT loaded, set are flag to notify to
	// false.
	let basedataLoaded = true;
	_pendingTrackers.forEach((trk) => {
		if (isPlatformLoaded(trk, false) === false)
			basedataLoaded = false;
	})

	// If the base data has been loaded for all platforms....
	if (basedataLoaded) {
		// Since Guided Selections relies on the initial
		// platform data loaded, we now proceed to load
		// the default guided selection for each platform.
		_pendingTrackers.forEach((trk) => {
			loadDefGuidedSelection(trk);
		})
	}
}

type TrackerCallbackMap = Map<onPlatformLoadedCallback, string[]>;

const _mapTrackerCallback = (map: TrackerCallbackMap, trk: PlatformLoadTracker) => {
	const entry = map.get(trk.onAllLoadedCallback);
	if (entry) {
		entry.push(trk.platform);
	}
	else {
		const newEntry = new Array<string>();
		newEntry.push(trk.platform);
		map.set(trk.onAllLoadedCallback, newEntry);
	}
}

const onDefaultGuidedSelectionLoaded = () => {
	// Are we all loaded? If not, just return.
	if (_pendingTrackers.some(x => x.defaultGuidedSelectionLoaded === false))
		return;

	// Create a map. Keys are unique callback
	// functions, and values are arrays of all
	// platforms that used that SAME callback
	// when requesting their loads.
	const callbackMap = new Map<onPlatformLoadedCallback, string[]>();

	// Build the map content.
	_pendingTrackers.forEach(trk => {
		_mapTrackerCallback(callbackMap, trk);
	});


	// Finally, call the callback functions.
	// IMPORTANT: No function called here should
	// attempt to start ANOTHER platform load. If
	// they did, it would be lost immedetiately 
	// below when we clear the _pendingTrackers.
	callbackMap.forEach((platforms, callback) => {
		platforms.forEach(platform => {
			callback(platform, true);
		});
	});

	// AFTER making the callbacks, clear our pending
	// tracker list.This makes sure we're clean for
	// any MORE load requests that could potentially
	// be made at some later time. It is important to
	_pendingTrackers.length = 0;
}


//////// UTILITIES //////////////////////////////////
const isPlatformLoaded = (tracker: PlatformLoadTracker, checkGuidedSelection: boolean): boolean => {
	if (checkGuidedSelection) {
		return (tracker.engineeringDataLoaded &&
			tracker.productSelectionLoaded &&
			tracker.countriesLoaded &&
			tracker.industriesLoaded &&
			tracker.defaultGuidedSelectionLoaded);
	}

	return (tracker.engineeringDataLoaded &&
		tracker.productSelectionLoaded &&
		tracker.countriesLoaded &&
		tracker.industriesLoaded);
}

const isDefaultDataUsed = (tracker: PlatformLoadTracker, productDataRelatedOnly: boolean): boolean => {
	// If we are only returning the status for
	// platform data (prod selection/data)...
	if (productDataRelatedOnly) 
		return (tracker.defProductData || tracker.defProductSelection);

	// Return if ANY data is the default.
	return (tracker.defCountries ||
		tracker.defIndustries ||
		tracker.defProductData ||
		tracker.defProductSelection ||
		tracker.defEngineeringData
	);
}

export const getPlatformLoaderWarnings = (): string[] => {
	// Run through our 'loader trackers' and return
	// an array of warnings if one or more platforms
	// fell back to using default data.
	const messages: string[] = [];
	_platformTrackers.forEach((trck) => {
		// For now only add a message if platform
		// 'product data related' information is
		//  using defaults, meaning the API failed.
		if (isDefaultDataUsed(trck, true)) {
			const msg =
				`The latest data for the ${trck.platform} platform could not be retrieved. ` +
				'Some Products or Product Selections may be out of date.';
			messages.push(msg);
		}
	});

	return messages;
}

const formatString = (template: string, ...args: string[]) => {
	return template.replace(/{([0-9]+)}/g, function (match, index) {
		return typeof args[index] === 'undefined' ? match : args[index];
	});
}

export const getPlatformDataWarnings = (): string[] => {
	// Run through our 'loader trackers' and return
	// an array of warnings if one or more platforms
	// fell back to using default data.
	const messages: string[] = [];

	_platformTrackers.forEach((trck) => {
		// For now only add a message if platform
		// 'product data related' information is
		//  using defaults, meaning the API failed.
		if (isDefaultDataUsed(trck, false)) {
			const msg = `The latest {0} data for the ${trck.platform} platform could not be retrieved.`;
			
			if (trck.defCountries) {
				messages.push(formatString(msg, 'Countries'));
			}
			if (trck.defIndustries) {
				messages.push(formatString(msg, 'Industries'));
			}
			if (trck.defProductData) {
				messages.push(formatString(msg, 'Product'));
			}
			if (trck.defProductSelection) {
				messages.push(formatString(msg, 'Product Selection'));
			}
			if (trck.defEngineeringData) {
				messages.push(formatString(msg, 'Engineering'));
			}
		}
	});

	return messages;
}
