import React from 'react';
import './PerformanceInfo.scss';
import '../../styles/Meteor.scss'
import { PerfUsageDetails } from '@csa-core/advisor.controlsystemcore'; // '../../model/CommDetails';
import { DetailItem } from '@csa-core/advisor.controlsystemcore'; // '../../model/DeviceDetail';
import PerfColUsageItem from './PerfColUsageItem';
import { PlatformMicro } from '@csa-core/advisor.controlsystemcore'; // '../../platforms/PlatformConstants';


interface Props {
    title: string;
    progWidth: number;
    perfUsageItems: PerfUsageDetails[];
    detailItems: DetailItem[];
    platform?:string;
}

const PerformanceColumn = (props: Props) => {

    let nextKey = 1;
    const isMicro800platform = props.platform===PlatformMicro;
    const className = isMicro800platform ? 'perfcap-column-detail-content a-type__body-new' : 'perf-column-detail-content a-type__body-new';
    return (
        <div className='perf-column'>
            <span className='perf-column-title'>
                {props.title}
            </span>
            {props.perfUsageItems.map(uitem => {
                return (
                    <PerfColUsageItem
                        key={nextKey++}
                        label={uitem.label}
                        pct={uitem.pctUsed}
                        text={uitem.valueText}
                        status={uitem.status}
                        progWidth={props.progWidth}
                    />
                )
            })}
            {props.detailItems.map(ditem => {
                return (
                    <div
                        key={nextKey++}
                        className={className}
                    >
                        <span key={nextKey++}>
                            {ditem.name}
                        </span>
                        <span key={nextKey++}>
                            {ditem.value}
                        </span>
                    </div>
                )
            })}
        </div>
    )
}

export default PerformanceColumn;
