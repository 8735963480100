import React, { useRef, useState } from "react";
import { PanelMessage, StatusLevel } from '@csa-core/advisor.controlsystemcore'; // "../types/MessageTypes"
import { Point } from '@csa-core/advisor.controlsystemcore'; // "../types/SizeAndPosTypes";
import { isNewStatusWorse } from '@csa-core/advisor.controlsystemcore'; // "../util/GeneralHelpers";
import BlueInfoIcon from '../components/layoutButtons/precoloredSvgs/blue-information-circle.svg';
import YellowWarningIcon from '../components/layoutButtons/precoloredSvgs/yellow-errors.svg';
import RedErrorIcon from '../components/layoutButtons/precoloredSvgs/red-failed.svg';
import { IconButton } from "@mui/material";
import FloatingMessagePanel from "../components/FloatingMessagePanel";
import { displayAlertMsg } from "../util/MessageHelp";
import { DefaultFltMsgPanelWidth } from "../components/MessagePanel";


interface Props {
	messages: PanelMessage[];
	iconWidth?: string | number;
}
export const ModalContentTipIconBtn = (props: Props) => {

	const [tipOpen, setTipOpen] = useState<boolean>(false);
	const tipDiv = useRef<HTMLImageElement | null>(null);
	const infoPt = useRef<Point>({ x: 0, y: 0 });
	const infoMsgs = useRef<PanelMessage[]>(new Array<PanelMessage>());

	const _iconSrc = useRef<string | undefined>(undefined);

	// If we PanelMessage Array instance has changed...
	if (infoMsgs.current != props.messages) {
		infoMsgs.current = props.messages;
		let statusLvl: StatusLevel | undefined = undefined;
		props.messages.forEach((msg) => {
			if (!statusLvl)
				statusLvl = StatusLevel.NA;

			if (isNewStatusWorse(msg.level, statusLvl))
				statusLvl = msg.level;
		});

		_iconSrc.current = undefined;
		if (statusLvl) {
			switch (statusLvl) {
				case StatusLevel.Info:
					_iconSrc.current = BlueInfoIcon;
					break;
				case StatusLevel.Warning:
					_iconSrc.current = YellowWarningIcon;
					break;
				case StatusLevel.Error:
					_iconSrc.current = RedErrorIcon;
					break;
			}
		}
	}

	const findModalBaseDiv = (): [parent: HTMLElement | undefined, modal: boolean] => {
		if (tipDiv.current) {
			// We have 3 possible cases:
			//	1] This control is a direct child of the "ModalBaseDiv".
			//	2] This control is nested inside of the "ModalBaseDiv".
			//	3] This control is NOT inside the Modal Window at all.
			// In cases 2 and 3, we return the parent element capable
			// of accommodating the width of the floating msg panel.
			// Otherwise, in the first case, we return the ModalBaseDiv.

			// Setup some starting variables then walk the parents.
			let modal = false;
			let parent = tipDiv.current.parentElement;
			let qualifiedParent: HTMLElement | undefined = undefined;
			let levelNesting = 0;
			while (parent) {
				// Did we get to the Modal's Base Div?
				if (parent.id === 'ModalBaseDiv') {
					// We are in the Modal.
					modal = true;
					// If we are a DIRECT CHILD...
					if (levelNesting === 0)
						return [parent, modal];
					else {
						// If we do NOT have a parent capable of hosting
						// the floating message panel, set it to the base div.
						if (qualifiedParent == null)
							qualifiedParent = parent;

						break;
					}
				}

				levelNesting++;

				// If we do not have a qualified parent....
				if (qualifiedParent == null) {
					// If the parent is wide enough to handle the
					// width of the floating message panel...
					if (parent.getBoundingClientRect().width >= DefaultFltMsgPanelWidth)
						qualifiedParent = parent;
				}

				parent = parent.parentElement;
			}
			return [qualifiedParent, modal];
		}

		return [undefined, false];
	}

	const onTipCallback = (pt: Point) => {

		// The pt arg is the center of the icon that was
		// clicked in DOM coords (browser viewport). We
		// need the point relative to our Modal.
		// Find the base div of our parent modal.
		const [parentElement, inModal] = findModalBaseDiv();

		// If we are NOT in a modal....
		if (!inModal) {
			// Displaying the <FloatingMessagePanel> outside of
			// the Modal window proved unreliable. Instead,
			// pop a modal Alert message.
			let message = '';
			let statusLvl: StatusLevel | undefined = undefined;

			props.messages.forEach((msg) => {
				if (message)
					message += '\n\n';

				message += msg.text;

				if ((statusLvl && isNewStatusWorse(msg.level, statusLvl)) || (statusLvl == null))
					statusLvl = msg.level;
			});

			displayAlertMsg(message, statusLvl);
		}
		else {
			// We are in the Modal. Get the rect of the
			// first parent, or the Modal Base, of our button.
			const rcParentClient = (parentElement ? parentElement.getBoundingClientRect() : undefined);

			// If we can, adjust our pt accordingly.
			if (rcParentClient) {
				// Set the verticle point.
				pt.y -= rcParentClient.top;
				// Add some extra room. assuming a line
				// is ~14px, move the top up ~2 lines.
				pt.y -= 28;

				// Center our message panel horizontally in
				// the parent.
				pt.x = (rcParentClient.width / 2);
			}

			// Save the adjusted point.
			infoPt.current = { ...pt };

			// And and set our panel-open state.
			// That'll re-render us, which will
			// then INCLUDE the floating panel.
			setTipOpen(true);
		}
	}

	const onCloseInfoPanel = () => {
		setTipOpen(false);
		infoPt.current.x = 0;
		infoPt.current.y = 0;
	}

	const onPointerDown = (e: React.MouseEvent<HTMLButtonElement>) => {

		// Consume the event so that it
		// doesn't make its way anywhere else.
		// Note: Not sure this is good in ALL 
		// situations. For now, 'stop the prop'.
		e.stopPropagation();

		// The pt we include in our callback is
		// expected to be the center of our icon.
		// if we can determine that. Init to 0,0.
		const pt: Point = { x: 0, y: 0 };

		// If we have a divRef (and we
		// always should at this point)...
		if (tipDiv.current) {
			// Set the .
			const r = tipDiv.current.getBoundingClientRect();
			pt.x = r.left + Math.round(r.width / 2);
			pt.y = r.top + Math.round(r.height / 2);
		}

		onTipCallback(pt);
	}

	let szIcon = props.iconWidth;
	if (!szIcon)
		szIcon = '14px';

	const disabled = (props.messages ? false : true);
	return (
		<div>
			<IconButton
				edge={false}
				size='small'
				disableRipple
				disabled={disabled}
				onPointerDown={onPointerDown}
				sx={{minHeight: 0, minWidth: 0, padding: 0, marginLeft: 0.5, marginTop: -0.2} }
			>
				<img ref={tipDiv} src={_iconSrc.current} width={szIcon} />
			</IconButton>
			{tipOpen
				?
				<FloatingMessagePanel
					ptCenter={infoPt.current}
					messages={infoMsgs.current}
					onClose={onCloseInfoPanel}
				/>
				:
				null}
		</div>
	);
}