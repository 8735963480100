import React from 'react';
import './Choices.scss';
import { GroupChoiceInfo, ChoiceGroupType, ChoiceInfo, ChoicesSubgroup } from '@csa-core/advisor.controlsystemcore'; // '../../types/MessageTypes';
import ChoiceGroupHdr from './ChoiceGroupHdr';
import ChoiceSelector from './ChoiceSelector';


interface Props {
    grpInfo: GroupChoiceInfo;
    selectionChanged: () => void;
    outlinedSelectors?: boolean;
}

const ChoiceGroup = (props: Props) => {

    const radType = (props.grpInfo.group.type === ChoiceGroupType.RequireOneOf);

    const choicesClass = (props.grpInfo.group.row === true)
        ? 'choices-subgroup-choices-row'
        : 'choices-subgroup-choices-column';

    const grpChoiceChanged = (info: ChoiceInfo, selected: boolean) => {

        // Do what's appropriate based on our accy group's type.
        switch (props.grpInfo.group.type) {

            case ChoiceGroupType.RequireOneOf:
                // For RequireOneOf, we want a single
                // selection. We'll ignore the change
                // if our selected arg is false. But,
                // if our info was 'selected'...
                if (selected) {

                    // Make our selections an array with
                    // just the info's id in it.
                    props.grpInfo.selections = [info.id];
                }
                break;

            case ChoiceGroupType.Require:
                // All members of a Require group will
                // ALWAYS remain selected. As such, we'll
                // just ignore any attempt to change that,
                break;

            case ChoiceGroupType.Recommend:
            case ChoiceGroupType.Optional:
                // For optionals, if this accy was selected...
                if (selected) {

                    // Add it to the our group's selections,
                    // but only if it's not already in there.
                    if (!props.grpInfo.selections.includes(info.id)) {
                        props.grpInfo.selections.push(info.id);
                    }
                }
                else {
                    // It was UN-selected.
                    // Remove it from our group's selections
                    // if it's currently in there.
                    props.grpInfo.selections =
                        props.grpInfo.selections.filter(elem => elem != info.id);
                }
                break;

            // Unexpected.
            default:
                throw new Error('Unexpected group type in AccessoryGroup grpChoiceChanged');
        }

        // Notify our parent that something changed.
        props.selectionChanged();
    }

    const isChecked = (id: string): boolean => {
        return props.grpInfo.selections.includes(id);
    }


    let nextKey = 1;

    const renderSubgroup = (subgroup: ChoicesSubgroup) => {

        return (
            <div
                key={nextKey++}
                className='choices-subgroup'
            >
                <ChoiceGroupHdr subgroup={subgroup} />
                <div className={choicesClass}>
                    {subgroup.selectionInfo.map(info => {
                        return (
                            <ChoiceSelector
                                key={nextKey++}
                                info={info}
                                radio={radType}
                                checked={isChecked(info.id)}
                                onSelChanged={grpChoiceChanged}
                                outlined={props.outlinedSelectors}
                            />
                        );
                    })}
                </div>
            </div>
            );
    }

    return (
        <div className='choices-group'>
            {props.grpInfo.group.subgroups.map(subgroup => {
                return renderSubgroup(subgroup)
            })}
        </div>
    );
}

export default ChoiceGroup;

