import React, {
	useEffect,
	useState,
	useCallback,
	useRef
} from 'react';
import './PointEntry.scss'
import '../styles/Meteor.scss'
import { PointEntryHeader } from './PointEntryHeader';
import { IOEntryModeEnum } from '@csa-core/advisor.controlsystemcore'; // '../types/SettingsTypes';
import {
	ComponentRenderInfo,
	DesignPageChildID,
	getDesignPageCompRenderInfo,
	registerDesignPageCompRenderInfo,
	unregisterDesignPageCompRenderInfo
} from '@csa-core/advisor.controlsystemcore'; // '../settings/DesignPageComponentRenderInfo';
import { getLocAttributeSetting } from '@csa-core/advisor.controlsystemcore'; // '../model/GuidedSelection';
import { ChassisProject, LocAttributeInfo } from '@csa-core/advisor.controlsystemcore'; // '../types/ProjectTypes';
import { PointEntryInfo, PointEntrySectionInfo } from '@csa-core/advisor.controlsystemcore'; // '../types/IOPointEntryTypes';
import {
	IOTypeAndFeatureInfo,
} from '@csa-core/advisor.controlsystemcore'; // '../types/IOModuleTypes';
import { PointEntry } from './PointEntry';
import { EngInfoIOModule } from '@csa-core/advisor.controlsystemcore'; // '../engData/EngineeringInfo';
import { getLocAttrFromProject } from '@csa-core/advisor.controlsystemcore'; // '../model/ChassisProject';
import { setConfigModified } from '@csa-core/advisor.controlsystemcore'; // '../userProject/UserProjectHelp';
import {
	getAvailableTypesToFeaturesMap,
	getIOModulesForLoc,
	getDefaultIOModuleCatalog,
	calcModQtyFromPoints,
	getClosestIOModMatch
} from '@csa-core/advisor.controlsystemcore'; // '../util/IOModuleHelp';
import { IOTypeAndQty } from '@csa-core/advisor.controlsystemcore'; // '../types/EngDataTypes';
//import { useWhatChanged } from '@simbathesailor/use-what-changed';

/// Flags to turn on/off component features.
export const overrideShowModuleSelections = false;	// Will show module combo in Basic I/O Entry Mode.
export const overrideShowModuleCount = false;		// Will show module totals for the points selected.

// Consildate the Entry into the maps.
const _addConsolidatedEntry = (
	mapCatToPt : Map<string, IOTypeAndQty[]>,
	mapCatToEntry : Map<string, PointEntryInfo[]>,
	qty: number,
	modCat: string,
	entry : PointEntryInfo ) => {

	const userPoints : IOTypeAndQty[] = [ { type: entry.type, qty: qty, typeID: 0 } ];
	// Tally the points in our map. If we have an entry
	// for the catalog, add the user points. Otherwise,
	// add a new entry to the map.
	const points = mapCatToPt.get( modCat );
	if ( points != null ) {
		points.push( ...userPoints );
	}
	else {
		mapCatToPt.set( modCat, userPoints );
	}

	// Add the entry to the array of entries for the catalog.
	const arrEntries = mapCatToEntry.get( modCat );
	if ( arrEntries == null )
		mapCatToEntry.set( modCat, [ entry ] );
	else
		arrEntries.push( entry );
}

// Process the consolidated maps.
const _processConsolidatedEntries = (
	mapCatToPt : Map<string, IOTypeAndQty[]>,
	mapCatToEntry : Map<string, PointEntryInfo[]>,
	platform : string,
	entryMode: IOEntryModeEnum) => {
	// Now walk the Advanced map (Advanced Selections Only!)...
	mapCatToPt.forEach( ( points, catalog ) => {
		// Get the array requested point type/qty.
		const entries = mapCatToEntry.get( catalog );
		if ( entries != null ) {
			// Calculate the module count needed. 
			let quantity = calcModQtyFromPoints( platform, catalog, points );

			// A negative return means error/invalid.
			const invalidEntry = ( quantity < 0 );
			if ( invalidEntry )
				quantity = 0;

			// Update each entry with the module count and whether
			// or not the module is selected in multiple entries.
			const consolidateMod = ( entries.length > 1 );
			entries.forEach( ( entry ) => {
				if ( entryMode === IOEntryModeEnum.Advanced )
					entry.advancedModCount = quantity;
				else
					entry.basicModCount = quantity;
				entry.moduleSelInMultipleEntries = consolidateMod;
				entry.invalidEntry = invalidEntry;
			} );
		}
	} );
}

export const resolvePointEntries = (info: PointEntrySectionInfo, locAttrInfo: LocAttributeInfo) => {
	// We need to resolve the new point counts. We will walk the
	// groups and their entries and populate 2 maps. One is catalog
	// to accumulated points (input/ouput). The other is catalog to
	// an array of entries. The idea here is to CONSOLIDATE the 
	// same modules selected in more than 1 entry.
	const mapAdvCatalogToPoints = new Map<string, IOTypeAndQty[]>();
	const mapAdvCatalogToEntries = new Map<string, PointEntryInfo[]>();
	const mapBasicCatalogToPoints = new Map<string, IOTypeAndQty[]>();
	const mapBasicCatalogToEntries = new Map<string, PointEntryInfo[]>();

	// Hardcode Attribute - 'SC' Spare I/O Capacity. We are treating
	// this as NON-PLATFORM Specific (for now). Either a platform has
	// it or they do not and the meaning will always be the same!
	let percentSpareIO = 0;
	const settingSpareIO = getLocAttributeSetting(locAttrInfo, 'SC');
	if (settingSpareIO) {
		percentSpareIO = Number(settingSpareIO.selectedOption.id) / 100;
	}

	info.entries.forEach((entry) => {
		if (entry.invalidEntry === false) {
			const qty = Math.ceil( entry.points + ( percentSpareIO * entry.points ) );

			// Handle the Basic Entry...
			if ( entry.basicModule ) {
				_addConsolidatedEntry(
					mapBasicCatalogToPoints,
					mapBasicCatalogToEntries,
					qty,
					entry.basicModule,
					entry );
			}

			// Handle the Advanced Entry
			if ( entry.advancedModule ) {
				_addConsolidatedEntry(
					mapAdvCatalogToPoints,
					mapAdvCatalogToEntries,
					qty,
					entry.advancedModule,
					entry );
			}
		}
	});

	// Process our basic consolidated entries
	_processConsolidatedEntries(
		mapBasicCatalogToPoints, mapBasicCatalogToEntries,
		locAttrInfo.platform, IOEntryModeEnum.Basic );
	// Process our advanced consolidated entries
	_processConsolidatedEntries(
		mapAdvCatalogToPoints, mapAdvCatalogToEntries,
		locAttrInfo.platform, IOEntryModeEnum.Advanced );
}

const _resolveAdvancedModOnExtMaskChanged = (entry: PointEntryInfo, loc: LocAttributeInfo, arrModInfo: EngInfoIOModule[]) => {
	// When this function is called, the default module
	// has been set ing the Basic-Mode Module.
	if (entry.basicModule == null) {
		// Should never get here if we do not have a default!
		entry.invalidEntry = true;
		return;
	}

	const defModCatalog = entry.basicModule;

	// We should not be using these anymore.

	// Determine the current entry selection. We can
	// have cases where this is undefined. Note: Even
	// though we can set the module catalog to the default,
	// we MUST continue so that we can query compatible
	// module options for the entry's combo selections.
	let oldSelection = '';
	if (entry.advancedModule != null) {
		oldSelection = entry.advancedModule;

		// If the old module is a default module...
		if (entry.isAdvModDefault) {
			// Set the old selection to the new default.
			oldSelection = defModCatalog;
		}
	}
	else {
		// Set the old selection to the new default.
		oldSelection = defModCatalog;
		entry.isAdvModDefault = true;
	}

	const closestMatch = getClosestIOModMatch(loc, oldSelection, entry.type, entry.feature, arrModInfo, true);
	if (closestMatch == null) {
		entry.invalidEntry = true;
	}
	else {
		entry.invalidEntry = false;
		entry.advancedModule = closestMatch.catNo;
		entry.isAdvModDefault = (closestMatch.catNo === defModCatalog);
	}
}

const resolveExternalMaskChanged = (info: PointEntrySectionInfo, locAttrInfo: LocAttributeInfo) => {
	info.entries.forEach((entry) => {
		updatePointEntry(entry, locAttrInfo);
	});
}


export const updatePointEntry = (entry: PointEntryInfo, locAttrInfo: LocAttributeInfo) => {
	// Reset the invalid flag.
	entry.invalidEntry = false;

	const [types, features, arrMod, type, feature] = getIOModulesForLoc(locAttrInfo, entry.type, entry.feature, true);

	entry.types = types;
	entry.features = features;
	entry.feature = feature;
	entry.moduleCatalogs = arrMod.map(x => x.catNo);
	entry.moduleDisplayStrs = arrMod.map(x => x.displayString);

	// We have an invalid type. Add it to 
	// the types and and set the entry to invalid.
	if (type.length === 0) {
		entry.types.splice(0, 0, entry.type);
		entry.invalidEntry = true;
		return;
	}

	// Get the default catalog from Product
	// Selection data OR from Eng Data when
	// product selection does not have one. 
	const catDefault = getDefaultIOModuleCatalog(entry.type, locAttrInfo);
	if (catDefault != null) {
		entry.basicModule = catDefault;
		_resolveAdvancedModOnExtMaskChanged(entry, locAttrInfo, arrMod);
	}
	else {
		// We do NOT have a default module!
		entry.invalidEntry = true;
	}
}

interface Props {
	project: ChassisProject;
	info: PointEntrySectionInfo;
	onModuleListChanged: () => void; // Callback to notify parent when modules change 
	contentChanged: () => void;
}


export const PointEntrySection = (props: Props) => {
	const [/*renderCnt*/, setRenderCnt] = useState(0);

	const mapTypesAndFeatures = useRef<Map<string, IOTypeAndFeatureInfo>>(new Map<string, IOTypeAndFeatureInfo>);
	const ioEntrySectionDiv = useRef<HTMLDivElement>(null);
	const paddingForVertScroll = useRef<string>('0px');

	useEffect(() => {
		// See comments for 'paddingBottom' definition below.
		if (ioEntrySectionDiv.current) {
			// We are adding a 4px fudge-factor.
			paddingForVertScroll.current = `${ioEntrySectionDiv.current.offsetTop + 4}px`;
		}

		// Register our refresh hooks so that other comps can use them.
		const hook: ComponentRenderInfo = { componentID: DesignPageChildID.PointEntrySection, renderCount: 1, setRenderCount: setRenderCnt };
		registerDesignPageCompRenderInfo(hook);

		return (() => unregisterDesignPageCompRenderInfo(hook));
	}, []);

	const locAttrInfo = getLocAttrFromProject(props.project);

	//useWhatChanged([props, locAttrInfo, props.info.externalMaskChanged, props.info.externalPercentSpareIOChanged]); // debugs the below useEffect dependencies.
	useEffect(() => {
		if (props.info.externalMaskChanged === true) {
			resolveExternalMaskChanged(props.info, locAttrInfo);
			resolvePointEntries(props.info, locAttrInfo);

			// Update our type and feature map.
			const [ , , arrModInfo] = getIOModulesForLoc(locAttrInfo, '', '', false);
			mapTypesAndFeatures.current = getAvailableTypesToFeaturesMap(arrModInfo);

			props.onModuleListChanged();

			const info = getDesignPageCompRenderInfo(DesignPageChildID.PointEntrySection);
			if (info)
				info.setRenderCount(++info.renderCount);
		}
		else if (props.info.externalPercentSpareIOChanged === true) {
			resolvePointEntries(props.info, locAttrInfo);
			props.onModuleListChanged();
		}

		props.info.externalMaskChanged = false;
		props.info.externalPercentSpareIOChanged = false;
	}, [props, locAttrInfo, props.info.externalMaskChanged, props.info.externalPercentSpareIOChanged]);

	const onPointSelectionChanged = useCallback(() => {
		const loc = getLocAttrFromProject(props.project);
		resolvePointEntries(props.info, loc);
		props.onModuleListChanged();

		// IOMod.debugLogPointTypeGroup(pointGroups); // Uncomment for console dump.

		setConfigModified(true);
		props.contentChanged();

		const info = getDesignPageCompRenderInfo(DesignPageChildID.PointEntrySection);
		if (info)
			info.setRenderCount(++info.renderCount);
	}, [props]);

	// 2024.1.24 If we do not have a location or the
	// Point Entry Section is not initialized...
	if (locAttrInfo == null || locAttrInfo.pointEntrySection.initialized === false) {
		return null;
	}

	const advancedMode = props.project.config.IOEntryMode === IOEntryModeEnum.Advanced;
	let nextKey = 1;

	// 2023.11.3 There were issues with the vertical scrolling of
	// the point entry section. The content extent was based from
	// the TOP of the page, not the TOP of the P.E. section <div>.
	// The paddingForVertScroll is the Offset of the section <div>
	// to the top of the page. By adding bottom padding to the
	// section <div>, we essentially extend the extent of the
	// content to compensate. Now the vertical scrollbar will show
	// when the browser starts to cover the lower Point Entries.
	const paddingBottom = (paddingForVertScroll.current ? paddingForVertScroll.current : '0px');

	return (
		<div ref={ioEntrySectionDiv} className="point-entry-section" style={{ paddingBottom: paddingBottom }}>
			<PointEntryHeader
				advancedMode={advancedMode}
				info={props.info}
				onSelectionChanged={() => onPointSelectionChanged()}
			/>
			{
				props.info.entries.map((entry, index) => {
					entry.indexEntry = index;
					entry.OnChanged = onPointSelectionChanged;
					return (
						<PointEntry
							locAttr={locAttrInfo}
							entry={entry}
							key={nextKey++}
							externalMaskChanged={props.info.externalMaskChanged}
							mapTypesAndFeatures={mapTypesAndFeatures.current}
						/>
					);
				})
			}
		</div>
	);
}
