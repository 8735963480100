import React, { useState } from 'react';
import './CanvasSettings.scss';
import { addButton, ModalRequestSpec, requestModal } from '../modals/ModalHelp';
import { getRedDepictOption, RedDepictOption, setRedDepictOption } from '@csa-core/advisor.controlsystemcore'; // '../util/RedChassisHelp';
import SimpleCombo from '../selection/SimpleCombo';
import { ChassisProject, ModalStatus } from '@csa-core/advisor.controlsystemcore'; // '../types/ProjectTypes';
import { updateRackLayout } from '@csa-core/advisor.controlsystemcore'; // '../model/ChassisProject';

const _idRedView = 'RedView';

const _redSelRight = 'Right of controller';
const _redSelBelow = 'Below controller';
const _redSelBehind = 'Offset behind controller';

const redDepictSels: string[] = [
    _redSelRight,
    _redSelBelow,
    _redSelBehind
];

const getRedDepictFromSel = (sel: string): RedDepictOption => {
    switch (sel) {
        case _redSelBelow:
            return RedDepictOption.Below;

        case _redSelBehind:
            return RedDepictOption.Behind;

        default:
            return RedDepictOption.Beside;
    }
}

const getSelFromRedDepict = (red: RedDepictOption): string => {
    switch (red) {
        case RedDepictOption.Behind:
            return _redSelBehind;

        case RedDepictOption.Below:
            return _redSelBelow;

        default:
            return _redSelRight;
    }
}

export interface CanvasSettingsCBData {
    project: ChassisProject;
    redDepictOption: RedDepictOption;
    settingChanged: () => void;
}



const canvasSettingsCallback = (status: number, data?: object) => {
    if (status === ModalStatus.Confirmed) {

        // Get our data object.
        const cbData = data as CanvasSettingsCBData;
        if (cbData) {
            const oldRedDepict = getRedDepictOption();
            if (cbData.redDepictOption !== oldRedDepict) {
                setRedDepictOption(cbData.redDepictOption);
                updateRackLayout(cbData.project.content, false);
                cbData.settingChanged();
            }
        }
    }
}

export const configCanvasSettings = (project: ChassisProject, settingChanged: () => void) => {


    // Set up the data we'll use as .requestorData
    // in our modal request. Includes:
    const callbackData: CanvasSettingsCBData = {
        project: project,
        redDepictOption: getRedDepictOption(),
        settingChanged: settingChanged
    };

    const request: ModalRequestSpec = {
        includeButtons: true,
        closeOnInsideClick: false,
        stayOpenOnBackdropClick: true,
        title: 'Canvas Settings',
        callback: canvasSettingsCallback,
        requestorData: callbackData,
        content: CanvasSettings
    };

    addButton(request, 'SAVE', ModalStatus.Confirmed, 'contained');

    requestModal(request);
}


interface SettingProps {
    id: string;
    title: string;
    selection: string;
    options: string[];
    settingChanged: (id: string, newValue: string) => void;
}

const SettingComp = (props: SettingProps) => {

    const onSelectionChanged = (newValue: string) => {
        if (newValue !== props.selection) {
            props.settingChanged(props.id, newValue);
        }
    }

    return (
        <>
            <span className='canvas-setting-label'>
                {props.title + ':'}
            </span>
            <SimpleCombo
                selection={props.selection}
                choices={props.options}
                selectionChanged={onSelectionChanged}
            />
        </>
        );
}

const CanvasSettings = (request: ModalRequestSpec) => {
    const [count, setCount] = useState<number>(0);

    const data = request.requestorData as CanvasSettingsCBData;

    if (!data) {
        throw new Error('INVALID Request to ConfigAccys!');
    }

    const onSettingChanged = (id: string, newValue: string) => {

        if (id === _idRedView) {
            data.redDepictOption = getRedDepictFromSel(newValue);

            // Trigger a re-render.
            setCount(count + 1);
        }
    }


    return (
        <div className='canvas-settings'>
            <SettingComp
                id={_idRedView}
                title={'Redundancy View'}
                selection={getSelFromRedDepict(data.redDepictOption)}
                options={redDepictSels}
                settingChanged={onSettingChanged}
            />
        </div>
    );
}

export default CanvasSettings;
