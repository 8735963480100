import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Box,
  FormControlLabel,
  Typography,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  FormControl,
  useTheme,
} from "@mui/material";
import { ProductSelectionAttribute } from '@csa-core/advisor.controlsystemcore'; // "../selectComponents/SelectComponentsTypes";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SearchIcon } from "@rockwell-automation-inc/ra-meatball";

interface SearchSidebarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupedAttributes: any;
  group: string;
  selectionAttributes: ProductSelectionAttribute[];
  selectedAttributes: ProductSelectionAttribute[];
  setSelectedAttributes: (
    selectionAttributes: ProductSelectionAttribute[]
  ) => void;
  handleFilterChange: (selectionAttribute: ProductSelectionAttribute) => void;
}

interface SearchBarProps {
  index: number;
  searchTerm: string;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const SearchBar = ({ index, searchTerm, handleChange }: SearchBarProps) => {
  return (
    <FormControl style={{ width: "98%" }}>
      <TextField
        id={`product-attribute-group-${index}`}
        label={"Filter Tags"}
        variant="outlined"
        type="text"
        value={searchTerm}
        onChange={(e) => handleChange(e)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          id: `product-attribute-group-input-${index}`,
        }}
      />
    </FormControl>
  );
};

const LoadSideBarItems = (props: SearchSidebarProps) => {
  const { groupedAttributes,  group } = props;
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchTerm]);

  const nextKey = 1;
  return (
    <>
      <Box key={nextKey+1} display="flex" flexDirection="column">
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`group-${group}-content`}
            id={`group-${group}-header`}
            style={{ fontWeight: "bold" }}
            sx={{ borderBottom: "1px solid #ccc", borderRadius: "4px" }}
          >
            <Typography display="flex" fontWeight="bold" sx={{ mb: 1 }}>
              {group}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            id={`group-${group}-content`}
            sx={{
              overflow: "auto",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              maxHeight: "14rem",
              scrollbarWidth:"thin"
            }}
          >
            { group.length >= 20 &&               
              <SearchBar
                index={nextKey}
                searchTerm={searchTerm}
                handleChange={handleChange}
              />
            }

            {groupedAttributes[group]
              .filter((i: ProductSelectionAttribute) =>
                i.value.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((i: ProductSelectionAttribute) => {
                return (
                  <FormControlLabel
                    key={nextKey+1}
                    label={i.value}
                    sx={{ ml: 0 }}
                    control={
                      <Checkbox
                        checked={
                          props.selectedAttributes.find(
                            (k) => k.name === i.name && k.value === i.value
                          ) !== undefined
                        }
                        onClick={() => props.handleFilterChange(i)}
                      />
                    }
                  />
                );
              })}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
export default LoadSideBarItems;
