import React, { useRef, useState } from 'react';
import Konva from "konva";
import { Node } from "konva/lib/Node";
import { Group, Image, Rect } from 'react-konva';
import { useLayoutMode, useSelectedChassis, useSelectedDevice } from "../../../context/SelectionInfoContext";
import { useCachedImage } from "../../../imageHelp/useCachedImage";
import { getNumBanks, getProjectFromChassis, LayoutModeType } from '@csa-core/advisor.controlsystemcore'; // "../../../model/ChassisProject";
import { useAppDispatch } from '@csa-core/advisor.controlsystemcore'; // "../../../redux/hooks";
import { setLayoutViewTip, ViewTipInfo } from '@csa-core/advisor.controlsystemcore'; // "../../../redux/slices/LayoutViewTipSlice";
import { FlexHAChassis, FlexHASAPowerSupply } from '@csa-core/advisor.controlsystemcore'; // "../../../types/ProjectTypes";
import { runChecker } from '@csa-core/advisor.controlsystemcore'; // "../../../util/Checker";
import { flexHAAddSAPwr, flexHADeleteSAPwr } from '@csa-core/advisor.controlsystemcore'; // "../model/FlexHAChassis";
import NoPowerIcon from "../../../components/images/NoPowerIcon.png";
import TrashcanIcon from "../../../components/images/Trashcan.png";
import AddBtnImage from "../../../components/images/Add.png";
import SelectableDeviceComp from '../../../components/SelectableDeviceComp';
import { getInflatedLoc, getLocCenter, offsetLoc } from '@csa-core/advisor.controlsystemcore'; // '../../../util/GeneralHelpers';
import { getChassisRenderSpec } from '../../../util/LayoutModeHelp';
import { flexHAGetLayoutInfo, FlexHASAPwrBtnLoc } from '@csa-core/advisor.controlsystemcore'; // '../model/FlexHALayout';
import { DfltActBtnSpecs } from '../../../types/LayoutActions';
import { LocAndSize } from '@csa-core/advisor.controlsystemcore'; // '../../../types/SizeAndPosTypes';
import { ChassisCompProps } from '@csa-core/advisor.controlsystemcore'; // '../../../implementation/ImplGeneral';
import { establishActBtnScale } from '../../../components/layoutButtons/ActionButtons';

const saPSUTip = 'I/O Base has SA Power supplied by recommended external power supplies.';
const saIconBtnTip = 'I/O Base uses jumpered SA Power from previous I/O Base. Click the above Add Button to add a SA Power Supply for this base.';
const saAddBtnTip = 'Add SA power supply to base.';

export const FlexHAChassisSAPwr = (props: ChassisCompProps) => {
    const [ idxMouseoverDel, setIdxMouseoverDel ] = useState( -1 );
    const [ idxMouseoverAddSA, setIdxMouseoverAddSA ] = useState( -1 );

    const [imageNoPwr, imageNoPwrStatus, /*imgSize*/] = useCachedImage(NoPowerIcon);
    const [imageAdd, imageAddStatus, /*imgSize*/] = useCachedImage(AddBtnImage);
    const [ imageDel, imageDelStatus, /*imgSize*/ ] = useCachedImage( TrashcanIcon );

    const btnLocalScale = useRef<number>( establishActBtnScale( props.chassis.platform ) );

    const { layoutMode } = useLayoutMode();
    const {/*selectedDevice*/ selectDevice } = useSelectedDevice();
    const {/*selectedDevice*/ selectChassis } = useSelectedChassis();
    const dispatch = useAppDispatch();
    const chassis = props.chassis as FlexHAChassis;

    const defaultRender = (!props.renderType) || (!props.layoutMode);
    const modeForSpec = defaultRender ? undefined : props.layoutMode;

    // Call a helper to give us rendering details.
    const elRenderSpec = getChassisRenderSpec(modeForSpec, chassis, true, (props.showAsSelected === true));

    const nBanks = getNumBanks(chassis);
    const iconBtns: FlexHASAPwrBtnLoc[] = [];
    for (let idx = 0; idx < nBanks; ++idx) {
        const layout = flexHAGetLayoutInfo(chassis, idx);
        if (layout) {
            iconBtns.push(...layout.saIconBtnLocs);
        }
    }

    //////// Event Handlers

    const saElementOnPointerDown = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;
        e.evt.stopPropagation();
        // Close the tip
        dispatch(setLayoutViewTip(undefined));
    }

    const OnPointerClickDeleteSAPwr = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;
        e.evt.stopPropagation();

        const idxIOBase = Number(e.target.attrs.ioBaseIndex);
        if (!isNaN(idxIOBase)) {
            // Delete FlexHA SA Power Supply
            const psu = chassis.saPSU.find(x => x.ioBaseIndex === idxIOBase);
            if (psu) {
                flexHADeleteSAPwr(psu);
                const project = getProjectFromChassis(chassis);
                if (project)
                    runChecker(project);

                const stage = e.target.getStage();
                if (stage) {
                    stage.container().style.cursor = 'default';
                }

                setIdxMouseoverDel(-1);
            }
        }
    }

    const OnPointerClickAddSAPwr = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;
        e.evt.stopPropagation();

        const idxIOBase = Number(e.target.attrs.ioBaseIndex);
        if (!isNaN(idxIOBase)) {
            // Add FlexHA SA Power Supply
            const newSAPwr = flexHAAddSAPwr(chassis, idxIOBase);
            selectChassis(chassis);
            selectDevice(newSAPwr);
            const project = getProjectFromChassis(chassis);
            if (project)
                runChecker(project);
        }
    }

    const saAddPwrOnMouseEnter = ( e : Konva.KonvaEventObject<MouseEvent> ) => {
        if ( layoutMode.type === LayoutModeType.Normal ) {
            const stage = e.target.getStage();
            if ( stage ) {
                stage.container().style.cursor = 'pointer';
            }

            // Find the element with the tip.
            let elTarget : Node | null = e.target;
            let tipTxt : string | undefined = elTarget?.attrs.tip;
            while ( !tipTxt ) {
                if ( elTarget ) {
                    tipTxt = elTarget.attrs?.tip;

                    // If target is the tip holder, we're done.
                    if ( elTarget.attrs.tipTarget )
                        break;

                    elTarget = elTarget.getParent();
                }
                else
                    break;
            }

            if ( tipTxt ) {
                const tip : ViewTipInfo = { text: tipTxt, rcClient: e.target.getClientRect() };
                dispatch( setLayoutViewTip( tip ) );
            }

            const idxIOBase = e.target.attrs.ioBaseIndex;
            if ( !isNaN( idxIOBase ) )
                setIdxMouseoverAddSA( idxIOBase );
        }
    }

    const saElementOnMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
        if (layoutMode.type === LayoutModeType.Normal) {
            // Find the element with the tip.
            let elTarget: Node | null = e.target;
            let tipTxt: string | undefined = elTarget?.attrs.tip;
            while (!tipTxt) {
                if (elTarget) {
                    tipTxt = elTarget.attrs?.tip;

                    // If target is the tip holder, we're done.
                    if (elTarget.attrs.tipTarget)
                        break;

                    elTarget = elTarget.getParent();
                }
                else
                    break;
            }

            if ( tipTxt ) {
                const tip : ViewTipInfo = { text: tipTxt, rcClient: e.target.getClientRect() };
                dispatch(setLayoutViewTip(tip));
            }
        }
    }

    const OnMouseEnterDeleteBtn = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'pointer';
        }
        const idxIOBase = e.target.attrs.ioBaseIndex;
        if (!isNaN(idxIOBase))
            setIdxMouseoverDel(idxIOBase);
    }


    const saElementOnMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'default';
        }

        setIdxMouseoverDel( -1 );
        setIdxMouseoverAddSA( -1 );

        dispatch(setLayoutViewTip(undefined));
    }

    const btnSize = DfltActBtnSpecs.height * btnLocalScale.current;

    /////// Render Functions

    const renderDeleteSAPwrBtn = (psu: FlexHASAPowerSupply) => {
        if (!psu.autoAdded && layoutMode.type === LayoutModeType.Delete) {
            const ptCenter = getLocCenter(psu.loc);
            const btnLoc: LocAndSize = {
                x: ptCenter.x + props.ptOrg.x - (btnSize / 2) - 20,
                y: ptCenter.y + props.ptOrg.y - (btnSize / 2),
                width: btnSize,
                height: btnSize,
            };

            return (
                <Group
                    key={'SADel' + psu.ioBaseIndex}
                    onPointerClick={OnPointerClickDeleteSAPwr}
                    onMouseEnter={OnMouseEnterDeleteBtn}
                    onMouseLeave={saElementOnMouseLeave}
                    tip={saPSUTip}
                    tipTarget={true}
                >
                    <Rect
                        {...getInflatedLoc(btnLoc, 10, true)}
                        ioBaseIndex={psu.ioBaseIndex}
                        cornerRadius={DfltActBtnSpecs.cornerRadPct * btnSize}
                        fill={idxMouseoverDel === psu.ioBaseIndex ? DfltActBtnSpecs.mouseoverFill : DfltActBtnSpecs.fill}
                        strokeWidth={DfltActBtnSpecs.strokeWidth}
                        stroke={idxMouseoverDel === psu.ioBaseIndex ? DfltActBtnSpecs.mouseoverStroke : DfltActBtnSpecs.stroke}
                    />

                    {(imageDelStatus === 'loaded')
                        &&
                        <Image
                            {...btnLoc}
                            ioBaseIndex={psu.ioBaseIndex}
                            image={imageDel}
                        />
                    }
                </Group>
            );
        }

        return null;
    }

    const renderSAPower = () => {
        return (
            chassis.saPSU.map((pwr, idx) => {
                return (
                    <Group
                        key={'SA' + idx}
                        onMouseEnter={saElementOnMouseEnter}
                        onMouseLeave={saElementOnMouseLeave}
                        tip={saPSUTip}
                        tipTarget={true}
                    >
                        <SelectableDeviceComp
                            device={pwr}
                            devDragStatus={pwr.dragStatus}
                            showSelected={props.showAsSelected && (props.localDeviceSelected === pwr)}
                            renderType={elRenderSpec.ps}
                            ptOrg={props.ptOrg}
                            relLocation={pwr.loc}
                            darkImagery={true}
                        />

                        {renderDeleteSAPwrBtn(pwr)}

                    </Group>
                )
            })
        );
    }

    let iconBtn = 1;

    const renderSAIconBtn = ( loc : FlexHASAPwrBtnLoc ) => {
        const isMouseOver = idxMouseoverAddSA === loc.ioBaseIndex;
        const commonProps = {
            stroke: isMouseOver ? DfltActBtnSpecs.mouseoverStroke : DfltActBtnSpecs.stroke,
            fill: isMouseOver ? DfltActBtnSpecs.mouseoverFill : DfltActBtnSpecs.fill,
            strokeWidth: DfltActBtnSpecs.strokeWidth,
            cornerRadius: DfltActBtnSpecs.cornerRadPct * btnSize,
        };

        // Adjust the loc based on chassis origin.
        const actualLoc = { ...loc };
        offsetLoc(actualLoc, props.ptOrg);
        // Position the '+' button
        const ptIconCenter = getLocCenter(actualLoc);
        const btnLoc: FlexHASAPwrBtnLoc = {
            x: ptIconCenter.x - ( btnSize / 2), // Center the button above the No-Pwr icon
            y: actualLoc.y - ( btnSize * 2.50), // Move the Add button into the chassis.    
            width: btnSize,         
            height: btnSize,
            ioBaseIndex: loc.ioBaseIndex,
        };

        return (
            <Group
                key={'NPI' + String(++iconBtn)}
                onMouseEnter={saElementOnMouseEnter}
                onMouseLeave={saElementOnMouseLeave}
                onPointerDown={saElementOnPointerDown}
                
                id="TipTarget"
                tipTarget={true}
            >
                {imageAddStatus === 'loaded' ? 
                    <Image
                        { ...btnLoc }
                        image={ imageAdd }
                        fill={ commonProps.fill }
                        cornerRadius={ DfltActBtnSpecs.cornerRadPct * btnSize }
                        tip={ saAddBtnTip }
                        onPointerClick={ OnPointerClickAddSAPwr }
                        onMouseEnter={ saAddPwrOnMouseEnter }
                    />
                    :
                    <Rect
                        { ...btnLoc }
                        fill={ commonProps.fill }
                    />

                }

                {(imageNoPwrStatus === 'loaded')
                    ?
                    <Image
                        {...actualLoc}
                        image={ imageNoPwr }
                        tip={ saIconBtnTip }
                    />
                    :
                    <Rect
                        {...actualLoc}
                        cornerRadius={3}
                        fill='#CCCCCC77'
                        strokeWidth={3}
                        stroke='#FFFFFF77'
                        tip={ saIconBtnTip }
                    />
                }
            </Group>
        );
    }

    ///// SA Power Render 
    return (
        <>
            {renderSAPower()}

            {
                chassis.selected && iconBtns.map((loc) => {
                    return renderSAIconBtn(loc)
                })
            }
        </>
    );
}

export default FlexHAChassisSAPwr;