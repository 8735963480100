import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { sanitizeNameString } from '@csa-core/advisor.controlsystemcore'; // '../util/NameHelp';


interface Props {
	startValue: string | undefined;
	onFinished: (cancelled: boolean, newName: string | undefined) => void;
}

const ChassisNameEditor = (props: Props) => {
	const [count, setCount] = useState(0)
	const textInputRef = useRef<HTMLInputElement | null>(null);

	const editText = useRef<string | undefined>(undefined);

	useEffect(() => {
		if (textInputRef.current) {
			textInputRef.current.focus();
			editText.current = props.startValue;
			setCount(count => count + 1);
		}
	}, [props.startValue]);

	const finishEdit = () => {
		// If we have a text value...
		if (editText.current) {
			// Sanitize it one more time. This time
			// we'll tell our helper that it's supposed
			// to be our final name.
			editText.current = sanitizeNameString(editText.current);

			// If the result is empty, change
			// to undefined.
			if (editText.current.length === 0) {
				editText.current = undefined;
			}
		}

		// If our final version is DIFFERENT than
		// what we were given in our props value...
		if (editText.current !== props.startValue) {
			// Notify upward that we have a new name.
			props.onFinished(false, editText.current);
		}
		else {
			// Otherwise, pass notice up that our
			// parent should ignore anything we did here.
			props.onFinished(true, undefined);
		}
	}

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

		// Call a helper to massage the editor text into
		// what would be allowable as a name in progress,
		// and store the result into our editText ref.
		editText.current = sanitizeNameString(event.target.value);

		// Trigger another render.
		setCount(count + 1);
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		switch (event.key) {
			case 'Enter':
				finishEdit();
				break;

			case 'Escape':
				props.onFinished(true, undefined);
				break;

			default:
				break;
		}
	}

	// Blur event is losing focus
	const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
		event;
		finishEdit();
	};

	const inputStyles = {
		fontFamily: 'Barlow',
		fontSize: '16px',
		fontWeight: '500',
		lineHeight: '22px',
		padding: '12px 14px 12px 14px',
		color: '#2D2D2D'
	};

	const textValue = (editText.current !== undefined)
		? editText.current
		: '';

	return (
		<TextField
			inputRef={textInputRef}
			id='outlined-basic'
			variant='outlined'
			size='small'
			fullWidth={true}
			label="Chassis Name"
			inputProps={{ style: { ...inputStyles }, spellCheck: 'false' }}
			value={textValue}
			onBlur={handleBlur}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			required
		/>
	);
}

export default ChassisNameEditor;
