import React from 'react';
import './PerformanceInfo.scss';
import '../../styles/Meteor.scss'
import {
    getCommCIPConnUsage,
    getCtlrCIPConnUsage,
    getEnetNodeUsage,
    getMemoryUsage,
    getNetBandwidthItemInfo,
    getPPSUsage,
    PerfUsageDetails,
    ProjectCommsInfo
} from '@csa-core/advisor.controlsystemcore'; // '../../model/CommDetails';
import { UsageLevelStatus } from '@csa-core/advisor.controlsystemcore'; // '../../types/ProjectTypes';
import { LogMsgLevel } from '@csa-core/advisor.controlsystemcore'; // '../../util/ProjectLog';
import { filterButtonTypes } from '../../projStatus/ProjectStatusDisplay';
import { DetailItem } from '@csa-core/advisor.controlsystemcore'; // '../../model/DeviceDetail';
import PerformanceColumn from './PerformanceColumn';

const itemProgBarWidth = 110;

interface Props {
    commsInfo: ProjectCommsInfo;
    selectedButton?: filterButtonTypes;
}

export const generateFlag = (finalStatus:LogMsgLevel,selectedButton: filterButtonTypes)=>{
  
    let flag = true;
    
    switch(finalStatus){
        case LogMsgLevel.error:
            if(!selectedButton.info && !selectedButton.warning && !selectedButton.error){
                flag = false;
            }
            break;
        case LogMsgLevel.warning:
            if(!selectedButton.info && !selectedButton.warning){
                flag = false;
            }
            break;
            case LogMsgLevel.info:
            if(!selectedButton.info){
                flag = false;
            }
            break;
        default:
            break;
        }
        return flag;
}


const finalPerformanceStatus = (allItemsLevelStatus:UsageLevelStatus[])=>{
    let finalStatus = LogMsgLevel.none;
    allItemsLevelStatus.forEach(item=>{
        if(item === UsageLevelStatus.Error){
            finalStatus = LogMsgLevel.error
        }
        else if(item === UsageLevelStatus.Warning && finalStatus !== LogMsgLevel.error){
            finalStatus = LogMsgLevel.warning;
        }
        else if(item === UsageLevelStatus.OK && finalStatus === LogMsgLevel.none){
            finalStatus = LogMsgLevel.info;
        }
    })
    return finalStatus;
    
}

const PerformanceUsage = (props: Props) => {

    const ctlrInfo = props.commsInfo.allCtlrChassis;
    const showCIPConns = !ctlrInfo.anyEnetNodeType;

    const ctlrUsageDtls = new Array<PerfUsageDetails>();
    const ctlrDtlItems = new Array<DetailItem>();

    const commUsageDtls = new Array<PerfUsageDetails>();
    const commDtlItems = new Array<DetailItem>();

    if (showCIPConns) {
        ctlrUsageDtls.push(getCtlrCIPConnUsage(props.commsInfo));
        commUsageDtls.push(getCommCIPConnUsage(props.commsInfo));
    }
    else {
        ctlrUsageDtls.push(getEnetNodeUsage(props.commsInfo));
    }

    ctlrUsageDtls.push(getMemoryUsage(props.commsInfo));
    commUsageDtls.push(getPPSUsage(props.commsInfo));

    commDtlItems.push(getNetBandwidthItemInfo(props.commsInfo.allRemIOChassis));

    const allStatus = new Array<UsageLevelStatus>();
    ctlrUsageDtls.forEach(d => { allStatus.push(d.status) });
    commUsageDtls.forEach(d => { allStatus.push(d.status) });

    const finalStatus = finalPerformanceStatus(allStatus);

    const showFlag = props.selectedButton
        ? generateFlag(finalStatus, props.selectedButton)
        : true;
    
    if(!showFlag){
        return <></>;
    }

    return (
        <div className='perf-columns'>
            <PerformanceColumn
                title={'Controller'}
                progWidth={itemProgBarWidth}
                perfUsageItems={ctlrUsageDtls}
                detailItems={ctlrDtlItems}
            />
            <PerformanceColumn
                title={'Communication'}
                progWidth={itemProgBarWidth}
                perfUsageItems={commUsageDtls}
                detailItems={commDtlItems}
            />
        </div>
    );
}

export default PerformanceUsage;

