import React, { useRef } from 'react';
import './Choices.scss';
import { SvgIcon } from '@mui/material';
import { ChoiceGroupTipInfo } from '@csa-core/advisor.controlsystemcore'; // '../../types/MessageTypes';
import { getStatusIconInfo } from '../../util/IconHelp';
import { Point } from '@csa-core/advisor.controlsystemcore'; // '../../types/SizeAndPosTypes';

interface Props {
    tipInfo: ChoiceGroupTipInfo;
}

const ChoiceGroupHdrTipIcon = (props: Props) => {

    const divRef = useRef<HTMLDivElement | null>(null);

    const [Icon, iconColor] = getStatusIconInfo(props.tipInfo.level);

    const onPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {

        // consume the event so that it
        // doesn't make its way anywhere else.
        e.stopPropagation();

        // The pt we include in our callback is
        // expected to be the center of our icon
        // if we can determine that. Init to 0,0.
        const pt: Point = { x: 0, y: 0 };

        // If we have a divRef (and we
        // always should at this point)...
        if (divRef.current) {
            // Calculate its center point in DOM
            // coordinates (relative to browser client).
            const r = divRef.current.getBoundingClientRect();
            pt.x = Math.round(divRef.current.clientWidth / 2 + r.left);
            pt.y = Math.round(divRef.current.clientHeight / 2 + r.top);
        }

        // Do the callback with our ID and center pt.
        props.tipInfo.callback(props.tipInfo.category, props.tipInfo.type, pt);
    }

    return (
        <div
            className='choices-group-header-tip-icon'
            ref={divRef}
            onPointerDown={onPointerDown}
        >
            {Icon
                ?
                <div>
                    <SvgIcon
                        component={Icon}
                        inheritViewBox
                        sx={{
                            width: '14px',
                            height: '14px',
                            color: iconColor,
                            stroke: iconColor,
                            fill: iconColor
                        }}
                    />
                </div>
                : null}
        </div>
    );
}

export default ChoiceGroupHdrTipIcon;