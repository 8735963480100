import React, { useEffect, useRef, useState } from 'react';
import '../common/ChassisConfig.scss';
import '../../styles/Meteor.scss'
import {
	StatusLevel,
	ChoiceGroupType,
	ChoiceInfo,
	MessageCategory,
	MessageType,
	PanelMessage,
	createNewGrpChoiceInfo,
	BaseChoiceInfo,
	createNewGdSelGrpChoiceInfo,
    GroupChoiceInfo,
    ModalStatus,
} from '@csa-core/advisor.controlsystemcore'; // '../../types/MessageTypes';
import {
	Chassis,
	ChassisProject,
	ResultStatus,
	SupportedWiringType,
	SelectableDevice,
	LocAttributeInfo,
	ChassisCfgGrpCategory
} from '@csa-core/advisor.controlsystemcore'; // '../../types/ProjectTypes';
import {
	addChassis,
	getChassisName,
	getNumBanks,
	getLocAttrFromProject,
	isWiringTypeSupported,
	updateRackLayout
} from '@csa-core/advisor.controlsystemcore'; // '../../model/ChassisProject';
import {
	addButton,
	ModalRequestSpec,
	requestModal
} from '../../modals/ModalHelp';
import {
    addChoiceInfo,
	makeChoicesGroup,
    setChoiceGroupTipInfo,
} from '@csa-core/advisor.controlsystemcore'; // '../../util/ChoicesHelp';
import {
	AutoScaleFitType,
	autoScaleToContent,
	ChassisLayoutKey,
	requestPendingScrollToBtm
} from '@csa-core/advisor.controlsystemcore'; // '../../util/LayoutHelp';
import { chassisChanging, suspendUndoSnapshots } from '@csa-core/advisor.controlsystemcore'; // '../../util/UndoRedo';
import { Point } from '@csa-core/advisor.controlsystemcore'; // '../../types/SizeAndPosTypes';
import { IncludeOldStyleMsgPnlInChassisConfig } from '@csa-core/advisor.controlsystemcore'; // '../../types/Globals';
import { ProjectSetting } from '@csa-core/advisor.controlsystemcore'; // '../../types/SettingsTypes';
import { cloneLocAttributeInfo, getLocAttributeSetting } from '@csa-core/advisor.controlsystemcore'; // '../../model/GuidedSelection';
import { getLocAttrInfoForChassisEdit } from '@csa-core/advisor.controlsystemcore'; // '../../implementation/ImplHardwareGen';
import {
	CfgDlgMsgDtls,
	CfgDlgTabID,
	cfgDlgUnspecifiedChassisName,
	cfgDlgUpdateMessages,
	ConfigChassisCBData,
	InitCfgData,
	loadTipPanelMsgs,
} from '@csa-core/advisor.controlsystemcore'; // '../common/ChassisConfig';
import ChassisConfigDlg from '../common/ChassisConfigComp';
import { cfgDlgGSSelectionChanged, cfgDlgInitializeLocAttrInfoFromChassis } from '../common/ChassisConfigGuidedSel';
import { cfgDlgUpdatePSGroup } from '@csa-core/advisor.controlsystemcore'; // '../common/ChassisConfigPower';
import { cfgDlgAdjustWiringMapForSwaps, cfgDlgChangeWiringTypeOnMods, cfgDlgGetPossibleModWiringTypes } from '@csa-core/advisor.controlsystemcore'; // '../common/ChassisConfigWiring';
import { replaceChassis } from '@csa-core/advisor.controlsystemcore'; // '../common/ChassisConfigReplaceChassis';
import {
	snapGetCfgDataFromChassis,
	snapGetChassisCatalogFromEnvRating,
	snapGetChassisCfgDataFromLocAttr,
	snapPrepareLocAttrForChassisConfig
} from '@csa-core/advisor.controlsystemcore'; // '../snap/snapGuidedSelection';
import { PlatformFlex, PlatformFlexHA } from '@csa-core/advisor.controlsystemcore'; // '../PlatformConstants';
import { getInterconnectCbls } from '@csa-core/advisor.controlsystemcore'; // '../../util/EngInfoHelp';
import { snapCompactModuleArray } from '@csa-core/advisor.controlsystemcore'; // './SnapGeneralImpl';
import { addModuleAtSlot, deleteModuleAtSlot, setNumBanks, updateChassis } from '@csa-core/advisor.controlsystemcore'; // '../../implementation/ImplGeneral';
import { logger } from '@csa-core/advisor.controlsystemcore'; // '../../util/Logger';
import { displayAlertMsg } from '../../util/MessageHelp';
import { flexHA_DefAdapter } from '@csa-core/advisor.controlsystemcore'; // '../flexHA/model/FlexHAHardwareImpl';


const unexpected = (msg: string) => {
	alert(msg);
	throw new Error(msg);
}

const _situationalMsgInfo = new Map<MessageCategory, CfgDlgMsgDtls>();
const _InterConnNoCableOpt = 'No Cable';
const _grpInterconnectID = 'Interconnect Cable';

// Bank constants (used only for FlexHA)
const _grpBanksID = 'Banks';
const _optOneBank = 'One';
const _optTwoBanks = 'Two';
const _optThreeBanks = 'Three';


let liveTipCallback = (category: MessageCategory, type: MessageType, pt: Point) => {
	category;
	type;
	pt;
	alert('No live tip callback established');
}

const tipCallback = (category: MessageCategory, type: MessageType, pt: Point) => {
	liveTipCallback(category, type, pt);
}

const _updateNumBanks = (chassis: Chassis, addlGrps: GroupChoiceInfo[]) => {
	addlGrps.forEach((grpInfo) => {
		if (grpInfo.group.subgroups) {
			const subGrp = grpInfo.group.subgroups[0];
			if ((subGrp.label === _grpBanksID) && grpInfo.selections) {
				const banksChoice = grpInfo.selections[0];
				let numBanksWanted = 1;
				switch (banksChoice) {
					case _optTwoBanks:
						numBanksWanted = 2;
						break;

					case _optThreeBanks:
						numBanksWanted = 3;
						break;

					default:
						break;
				}
				const numExistingBanks = getNumBanks(chassis);
				if (numBanksWanted !== numExistingBanks) {
					chassisChanging(chassis);
					if (setNumBanks(chassis, numBanksWanted)) {
						updateChassis(chassis);
					}
				}
			}
		}
	});
}

// Callback from the Modal.
const configChassisCallback = (status: number, data?: object) => {

	// If we got our 'go-ahead'...
	if (status === ModalStatus.Confirmed) {

		// Get our data object.
		const cbData = data as ConfigChassisCBData;
		// If we can...
		if (cbData) {
			// Get the final selected information from the Guided selection.
			const gsData = snapGetChassisCfgDataFromLocAttr(cbData.cfgAttrInfo);

			const chassisCatalog = snapGetChassisCatalogFromEnvRating(cbData.cfgAttrInfo.platform, gsData.envType);

			const newName = (cbData.chassisName.length > 0)
				? cbData.chassisName
				: cfgDlgUnspecifiedChassisName;

			let targetChassis: Chassis | undefined = cbData.chassis;
			// If we're editing an EXISTING chassis...
			if (cbData.chassis) {

				// Determine what, if anything actually changed.
				const changeChassis = (chassisCatalog !== cbData.chassis.catNo);
				const changeName = (getChassisName(cbData.chassis) !== newName);
				//const changePS = (psCat !== cbData.origPSCat);
				const changeWiring = (gsData.wiringType !== cbData.chassis.defaultIOModWiring);

				const anyChanges = (changeChassis || changeName || changeWiring);

				// If anything is actually changing...
				if (anyChanges) {
					// BEFORE making any change, call our helper to take
					// a snapshot of our CURRENT content for undo purposes.
					chassisChanging(cbData.chassis);

					// Then suspend those snapshots until we're
					// done here. If we don't do this, adding the
					// new replacement chassis and the modules would
					// EACH end up as their own undo snapsnots.
					suspendUndoSnapshots(true);

					// De-select the currently selected chassis
					// and device if any selected.
					cbData.selectChassisCallback(undefined);

					cbData.selectDeviceCallback(undefined);

					// If we're actually changing the chassis itself...
					if (changeChassis) {

						// Replace using the new info.
						const [rsltStatus, /*msgLevel*/, /*msg*/, swapMap] =
							replaceChassis(cbData.chassis, chassisCatalog, '', false);

						// If we're changing our wiring type AND
						// we got anything back in the swap map,
						// call a helper to make wiring support map
						// adjustments.
						if (changeWiring && (swapMap.size > 0)) {
							if (cbData.mapCatToWiringSpt &&
								(cbData.mapCatToWiringSpt.size > 0)) {
								cfgDlgAdjustWiringMapForSwaps(cbData.mapCatToWiringSpt, swapMap);
							}
						}

						// Test result.
						if (rsltStatus !== ResultStatus.Success) {
							throw new Error('Final Chassis replace FAILED!');
						}
					}

					// Set the new name if that changed.
					if (changeName) {
						cbData.chassis.name = newName;
					}

					// If we're changing wiring type...
					if (changeWiring) {
						// Set the new default on the chassis.
						cbData.chassis.defaultIOModWiring = gsData.wiringType;

						// Call a helper to see if there's any overlap
						// with the new type and the combined possible
						// types of all of our chassis' modules. If not,
						// no existing modules can be configured to use
						// the new type, so there's nothing more to do.
						// If we DO have any...
						if (isWiringTypeSupported(gsData.wiringType, cbData.allPossibleWiringTypes)) {

							// Then we should also have the associated
							// map of info in our data object. If we do...
							if (cbData.mapCatToWiringSpt && (cbData.mapCatToWiringSpt.size > 0)) {

								// Call a helper to make the changes needed.
								cfgDlgChangeWiringTypeOnMods(cbData.chassis, gsData.wiringType, cbData.mapCatToWiringSpt);
							}
							else {
								throw new Error('Missing wiring trail in callback!');
							}
						}
					}

					// Turn undo snapshots back on.
					suspendUndoSnapshots(false);

					// Update our rack layout. This might not be
					// needed in all cases, but doesn't hurt anything.
					if (cbData.chassis.parent) {
						updateRackLayout(cbData.chassis.parent);

					}
					else {
						throw new Error('Chassis without parent after replace?');
					}

					// Finally, (re)select our chassis. There shouldn't
					// be a need to 'bring it into view'. If it wasn't
					// already at least partially visible, we wouldn't
					// have been able to edit its configuration.
					cbData.selectChassisCallback(cbData.chassis);
				}
			}
			else {
				// We're adding a NEW chassis. Before we do,
				// De-select the currently selected chassis
				// and device if any selected.
				cbData.selectChassisCallback(undefined);
				cbData.selectDeviceCallback(undefined);

				// Check if our project already has any others.
				const wasEmpty = (cbData.project.content.racks.length === 0);

				const psCatalog = (cbData.cfgAttrInfo.platform === PlatformFlexHA ? '1606-XLE240ECRZ' : undefined);

				// Add the new chassis using the info we got above.
				const newChassis = addChassis(cbData.project, cbData.cfgAttrInfo.platform, chassisCatalog, undefined, psCatalog);

				// If we got new chassis added...
				if (newChassis) {
					targetChassis = newChassis;

					// Give the chassis its name.
					newChassis.name = newName;

					// Set its default wiring to match our selection.
					newChassis.defaultIOModWiring = gsData.wiringType;

					// If this is the project's FIRST chassis, 
					// auto-scale our layout appropriately.
					if (wasEmpty) {
						autoScaleToContent(ChassisLayoutKey, cbData.project.content.totalExtent,
							AutoScaleFitType.NewContent);
					}

					addDefaultComponentsToNewChassis(newChassis);

					// Select the new chassis
					cbData.selectChassisCallback(newChassis);

					// If we had NO other chassis, the new one will ALREADY
					// be visible in our layout. If we DID have others, we
					// want to MAKE SURE that the new one ends up visible.
					// Since it was just added, it will ALWAYS be the LAST
					// rack, so we'll request a scroll-to-btm.
					if (!wasEmpty) {
						requestPendingScrollToBtm(ChassisLayoutKey,);
					}
				}
			}

			if (targetChassis && cbData.additionalGrps) {
				switch (targetChassis.platform) {
					case PlatformFlex:
						cbData.additionalGrps.forEach((grpInfo) => {
							// As of now we know about one other group
							// for a 'snap' platform and that's the 5094
							// Interconnect cable selection. The structure
							// GroupChoiceInfo is somewhat convoluted. The
							// GroupChoiceInfo has a ChoicesGroup, which has
							// 'Subgroups', which contains an array of ChoiceInfos.
							// We need to get to the subgroup to ID the GrpChInfo
							// as the one we want here.
							if (grpInfo.group.subgroups) {
								const subGrp = grpInfo.group.subgroups[0];
								if (subGrp.label === _grpInterconnectID && grpInfo.selections) {
									const icChoice = grpInfo.selections[0];
									_snapAdjustInterconnectCable(targetChassis, icChoice);
								}
							}
						});
						break;

					case PlatformFlexHA:
						_updateNumBanks(targetChassis, cbData.additionalGrps);
						break;

					default:
						break;
				}
			}

			//// Whether we edited or added a chassis, check for
			//// an interconnect cable selection (5094 only)
			//if (targetChassis && targetChassis.platform === PlatformFlex && cbData.additionalGrps) {
			//	cbData.additionalGrps.forEach((grpInfo) => {
			//		// As of now we know about one other group
			//		// for a 'snap' platform and that's the 5094
			//		// Interconnect cable selection. The structure
			//		// GroupChoiceInfo is somewhat convoluted. The
			//		// GroupChoiceInfo has a ChoicesGroup, which has
			//		// 'Subgroups', which contains an array of ChoiceInfos.
			//		// We need to get to the subgroup to ID the GrpChInfo
			//		// as the one we want here.
			//		if (grpInfo.group.subgroups) {
			//			const subGrp = grpInfo.group.subgroups[0];
			//			if (subGrp.label === _grpInterconnectID && grpInfo.selections) {
			//				const icChoice = grpInfo.selections[0];
			//				_snapAdjustInterconnectCable(targetChassis, icChoice);
			//			}
			//		}
			//	});
			//}

			// Either way, we changed project content.
			cbData.contentChangedCallback();
		}
		else {
			// Unexpected
			throw new Error('ERROR: Invalid data in configChassisCallback!');
		}
	}
}

const _snapAdjustInterconnectCable = (chassis: Chassis | undefined, icChoice: string) => {
	if (!chassis)
		return;

	// First determine if the chassis has an existing
	// intercoonect cable.
	const idxExisting = chassis.modules.findIndex(mod => mod && mod.isInterconnect);
	let catExisting = '';
	const catNew = (icChoice && icChoice !== _InterConnNoCableOpt ? icChoice : '');
	if (idxExisting > 0) {
		const mod = chassis.modules[idxExisting];
		catExisting = (mod ? mod.catNo : '' );
	}

	if (catExisting === catNew)
		return;

	// Are we removing the existing IC...
	if (!catNew) {
		// Set the IC module to undefined and 
		// compact the array.
		chassis.modules[idxExisting] = undefined;
		snapCompactModuleArray(chassis);
		return;
	}

	// Delete the existing Interconnect (if exists)
	if (idxExisting > 0) {
		if (!deleteModuleAtSlot(chassis, idxExisting)) {
			displayAlertMsg('Unable to replace existing Interconnect Cable.');
			return;
		}
	}

	// We are adding an IC. Start by adding it to
	// the end of the array.
	if (addModuleAtSlot(chassis, catNew, -1, true)) {
		const lenModArr = chassis.modules.length;
		// If the module array's length is less
		// than 2, we can leave the IC where it is.
		// Otherwise...
		if (lenModArr > 2) {
			// Get the IC we just added (the last module).
			const icNew = chassis.modules[lenModArr - 1];

			// Start our insert/move index at the
			// existing IC (if any)...
			let idxInsert = idxExisting;
			if (idxInsert < 0) {
				idxInsert = Math.floor(lenModArr / 2);
			}

			chassis.modules.splice(idxInsert, 0, icNew);
			chassis.modules.length = lenModArr;
		}
		else if(idxExisting >= 0) {
			// We should NOT have had an existing IC.
			logger.error('_snapAdjustInterconnectCable(): Existing IC in slot 0.');
			// Remove the old IC from slot 0.
			const modSlot0 = chassis.modules[0];
			if (modSlot0 && modSlot0.isInterconnect)
				chassis.modules[0] = undefined;
		}

		snapCompactModuleArray(chassis);
	}
}

const addDefaultComponentsToNewChassis = (chassis: Chassis) => {
	// For FlexHA, add the adapter to a newly
	// created chassis.
	if (chassis.platform === PlatformFlexHA) {
		addModuleAtSlot(chassis, flexHA_DefAdapter, 0, true);
	}
}

let _initData: InitCfgData | undefined = undefined;

export const snapConfigureChassis = (
	project: ChassisProject,
	platform: string,
	selectChassisCallback: (chassis: Chassis | undefined) => void,
	selectDeviceCallback: (device: SelectableDevice | undefined) => void,
	contentChangedCallback: () => void,
	chassis?: Chassis
) => {
	_initData = {
		project: project,
		selectChassisCallback: selectChassisCallback,
		selectDeviceCallback: selectDeviceCallback,
		contentChangedCallback: contentChangedCallback,
		chassis: chassis,
	};

	if (chassis) {
		getLocAttrInfoForChassisEdit(chassis.platform, onChassisEditAttrInfoLoaded);
	}
	else {
		const loc = getLocAttrFromProject(project);
		// If the Loc platform is NOT the same as the platform
		// passed in...
		if (loc.platform !== platform) {
			getLocAttrInfoForChassisEdit(platform, onChassisEditAttrInfoLoaded);
		}
		else {
			// Use the Project's Loc as our config basis.
			// We need to clone the project's loc so that
			// changes in the Cfg Dlg do NOT affect the
			// selections on the Design Page.
			const newLocInfo = cloneLocAttributeInfo(loc);
			snapPrepareLocAttrForChassisConfig(newLocInfo);
			onChassisEditAttrInfoLoaded(true, newLocInfo);
		}
	}
}

const onChassisEditAttrInfoLoaded = (success: boolean, attrInfo: LocAttributeInfo) => {
	if (_initData == null || attrInfo == null)
		return;

	// Note: We may need to do something special
	// for slot fillers. 5069 does NOT have them,
	// but 5094 does. Depending on how 5094 fillers
	// are treated (as a module or as a placeholder)
	// will affect the calculation of minSlotsReqd.
	const slotFillers = 0;
	let totalSlotsInUse = 0;
	if (_initData.chassis)
		totalSlotsInUse = _initData.chassis.modules.length;

	const minSlotsReqd = totalSlotsInUse - slotFillers;

	const initSelections = (_initData.chassis ? snapGetCfgDataFromChassis(_initData.chassis) : snapGetChassisCfgDataFromLocAttr(attrInfo));

	if (_initData.chassis)
		cfgDlgInitializeLocAttrInfoFromChassis(attrInfo, initSelections);

	const [allPossibleWiringTypes, mapCatToWiringSpt] = (_initData.chassis && (minSlotsReqd > 0))
		? cfgDlgGetPossibleModWiringTypes(_initData.chassis)
		: [SupportedWiringType.NA, undefined];


	// 2024.2.20 Convert LocAttrInfo into usable groups
	// for the 'Choices' paradigm.
	let arrChassisPage: BaseChoiceInfo[] = [];
	const attrGrpChassis = attrInfo.attrGroups.find(x => x.title === ChassisCfgGrpCategory.Chassis);
	if (attrGrpChassis) {
		arrChassisPage = attrGrpChassis.settings.map((setting, idx) => {
			return createNewGdSelGrpChoiceInfo(
				attrInfo, setting, tipCallback, ChassisCfgGrpCategory.Chassis, idx
			);
		});
	}

	// Add any additional Chassis Page groups.
	const additionalGrps = _getAdditionalChassisPageGroups(attrInfo);
	additionalGrps.forEach(grp => arrChassisPage.push(grp));

	let arrPowerPage: BaseChoiceInfo[] = [];
	let lastIdx = 0;
	const attrGrpPower = attrInfo.attrGroups.find(x => x.title === ChassisCfgGrpCategory.Power);
	if (attrGrpPower) {
		arrPowerPage = attrGrpPower.settings.map((setting, idx) => {
			lastIdx = idx;
			return createNewGdSelGrpChoiceInfo(
				attrInfo, setting, tipCallback, ChassisCfgGrpCategory.Power, idx
			);
		});
	}

	// We need to define a Power Group, but it
	// will not be used.
	const psTempGrp = makeChoicesGroup(ChoiceGroupType.RequireOneOf, 'Power Supply');
	const psGrpInfo = createNewGrpChoiceInfo(psTempGrp, ['']);
	psGrpInfo.pageID = ChassisCfgGrpCategory.Power;
	psGrpInfo.index = lastIdx + 1;

	const callbackData: ConfigChassisCBData = {
		project: _initData.project,
		cfgAttrInfo: attrInfo,
		initialGSSelections: [], // initially set to empty.

		selectChassisCallback: _initData.selectChassisCallback,
		selectDeviceCallback: _initData.selectDeviceCallback,
		contentChangedCallback: _initData.contentChangedCallback,

		chassis: _initData.chassis,
		chassisName: _initData.chassis ? getChassisName(_initData.chassis) : '',
		origCat: _initData.chassis ? _initData.chassis.catNo : '',
		origPSCat: (_initData.chassis && _initData.chassis.ps) ? _initData.chassis.ps.catNo : '',
		origTotalSlotsInUse: totalSlotsInUse,
		origSlotFillers: slotFillers,
		origQtyRMMods: 0,

		allPossibleWiringTypes: allPossibleWiringTypes,
		mapCatToWiringSpt: mapCatToWiringSpt,

		msgLevel: StatusLevel.NA,
		panelMessages: [],
		gsPanelMessage: [],

		ChassisPage: arrChassisPage,
		PowerPage: arrPowerPage,

		// TODO: External PSU Selection.
		psGrp: psGrpInfo,
		additionalGrps: [...additionalGrps],
	};

	cfgDlgUpdateMessages(callbackData, _situationalMsgInfo, tipCallback);

	const title = _initData.chassis
		? 'Edit Chassis'
		: 'Add Chassis';

	const request: ModalRequestSpec = {
		includeButtons: true,
		closeOnInsideClick: false,
		stayOpenOnBackdropClick: true,
		title: title,
		callback: configChassisCallback,
		requestorData: callbackData,
		content: SnapChassisConfig,
		width: Math.min(800, window.innerWidth - 40),
	};

	addButton(
		request,
		(_initData.chassis ? 'SAVE' : 'ADD'),
		ModalStatus.Confirmed,
		'contained');

	requestModal(request);
}

const _addFlexICCableGrp = (gci: GroupChoiceInfo[]) => {
	// Add the Interconnect Cable group.
	const grpIC = makeChoicesGroup(ChoiceGroupType.RequireOneOf, _grpInterconnectID);
	grpIC.row = true;

	setChoiceGroupTipInfo(grpIC, StatusLevel.Info,
		MessageCategory.InterconnectCbl, MessageType.GeneralInfo, tipCallback);

	const cables = getInterconnectCbls(PlatformFlex);
	if (cables) {
		const arrCables = cables.map(cbl => {
			return cbl.cableLenDescr;
		})

		arrCables.sort();
		arrCables.splice(0, 0, _InterConnNoCableOpt);
		addChoiceInfo(grpIC, { id: _InterConnNoCableOpt, label: _InterConnNoCableOpt });
		arrCables.forEach((cbl) => {
			const cblInfo = cables.find(info => info.cableLenDescr === cbl);
			if (cblInfo)
				addChoiceInfo(grpIC, { id: cblInfo.catNo, label: cbl });
		});

		// Does the chassis have an Interconnect
		let selection = _InterConnNoCableOpt;
		if (_initData && _initData.chassis) {
			const existingIC = _initData.chassis.modules.find(mod => mod && mod.isInterconnect);
			if (existingIC)
				selection = existingIC.catNo;
		}

		const infoIC = createNewGrpChoiceInfo(grpIC, [selection]);
		gci.push(infoIC);
	}
}

const _addFlexHABankGrp = (gci: GroupChoiceInfo[]) => {
	// Add the Bank group.
	const grpBanks = makeChoicesGroup(ChoiceGroupType.RequireOneOf, _grpBanksID);
	grpBanks.row = true;

	setChoiceGroupTipInfo(grpBanks, StatusLevel.Info,
		MessageCategory.Banks, MessageType.GeneralInfo, tipCallback);

	addChoiceInfo(grpBanks, { id: _optOneBank, label: _optOneBank })
	addChoiceInfo(grpBanks, { id: _optTwoBanks, label: _optTwoBanks })
	addChoiceInfo(grpBanks, { id: _optThreeBanks, label: _optThreeBanks })

	let selection = _optOneBank;
	if (_initData && _initData.chassis) {
		const existingBanks = getNumBanks(_initData.chassis);
		switch (existingBanks) {
			case 2:
				selection = _optTwoBanks;
				break;

			case 3:
				selection = _optThreeBanks;
				break;

			default:
				break;
		}
	}

	const infoBanks = createNewGrpChoiceInfo(grpBanks, [selection]);
	gci.push(infoBanks);
}

const _getAdditionalChassisPageGroups = (attrInfo: LocAttributeInfo): GroupChoiceInfo[] => {
	if (_initData == null || attrInfo == null)
		return [];

	const additionalGroups: GroupChoiceInfo[] = []; 

	switch (attrInfo.platform) {
		case PlatformFlex:
			_addFlexICCableGrp(additionalGroups);
			break;

		case PlatformFlexHA:
			_addFlexHABankGrp(additionalGroups);
			break;

		default:
			break;
	}
	// If we have the 5094 Flex platform....
	//if (attrInfo.platform === PlatformFlex) {

	//	// Add the Interconnect Cable group.
	//	const grpIC = makeChoicesGroup(ChoiceGroupType.RequireOneOf, _grpInterconnectID);
	//	grpIC.row = true;

	//	setChoiceGroupTipInfo(grpIC, StatusLevel.Info,
	//		MessageCategory.InterconnectCbl, MessageType.GeneralInfo, tipCallback);

	//	const cables = getInterconnectCbls(attrInfo.platform);
	//	if (cables) {
	//		const arrCables = cables.map(cbl => {
	//			return cbl.cableLenDescr;
	//		})

	//		arrCables.sort();
	//		arrCables.splice(0, 0, _InterConnNoCableOpt);
	//		addChoiceInfo(grpIC, { id: _InterConnNoCableOpt, label: _InterConnNoCableOpt });
	//		arrCables.forEach((cbl) => {
	//			const cblInfo = cables.find(info => info.cableLenDescr === cbl);
	//			if (cblInfo)
	//				addChoiceInfo(grpIC, { id: cblInfo.catNo, label: cbl });
	//		});

	//		// Does the chassis have an Interconnect
	//		let selection = _InterConnNoCableOpt;
	//		if (_initData.chassis) {
	//			const existingIC = _initData.chassis.modules.find(mod => mod && mod.isInterconnect);
	//			if (existingIC)
	//				selection = existingIC.catNo;
	//		}

	//		const infoIC = createNewGrpChoiceInfo(grpIC, [selection]);
	//		additionalGroups.push(infoIC);
	//	}
	//}

	return additionalGroups;
}


const SnapChassisConfig = (request: ModalRequestSpec) => {
	const [currentTab, setCurrentTab] = useState<number>(CfgDlgTabID.Chassis);
	const [count, setCount] = useState<number>(0);
	const [infoPanelOpen, setInfoPanelOpen] = useState<boolean>(false);

	const divRef = useRef<HTMLDivElement | null>(null);
	const infoPt = useRef<Point>({ x: 0, y: 0 });
	const initializing = useRef(true);
	const infoMsgs = useRef<PanelMessage[]>(new Array<PanelMessage>());

	const data = request.requestorData as ConfigChassisCBData;

	if (!data) {
		unexpected('INVALID Request to ConfigAccys!');
	}

	const inclOldStyleMsgPanel = IncludeOldStyleMsgPnlInChassisConfig &&
		(data.panelMessages.length > 0);

	// Added to set the starting tab to the POWER
	// tab(???) 
	// https://github.com/Rockwell-Automation-IT-LCS/ControlSystemAdvisor/pull/146
	useEffect(() => {
		if (data?.chassis?.isPower) {
			setCurrentTab(CfgDlgTabID.PowerSupply);
		}
	}, [data?.chassis?.isPower]);

	const onTabSelected = (tabID: number) => {
		if (tabID !== currentTab) {
			setCurrentTab(tabID);
		}
	}

	const selectionChanged = () => {
		// 2024.2.16 This is for a non-Guided Selection
		// driven change - As of now, only for the PSU.

		// An option selection was changed, or at
		// least an attempt to change it was made.
		// Update the .allSelections collection in
		// our data object to match what we now
		// SHOULD have.
		cfgDlgUpdatePSGroup(data, tipCallback);
		cfgDlgUpdateMessages(data, _situationalMsgInfo, tipCallback);

		// Trigger a re-render.
		setCount(count + 1);
	}

	const gsSelectionChanged = (gsSetting: ProjectSetting, newChoiceInfo: ChoiceInfo) => {
		const reRender = cfgDlgGSSelectionChanged(request, initializing.current, _situationalMsgInfo, tipCallback, gsSetting, newChoiceInfo);
		if (reRender)
			setCount(count + 1);
	}

	const findModalBaseDiv = (): HTMLElement | undefined => {
		if (divRef.current) {
			let parent = divRef.current.parentElement;
			while (parent) {
				if (parent.id === 'ModalBaseDiv') {
					return parent;
				}
				parent = parent.parentElement;
			}
		}
		return undefined;
	}

	const _horizontallyCenterTipInModal = true;

	const onTipCallback = (category: MessageCategory, type: MessageType, pt: Point) => {

		// The pt arg is the center of the icon that was
		// clicked in DOM coords (browser viewport). We
		// need the point relative to our Modal.
		// Find the base div of our parent modal.
		const mdlBase = findModalBaseDiv();

		// The get ITS client rect.
		const baseClRect = mdlBase ? mdlBase.getBoundingClientRect() : undefined;

		// If we can, adjust our pt accordingly.
		if (baseClRect) {
			pt.x -= baseClRect.left;
			pt.y -= baseClRect.top;

			// If we leave our pt here, the tip popup will/would
			// be centered horizontally at our point.
			// If our 'center in modal' flag is set, change
			// the x to the middle of our client instead.
			if (_horizontallyCenterTipInModal) {
				pt.x = (baseClRect.width / 2);
			}
		}


		// Save the adjusted point.
		infoPt.current = { ...pt };

		// Load messages for the given id.
		loadTipPanelMsgs(infoMsgs.current, _situationalMsgInfo, category, type);

		// And and set our panel-open state.
		// That'll re-render us, which will
		// then INCLUDE the floating panel.
		setInfoPanelOpen(true);
	}

	const onCloseInfoPanel = () => {
		setInfoPanelOpen(false);
		infoPt.current.x = 0;
		infoPt.current.y = 0;
		infoMsgs.current.length = 0;
	}

	liveTipCallback = onTipCallback;

	// If this is our first time through...
	if (initializing.current) {
		// Get the EnvRating Setting.
		const er = getLocAttributeSetting(data.cfgAttrInfo, 'ER');
		if (er) {
			// We have to do this here. By simulating
			// a Guided Selection change, the options
			// will be verified and disabled if need be.
			const chInfo: ChoiceInfo = {
				id: er.selectedOption.id,
				label: er.selectedOption.display,
				disabled: false
			}
			gsSelectionChanged(er, chInfo);
		}
		initializing.current = false;
	}

	return (
		<div
			className='config-chassis-dialog'
			ref={divRef}
		>
			<ChassisConfigDlg
				data={data}
				selectionChanged={selectionChanged}
				gsSelectionChanged={gsSelectionChanged}
				onTabSelected={onTabSelected}
				onCloseInfoPanel={onCloseInfoPanel}
				infoPanelOpen={infoPanelOpen}
				currentTab={currentTab}
				inclOldStyleMsgPanel={inclOldStyleMsgPanel}
				infoPt={infoPt.current}
				infoMsgs={infoMsgs.current}
			/>
		</div>
	);
}

export default SnapChassisConfig;