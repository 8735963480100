import React from 'react';
import { Label, Tag, Text } from 'react-konva';
import { LocAndSize, Point } from '@csa-core/advisor.controlsystemcore'; // '../types/SizeAndPosTypes';
import { getRelPtInLoc, offsetLoc } from '@csa-core/advisor.controlsystemcore'; // '../util/GeneralHelpers';

const _idLeader = '[';
const _idTrailer = ']';

const _lblRelX = 0.5;
const _lblRelY = 0.85;

// WCS:
// The previous approach to dynamic font sizing was
// invalid due to a misunderstanding on my part.
// For now, that's been removed and we now use the
// SAME font size regardless of scale. That makes
// it always the same size relative to what's around
// it (it just zooms in/out with the rest).
// We MIGHT decide to revisit this at some point to
// make the font get proportionally larger somewhat
// when we're zoomed way out (to make IDs easier to
// see at small scales), and to not keep getting 
// bigger as we zoom in.

// We'll dynamically size our font based on our current
// scale, but only to a point. _base size here is the
// size we use for a scale of 1.0.
const _baseFontSize = 35;
//const _minFontSize = 4;
//const _maxFontSize = 20;


const getFontSize = (): number => {
    return _baseFontSize;
    //// Get our current scale.
    //const scale = getStageScale();

    ////const scaledMin = _minFontSize / scale;
    ////const scaledMax = _maxFontSize / scale;

    //// Scale the base size accordingly.
    //const scaledSize = Math.round(_baseFontSize / scale);
    //return scaledSize;

//    // Use the size after making sure it's
//    // in our allowed range.
//    const size = Math.min(Math.max(scaledSize, scaledMin), scaledMax);
//    return size;
}

//const getFontSize = (): number => {
//    // Get our current scale.
//    const scale = getStageScale();

//    const scaledMin = _minFontSize / scale;
//    const scaledMax = _maxFontSize / scale;

//    // Scale the base size accordingly.
//    const scaledSize = Math.round(_baseFontSize / scale);

//    // Use the size after making sure it's
//    // in our allowed range.
//    const size = Math.min(Math.max(scaledSize, scaledMin), scaledMax);
//    return size;
//}


interface Props {
    slotId: string;
    ptOrg: Point;
    relSlotLoc: LocAndSize; // location relative to ptOrg
    emptySlot: boolean;
}

const SlotIDComp = (props: Props) => {

    const renderLoc: LocAndSize = { ...props.relSlotLoc };
    offsetLoc(renderLoc, props.ptOrg);

    // Constuct the text we'll display.
    const idText = _idLeader + props.slotId + _idTrailer;

    // Determine the font size we should use.
    const fontSize = getFontSize();

    // Determine the width that our text should
    // be based on its length and the font size.
    const textWidth = fontSize * idText.length / 2;

    // Get a point inside the provided slot loc
    // offset by our constants (.5 and .85 of the
    // loc's width and height, repectively).
    const lblBasePt = getRelPtInLoc(renderLoc, _lblRelX, _lblRelY);

    // The x,y pt we pass to the Label control
    // will be its upper left corner, and its
    // width will be our textWidth. So, move the
    // base x over half that width.
    lblBasePt.x -= (textWidth / 2);

    // The 'Tag' is the 'box' around our text.
    // Use a light fill color for that, but
    // ONLY if this is NOT an empty slot.
    const tagProps = {
        fill: props.emptySlot ? '' : 'lightgray',
    };

    // Props for the actual text...
    const textProps = {
        text: idText,               // Our constructed disp text
        fontSize: fontSize,         // Our calculated font size
        padding: 1,                 // Small padding gives extra height
                                    // to the Tag when we show that.
        fill: props.emptySlot             // Text color based on empty or not
            ? 'white'
            : 'black',
        width: textWidth,           // Width of the box our text will
                                    // actually be displayed inside of
        align: 'center'             // Specify that text should be
                                    // centered horizontally in that box.
    };

    return (
        <Label
            listening={false}
            {...lblBasePt}
        >
            <Tag  {...tagProps} />
            <Text {...textProps} />
        </Label>
    );
}

export default SlotIDComp;

