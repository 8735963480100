import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";


// Exporting SaveRestInfoState due to --declaration flag in
// the core's tsconfig. The store needs this interface
// defined in order to produce a declaration file.
export interface SaveRestInfoState {
    saveInProgress: boolean;
    configEverSaved: boolean;
}

const initialState: SaveRestInfoState = {
    saveInProgress: false,
    configEverSaved: false
}


export const saveRestInfo = createSlice({
    name: 'saveRestInfo',
    initialState,
    reducers: {
        setSaveInProgress: (state, action: PayloadAction<boolean>) => {
            state.saveInProgress = action.payload;
        },
        setConfigEverSaved: (state, action: PayloadAction<boolean>) => {
            state.configEverSaved = action.payload;
        }
    }
});

export const {
    setSaveInProgress,
    setConfigEverSaved
} = saveRestInfo.actions;

export const saveInProgress = (state: RootState) => state.saveRestInfo.saveInProgress;
export const configEverSaved = (state: RootState) => state.saveRestInfo.configEverSaved;
export default saveRestInfo.reducer;

