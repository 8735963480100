import { GeneralUIImplBase } from "../../../implementation/GeneralUIImplBase";
import { ChassisCompProps } from '@csa-core/advisor.controlsystemcore'; // "../../../implementation/ImplGeneral";
import { ActBtnInfo, LayoutActionType } from '@csa-core/advisor.controlsystemcore'; // "../../../types/LayoutActions";
import { Chassis, ChassisProject, Rack, SelectableDevice } from '@csa-core/advisor.controlsystemcore'; // "../../../types/ProjectTypes";
import { snapConfigureChassis } from "../../snap/SnapChassisConfig";
import { snapGetActionBtnInfo, snapGetChassisRenderer } from "../../snap/SnapGeneralUIImpl";

class CpLXGeneralUIImpl extends GeneralUIImplBase {

	getChassisRenderer(): React.FC<ChassisCompProps> {
		return snapGetChassisRenderer();
	}

	getActionBtnInfo(
		action: LayoutActionType,
		rack: Rack,
		slotNum: number): ActBtnInfo {

		// Forward to snap
		return snapGetActionBtnInfo(action, rack, slotNum);
	}

	hasGetActBtnInfo(__platform: string): boolean {

		return true;
	}

	configureChassis(
		project: ChassisProject,
		platform: string,
		selectChassisCallback: (chassis: Chassis | undefined) => void,
		selectDeviceCallback: (device: SelectableDevice | undefined) => void,
		contentChangedCallback: () => void,
		chassis?: Chassis
	): void {

		return snapConfigureChassis(
			project,
			platform,
			selectChassisCallback,
			selectDeviceCallback,
			contentChangedCallback,
			chassis
		);
	}
}

export const createCpLXGeneralUIImpl = (): GeneralUIImplBase => {
	return new CpLXGeneralUIImpl();
}