import React from 'react';
import './Choices.scss';
import { ChoiceInfo } from '@csa-core/advisor.controlsystemcore'; // '../../types/MessageTypes';
import { FormControlLabel, Radio, Checkbox } from '@mui/material';


interface Props {
    info: ChoiceInfo;
    radio: boolean;
    checked: boolean;
    onSelChanged: (info: ChoiceInfo, selected: boolean) => void;
    outlined?: boolean;
}

const ChoiceSelector = (props: Props) => {

    const disabled = (props.info.disabled === true) ? true : undefined;

    const handleChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
        props.onSelChanged(props.info, checked);
    };

    const getFCLabelSXprops = (): object => {
        if (props.outlined === true) {
            return {
                margin: '0px',
                border: 1,
                borderRadius: '4px',
                borderColor: props.checked ? '#2A79C4' : '#E1EBF4',
                bgcolor: props.checked ? '#C0D7EE' : '#F7F9FC',
                padding: '4px 6px 4px 6px',
            }
        }
        else {
            return {
                margin: '0px'
            }
        }
    }


    const getControl = (radio: boolean): React.ReactElement => {
        if (radio) {
            return (
                <Radio
                    disableRipple
                    size='small'
                    sx={{ p: 0, mr: '9px' }}
                />
            );
        }
        else {
            return (
                <Checkbox
                    disableRipple
                    size='small'
                    sx={{ p: 0, mr: '9px' }}
                />
            );
        }
    }

    return (
        <FormControlLabel
            sx={{ ...getFCLabelSXprops() }}
            value={props.info.id}
            control={getControl(props.radio)}
            label={<span className='choice-selector-label' >{props.info.label}</span>}
            checked={props.checked}
            onChange={handleChange}
            disabled={disabled}
        />
    );

}

export default ChoiceSelector;

