import React from 'react';
import { useAppDispatch, useAppSelector } from '@csa-core/advisor.controlsystemcore'; // '../redux/hooks';
import { layoutViewTip, setLayoutViewTip } from '@csa-core/advisor.controlsystemcore'; // '../redux/slices/LayoutViewTipSlice';
import './LayoutViewToolTip.scss'
import { Size } from '@csa-core/advisor.controlsystemcore'; // '../types/SizeAndPosTypes';

const cyOffsetTop = 60;   // ~Layout toolbar height.
const cyOffsetBottom = 10;   // ~Layout toolbar height.

// Test if we clicked inside the tip.
const isPtrInside = (e: PointerEvent, element: HTMLDivElement | null) => {
    if (e.target instanceof Element) {
        const parent = e.target as Element;
        let node: ParentNode | null = parent.parentNode;
        while (node) {
            if (node === element) {
                return true;
            }
            node = node.parentNode
        }
    }

    return false;
};

enum LocID {
    None = 0,
    Top = 0x01,
    Bottom = 0x02,
    Left = 0x04,
    Right = 0x08,
}

const getStyle = ( idLoc : LocID, orgX : number, orgY : number, targetSize : Size, sizeParent : Size ) => {
    // Note: when setting the Bottom/Right, it
    // is the offset FROM the Bottom/Right, and
    // not the Top/Left.
	if ( idLoc & LocID.Top ) {
		if ( idLoc & LocID.Left )
			return { left: ( orgX + targetSize.width ) + 'px', top: ( orgY + targetSize.height + cyOffsetTop ) + 'px' };
		else // Assume Right
			return { right: ( sizeParent.width - orgX ) + 'px', top: ( orgY + targetSize.height + cyOffsetTop ) + 'px' };
	}
	else { // Assume Bottom
		if ( idLoc & LocID.Left )
			return { left: ( orgX + targetSize.width ) + 'px', bottom: ( sizeParent.height - orgY + cyOffsetBottom ) + 'px' };
		else // Assume Right
			return { right: ( sizeParent.width - orgX ) + 'px', bottom: ( sizeParent.height - orgY + cyOffsetBottom ) + 'px' };
	}
}

interface Props {
    parentDiv?: React.MutableRefObject<HTMLDivElement | null>;
}

const LayoutViewTooltip = (props: Props) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const currentLayoutTip = useAppSelector(layoutViewTip);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const onPtrDown = (e: PointerEvent) => {
            if (!isPtrInside(e, containerRef.current)) {
                // Close the tip.
                dispatch(setLayoutViewTip(undefined));
            }
        }

        window.addEventListener('pointerdown', onPtrDown);
        return () => {
            window.removeEventListener('pointerdown', onPtrDown);
        };
    }, [dispatch]);

    const parent = props.parentDiv?.current;
    if ( !currentLayoutTip || currentLayoutTip.text.trim().length === 0 || !parent )
        return null;

    const sizeParent : Size = { width: parent.clientWidth, height: parent.clientHeight };
    // Guesstimate the width and height of the tip.
    // Font size is set to 12px and tip max-width
    // is 350px, which can fit ~56 chars. 
    const widthTip = Math.min(350, (350 / 56) * currentLayoutTip.text.length);
    const heightTip = Math.ceil(currentLayoutTip.text.length / 56) * 20;
    const targetSize : Size = { width: currentLayoutTip.rcClient.width, height: currentLayoutTip.rcClient.height};

    const targetX = currentLayoutTip.rcClient.x;
    const targetY = currentLayoutTip.rcClient.y;

    // Start out tip loc ID as 0. 
    let locID = 0;

    // Test the Top and Bottom
    const topTest = ( targetY - heightTip );
    const bottomTest = sizeParent.height - ( targetY + heightTip + targetSize.height );

    // If the space ABOVE is avaiable, we
    // want to set the BOTTOM of the tip.
    if ( topTest >= 0 || topTest > bottomTest )
        locID |= LocID.Bottom;
    else
        locID |= LocID.Top;

    // Test the Left and Right
    const leftTest = ( targetX - widthTip );
    const rightTest = sizeParent.width - ( targetX + widthTip + targetSize.width );

    // If the space to the RIGHT is avaiable,
    // we want to set the LEFT SIDE of the tip.
    if (rightTest >= 0 || rightTest > leftTest)
        locID |= LocID.Left;
    else
        locID |= LocID.Right;

    // Get our style.
    const style = getStyle( locID, targetX, targetY, targetSize, sizeParent);

    return (
        <div className="layout_view_tooltip"
            ref={containerRef}
            style={{ ...style }}
        >
            <div>{currentLayoutTip.text}</div>
        </div>
    );
}

export default LayoutViewTooltip;