import { LayoutMode } from '@csa-core/advisor.controlsystemcore'; // "../util/LayoutModeHelp";
import { ActBtnInfo, LayoutActionType } from '@csa-core/advisor.controlsystemcore'; // "../types/LayoutActions";
import { Chassis, ChassisProject, Rack, SelectableDevice } from '@csa-core/advisor.controlsystemcore'; // "../types/ProjectTypes";
import { ChassisCompProps } from '@csa-core/advisor.controlsystemcore'; // "./ImplGeneral";
import { getGeneralUIImplMgr } from "./GeneralUIImplMgr";

// General UI Implementation Functions 

export const getChassisRenderer = (
    platform: string
): React.FC<ChassisCompProps> | undefined => {

    return getGeneralUIImplMgr().getChassisRenderer(platform);
}


export const hasGetActBtnInfo = (platform: string): boolean => {

    return getGeneralUIImplMgr().hasGetActBtnInfo(platform);
}


export const getActBtnInfo = (
    action: LayoutActionType,
    rack: Rack,
    slotNum: number
): ActBtnInfo => {

    return getGeneralUIImplMgr().getActionBtnInfo(action, rack, slotNum);
}


export const configureChassis = (
    platform: string,
    project: ChassisProject,
    selectChassisCallback: (chassis: Chassis | undefined) => void,
    selectDeviceCallback: (device: SelectableDevice | undefined) => void,
    contentChangedCallback: () => void,
    chassis?: Chassis
) => {

    return getGeneralUIImplMgr().configureChassis(
        project,
        platform,
        selectChassisCallback,
        selectDeviceCallback,
        contentChangedCallback,
        chassis
    );
}

export const doesSlotQualifyForBtnAction = (
    mode: LayoutMode,
    type: LayoutActionType,
    chassis: Chassis,
    slotNum: number
): boolean => {

    return getGeneralUIImplMgr().doesSlotQualifyForBtnAction(
        mode,
        type,
        chassis,
        slotNum
    );
}
