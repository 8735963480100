import React from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { GraphicalDevice } from '@csa-core/advisor.controlsystemcore'; // '../../types/ProjectTypes';
import { getAccessoryInfo } from '@csa-core/advisor.controlsystemcore'; // '../../model/ChassisProject';
import DetailsAccyItem from './DetailsAccyItem';


interface Props {
    device: GraphicalDevice;
}

const DetailsCommonAccysTab = (props: Props) => {

    const accyInfo = getAccessoryInfo(props.device);

    if (accyInfo) {
        let nextKey = 1;

        return (
            <div className='floating-details-accys-tab'>
                {accyInfo.map(info => {
                    return (
                        <React.Fragment key={nextKey++}>
                            <DetailsAccyItem
                                accyInfo={info}
                            />
                            <div className='floating-details-accy-line'></div>
                        </React.Fragment>
                    );
                })}
            </div>
        );
    }
    else {
        return (null);
    }
}

export default DetailsCommonAccysTab;
