import React, { useCallback, useContext, useReducer } from 'react';
import { createContext, Dispatch, ReactNode } from "react";
import { getDevIdText, getEngineeringInfoFor, updateContentExtent } from '@csa-core/advisor.controlsystemcore'; // "../model/ChassisProject";
import { Chassis, DeviceType, SelectableDevice } from '@csa-core/advisor.controlsystemcore'; // "../types/ProjectTypes";
import { LayoutMode } from '@csa-core/advisor.controlsystemcore'; // '../util/LayoutModeHelp';
import { logger, LogSpec } from '@csa-core/advisor.controlsystemcore'; // '../util/Logger';
import { contentChanging } from '@csa-core/advisor.controlsystemcore'; // '../util/UndoRedo';
import { getEmptyProjectCommsInfo, ProjectCommsInfo } from '@csa-core/advisor.controlsystemcore'; // '../model/CommDetails'
import { PlatformMicro } from '@csa-core/advisor.controlsystemcore'; // '../platforms/PlatformConstants';

// CORE_RECHECK - we are getting 'use before init'
// error for LayoutModeType. We duped the enum here,
// but after the Core is implemented, remove this.
// We believe LayoutModeType will be already built.
enum LayoutModeType {
    Normal = 'Normal',
    Copy = 'Copy',
    Delete = 'Delete',
    Drag = 'Drag'
}

const _getModeChangeLogMsg = (oldMode: LayoutModeType, newMode: LayoutModeType): string => {
    return 'Layout mode change from ' + oldMode + ' to ' + newMode + '.';
}

// CORE_RECHECK - 'create on first use' used
// instead of creating defaultLayoutMode object
// in file/global scope.
let defLayoutMode: LayoutMode | undefined = undefined;
const getDefaultLayoutMode = (): LayoutMode => {
    if (!defLayoutMode) {
        defLayoutMode = {
            type: LayoutModeType.Normal,
            origCat: '',
            origCC: false,
            origET: false,
            engInfo: undefined
        };
    }

    return defLayoutMode;
}

// CORE_RECHECK - Commented and replaced with
// create on first use (above). Assumed this
// was the issue with circ-deps, but was not.

//// Default layout mode info.
//const defaultLayoutMode: LayoutMode = {
//    type: LayoutModeType.Normal,
//    origCat: '',
//    origCC: false,
//    origET: false,
//    engInfo: undefined
//}

// Combined state for layout-related info.
type ISelectionStateInfo = {
    layoutMode: LayoutMode,
    selectedChassis: Chassis | undefined,
    selectedDevice: SelectableDevice | undefined
    CommInfo: ProjectCommsInfo
}

// Initial state. Normal layout mode
// with nothing selected.
const initialState: ISelectionStateInfo = {
    layoutMode: getDefaultLayoutMode(),
    selectedChassis: undefined,
    selectedDevice: undefined,
    CommInfo:getEmptyProjectCommsInfo()
}

// Helper - called from selectionsReducer below.
const setLayoutMode = (state: ISelectionStateInfo,
    modeType: LayoutModeType): ISelectionStateInfo => {

    // Sanity test.
    // If we're currently in one of our 'special' modes...
    if (state.layoutMode.type !== LayoutModeType.Normal) {

        // Then new mode can ONLY be normal mode.
        if (modeType !== LayoutModeType.Normal) {
            throw new Error('Invalid layout mode change from ' +
                state.layoutMode.type + ' to ' + modeType);
        }
    }

    // If the requested mode is different
    // from our current mode...
    if (modeType !== state.layoutMode.type) {

        if (LogSpec.logContext) {
            logger.logContext(_getModeChangeLogMsg(state.layoutMode.type, modeType));
        }

        // Make a new copy of our state. In the copy,
        // init the layoutMode back to default. Needed for
        // memoized child components to see a prop change.
        const newState = {
            ...state,
            layoutMode: { ...getDefaultLayoutMode() }
        }

        // If the new new mode is Copy or Delete, or Drag (not normal)
        if (modeType !== LayoutModeType.Normal) {

            // If going to copy or drag mode...
            if ((modeType === LayoutModeType.Copy) ||
                (modeType === LayoutModeType.Drag)) {

                // We SHOULD have a selected chassis AND device, and
                // the device SHOULD NOT be a power supply. If so...
                if ((state.selectedDevice  && state.selectedDevice.deviceType !== DeviceType.PS) ||
                     (state.selectedDevice  && state.selectedDevice.deviceType === DeviceType.PS && state.selectedDevice.platform ==PlatformMicro )) {

                    const copySrc = state.selectedDevice;

                    // Save the catalog number and related info for
                    // our source device (what we'll be copying).
                    newState.layoutMode.origCat = copySrc.catNo;
                    newState.layoutMode.origCC = copySrc.conformal;
                    newState.layoutMode.origET = copySrc.extendedTemp;
                    //newState.layoutMode.prodData = getProductData(copySrc.platform, copySrc.catNo);
                    newState.layoutMode.engInfo = getEngineeringInfoFor(copySrc);
                }
                else {
                    throw new Error('Invalid change to Copy mode without proper device selection!');
                }
            }

            // We're going to either copy or delete mode.
            // In either case, we don't want to have anything
            // selected. If anything IS selected, then we'd
            // HAVE to have a selected chassis. If so...
            if (((modeType !== LayoutModeType.Drag)) && newState.selectedChassis) {

                // Take an EXTRA undo snapshot in this 
                // special case to 'save' what HAD been selected.
                if (newState.selectedChassis.parent) {
                    contentChanging(newState.selectedChassis.parent);
                }

                // Do needed deselection manually.
                if (newState.selectedDevice) {
                    newState.selectedDevice.selected = false;
                    newState.selectedDevice = undefined;
                }

                if (newState.selectedChassis) {
                    newState.selectedChassis.selected = false;
                    newState.selectedChassis = undefined;
                }

            }
        }

        // Finally, set the internal mode type to match
        // what we're changing to. 
        newState.layoutMode.type = modeType;

        // Return the NEW state.
        return newState;
    }
    else {
        // Nothing changed. Just 
        // return the state provided.
        return state;
    }
}

// Helper - called from selectionsReducer below.
const selectChassis = (state: ISelectionStateInfo,
    chassis: Chassis | undefined): ISelectionStateInfo => {

    // If we're in Copy or Delete mode...
    if (state.layoutMode.type !== LayoutModeType.Normal) {

        // Then we generally want to ignore the request.
        // However, if the request was made as part of an
        // undo/redo action, the requestor may be attempting
        // to re-establish a selection. In that case, the
        // chassis that was reloaded from the JSON snapshot
        // would have its .selected property set. For Copy
        // and Delete modes, we need to make sure that
        // .selected property is RESET. NOTHING should be
        // selected while in one of our Advanced modes.
        if (chassis && chassis.selected) {
            chassis.selected = false;

            if (LogSpec.logContext) {
                logger.logContext('selectChassis RESETS chassis selected flag for ' + chassis.name);
            }
        }

        // Then just return the
        // state we were given.
        return state;
    }

    // Regardless of whether we're given a chassis or
    // not, if it matches whatever's ALREADY
    // our selected chassis, then there's nothing
    // to do. We either leave the old one selected,
    // or STILL don't have a selection. If we
    // have a mismatch...
    if (chassis !== state.selectedChassis) {

        if (LogSpec.logContext) {
            if (chassis) {
                logger.logContext('New chassis selection: ' + chassis.name);
            }
            else {
                logger.logContext('New chassis selection: <none>');
            }
        }

        const parentContent = chassis
            ? chassis.parent
            : state.selectedChassis
                ? state.selectedChassis.parent
                : undefined;

        // If we HAD a previously selected
        // chassis, mark it as NOT selected.
        if (state.selectedChassis) {
            state.selectedChassis.selected = false;
        }

        // If we have a NEW chassis to
        // be selected...
        if (chassis) {
            // mark it as selected.
            chassis.selected = true;

            // NOTE: Need to figure out how to do bring-into-view again!!
            //    if (bringIntoView) {
            //        const loc = getChassisLoc(chassis);
            //        bringLocIntoView(loc);
            //    }
        }

        if (parentContent) {
            updateContentExtent(parentContent, false);
        }
        else {
            throw new Error('Unexpected error in context selectChassis!');
        }

        // Return a NEW copy of the state provided,
        // with its selectedChassis prop set to what
        // we were given.
        return {
            ...state,
            selectedChassis: chassis
        }
    }
    else {
        // Nothing changed. Just 
        // return the state provided.
        return state;
    }
}

// Helper - called from selectionsReducer below.
const selectDevice = (state: ISelectionStateInfo,
    device: SelectableDevice | undefined): ISelectionStateInfo => {

    // If we're in Copy or Delete mode...
    if (state.layoutMode.type !== LayoutModeType.Normal) {

        // Then we generally want to ignore the request.
        // However, if the request was made as part of an
        // undo/redo action, the requestor may be attempting
        // to re-establish a selection. In that case, the
        // device that was reloaded from the JSON snapshot
        // would have its .selected property set. For Copy
        // and Delete modes, we need to make sure that
        // .selected property is RESET. NOTHING should be
        // selected while in one of our Advanced modes.
        if (device && device.selected) {
            device.selected = false;

            if (LogSpec.logContext) {
                logger.logContext('selectDevice RESETS selected flag for ' + device.catNo);
            }
        }

        // Then just return the
        // state we were given.
        return state;
    }

    // Regardless of whether we're given a device or
    // not, if it matches whatever's ALREADY
    // our selected device, then there's nothing
    // to do. We either leave the old one selected,
    // or STILL don't have a selection. If we
    // have a mismatch...
    if (device !== state.selectedDevice) {

        if (LogSpec.logContext) {
            if (device) {
                logger.logContext('New device selection: ' + getDevIdText(device));
            }
            else {
                logger.logContext('New device selection: <none>');
            }
        }

        // If we HAD a previously selected
        // device, mark it as NOT selected.
        if (state.selectedDevice) {
            state.selectedDevice.selected = false;
        }

        // If we have a NEW device to
        // be selected...
        if (device) {
            // mark it as selected.
            device.selected = true;
        }

        // Return a NEW copy of the state provided,
        // with its selectedDevice prop set to what
        // we were given.
        return {
            ...state,
            selectedDevice: device
        }
    }
    else {
        // Nothing changed. Just 
        // return the state provided.
        return state;
    }
}

const selectCommInfo = (state: ISelectionStateInfo,
    comminfo: ProjectCommsInfo|undefined): ISelectionStateInfo => {
        if(comminfo!== undefined)
        {
            //console.log("Reducer payload",comminfo)
            //console.log("Reducer state",state)

            return {
                ...state,
                CommInfo:comminfo
            }  
        }
        return state;
    
}
// Specific actions we support in our selections reducer.
enum SelectionAction {
    SET_MODE = 'setLayoutMode',
    RESET_MODE = 'resetLayoutMode',
    CHASSIS_SELECT = 'selectChassis',
    DEVICE_SELECT = 'selectDevice',
    COMM_INFO = 'selectCommInfo'
}

// Payload definition for dispatch actions.
interface ISelectionAction {
    type: SelectionAction;
    modeType?: LayoutModeType;
    chassis?: Chassis;
    device?: SelectableDevice;
    commInfo?: ProjectCommsInfo|undefined;
}

// Reducer for dealing with all selection-related state info.
const selectionInfoReducer = (state: ISelectionStateInfo, action: ISelectionAction) => {
    switch (action.type) {
        case SelectionAction.RESET_MODE:
            return setLayoutMode(state, LayoutModeType.Normal);
 
        case SelectionAction.SET_MODE:
            if (action.modeType) {
                return setLayoutMode(state, action.modeType);
            }
            else {
                throw new Error('Set mode with no target mode provided?');
            }

        case SelectionAction.CHASSIS_SELECT:
            return selectChassis(state, action.chassis);

        case SelectionAction.DEVICE_SELECT:
            return selectDevice(state, action.device);
        case SelectionAction.COMM_INFO:
            return selectCommInfo(state, action.commInfo);

        default:
            throw new Error('Unsupported selections reducer action: ' + action.type);
    }
}

// Create our context.
const SelectionInfoContext = createContext<{
    state: ISelectionStateInfo;
    dispatch: Dispatch<ISelectionAction>;
}>({
    state: initialState,
    dispatch: () => null
});

// Define props used by provider below.
type InfoProviderProps = {
    children: ReactNode
}

// Our provider component.
export const SelectionInfoProvider = (props: InfoProviderProps) => {

    const [state, dispatch] = useReducer(selectionInfoReducer, initialState)

    return (
        <SelectionInfoContext.Provider value={{ state, dispatch }}>
            {props.children}
        </SelectionInfoContext.Provider>
    );
}


// General custom hook for selection info.
// Note that using this one requires the client to
// provide full payload info for any 'setter' calls.
// In most cases, it's better to use the custom hooks
// defined below for specific info (layoutMode and selections) 
export const useSelectionInfo = () => {
    const context = useContext(SelectionInfoContext);
    if (!context) {
        throw new Error('useSelectionInfo must be used with a SelectionInfoProvider!');
    }

    return context;
}


interface LayoutModeInfo {
    layoutMode: LayoutMode;
    resetLayoutMode: () => void;
    setLayoutMode: (modeType: LayoutModeType) => void;
}

export const useLayoutMode = (): LayoutModeInfo => {
    const context = useContext(SelectionInfoContext);
    if (!context) {
        throw new Error('useLayoutMode must be used with a SelectionInfoProvider!');
    }

    const dispatch = context.dispatch;

    const resetLayoutMode = useCallback(() => {
        dispatch({
            type: SelectionAction.RESET_MODE
        });
    }, [dispatch]);

    const setLayoutMode = useCallback((modeType: LayoutModeType) => {
        dispatch({
            type: SelectionAction.SET_MODE,
            modeType: modeType
        });
    }, [dispatch]);

    const info: LayoutModeInfo = {
        layoutMode: context.state.layoutMode,
        resetLayoutMode: resetLayoutMode,
        setLayoutMode: setLayoutMode
    };

    return info;
}


interface SelectedChassisInfo {
    selectedChassis: Chassis | undefined;
    selectChassis: (chassis: Chassis | undefined) => void;
}

export const useSelectedChassis = (): SelectedChassisInfo => {
    const context = useContext(SelectionInfoContext);
    if (!context) {
        throw new Error('useSelectedChassis must be used with a SelectionInfoProvider!');
    }

    const dispatch = context.dispatch;

    const selectChassis = useCallback((chassis: Chassis | undefined) => {
        dispatch({
            type: SelectionAction.CHASSIS_SELECT,
            chassis: chassis
        });
    }, [dispatch]);

    const info: SelectedChassisInfo = {
        selectedChassis: context.state.selectedChassis,
        selectChassis: selectChassis
    };

    //return context;
    return info;
}


interface SelectedDeviceInfo {
    selectedDevice: SelectableDevice | undefined;
    selectDevice: (device: SelectableDevice | undefined) => void;
}

export const useSelectedDevice = (): SelectedDeviceInfo => {
    const context = useContext(SelectionInfoContext);
    if (!context) {
        throw new Error('useSelectedChassis must be used with a SelectionInfoProvider!');
    }

    const dispatch = context.dispatch;

    const selectDevice = useCallback((device: SelectableDevice | undefined) => {
        dispatch({
            type: SelectionAction.DEVICE_SELECT,
            device: device
        });
    }, [dispatch]);

    const info: SelectedDeviceInfo = {
        selectedDevice: context.state.selectedDevice,
        selectDevice: selectDevice
    };

    //return context;
    return info;
}

interface CommInfoContext {
    CommInfo: ProjectCommsInfo;
    setCommInfo: (comminfo: ProjectCommsInfo) => void;
}
export const useCommInfo = (): CommInfoContext => {
    const context = useContext(SelectionInfoContext);
    if (!context) {
        throw new Error('useSelectedChassis must be used with a SelectionInfoProvider!');
    }

    const dispatch = context.dispatch;

    const setCommInfo = useCallback((comminfo: ProjectCommsInfo) => {
        dispatch({
            type: SelectionAction.COMM_INFO,
            commInfo: comminfo
        });
    }, [dispatch]);

    const info: CommInfoContext = {
        CommInfo: context.state.CommInfo,
        setCommInfo: setCommInfo
    };

    //return context;
    return info;
}

