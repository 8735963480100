import { Chassis } from "./ProjectTypes";
import { Point } from "./SizeAndPosTypes";

export enum LayoutActionType {
    None = 'None',
    AddModule = 'AddModule',
    ModeCopy = 'ModeCopy',
    ModeDelete = 'ModeDelete',
    MakeDuplex = 'MakeDuplex',
    MakeSimplex = 'MakeSimplex',
}

export enum CopyModeCompat {
    Match = 'Match',
    Swap = 'Swap',
    Mismatch = 'Mismatch'
}

export interface ActBtnInfo {
    action: LayoutActionType;
    chassis: Chassis;
    slot: number;
    ctrPt: Point;
    copyCat?: string;
    compat?: CopyModeCompat;
    event?: PointerEvent;
    tip?: string;
}

export interface ActBtnSpecs {
    width: number;
    height: number
    cornerRadPct: number;
    lineGapPct: number;
    imgSizePct: number;
    strokeWidth: number;
    stroke: string;
    fill: string;
    mouseoverStroke: string;
    mouseoverFill: string;
}
