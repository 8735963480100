import React, { useState } from 'react';
import './SampleCompCard.scss';
import { SelectedProduct, SelectedCompInfoExtended } from '@csa-core/advisor.controlsystemcore'; // './SelectComponentsTypes';
import { AddIcon, TeaserCard, TeaserCardContent, TeaserCardDescription, TeaserCardSubtitle, TeaserCardTitle } from '@rockwell-automation-inc/ra-meatball';
import { Box, Button, CardMedia, TextField, Typography } from '@mui/material';
interface Props {
    comp: SelectedProduct;
  maxVal?: number;
  onAdd: (product: SelectedCompInfoExtended) => void;
}

const ProductCards = (props: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if(!isNaN(value) && value >= 0) {
      setQuantity(value);
    } else {
      setQuantity(quantity);
    }
  };

  return (
    <TeaserCard
      raVariant="horizontal"
      hover={false}
      sx={{
        height: "128px",
        "&:hover": {
          cursor: "pointer",
          boxShadow: "2px 2px 10px 2px #00000040",
        },
      }}
      style={{ overflow: "initial" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardMedia
        sx={{
          display: "flex",
          alignSelf: "center",
          marginLeft: "1rem",
          height: "5rem",
          minWidth: "2rem",
          backgroundSize: "contain"
        }}
        image={props.comp.photo}
      />
      <TeaserCardContent sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <Box sx={{ width: "60%", textAlign: "left" }}>
          <TeaserCardTitle sx={{ fontSize: "1.2rem" }}>{props.comp.catalogNumber}</TeaserCardTitle>
          <TeaserCardSubtitle sx={{ fontSize: "1rem" }}>{props.comp.templateTitle}</TeaserCardSubtitle>
          <TeaserCardDescription
            sx={{
              fontSize: "1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              "-webkit-line-clamp": "2",
              "-webkit-box-orient": "vertical",
            }}
          >
            {props.comp.description}
          </TeaserCardDescription>
        </Box>
        <Box display="flex" flexDirection="row" sx={{ width: "40%" }}>
          <Box display="flex" flexDirection="column" sx={{ width: "80%" }}>
            <Typography variant="subtitle1" sx={{ fontSize: "1rem" }}><strong>Lead Time: </strong> {(props.comp.estimatedLeadTime + " days") || "N/A"}</Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "1rem" }}><strong>List Price: </strong> {props.comp.listPriceDisplay || "N/A"}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <TextField
              label="Qty"
              type="number"
              size="small"
              InputProps={{
                inputProps: {
                  sx: { textAlign: 'right', pt: 1, pb: 1 }
                }
              }}
              value={quantity}
              sx={{
                mt: 1,
                width: '5rem',
                fieldset: {
                  borderWidth: '1px !important',
                  borderColor: '#E5E6E9 !important'
                },
              }}
              onClick={e => e.currentTarget.focus()}
              onChange={handleInputChange}
            />
            <Box sx={{ height: "32px" }}>
              {
                isHovered && (
                  <Button
                    variant="text"
                    sx={{ mt: 1 }}
                    onClick={() => props.onAdd({
                      id: Math.floor(Math.random() * 100).toString(),
                      catNo: props.comp.catalogNumber,
                      isSelected: true,
                      quantity: quantity,
                      description: props.comp.description
                    })}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <AddIcon fontSize="small" />
                    </Box>
                    <Typography
                      sx={{
                        textAlign: "center",
                        marginLeft: "10px",
                      }}
                    >
                      ADD
                    </Typography>
                  </Button>
                )
              }
            </Box>
          </Box>
        </Box>
      </TeaserCardContent>
    </TeaserCard>
  )
}

export default ProductCards;