import { ActBtnSpecs } from "@csa-core/advisor.controlsystemcore";
import { Theme } from "@rockwell-automation-inc/ra-meatball";


//export enum LayoutActionType {
//    None = 'None',
//    AddModule = 'AddModule',
//    ModeCopy = 'ModeCopy',
//    ModeDelete = 'ModeDelete',
//    MakeDuplex = 'MakeDuplex',
//    MakeSimplex = 'MakeSimplex',
//}

//export enum CopyModeCompat {
//    Match = 'Match',
//    Swap = 'Swap',
//    Mismatch = 'Mismatch'
//}

//export interface ActBtnInfo {
//    action: LayoutActionType;
//    chassis: Chassis;
//    slot: number;
//    ctrPt: Point;
//    copyCat?: string;
//    compat?: CopyModeCompat;
//    event?: PointerEvent;
//    tip?: string;
//}

//export interface ActBtnSpecs {
//    width: number;
//    height: number
//    cornerRadPct: number;
//    lineGapPct: number;
//    imgSizePct: number;
//    strokeWidth: number;
//    stroke: string;
//    fill: string;
//    mouseoverStroke: string;
//    mouseoverFill: string;
//}

export const DfltActBtnSpecs: ActBtnSpecs = {
    width: 40,
    height: 40,
    cornerRadPct: 0.20,
    lineGapPct: 0.10,
    imgSizePct: 0.80,
    strokeWidth: 3,
    stroke: Theme.palette.mediumGrey.main,
    fill: Theme.palette.white.main,
    mouseoverStroke: Theme.palette.darkGrey.main,
    mouseoverFill: Theme.palette.lightGrey.main
};

export const flexHADuplexActBtnSpecs: ActBtnSpecs = {
    width: 80,
    height: 40,
    cornerRadPct: 0.20,
    lineGapPct: 0.10,
    imgSizePct: 0.80,
    strokeWidth: 3,
    stroke: Theme.palette.mediumGrey.main,
    fill: Theme.palette.white.main,
    mouseoverStroke: Theme.palette.darkGrey.main,
    mouseoverFill: Theme.palette.lightGrey.main
};
