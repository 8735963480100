import React, { useEffect, useRef } from 'react';
import { Image, Rect } from 'react-konva';
import { useCachedImage } from '../imageHelp/useCachedImage';
import { Point } from '@csa-core/advisor.controlsystemcore'; // '../types/SizeAndPosTypes';
import { DragDeviceInfo, DropStatus } from '@csa-core/advisor.controlsystemcore'; // '../util/DragAndDropHelp';
import { getDragHighlightProps } from '@csa-core/advisor.controlsystemcore'; // '../util/ImageHighlightHelp';
import CopyDropSymbol from './CopyDropSymbol';
import NoDropSymbol from './NoDropSymbol';
import SwapDropSymbol from './SwapDropSymbol';

const _symbolMargin = 10;
const _minSymbolSize = 20;
const _maxSymbolSize = 60;

type ImageRef = React.ElementRef<typeof Image>;

interface Props {
    dragDev: DragDeviceInfo;
}

const DeviceDragComp = (props: Props) => {

    const [image, imageStatus, /*imgSize*/] = useCachedImage(props.dragDev.imgSrc);
    const imageRef = useRef<ImageRef>(null);

    // IMPORTANT: In order for the Filters used by our
    // highlights to work, our image needs to be cached.
    useEffect(() => {
        if (image && imageRef.current) {
            imageRef.current.cache();
        }
    }, [image])


    const imageReady = (imageStatus === 'loaded');
    const hlProps = imageReady
        ? getDragHighlightProps(props.dragDev)
        : {};

    const symbolSize = Math.max(_minSymbolSize,
        Math.min(props.dragDev.loc.width - (2 * _symbolMargin), _maxSymbolSize));
    const symbolGap = symbolSize / 4;

    const addNoDropSymbol = (props.dragDev.dropStatus === DropStatus.NoDrop);
    const addSwapSymbol = (props.dragDev.dropStatus === DropStatus.DropOkAfterSwap);

    const addCopySymbol = (props.dragDev.copy && (props.dragDev.dropStatus !== DropStatus.NoDrop));
    const copySymPt: Point = addSwapSymbol
        ? { x: props.dragDev.ptCtr.x, y: props.dragDev.ptCtr.y + symbolSize + symbolGap }
        : { ...props.dragDev.ptCtr }

    return (
        <>
            {imageReady
                ? <Image
                    ref={imageRef}
                    image={image}
                    {...props.dragDev.loc}
                    {...hlProps}
                />
                :
                <Rect
                    fill={'lightgray'}
                    stroke={'gray'}
                    strokeScaleEnabled={false}
                    {...props.dragDev.loc}
                />
            }
            {addNoDropSymbol
                ? <NoDropSymbol
                    ptCtr={props.dragDev.ptCtr}
                    radius={symbolSize / 2}
                />
                : null}
            {addSwapSymbol
                ? <SwapDropSymbol
                    ptCtr={props.dragDev.ptCtr}
                    size={symbolSize}
                />
                : null}
            {addCopySymbol
                ? <CopyDropSymbol
                    ptCtr={copySymPt}
                    size={symbolSize}
                />
                : null}
        </>
    );
}

export default DeviceDragComp;
