import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../styles/AppLayout.scss';
import {
    DEV_DEBUG,
    HideRAHeaderOnFirstVScroll,
    IEnvConfig,
    registerCoreEnvConfig,
    RunningStrictMode,
    SkipUnloadDlgInDevMode,
    ToastStartupProgress,
} from '@csa-core/advisor.controlsystemcore'; // '../types/Globals';
import ChassisProjView from './ChassisProjView';
import {
    ModalRequestSpec,
    RegisterModalRequestHandler,
    requestModal
} from '../modals/ModalHelp';
import ModalWrapper from '../modals/ModalWrapper';
import { logger } from '@csa-core/advisor.controlsystemcore'; // '../util/Logger';
import AdvisorFeedback from './AdvisorFeedback';
import {SelectionInfoProvider} from '../context/SelectionInfoContext';
import {
    getWindowTitleFor
} from '@csa-core/advisor.controlsystemcore'; // '../util/PlatformHelp';
import {
    setStartupDetails,
    startupDetails
} from '@csa-core/advisor.controlsystemcore'; // '../redux/slices/StartupInfoSlice';
import { useAppDispatch, useAppSelector } from '@csa-core/advisor.controlsystemcore'; // '../redux/hooks';
import { getPlatformDataWarnings } from '@csa-core/advisor.controlsystemcore'; // '../services/selectionAPIs/PlatformDataLoader';
import { isConfigModified } from '@csa-core/advisor.controlsystemcore'; // '../userProject/UserProjectHelp';
import {
    establishStartupDetails,
    isUnauthorizedStartOk,
    loadStartupPlatforms,
    StartupDetails,
    StartupResult,
    getProjectIndustry
} from '@csa-core/advisor.controlsystemcore'; // '../util/AppStartupHelp';
import { displayAlertMsg, displayUnderConstructionMsg, displayUnexpectedErrorMsg } from '../util/MessageHelp';
import { StatusLevel } from '@csa-core/advisor.controlsystemcore'; // '../types/MessageTypes';
import ToastHost from '../toast/ToastHost';
import toast from '../toast/toast';
import { Id } from 'react-toastify';
import {
    usePSAppSelector,
    selectUser,
    authStatus,
    AuthStatus,
    StdRAHeaders,
    getApiUrl
} from "platform-services";
import { User } from 'oidc-client';
import { configEverSaved } from '@csa-core/advisor.controlsystemcore'; // '../redux/slices/SaveRestoreSlice';
import { IProject } from 'platform-services/dist/interfaces/IProject';
import { ChassisProject, LocAttributeInfo } from '@csa-core/advisor.controlsystemcore'; // '../types/ProjectTypes';
import { persistToLocalStorage, removeLocalPersist } from '@csa-core/advisor.controlsystemcore'; // '../userProject/LocalPersist';
import { getURLParamValue } from '@csa-core/advisor.controlsystemcore'; // '../authentication/util/LocationSaveHelp';
import { ProjGuidUrlParam } from '@csa-core/advisor.controlsystemcore'; // '../platforms/PlatformConstants';
import { updateGuidedSelection } from '@csa-core/advisor.controlsystemcore'; // '../model/GuidedSelection';
import { getLocAttrFromProject } from '@csa-core/advisor.controlsystemcore'; // '../model/ChassisProject';
import { IMessenger, registerUIMessenger } from '@csa-core/advisor.controlsystemcore';
import { startupErrorExit } from './AppViewHelpers';
import config from '../config';


export const touchDevice = matchMedia('(hover: none)').matches;
export let linkExportElement: HTMLAnchorElement | undefined = undefined;

const AppView: React.FC = () => {

    const dispatch = useAppDispatch();

    const cfgEverSaved = useAppSelector(configEverSaved);
    const startDtls = useAppSelector(startupDetails);

    const user = usePSAppSelector(selectUser);
    const authStat = usePSAppSelector(authStatus);

    const activeCfg = useRef<ChassisProject | undefined>(undefined);
    const startupDtlsRequested = useRef<boolean>(false);

    const [raHeaderVisible, setRaHeaderVisible] = useState(true);
    const raHeaderManual = useRef<boolean>(false);

    const [modalOpen, setModalOpen] = useState(false);
    const modalOpenRef = useRef<boolean>(false);
    modalOpenRef.current = modalOpen;

    const modalReqProps = useRef<ModalRequestSpec>();
    const pendingModalReqs = useRef<ModalRequestSpec[]>([]);

    const strictModeFirstRender = useRef<boolean>(RunningStrictMode);

    //useEffect(() => {
    //    logger.warn('AppView States:' +
    //        ' everSaved=' + cfgEverSaved +
    //        ', startDtls: ' + (startDtls ? 'yes' : 'no') +
    //        ', haveUser: ' + (user ? 'yes' : 'no') +
    //        ', authStat: ' + authStat);
    //}, [cfgEverSaved, startDtls, user, authStat])

    // Startup operations.
    useEffect(() => {

        let toastId: Id = '';

        // FINAL callback of startup process.
        const platformsLoadedCallback = (
            dtls: StartupDetails,
            okToStart: boolean,
            errMsg: string
        ) => {

            logger.logStartup('Startup: platforms callback');
            toast.dismiss(toastId);

            // If we got the ok to start...
            if (okToStart) {

                logger.logStartup('Startup: platforms ok, all details ready.');

                // Set our title (browser tab text) to be
                // suitable for our primary platform.
                window.document.title = getWindowTitleFor(dtls.platform);

                // Check for any platform warnings. If any, place
                // them into our state object (for snackbar msgs).
                const warnings = getPlatformDataWarnings();

                // If we have any...
                if (warnings.length > 0) {

                    // Show 6 max.
                    const numToShow = Math.min(warnings.length, 6);

                    // Do a warning toast for each.
                    for (let i = 0; i < numToShow; i++) {
                        toast.warn(warnings[i]);
                    }
                }

                // All startup operations are complete.
                // Pass startup details to our redux state.
                dispatch(setStartupDetails(dtls));

                // We might still get an error message if
                // we requested any non-required platforms
                // for testing purposes that FAILED to load.
                // If we get a message, just pop it up. Real
                // users would never see this. 
                if (DEV_DEBUG) {
                    if (errMsg) {
                        alert('errMsg');
                    }
                }
            }
            else {
                // NOT OK to start. Display an Error message.
                const msg = errMsg
                    ? errMsg
                    : 'Platform load FAILED';
                displayAlertMsg(msg, StatusLevel.Error, 'Startup Error');
            }
        }
         
        const startupDtlsCallback = (result: StartupResult, dtls: StartupDetails) => {

            logger.logStartup('Startup: details callback: ' + result);

            // If we have sufficient details to start...
            if (result === StartupResult.OkToStart) {

                // Call another helper, this time to load all
                // of the platforms referenced in our dtls object.
                // After ALL platform loading is complete, we'll
                // get notified via our platformsLoadedCallback func.
                logger.logStartup('Startup: Loading platforms.');
                loadStartupPlatforms(dtls, toastId, platformsLoadedCallback);
            }
            else {
                toast.dismiss(toastId);
                startupErrorExit(result, dtls);
            }
        }

        const canStart = (): [okToStart: boolean, userForStart: User | undefined] => {

            switch (authStat) {
                case AuthStatus.Authorized:
                    if (user && !user.expired) {
                        return [true, user];
                    }
                    break;

                case AuthStatus.NotLoggedIn:
                    if (isUnauthorizedStartOk()) {
                        return [true, undefined];
                    }
                    break;

                default: break;
            }
            return [false, undefined];
        }

        // If we're running in strict mode, and this is the
        // first (extra) render, just set that flag to false
        // and return. We only want to actually do all of
        // our startup work after the REAL first render.
        if (strictModeFirstRender.current) {
            strictModeFirstRender.current = false;
            logger.logStartup('Startup: deferred for first strict render.');
        }
        else {
            // Determine if it's OK to begin our startup. If so,
            // the 'userForStart' returned by our canStart
            // helper will be UNDEFINED for a user that's not
            // fully authorized (logged in), EVEN if we DO
            // otherwise have a UserManager user.
            const [okToStart, userForStart] = canStart();

            // If so...
            if (okToStart) {
                //if we haven't yet gotten the startup
                // process in motion already...
                if (!startupDtlsRequested.current) {

                    // We only want to do this once.
                    startupDtlsRequested.current = true;

                    if (ToastStartupProgress) {
                        toastId = toast.loading('Startup');
                    }

                    // Call a helper to establish all details we'll need
                    // to get started. Whether it succeeds or not, it will
                    // call our callback function above when it's finished.
                    logger.logStartup('Startup: Establishing details.');
                    establishStartupDetails(userForStart, toastId, startupDtlsCallback);
                }
            }
            else {
                logger.logStartup('Startup: waiting for user');
            }
        }
    }, [dispatch, user, authStat]);


    useEffect(() => {
        const forwardToastLoadProgress = (id: Id, text: string) => {
            toast.loadProgress(id, text);
        }

        const forwardToastSaved = (id: Id, text: string) => {
            toast.saved(id, text);
        }

        const forwardToastSaving = (text: string): Id => {
            return toast.saving(text);
        }

        const registerCoreInterfaces = () => {
            // Note: we originally were setting the interface toast
            // functions directly (ie toastSaving = toast.saving).
            // However, something goes wrong with the function
            // pointers where they do NOT have the original toast
            // class instance. Defined 'forward' funcs to address this. 
            const messenger: IMessenger = {};
            messenger.displayAlertMsg = displayAlertMsg;
            messenger.displayUnderConstructionMsg = displayUnderConstructionMsg;
            messenger.displayUnexpectedErrorMsg = displayUnexpectedErrorMsg;
            messenger.toastLoadProgress = forwardToastLoadProgress;
            messenger.toastSaved = forwardToastSaved;
            messenger.toastSaving = forwardToastSaving;

            registerUIMessenger(messenger);

            // Set the config and api url in the Core.
            const iEnvCore: IEnvConfig = {
                config: config,
                getApiUrl: getApiUrl,
            }

            registerCoreEnvConfig(iEnvCore);
        }

        // Function that gets called whenever a requestModal
        // or any of the helper functions that use that are called.
        const doModalRequest = (reqProps: ModalRequestSpec): void => {

            // If the modal is (or is STILL) open...
            if (modalOpenRef.current) {
                // Add the request to our 'pending' queue.
                // See the useEffect directly below this
                // one for how we deal with pendings.
                pendingModalReqs.current.push(reqProps);
            }
            else {
                // Not open. Set our current request to
                // this one and set the modal-open state.
                modalReqProps.current = reqProps;
                setModalOpen(true);
            }
        };

        RegisterModalRequestHandler(doModalRequest);

        registerCoreInterfaces();

        // 2023.11.14 Store the global link element. Used
        // to export settings templates.
        linkExportElement = document.getElementById('LinkExportElement') as HTMLAnchorElement;
    }, []);

    // This useEffect watches for changes in the modalOpen state.
    useEffect(() => {
        // If the modal is currently closed...
        if (modalOpen === false) {

            // And we have any 'pending' modal requests...
            if (pendingModalReqs.current.length > 0) {

                // Pop the next in line off of the
                // front of the pending queue.
                const nextPending = pendingModalReqs.current.shift();

                // And call the requestModal (again) for it.
                if (nextPending) {
                    requestModal(nextPending);
                }
            }
        }

    }, [modalOpen])

    const onModalClosed = useCallback((status: number) => {
        if (modalReqProps.current && modalReqProps.current.callback) {
            modalReqProps.current.callback(status,
                modalReqProps.current.requestorData);
        }
        setModalOpen(false);
        modalReqProps.current = undefined;
    }, []);


    useEffect(() => {

        const handleDesktopUnload = (e: BeforeUnloadEvent) => {
            // TODO: Persist State.

            // Setting the event's returnValue to a 'truthy' value
            // will pop a browser specific dialog asking if the
            // user wants to leave the page. The message below
            // PROBABLY WILL NOT be the message displayed.
            //e.returnValue = 'Changes you have made will not be saved.';

            e.returnValue = isConfigModified()
                ? 'Changes you have made will not be saved.'
                : '';
        }

        const handleMobileUnload = (e: Event) => {
            e; // prevent warning.
            // TODO: Persist State.

            // Note: We CANNOT prevent a mobile user from leaving
            // the page, but we can persist their session state.
        }

        if (!SkipUnloadDlgInDevMode) {
            if (touchDevice)
                document.addEventListener('visibilitychange', handleMobileUnload);
            else
                window.addEventListener("beforeunload", handleDesktopUnload);
        }

        return () => {
            if (!SkipUnloadDlgInDevMode) {
                if (touchDevice)
                    document.removeEventListener('visibilitychange', handleMobileUnload);
                else
                    window.removeEventListener("beforeunload", handleDesktopUnload);
            }
        }

    }, []);

    const onFirstVScroll = useCallback(() => {
        // One of our views is reporting that it has
        // detected that vertical scrolling is possible
        // in its right panel. Note: Views that actually
        // care about this should keep track of reports,
        // so they each call back at most one time.
        // If the auto-hide feature is enabled at all...
        if (HideRAHeaderOnFirstVScroll) {
            //If we're not already in
            // 'manual mode' for header show/hide...
            if (!raHeaderManual.current) {
                // Set manual mode, so we never do 
                // an auto-collapse more than one time.
                raHeaderManual.current = true;

                // If the header is currently visible...
                if (raHeaderVisible) {
                    // Set our state, which will
                    // force a re-render (with the
                    // header hidden).
                    setRaHeaderVisible(false);
                }
            }
        }
    }, [raHeaderVisible]);

    const onCfgEstablished = useCallback((cfg: ChassisProject) => {
        activeCfg.current = cfg;
    }, []);


    const onProjectChanged = (proj: IProject | null) => {

        // Start our new StartupDetails as undefined.
        let newDetails: StartupDetails | undefined = undefined;
        let prevIndustryID = '';

        // We have a series of local helper functions, which
        // are called within this function (onProjectChanged).

        /// Local Function
        const _finalizeProjectChange = () => {
            if (newDetails && activeCfg.current) {
                dispatch(setStartupDetails(newDetails));

                // Take a new snapshot.
                persistToLocalStorage(activeCfg.current);
            }
        } /// End Local Function

        /// Local Function
        const _onGuidedSelLoaded = (success: boolean, locAttrInfo: LocAttributeInfo | undefined) => {
            if (success && locAttrInfo && newDetails && activeCfg.current) {
                // Set the industry in the details AND in
                // our current project config, which is
                // displayed in the Design Page combo.
                newDetails.industryID = locAttrInfo.industryID;
                activeCfg.current.config.industryID = locAttrInfo.industryID;
            }
            else if (locAttrInfo) {
                locAttrInfo.industryID = prevIndustryID;
            }
                
            _finalizeProjectChange();
        } /// End Local Function

        /// Local Function
        const _updateIndustryOnProjectChange = async () => {
            if (newDetails && newDetails.projectGuid && activeCfg.current) {
                // Get our current location.
                const loc = getLocAttrFromProject(activeCfg.current);
                if (loc) {
                    // Update the 'new details' with the new
                    // project's industry. If the project does
                    // not have an Industry ID, it is set to ''.
                    newDetails.industryID = await getProjectIndustry(newDetails.projectGuid);
                    
                    // If our current location has a different industry...
                    if (loc.industryID !== newDetails.industryID) {
                        // If the new project did NOT have an industry...
                        if (!newDetails.industryID) {
                            // Finalize the new project and leave the
                            // location industry as is.
                            _finalizeProjectChange();
                            return;
                        }
                        else {
                            // The industry has changed. Store the new
                            // industry ID and update the location. Call
                            // a helper to update the Guided Selection.
                            prevIndustryID = loc.industryID;
                            loc.industryID = newDetails.industryID;
                            updateGuidedSelection(loc, _onGuidedSelLoaded);
                            return;                          
                        }
                    }
                }
            }

            _finalizeProjectChange();
        } /// End Local Function

        // If we have enough info...
        if (startDtls && proj && activeCfg.current) {

            // If the guid if the incoming proj is
            // different than what we're already using...
            if (proj.guid !== startDtls.projectGuid) {

                // Sanity check. If our config has EVER been
                // saved, the projects button up in the header
                // area should NOT show the split extension that
                // allows a project change. If we're ok.
                if (!cfgEverSaved) {
                    //logger.warn('project changed to: ' + proj.guid);

                    // Remove the last snapshot of our config (if there
                    // is one) using the OLD projectGuid.
                    removeLocalPersist(startDtls.projectGuid, activeCfg.current);

                    // Copy the current startup details.
                    // Replace the project Guid with the
                    // selected project's Guid and clear
                    // the Industry ID.
                    newDetails = { ...startDtls };
                    newDetails.projectGuid = proj.guid;
                    newDetails.industryID = '';

                    // Call a helper to determine if our current
                    // 'location attribute info' needs to be
                    // updated based on the the new project's
                    // Industry ID (if it has one).
                    _updateIndustryOnProjectChange();
                }
                else {
                    // Ignore and log the unexpected change.
                    logger.error('Unexpected onProjectChanged');
                }
            }
        }
    }

    const showProjBtn = ((startDtls !== undefined) &&
        (authStat === AuthStatus.Authorized));
    const reqProjGuid = startDtls
        ? startDtls.projectGuid
        : getURLParamValue(ProjGuidUrlParam);

    //logger.warn('AppView rnd: ' + window.location.href);
    //logger.warn('AppView showProjBtn: ' + showProjBtn);
    //if (reqProjGuid) {
    //    logger.warn('AppView req projGuid: ' + reqProjGuid);
    //}

    return (
        <SelectionInfoProvider>
            <div className='app-view'>
                <StdRAHeaders
                    showProjectButton={showProjBtn}
                    useSplitBtnVersion={!cfgEverSaved}
                    onProjectChange={onProjectChanged}
                    requestedProjectGuid={reqProjGuid}
                />
                <ChassisProjView
                    onFirstVScroll={onFirstVScroll}
                    onConfigEstablished={onCfgEstablished}
                />
                <AdvisorFeedback />
            </div>
            {(modalOpen && modalReqProps.current)
                ? < ModalWrapper
                    open={modalOpen}
                    onClose={onModalClosed}
                    reqProps={modalReqProps.current}
                />
                : null}
            <div className="app-element-hidden" >
                <a id="LinkExportElement" href="https:/dummy.com" download>Not Visible</a>
            </div>
            <ToastHost />
        </SelectionInfoProvider>
    );

}

export default AppView;
