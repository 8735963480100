import { GeneralUIImplBase } from "../../../implementation/GeneralUIImplBase";
import { ChassisCompProps } from '@csa-core/advisor.controlsystemcore'; // "../../../implementation/ImplGeneral";
import { ActBtnInfo, LayoutActionType } from '@csa-core/advisor.controlsystemcore'; // "../../../types/LayoutActions";
import { DfltActBtnSpecs } from "../../../types/LayoutActions";
import { Chassis, ChassisProject, Rack, SelectableDevice } from '@csa-core/advisor.controlsystemcore'; // "../../../types/ProjectTypes";
import { getLocCenter, offsetLoc } from '@csa-core/advisor.controlsystemcore'; // "../../../util/GeneralHelpers";
import CLXChassisComp from "../components/CLXChassisComp";
import { clxConfigureChassis } from "../config/CLXChassisConfig";
import { CLXLayoutInfo } from '@csa-core/advisor.controlsystemcore'; // "../types/CLXTypes";


class CLXGeneralUIImpl extends GeneralUIImplBase {

	getChassisRenderer(): React.FC<ChassisCompProps> {
		return CLXChassisComp;
	}

	getActionBtnInfo(
		action: LayoutActionType,
		rack: Rack,
		slotNum: number): ActBtnInfo {

		const layout = rack.chassis.layout as CLXLayoutInfo;
		const slotLoc = { ...layout.slotLocs[slotNum] };
		offsetLoc(slotLoc, rack.ptOrg);
		const pt = getLocCenter(slotLoc);
		pt.y += DfltActBtnSpecs.height;
		return {
			action: action,
			chassis: rack.chassis,
			slot: slotNum,
			ctrPt: pt
		};
	}

	hasGetActBtnInfo(__platform: string): boolean {
		return true;
	}

	configureChassis(
		project: ChassisProject,
		platform: string,
		selectChassisCallback: (chassis: Chassis | undefined) => void,
		selectDeviceCallback: (device: SelectableDevice | undefined) => void,
		contentChangedCallback: () => void,
		chassis?: Chassis
	): void {
		return clxConfigureChassis(
			project,
			platform,
			selectChassisCallback,
			selectDeviceCallback,
			contentChangedCallback,
			chassis
		);
	}
}

export const createCLXGeneralUIImpl = (): GeneralUIImplBase => {
	return new CLXGeneralUIImpl();
}

