import { EngInfoModule } from "../engData/EngineeringInfo";
import { addModulesToChassis } from "../implementation/ImplGeneral";
import { getProjectFromChassis, updateAllChassis } from "../model/ChassisProject";
import { ModalStatus } from "../types/ModalTypes";
import { Chassis } from "../types/ProjectTypes";
import { getEngInfoForComp } from "../util/EngInfoHelp";


export interface CompChoiceDetail {
    catNo: string;
    type: string;
    subType1: string;
    subType2: string;
    description: string;
    imgSrc?: string;
}


export interface SelectedProduct {

    id: string,
    catalogNumber: string,
    description: string,
    photo: string,
    templateId: string,
    prodLifeCycleStatus: string,
    estimatedLeadTime: number,
    pricing: string,
    listPrice: number,
    listPriceDisplay: string,
    listPriceCurrency: string,
    stockStatus: string,
    stockStatusDisplay: string,
    templateTitle: string
}
export interface SelectedCompInfo {
    catNo: string;
    quantity: number;
    description: string;
    isSelected: boolean
}

export interface SelectedCompInfoExtended extends SelectedCompInfo {
    id: string;
}
export interface PostProductGetProducts {
    catalogNumbers: string[];
    includePimAttributes: boolean
}

export interface ProductSelectionAttribute {
    productId: string;
    catalogNumber: string;
    id: number;
    name: string;
    description: string;
    value: string;
    attrSeqIdx: number;
    valSeqIdx: number;
    templateId: string;
    minValue: number;
    maxValue: number;
}
export interface PostProductGetProductsProduct {
    id: string;
    catalogNumber: string;
    templateId: string;
    description: string;
    displayPrice: string;
    regionalStock: string;
    estimatedLeadTime: number;
    selectionAttributes: ProductSelectionAttribute[];
}
export interface PostProductGetProductsResponse {
    success: boolean;
    message: string;
    products: PostProductGetProductsProduct[];
}


export interface SelectComponentsProps {

    // We'll most likely need some way of passing along
    // run type to the shared component. Ex: Dev, QA, Prod.
    // This is just a reminder for now.
    appStage?: unknown;

    // Set by Client
    // Three options for specifying the 'range'
    // from which selections are to be made.
    // 1. RAISE Template ID
    templateID?: string;

    // 2. An array of catalog numbers
    catNoChoices?: string[];

    // 3. Similar to the similar catalog number array,
    //    but each entry contains additional product info.
    detailedChoices?: CompChoiceDetail[];

    // Optional filters possible. NOT
    // clearly defined at this time.
    filters?: string[];

    // If provided, specifies the maximum
    // number of component selections allowed.
    maxQuantity?: number;

    // If provided, specifies a check function
    // to be called as the selection set changes.
    checkResults?: (selections: SelectedCompInfo[]) => void;

    // Set by SelectComponents
    // If checkResults is used, .selections
    // property is expected to reflect current
    // selections and quantities.
    selections: SelectedCompInfo[];
}

export interface SelectCompsExchangeData {
    chassis: Chassis;
    initialSlot: number;
    contentChangedCallback: () => void,
    selectorProps: SelectComponentsProps;
}

const mapModCatToSlots = new Map<string, number>();

export const getSlotsOccupied = (platform: string, catalog: string) => {
    // If we have it in the map...
    const slotsOccupied = mapModCatToSlots.get(catalog);
    if (slotsOccupied)
        return slotsOccupied;

    // Look it up.
    return getSelectedCompSlotQuantity(platform, [{ catNo: catalog, quantity: 1, description: '', isSelected: false }]);
}

export const getSelectedCompSlotQuantity = (platform: string, sels: SelectedCompInfo[]): number => {
    let slotsQty = 0;
    sels.forEach(sel => {
        const slotsOccupied = mapModCatToSlots.get(sel.catNo);
        // If we have it in the map...
        if (slotsOccupied)
            slotsQty += sel.quantity * slotsOccupied;
        else {
            // Look up how many slots the module occupies.
            // For things that are not module, we default
            // them to '1'.
            let slotsUsedSet = false;
            const info = getEngInfoForComp(platform, sel.catNo);
            if (info && info.isModule) {
                // Cast to module info...
                const modInfo = info as EngInfoModule;
                if (modInfo.slotsUsed > 0) {
                    mapModCatToSlots.set(sel.catNo, modInfo.slotsUsed);
                    slotsQty += sel.quantity * modInfo.slotsUsed;
                    slotsUsedSet = true;
                }
            }
            // If we did not find it as a module...
            if (!slotsUsedSet) {
                // Set the slots occupied to 1.
                mapModCatToSlots.set(sel.catNo, 1);
                slotsQty += sel.quantity
            }
        }
    });

    return slotsQty;
}

// Callback from modal completion.
export const selectCompsCallback = (status: number, data?: object) => {

    // We only care if we got a confirm. If so...
    if (status === ModalStatus.Confirmed) {

        // Cast the data back to our exchange data.
        const exchData = data as SelectCompsExchangeData;

        // If we can...
        if (exchData) {
            // Get the selector props out of that.
            const selProps = exchData.selectorProps;

            // Determine the total number of modules to add.
            const totalQty = getSelectedCompSlotQuantity(exchData.chassis.platform, selProps.selections);

            // If any...
            if (totalQty > 0) {
                // Call a helper to add what was selected.
                addModulesToChassis(exchData.chassis, selProps.selections,
                    totalQty, exchData.initialSlot);

                // Notify that the project has changed.
                exchData.contentChangedCallback();

                const project = getProjectFromChassis(exchData.chassis);
                if (project)
                    updateAllChassis(project.content);
            }

            // Toss our cache...
            mapModCatToSlots.clear();
        }
    }
}
