import React from 'react';
import './DocumentLinks.scss';
import '../../styles/Meteor.scss'
import { DocLinkGroupInfo } from '@csa-core/advisor.controlsystemcore'; // '../../model/DeviceDetail';
import DocumentLink from './DocumentLink';


interface Props {
    group: DocLinkGroupInfo;
}

const DocumentLinkGroup = (props: Props) => {

    let nextKey = 1;

    return (
        <div className="document-link-group">
            {(props.group.title && props.group.title.length)
                ? <span className="a-type__subTitle1">
                    {props.group.title}
                </span>
                : null}
            {props.group.links.map(linkInfo => {
                return (
                    <DocumentLink
                        key={nextKey++}
                        info={linkInfo}
                    />
                )
            })}
        </div>
    );

}

export default DocumentLinkGroup;
