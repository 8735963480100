import React, { useState, useRef } from 'react';
import Konva from 'konva';
import { Group, Rect, Image } from 'react-konva';
import { DfltActBtnSpecs, flexHADuplexActBtnSpecs } from '../../types/LayoutActions';
import { LayoutActionType, ActBtnSpecs, CopyModeCompat, ActBtnInfo } from '@csa-core/advisor.controlsystemcore'; // '../../types/LayoutActions';
import { LocAndSize, Size } from '@csa-core/advisor.controlsystemcore'; // '../../types/SizeAndPosTypes';
import Clipboard from '../images/Clipboard.png';
import ClipboardBlue from '../images/ClipboardBlue.png';
import ClipboardRed from '../images/ClipboardRed.png';
import MakeSimplex from '../images/BtnMakeSimplex.png';
import MakeDuplex from '../images/BtnMakeDuplex.png';
import TrashcanImage from '../images/Trashcan.png';
import { LogRender } from '@csa-core/advisor.controlsystemcore'; // '../../types/Globals';
import { logger } from '@csa-core/advisor.controlsystemcore'; // '../../util/Logger';
import { useCachedImage } from '../../imageHelp/useCachedImage';
import { useAppDispatch } from '@csa-core/advisor.controlsystemcore'; // '../../redux/hooks';
import { setLayoutViewTip, ViewTipInfo } from '@csa-core/advisor.controlsystemcore'; // '../../redux/slices/LayoutViewTipSlice';
import { establishActBtnScale } from './ActionButtons';

const _extraSizeForModeBtns = 20;

const getImageSource = (btnInfo: ActBtnInfo) => {
    switch (btnInfo.action) {
        case LayoutActionType.ModeCopy:
            switch (btnInfo.compat) {
                case CopyModeCompat.Mismatch:
                    return ClipboardRed;

                case CopyModeCompat.Swap:
                    return ClipboardBlue;

                default:
                    return Clipboard;
            }

        case LayoutActionType.MakeDuplex:
            return MakeDuplex;

        case LayoutActionType.MakeSimplex:
            return MakeSimplex;

        case LayoutActionType.ModeDelete:
        default:
            return TrashcanImage;
    }
}

const getDefaultSpecs = (btnInfo: ActBtnInfo): ActBtnSpecs => {
    switch (btnInfo.action) {
        case LayoutActionType.MakeDuplex:
        case LayoutActionType.MakeSimplex:
            return flexHADuplexActBtnSpecs;
        default:
            break;
    }

    return DfltActBtnSpecs;
}

interface Props {
    btnInfo: ActBtnInfo;
    onClick: (actBtnInfo: ActBtnInfo) => void;
    btnSpecs?: ActBtnSpecs;
}

const ModeActionButton = ( props : Props ) => {
    const [ isMouseover, setIsMouseover ] = useState( false );
    const dispatch = useAppDispatch();

    const [ image, /*imageStatus*/, /*imgSize*/ ] = useCachedImage( getImageSource( props.btnInfo ) );

    // 2024.8 Depending on the initial stage scale
    // for the platform, adjust our button specs.
    const specsScale = useRef<number>( establishActBtnScale( props.btnInfo.chassis.platform ));
    const specRef = props.btnSpecs ? props.btnSpecs : getDefaultSpecs( props.btnInfo );
    const specs : ActBtnSpecs = {  
        ...specRef,
        width: specRef.width * specsScale.current,
        height: specRef.height * specsScale.current
    };

    const btnSize : Size = {
        width: specs.width + _extraSizeForModeBtns,
        height: specs.height + _extraSizeForModeBtns
    };

    const imgSize: Size = {
        width: btnSize.width * specs.imgSizePct,
        height: btnSize.height * specs.imgSizePct,
    };

    const btnCtr = props.btnInfo.ctrPt;

    const rctProps = {
        x: btnCtr.x - (btnSize.width / 2),
        y: btnCtr.y - (btnSize.height / 2),
        width: btnSize.width,
        height: btnSize.height,
        cornerRadius: btnSize.height * specs.cornerRadPct
    };

    const imgLoc: LocAndSize = {
        x: btnCtr.x - (imgSize.width / 2),
        y: btnCtr.y - (imgSize.height / 2),
        width: imgSize.width,
        height: imgSize.height
    };

    const commonProps = {
        stroke: isMouseover ? specs.mouseoverStroke : specs.stroke,
        fill: isMouseover ? specs.mouseoverFill : specs.fill,
        strokeWidth: specs.strokeWidth
    };


    const onPointerDown = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;
        e.evt.stopPropagation();
        dispatch(setLayoutViewTip(undefined));
    }

    const onPointerClick = (e: Konva.KonvaEventObject<PointerEvent>) => {
        // Consume the event so it doesn't
        // bubble up to our layout view.
        e.cancelBubble = true;
        e.evt.stopPropagation();

        // Then notify our parent we were clicked.
        props.onClick(props.btnInfo);
    }

    const onMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'pointer';
        }
        setIsMouseover(true);

        if (props.btnInfo.tip) {
            const tip : ViewTipInfo = { text: props.btnInfo.tip, rcClient: e.target.getClientRect() };
            dispatch(setLayoutViewTip(tip));
        }
    }

    const onMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (stage) {
            stage.container().style.cursor = 'default';
        }
        setIsMouseover(false);
        dispatch(setLayoutViewTip(undefined));
    }


    if (LogRender.ActButtons) {
        logger.logRender('ModeActionButton');
    }


    return (
        <Group
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onPointerDown={onPointerDown}
            onPointerClick={onPointerClick}
        >
            <Rect
                {...rctProps}
                {...commonProps}
            />
            <Image
                image={image}
                {...imgLoc}
            />
        </Group>
    );

}

// Note: There's no point in memo-izing these at
// this point, and there is a small cost we avoid.
// At present, each time we're used, we're getting
// a NEW btnInfo prop, so we'd never actually use
// the memo as things are currently set up.
export default ModeActionButton;
//export default React.memo(ModeActionButton);


