import { getChassisNetworkInfo, getChassisProperties, getChassisRendInfo } from "../implementation/ImplGeneral";
import { getRedundantChassisNameAndID } from "../model/ChassisProject";
import {
    PlatformCLX,
    PlatformCpLX,
    PlatformFlex,
    PlatformFlexHA,
    PlatformMicro
} from "../platforms/PlatformConstants";
import { Chassis } from "../types/ProjectTypes";
import { LocAndSize, Size } from "../types/SizeAndPosTypes";
import { BOMItemExch, ChassisPropertiesExch } from "../userProject/UserProjectTypes";
import { LogMsgLevel } from "./ProjectLog";


export interface RendBorder {
    stroke: string;
    strokeWidth?: number;
    strokeScaleEnabled?: boolean;
}

export interface RendPortion {
    loc: LocAndSize;
    image: string;
    border?: RendBorder;
    slot?: number;
}

export interface RendInfo {
    size: Size;
    simpleImage?: string;
    els?: RendPortion[];
}

export interface NetCommInfo {

    // Catalog number of contained network-connectable
    // module. Ex: '1756-EN2T' inside of a CLX chassis.
    // Used to 'look up' supported port information.
    catNo?: string;

    // Id of the module. For CSA, this would be the
    // guid of the ChassisModule itself. Used to
    // distinguid multiple comms of the same type.
    id?: string;

    // Used only if comm falls on a secondary bank.
    bank?: number;

    slot?: number;

    // If provided, location of the module,
    // relative to the overall rendered size.
    loc?: LocAndSize;
}

const DeviceMsgLevel = {
    Error: 'ERROR',
    Warning: 'WARNING',
}

interface DeviceMessage {
    level: string;
    message: string;
}

export interface DevMetadata {
    rendInfo?: RendInfo[];
    ctlrCommsSpt?: unknown;
    remCommsReqd?: unknown;
    chassisDetails?: ChassisPropertiesExch;
    netCommInfo?: NetCommInfo[];
    messages?: DeviceMessage[];
}

export interface ChassisFmtForMdTp {
    version?: number;
    guid: string;
    devMetadata: DevMetadata;
}

export const getSysDsgDefChassisImg = (platform: string): string | undefined => {
    switch (platform) {
        case PlatformCLX:
            return '1756DefChassis.png';
        case PlatformCpLX:
            return '5069DefChassis.png';
        case PlatformFlex:
            return '5094DefChassis.png';
        case PlatformFlexHA:
            return '5015DefChassis.png';
        case PlatformMicro:
            return '2080DefChassis.png';
        default:
            break;
    }

    return undefined;
}

const _getDevMsgLevel = (logMsgLevel: LogMsgLevel): string => {
    switch (logMsgLevel) {
        case LogMsgLevel.error:
            return DeviceMsgLevel.Error;

        case LogMsgLevel.warning:
            return DeviceMsgLevel.Warning;

        default:
            return '';
    }
}

const addDevMetadataMsgs = (chassis: Chassis, devMd: DevMetadata) => {
    if (chassis.statusLog && chassis.statusLog.messages.length) {
        const mdMsgs = new Array<DeviceMessage>();
        chassis.statusLog.messages.forEach(logMsg => {
            const devMsgLevel = _getDevMsgLevel(logMsg.level);
            if (devMsgLevel) {
                mdMsgs.push({
                    level: devMsgLevel,
                    message: logMsg.message
                });
            }
        });
        if (mdMsgs.length > 0) {
            devMd.messages = mdMsgs;
        }
    }
}

export const getSlotRenderLoc = (
    rendInfo: RendInfo[],
    slot: number
): [bank: number, loc: LocAndSize | undefined] => {

    for (let bank = 0; bank < rendInfo.length; bank++) {
        const info = rendInfo[bank];
        if (info.els) {
            for (let elIdx = 0; elIdx < info.els.length; elIdx++) {
                if (info.els[elIdx].slot === slot) {
                    return [bank, info.els[elIdx].loc];
                }
            }
        }
    }
    return [-1, undefined];
}

export const getChassisMetadata = (chassis: Chassis, red: boolean, chassisBomItExHdr?: BOMItemExch): string => {

    const rendInfo = getChassisRendInfo(chassis);

    const devMd: DevMetadata = {
        rendInfo: rendInfo,
    }

    if (chassis.ctlrCommsSpt) {
        devMd.ctlrCommsSpt = { ...chassis.ctlrCommsSpt };
    }

    if (chassis.remCommsReqd) {
        devMd.remCommsReqd = { ...chassis.remCommsReqd };
    }

    devMd.chassisDetails = getChassisProperties(chassis, red, chassisBomItExHdr);

    const netInfo = getChassisNetworkInfo(chassis, rendInfo, red);
    if (netInfo && (netInfo.length > 0)) {
        devMd.netCommInfo = netInfo;
    }

    addDevMetadataMsgs(chassis, devMd);

    // Call a common function to get 
    // the redundant chassis ID.
    let chasId = chassis.id;
    if (red)
        [, chasId] = getRedundantChassisNameAndID(chassis);

    // Bundle the current version, the chassis guid,
    // and the DevMetadata into a single object.
    // NOTE: The .version property's FIRST use is for
    // version 2. CSA configurations saved prior will
    // NOT have a .version property.
    const dataForTp: ChassisFmtForMdTp = {
        version: 2,
        guid: chasId,
        devMetadata: devMd
    }

    const tp = JSON.stringify(dataForTp);
    return tp;
}

export const sortRendPortions = (els: RendPortion[]) => {
    els.sort((a, b) => {
        return (a.loc.x < b.loc.x) ? -1 : 1;
    });
}

const verifyPackable = (els: RendPortion[]): boolean => {

    // Init the previous left var we'll
    // use for position comparison.
    let prevLeft = 0;

    // Walk all of the els. For each...
    for (let idx = 0; idx < els.length; idx++) {

        // Get the contained loc.
        const loc = els[idx].loc;

        // If its left side is to the left of
        // the left side of the previous, these
        // els are NOT packable.
        if (loc.x < prevLeft) {
            return false;
        }

        // If we're still here, this one's x becomes
        // the prev left for the next iteration.
        prevLeft = loc.x;
    }

    // Packable.
    return true;
}

// WARNING: Caller guarantees that incoming portion locs are
// in left to right order. If not, the function does nothing.
export const packRendPortions = (els: RendPortion[]): number => {

    let lastRight = 0;

    // We won't do anything unless we have any portion
    // elements, AND they're verified to be packable. 
    // If so...
    if (els.length && verifyPackable(els)) {

        // Get the loc from the first portion.
        let loc = els[0].loc;

        // Initialize our 'last' right to
        // the first one's right side.
        lastRight = loc.x + loc.width;

        // Then walk any/all others. For each...
        for (let idx = 1; idx < els.length; idx++) {
            // Get the portion's current loc.
            loc = els[idx].loc;

            // Set the loc's x to match the
            // right side of the prev loc.
            loc.x = lastRight;

            // Our loc's right side becomes our
            // 'last' right for the next iteration.
            lastRight = loc.x + loc.width;
        }
    }

    // Return our last known right.
    return lastRight;
}

