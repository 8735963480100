/* eslint-disable */

import { AxiosRequestConfig, AxiosResponseHeaders } from "axios";

export class ServiceResponse {
  data: any;
  status: number;
  statusText: string;
  headers: AxiosResponseHeaders;
  config: AxiosRequestConfig;

  constructor(res: any) {
    this.data = res.data;
    this.status = res.status;
    this.statusText = res.statusText;
    this.headers = res.headers;
    this.config = res.config;
  }

  getData() {
    return this.data;
  }

  getStatusCode() {
    return this.status;
  }

  isSuccessful() {
    const statusCode = this.getStatusCode();
    return statusCode > 199 && statusCode < 400;
  }
}
export interface ResponseModel {
  success: boolean;
  message: string;
}