
//export interface UserProject {
//    id: number,
//    guid: string;
//    name: string;
//    createdDateUtc: string;
//    lastModifiedDateUtc: string;
//    lastOpenedDateUtc: string;
//    softDeleteDateUtc: string;
//    notes: string;
//    createdByUserId: string;
//    users: unknown[];
//}

export enum BomItemSourceType {
    Unknown = 'Unknown',
    RaiseWildCard = 'RaiseWildCard',
    RaiseExactMatchCatalogNumber = 'RaiseExactMatchCatalogNumber',
    RaiseExactMatchPartNumber = 'RaiseExactMatchPartNumber',
    ProposalWorksExactMatchUserDefined = 'ProposalWorksExactMatchUserDefined',
    PstApiExactMatch = 'PstApiExactMatch',
    CrossworksExactMatch = 'CrossworksExactMatch',
    ProposalWorksGroup = 'ProposalWorksGroup'
}

export enum BomItemProductType {
    Product = 'Product',
    Accessory = 'Accessory',
    SparePart = 'SparePart'
}

export interface BOMItemExch {
    name: string;
    templateTitle: string;
    description: string;
    quantity: number;
    sourceType: BomItemSourceType;
    productType: BomItemProductType;
    configThumbprint: string;
    listPrice: number;
    photo: string;
    locale: string;
    notification: string;
    userNotes: string;
    children?: BOMItemExch[];
}


// JSON expectation for both POST and PUT
// versions of Configuration API.
export interface ConfigurationExchange {

    // Configuration ID. Same as id in ChassisProject
    id: string;

    // Our configuration name. Same as proj.config.projectName
    name: string;

    // Guid ID of higher level user project, not to be
    // confused with our ChassisProject's internal id.
    projectId: string;

    createdDateUtc?: string;
    lastOpenedDateUtc?: string;

    // Note: softDeleteDateUtc is normally
    // null. Anything OTHER than null indicates
    // that the configuration has been deleted.
    softDeleteDateUtc?: string;

    lastModifiedDateUtc?: string;

    // Same as proj.config.installLocID
    country: string;

    hasChanges: boolean;

    // Whatever we want to get BACK if/when
    // the user EDIT's this configuration.
    configThumbprint: string;

    // Standard form of BOM gleaned from
    // our project for use by anyone above
    // us. On EDIT, we don't care about getting
    // this back.
    bomItems: BOMItemExch[];
}

export interface ProjectItem {
    projectId: number;
    projectGuid: string;
    subSystemType: string;
    subSystemId: string;
    name: string;
    createdDateUtc: string;
    lastModifiedDateUtc: string;
    lastOpenedDateUtc: string;
    softDeleteDateUtc: string;
    sequenceNumber: number;
}

export interface ChassisPropertiesExch {
    deviceID: string;
    deviceName: string;
    headers: ChassisPropHeaderExch[];
}

export interface ChassisPropHeaderExch {
    headerName: string;
    catalogNumber?: string;
    headers: ChassisPropHeaderExch[];
    items: ChassisPropItemExch[];
}

export interface ChassisPropItemExch {
    name: string;
    value: string;
}
