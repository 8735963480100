import React, { ReactNode, useRef } from 'react';
import '../../styles/Meteor.scss'
import { LinearProgress } from '@mui/material';
import { UsageItemProgbarReplacement, UsageLevelStatus } from '@csa-core/advisor.controlsystemcore'; // '../../types/ProjectTypes';
import './PerformanceInfo.scss';
import { Theme } from '@rockwell-automation-inc/ra-meatball';
import { MessageCategory, MessageType, StatusLevel } from '@csa-core/advisor.controlsystemcore'; // '../../types/MessageTypes';
import { ModalContentTipIconBtn } from '../../modals/ModalContentTipIconBtn';
import { makePanelMsg } from '@csa-core/advisor.controlsystemcore'; // '../../platforms/common/ChassisConfig';


const getColor = (status: UsageLevelStatus): string => {
    switch (status) {
        case UsageLevelStatus.OK:
            return Theme.palette.success.main;

        case UsageLevelStatus.Warning:
            return Theme.palette.warning.main;

        default:
            return Theme.palette.error.main;
    }
}


interface Props {
    label: string;
    pct: number;
    text: string;
    status: UsageLevelStatus;
    progWidth?: number;
    boldLabel?: boolean;
    progbarReplacement?: UsageItemProgbarReplacement;
}

const UsageItem = (props: Props) => {

    const _iconButton = useRef<ReactNode>(null);
    const _lastTip = useRef<string>('');

    const repl = props.progbarReplacement;
    if (repl) {
        // If we have tip text and it has changed...
        if (repl.replTipText && _lastTip.current !== repl.replTipText) {
            _lastTip.current = repl.replTipText;
            const pm = makePanelMsg(_lastTip.current, repl.replTipStatus, MessageCategory.General, MessageType.GeneralInfo);
            _iconButton.current = <ModalContentTipIconBtn messages={[pm]} iconWidth={'12px'} />
        }
    }

    const progBarValue = props.pct * 100;
    const ctlColor = getColor(props.status);
    const textColor = (props.status === UsageLevelStatus.Error)
        ? Theme.palette.error.main
        : Theme.palette.black.main;

    const progWidth = (props.progWidth !== undefined)
        ? props.progWidth
        : 150;

    const lableFontWeight = props.boldLabel ? 500 : "normal";
    return (
        <>
            <span
                className='main-body-highlighted'
                style={{ color: textColor, fontWeight: lableFontWeight }}
            >
                {props.label + ': '}
            </span>

            {/*If we have Progressbar replacement information...*/}
            {   repl ?
                <>
                    <span className='usage-single-line-text'>

                        <span
                            className='a-type__body-new'
                            style={{ fontSize: '14px' }}
                        >
                            {repl.repLabel}
                        </span>

                        {/*If we have a status level of NA, do not show the button.*/}
                        {repl.replTipStatus !== StatusLevel.NA && _iconButton.current}

                    </span>

                    <span id={'Eat up second column of grid with this...'} />
                </>
                :
               <>
                    <LinearProgress
                        variant={'determinate'}
                        value={progBarValue}
                        sx={{
                            marginTop: '10px',
                            width: progWidth,
                            bgcolor: `${ctlColor}40`,
                            '& .MuiLinearProgress-bar': {
                                bgcolor: ctlColor,
                            }
                        }}
                    />
                    <span
                        className='a-type__body-new'
                        style={{ color: textColor }}
                    >
                        {props.text}
                    </span>
                </>
            }
        </>
    );
}

export default UsageItem;

