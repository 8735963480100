import { createBasicConfirmRequest, requestModal } from "../modals/ModalHelp";
import { StatusLevel } from '@csa-core/advisor.controlsystemcore'; // "../types/MessageTypes";
import { gotoConfigSelPage, gotoProjectURL } from '@csa-core/advisor.controlsystemcore'; // "../userProject/UserProjectHelp";
import { StartupDetails, StartupResult } from '@csa-core/advisor.controlsystemcore'; // "../util/AppStartupHelp";

// 2024.12.11 startupErrorExit and supporting code was
// moved from AppStartupHelp. AppStartupHelp will be
// migrated to the core, but startupErrorExit will NOT.

const _getStartErrorMsg = (result: StartupResult): string => {
    switch (result) {
        case StartupResult.InvalidCid:
            return 'Could not access requested configuration.';

        case StartupResult.GuidMismatch:
            return 'Requested configuration not located in requested project.';

        case StartupResult.InvalidConfigProj:
            return 'Could not access project information for requested configuration.';

        case StartupResult.InvalidProjGuid:
            return 'Could not access requested project.';

        case StartupResult.NoUserProj:
            return 'You must create/select a Project before you can create a Control System Component configuration.';

        case StartupResult.InvalidConfig:
            return 'Configuration is invalid and CANNOT be restored.';

        case StartupResult.BadPathPlatform:
            return 'Invalid product family specified.';

        case StartupResult.BadBusCodePlatform:
            return 'Invalid Business code specified.';

        case StartupResult.NoPlatform:
            return 'Failed to determine platform for configuration.';

        default:
            throw new Error('Unrecognized result in _getStartErrorMsg!');
    }
}

interface ExitCBData {
    result: StartupResult;
    dtls: StartupDetails;
}

const _callbackForExit = (status: number, data?: object) => {
    status;
    const cbData = data as ExitCBData;
    if (cbData) {
        switch (cbData.result) {
            case StartupResult.InvalidCid:
            case StartupResult.InvalidConfig:
            case StartupResult.BadPathPlatform:
            case StartupResult.BadBusCodePlatform:
            case StartupResult.NoPlatform:
                //gotoProjectURL(cbData.dtls.userProj?.guid);
                // Per Colin 2024.04.09
                gotoConfigSelPage();
                break;

            case StartupResult.GuidMismatch:
            case StartupResult.InvalidConfigProj:
            case StartupResult.InvalidProjGuid:
                gotoConfigSelPage();
                break;

            case StartupResult.NoUserProj:
            default:
                gotoProjectURL();
        }
    }
    else {
        throw new Error('Missing startup dtls in _callbackForExit');
    }
}

export const startupErrorExit = (result: StartupResult, dtls: StartupDetails) => {

    let msg = _getStartErrorMsg(result);
    if (dtls.csaVersion)
        msg += ` <csa version ${dtls.csaVersion}>`;

    const data: ExitCBData = {
        result: result,
        dtls: dtls
    }

    const modalReq = createBasicConfirmRequest(
        msg,
        'Startup Error',
        _callbackForExit,
        data,
        StatusLevel.Error
    );

    // Remove the buttons...
    modalReq.includeButtons = false;

    requestModal(modalReq);
}