import React, { useState } from 'react';
import {
    addButton,
    ModalRequestSpec,
    requestModal
} from '../modals/ModalHelp';
import SimpleCombo from '../selection/SimpleCombo';
import { ModalStatus, selectPlatformCallback } from '@csa-core/advisor.controlsystemcore'; // '../util/PlatformHelp';
import { PlatformCLX } from '@csa-core/advisor.controlsystemcore'; // './PlatformConstants';


interface PlatformSelExchData {
    platforms: string[];
    selection: string;
    clientCallback: selectPlatformCallback;
}

const _reqCallback = (status: number, data?: object) => {

    const exchData = data as PlatformSelExchData;

    let platform = '';
    if (status === ModalStatus.Confirmed) {
        platform = exchData.selection;
    }

    if (!platform) {
        platform = exchData.platforms[0];
    }

    exchData.clientCallback(platform);
}

export const selectPlatform = (
    platforms: string[],
    onSelectCallback: selectPlatformCallback
) => {
    switch (platforms.length) {
        case 0:
            onSelectCallback(PlatformCLX);
            return;

        case 1:
            onSelectCallback(platforms[0]);
            return;

        default:
            break;
    }

    const exchData: PlatformSelExchData = {
        platforms: platforms,
        selection: platforms[0],
        clientCallback: onSelectCallback
    }

    const modalReq: ModalRequestSpec = {
        includeButtons: true,
        closeOnInsideClick: false,
        stayOpenOnBackdropClick: true,
        title: 'Select Platform',
        callback: _reqCallback,
        requestorData: exchData,
        content: PlatformSelector
    }

    addButton(modalReq, 'Select', ModalStatus.Confirmed, 'contained');

    requestModal(modalReq);
}

const _getFirstPlatformChoice = (propsModal: ModalRequestSpec): string => {
    const exchData = propsModal.requestorData as PlatformSelExchData;
    return exchData.platforms[0];
}

export const PlatformSelector = (propsModal: ModalRequestSpec) => {

    const [platform, setPlatform] = useState(_getFirstPlatformChoice(propsModal));

    const exchData = propsModal.requestorData as PlatformSelExchData;

    const onSelChanged = (newSel: string) => {
        exchData.selection = newSel;
        setPlatform(newSel);
    }

    return (
        <SimpleCombo
            selection={platform}
            choices={exchData.platforms}
            selectionChanged={onSelChanged}
        />
    );
}
