import React, { useEffect, useRef, useState } from 'react';
import { ChassisProject } from '@csa-core/advisor.controlsystemcore'; // '../types/ProjectTypes';
import { getPlatformProjectInfo, PlatformProjInfo } from '../util/ProjInfoHelp';
import './ProjInfoBrief.scss';
import '../styles/Meteor.scss';
import { IconButton, Tooltip } from '@mui/material';
import { ExternalLinkIcon } from '@rockwell-automation-inc/ra-meatball';
import { PlatformCLX } from '@csa-core/advisor.controlsystemcore'; // '../platforms/PlatformConstants';

interface Props {
	info: ChassisProject;
	platform: string;
}


export const ProjInfoSettings = (props: Props) => {
	const [ isMounted, setMounted] = useState(false);
	const [renderCnt, setRenderCnt] = useState(0);
	// Start our info as CLX - it will be updated...
	const info = useRef<PlatformProjInfo>(getPlatformProjectInfo(PlatformCLX));

	useEffect(() => {
		setMounted(true);
		return (() => { setMounted(false); });
	}, []);


	// --- ^ All hooks above here ^ -----


	if (info.current.platform !== props.platform) {
		info.current = getPlatformProjectInfo(props.platform);
		if (isMounted)
			setRenderCnt(renderCnt + 1);
	}

	const onPlatformInfo = () => {
		if (info.current.url) {
			const newWin = window.open(info.current.url, info.current.title);
			if (newWin)
				newWin.focus();
		}
	}
	return (
		<div className="proj-info-setting">
			{info.current.img
				? <img
					style={{ height: '97px' }} 
					className="proj-info-setting-image"
					src={info.current.img}
				/>
				:
				null
			}

			{info.current.title ?
				<div className="proj-info-setting-title-section a-type__subTitle2">
					<div className="proj-info-setting-platform-title">
						{info.current.title}
					</div>
					<Tooltip title='Platform Information'>
						<IconButton
							color='primary'
							onClick={onPlatformInfo}
						>
							<ExternalLinkIcon />
						</IconButton>
					</Tooltip>
				</div>
				:
				null
			}

			{info.current.infoText ?
				<div className="proj-info-setting-platform-information a-type__body">
					{info.current.infoText}
				</div>
				:
				null
			}

			<div style={{ marginTop: '20px', marginLeft: '5px', borderBottom: '1px solid rgba(0,0,0, 0.3)', width: '95%', maxWidth: '300px' }} />

		</div>
	);
}
