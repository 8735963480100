import React from 'react';
import './FloatingDetails.scss';
import { Chassis } from '@csa-core/advisor.controlsystemcore'; // '../../types/ProjectTypes';
import { getChassisName } from '@csa-core/advisor.controlsystemcore'; // '../../model/ChassisProject';
import { DetailGroup, makeSingletonDtlGroup } from '@csa-core/advisor.controlsystemcore'; // '../../model/DeviceDetail';
import { Button } from '@mui/material';
import DetailsGroup from './DetailsGroup';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const getCatDescGrp = (chassis: Chassis): DetailGroup | undefined => {
    if (chassis.isPlaceholder) {
        return undefined;
    }

    const descInfo = chassis.catNo + ' - ' + chassis.description;
    return makeSingletonDtlGroup('Chassis', descInfo);
}


interface Props {
    chassis: Chassis;
    onClickEdit: () => void;
}

const DetailsChassisProduct = (props: Props) => {

    const catDescGrp = getCatDescGrp(props.chassis);

    return (
        <div className='floating-details-chassis-product'>
            <div className='floating-details-chassis-name'>
                {getChassisName(props.chassis)}
            </div>
            <Button
                disableRipple
                variant={'text'}
                startIcon={<EditOutlinedIcon />}
                onClick={props.onClickEdit}
                sx={{
                    textTransform: 'none',
                    justifyContent: 'left',
                    padding: '4px 4px'
                }}
            >
                Edit Chassis Settings
            </Button>
            {catDescGrp
                ? < DetailsGroup
                    group={catDescGrp}
                />
                : null}
        </div>
    );
}

export default DetailsChassisProduct;
