import React from 'react';
import { StandardMenuItemDetail } from './StandardMenuItem';
import MenuForButtons from './MenuForButtons';
import { Button, Tooltip } from '@mui/material';
import { TooltipButtonProps } from '@csa-core/advisor.controlsystemcore'; // '../types/TooltipButtonProps';

interface Props {
    buttonProps: TooltipButtonProps;
    menuItems: StandardMenuItemDetail[];
    onTBAction: (action: string) => void;
}

const StandardButtonWithMenu = (props: Props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const menuIsOpen = Boolean(anchorEl);

    const onClickButton = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Tooltip title={props.buttonProps.tip}>
                <Button
                    {...props.buttonProps}
                    onClick={onClickButton}
                />
            </Tooltip>
            <MenuForButtons
                menuItems={props.menuItems}
                isOpen={menuIsOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                onTBAction={props.onTBAction}
            />
        </>
    );

}

export default StandardButtonWithMenu;

