import React, { ReactNode } from 'react';
import './ModalStyles.scss';
import { ModalButtonInfo } from './ModalHelp';
import ModalButton from './ModalButton';
import { ModalStatus } from '@csa-core/advisor.controlsystemcore';

const _defaultConfirmText = 'OK';
const _defaultCancelText = 'CANCEL';


const getButtonInfo = (propSpec?: ModalButtonInfo[]): ModalButtonInfo[] => {

    if (propSpec && propSpec.length) {
        return propSpec;
    }

    const info = new Array<ModalButtonInfo>();
    info.push({
        btnText: _defaultCancelText,
        resultStatus: ModalStatus.Cancelled,
        variant: 'outlined'
    });
    info.push({
        btnText: _defaultConfirmText,
        resultStatus: ModalStatus.Confirmed,
        variant: 'contained'
    });
    return info;
}


interface Props {
    btnSpecs?: ModalButtonInfo[];
    onClickBtn: (resultStat: number) => void;
}

const createButtonGroup = (info: ModalButtonInfo[], callback: (resultStat: number) => void, position: string): ReactNode[] => {
	const btns =
        info.map((info, index) => {
            // Default to right when undefined.
            const placement = (info.placement ? info.placement : 'right'); 
            if (placement === position) {
				return (<ModalButton
					key={index}
					btnInfo={info}
                    onClickBtn={callback}
				/>);
			}
        })

    return btns.filter(x => x !== undefined);
}

const ModalButtonRow = (props: Props) => {

    const btnInfo = getButtonInfo(props.btnSpecs);

    const buttonsLeft = createButtonGroup(btnInfo, props.onClickBtn, 'left');
    const buttonsCenter = createButtonGroup(btnInfo, props.onClickBtn, 'center');
    const buttonsRight = createButtonGroup(btnInfo, props.onClickBtn, 'right');

    return (
        <div className='modal-button-row'>
            <div id='btnDivLeft' className="modal-button-row-left" >{buttonsLeft}</div>
            <div id='btnDivCenter' className="modal-button-row-center" >{buttonsCenter}</div>
            <div id='btnDivRight' className="modal-button-row-right" >{buttonsRight}</div>
        </div>
    );
}

export default ModalButtonRow;

